import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import { Category } from 'app/models/category';
import { IColumnData, IDialogData, IInputType } from "app/models/dialog-interface";
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { Term } from 'app/models/term';
import { CategoryService } from 'app/services/category.service';
import { GlossaryService } from 'app/services/glossary.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarHandler } from 'utilities/snackbar';
import { TermService } from '../../services/term.service';
import { TermStatusService } from 'app/services/term-status.service';
import { TermStatus } from 'app/models/term-status';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'dp-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [ TermService, GlossaryService, CategoryService, TermStatusService ]
})
export class CategoryComponent {

  serviceData: any;
  id: string;
  id2: string;
  ref: boolean;
  tableDetails: ITableDetails;
  editModalData: any[];
  categoryName: Observable<Category>;
  private destroy = new Subject<void>();
  private TermStatuses: TermStatus[] = new Array<TermStatus>();
  termStatusDropdown: IInputType = {
    type: "dropdown",
    dropdownOptions: []
  }

  columns: IColumns[] = [
    {
      columnDef: "TermId",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.TermId}`
    },
    {
      columnDef: "TermName",
      header: "Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.TermName}`
    },
    {
      columnDef: "TermDescription",
      header: "Description",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.TermDescription}`
    },
    {
      columnDef: "UpdatedDate",
      header: "Last Updated",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.UpdatedDate.toString().replace("T", " ")}`
    }
  ];


  ngOnInit(){
    this.categoryName.pipe(takeUntil(this.destroy)).subscribe(category => {
      this.glossaryService.getGlossaryById(category.BusinessGlossaryId).subscribe(glossary => {
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Business Glosssary", glossary.BusinessGlossaryName, category.CategoryName], ["business-glossary/", "business-glossary/glossary/" + glossary.BusinessGlossaryId, "business-glossary/glossary/" + glossary.BusinessGlossaryId + "/category/" + category.CategoryId]);
      })
    })

  }

  ngOnDestroy(){
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  constructor(private route: ActivatedRoute, private termStatusService: TermStatusService, private termService: TermService, private router: Router,
    private authService: AuthService, private dialog: MatDialog, private snackBarHandler: SnackBarHandler, 
      private routeBreadcrumbService: RouteBreadcrumbService, private glossaryService: GlossaryService, private categoryService: CategoryService, private sharedService: SharedService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.id2 = this.route.snapshot.paramMap.get('id2');
    let routingPath = ["/business-glossary/glossary/" + this.id + "/category/","term"];
    this.categoryName = this.categoryService.getCategoryById(Number(this.id2));

    this.TermStatuses = this.termStatusService.getTermStatus();
    this.termStatusDropdown.dropdownOptions = [];
    for(var i = 0; i < this.TermStatuses.length; i++){
      this.termStatusDropdown.dropdownOptions.push(this.TermStatuses[i].Status);
    }
    this.termStatusDropdown.dropdownOptions.sort();

    this.tableDetails = { routing: {routingPath: routingPath, firstId: this.id2}, edit: false };
    this.serviceData = this.authService.getFakeSubscribe([]);
    this.authService.authorizeSection().subscribe(permissions =>{
      if(permissions.view){
        var details = { routing: {routingPath: routingPath, firstId: this.id2}, edit: permissions.update }
        if(permissions.add){
          details['add'] = "Term";
        }
        if(permissions.delete)
          details['delete'] = "TermName";

        this.tableDetails = details;
        this.serviceData = this.termService.getTermByCategoryId(Number(this.id2));
        this.ref = true;
      }
      else {
        this.router.navigate(['/']);
      }
    });
    this.ref = false;
  }

  resetRefresh(value: boolean) {
    this.ref = value;
  }

  editModal({ Id, TermName, TermDescription, TermStatus }: Term) {
    var statusName = this.TermStatuses.find(x => x.Id == TermStatus).Status;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "CategoryId",
        label: -1,
        value: this.id2,
        validators: []
      },
      {
        id: "TermId",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "TermName",
        label: "Name",
        value: TermName,
        validators: ["required"]
      },
      {
        id: "TermDescription",
        label: "Description",
        value: TermDescription,
        validators: ["required"]
      },
      {
        id: "TermStatusName",
        label: "Term Status",
        value: statusName,
        validators: ["required"],
        inputType: this.termStatusDropdown
      }
    ];
    let data: IDialogData = {
      description: "Edit " + TermName,
      numCols: 2,
      columnData: columnData
    }
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(EditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.TermStatus = this.TermStatuses.find(x => x.Status == result.TermStatusName).Id;
        this.termService.saveTerm(result).subscribe(savedTerm => {
          if (savedTerm) {
            this.sharedService.sendBGUpdateRequest(); //Sends the request to update the BG nav
            this.snackBarHandler.open(`Success: Edited '${result.TermName}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Term not edited`, 'failure'));
      }
    });

  }

  viewModal({ TermName, TermDescription, UpdatedDate, TermStatus }: Term) {
    var statusName = this.TermStatuses.find(x => x.Id == TermStatus).Status;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "TermName",
        label: "Name",
        value: TermName,
        validators: ["required"]
      },
      {
        id: "TermDescription",
        label: "Description",
        value: TermDescription,
        validators: ["required"]
      },
      {
        id: "TermStatusName",
        label: "Term Status",
        value: statusName,
        validators: ["required"]
      },
      {
        id: "UpdatedDate",
        label: "Last Updated",
        value: UpdatedDate,
        validators: ["required"]
      }
    ];
    let data: IDialogData = {
      description: "View " + TermName,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }


  addModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "CategoryId",
        label: -1,
        value: this.id2,
        validators: []
      },
      {
        id: "TermId",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "TermName",
        label: "Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "TermDescription",
        label: "Description",
        value: "",
        validators: ["required"]
      },
      {
        id: "TermStatusName",
        label: "Term Status",
        value: "",
        validators: ["required"],
        inputType: this.termStatusDropdown
      }
    ];
    let data = {
      description: "Add Term",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(AddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.TermStatus = this.TermStatuses.find(x => x.Status == result.TermStatusName).Id;
        this.termService.saveTerm(result).subscribe(savedTerm => {
          if (savedTerm) {
            this.sharedService.sendBGUpdateRequest(); //Sends the request to update the BG nav
            this.snackBarHandler.open(`Success: Added '${result.TermName}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Term not added`, 'failure'));
      }
    });

  }

  deleteRows(rows: number[]) {
    for (let row of rows) {
      this.termService.deleteTerm(row).subscribe(result => {
        this.sharedService.sendBGUpdateRequest(); //Sends the request to update the BG nav
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.ref = true;
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }

}
