import { Injectable } from '@angular/core';
const csv = require('papaparse');

@Injectable()
export class FileHandler {

  constructor() { }
  /**
  * Reads a csv from a file input
  * @param file
  * @param Header
  * @param Delimiter
  * @param TextQualifier
  */
  readLocalCsv(file: any, Header: boolean = true, Delimiter: string = ",", TextQualifier: string = `"`): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        csv.parse(file, {
          header: Header,
          skipEmptyLines: true,
          delimiter: Delimiter,
          quoteChar: TextQualifier,
          complete: function (result, file) {
            resolve(result.data);
          }
        });

      }
      catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Reads a csv from a url
   * @param fileUrl
   * @param Header
   * @param Delimiter
   * @param TextQualifier
   */
  readWebCsv(fileUrl: string, Header: number = 1, Delimiter: string = ",", TextQualifier: string = `"`): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        csv.parse(fileUrl, {
          download: true,
          delimiter: Delimiter,
          quoteChar: TextQualifier,
          complete: function (result, file) {
            resolve(result.data);
          }
        });
      } catch (err) {
        reject(err);
      }
    });
  }


}
