
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SSISGeneratorService {
    private apiPath = 'api/SSISGenerator';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Links to SSIS
     * @param tableTableId
     * @author John Crabill
     */
    linkToSSIS(tableTableId: number){
        return this.http.get(`${this.requestUrl}?Id=${tableTableId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
