/**
 * Generated Jobs
 * @author John Crabill
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { GeneratedJobsService } from 'app/services/generatedjobs.service';
import { Subject } from 'rxjs';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SnackBarHandler } from 'utilities/snackbar';
import { IColumns, ITableDetails } from "app/models/table-interfaces";

@Component({
    selector: 'dp-generated-jobs',
    templateUrl: './generatedjobs.component.html',
    providers: [GeneratedJobsService, SnackBarHandler],
    styleUrls: ['./generatedjobs.component.scss']
})

export class GeneratedJobsComponent implements OnInit {
    serviceData: any;
    ref: boolean;
    tableDetails: ITableDetails = { edit: false, noView: true, checkbox: true, downloadButton: true };
    columns: IColumns[] = [
        {
          columnDef: "PackageName",
          header: "Package Name",
          sortable: true,
          filterable: true,
          cell: (element: any) => `${element.PackageName}`
        },
        {
          columnDef: "TimeStamp",
          header: "Time Stamp",
          sortable: true,
          filterable: true,
          cell: (element: any) => `${element.TimeStamp != null ? element.TimeStamp.substring(0, 10) : ''}`
        }
    ];

    constructor(
      private generatedJobsService: GeneratedJobsService,
      private authService: AuthService, private routeBreadcrumbService: RouteBreadcrumbService, 
      private snackBarHandler: SnackBarHandler,
      private router: Router) { 
        this.authService.authorizeSection().subscribe(permissions =>{
            if(permissions.view){
                this.serviceData = this.generatedJobsService.getJobs();
            }
            else {
              this.router.navigate(['/']);
            }
        });
        this.ref = false;
      }

    ngOnInit() {
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Generated Jobs"], ["generated-jobs/"]);
    }

    /**
     * Download all in selectedPackages
     * @author John Crabill
     */
    private downloadLinks(jobNames) {
        for (var i = 0; i < jobNames.length; i++) {
            this.downloadLink({PackageName: jobNames[i]});
        }
    }

    /**
     * Sends to new window for download of Generated Jobs
     * @author John Crabill
     */
    private downloadLink(job) {
        this.generatedJobsService.downloadJob(job.PackageName)
            .subscribe(data => {
                this.downloadUri("data:application/zip;base64," + data, job.PackageName);
            },
            err => {
                console.log(err);
                this.snackBarHandler.open(`Failed to download ${job.PackageName}`, 'failure');
            });
    }

    /**
     * Adds uri link
     * @param uri
     * @param name
     * @author John Crabill
     */
    private downloadUri(uri: string, name: string) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
