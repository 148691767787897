
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SourceSystem } from 'app/models/source-system';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';
import { environment } from '../../environments/environment';
import { SourceSystemNew } from '../resources/SourceSystemNew';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable()
export class SourceSystemService {
    private apiPath = 'api/EDWSourceSystem';
    private apiConn = 'api/Connection';
    private requestUrl = environment.serviceUrl + this.apiPath;
    private connUrl = environment.serviceUrl + this.apiConn;

    constructor(private http: HttpClient) { }

    /**
     * Gets all Source Systems
     * @author Collin Atkins / 11.1.17
     */
    getAllSourceSystems(basic: boolean = false): Observable<any> {
        return this.http.get(this.requestUrl + '?basic=' + basic, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all displayed Source Systems columns in a JSON
     */
    getDisplayedSourceSystemsJSON(basic: boolean = false): Observable<SourceSystemNew[]> {
        let sourceSystems: SourceSystemNew[] = [];

        return this.http.get(this.requestUrl + '?basic=' + basic, httpOptions).pipe(
            map(res => {
                let sourceSystems: SourceSystemNew[] = [];

                let jsonArrayObj: object[] = res as object[];

                jsonArrayObj.forEach(sourceSystemObject => {
                    sourceSystems.push(new SourceSystemNew(sourceSystemObject['Id'],
                        sourceSystemObject['Name'], sourceSystemObject['DBVendorName'],
                        sourceSystemObject['DBHostName'], sourceSystemObject['DBPort']));
                });


                return sourceSystems;
            })
        );

        // return this.http.get(this.requestUrl + '?basic=' + basic, httpOptions).pipe(
        //     catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets source system by id
     * @param Id
     * @author Collin Atkins / 10.19.17
     */
    getSourceSystemById(Id: number): Observable<any> {
        return this.http.get(`${this.requestUrl}?Id=${Id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Save the Source System from modal
     * @param SourceSystem SourceSystem - source system to save
     */
    saveSystem(SourceSystem: SourceSystem): Observable<any> {
        return this.http.put(this.requestUrl, SourceSystem, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Tests the connection
     * @author John Crabill
     * @param SourceSystem SourceSystem - source system to scheck
     */
    testConnection(SourceSystem: SourceSystem) {
        return this.http.put(this.connUrl, SourceSystem, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    deleteSourceSystem(id: number): Observable<any> {
      return this.http.delete(`${this.requestUrl}?Id=${id}`, httpOptions).pipe(
        catchError(new ErrorHandler().handleError));
    }

}
