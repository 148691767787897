/**
 * Collapsible Tree - Collapsible Tree Graphic created using D3 js
 * @author Collin Atkins / 12.6.17 / Overhauled data creation functionality to work with new dataLineage format. Changed tree graphics to use
 *      outlined text rather than circles. Created custom d3Tip to show extra information on node hover. Fixed bugs of not showing
 *      initial tree display.
 * @author Collin Atkins / 12.12.17 / Changed tree to use new data lineage query which traverses all the way up/down the lineage.
 *      Tree now is populated then flipped in reverse to show proper tree. Duplicates are now filtered from the tree.
 */
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataLineageService } from 'app/services/datalineage.service';
import { TableMappingService } from 'app/services/table-mapping.service';
import { TreeData } from 'app/models/tree-data';
import { TargetColumn } from 'app/models/target-column';
import { DataLineage } from 'app/models/datalineage';

@Component({
    selector: 'dp-collapsible-tree',
    templateUrl: './collapsible-tree.component.html',
    styleUrls: ['collapsible-tree.component.scss'],
    providers: [DataLineageService, TableMappingService],
    encapsulation: ViewEncapsulation.ShadowDom
})

export class CollapsibleTreeComponent implements OnChanges {

    private treeData: TreeData;

    constructor(private dataLineageService: DataLineageService, private tableMappingService: TableMappingService) { }

    // Called whenever a component input is changed
    ngOnChanges() {
        this.initTreeRoot();
        this.getDataLineage();
    }

    /**
     * Initializes the tree data
     * @author Collin Atkins / 12.6.17
     */
    private initTreeRoot() {
        this.treeData = new TreeData(`${this.TargetColumn.DatabaseName}`, `Root`);
    }

    //#region Data Lineage

    @Input() TargetColumn: TargetColumn = new TargetColumn();
    @Input() width: number = 900;
    @Input() height: number = 600;

    private DataLineageArray: DataLineage[] = new Array<DataLineage>();

    /**
     * Creates TreeData node from the dataLineage given
     * @param dataLineage
     * @author Collin Atkins / 12.6.17
     */
    private createDataLineageNode(dataLineage: DataLineage): TreeData {
        return new TreeData(dataLineage.TargetSchemaName, dataLineage.TargetTableName, dataLineage.TargetColumnName,
            `Target Schema: ${dataLineage.TargetSchemaName}\nTarget Table: ${dataLineage.TargetTableName}\nTarget Column: ${dataLineage.TargetColumnName}`);
    }

    /**
     * Creates TreeData node for the root node showing sources rather than target
     * @param dataLineage
     * @author Collin Atkins / 12.6.17
     */
    private createDataLineageLeafNode(dataLineage: DataLineage): TreeData {
        return new TreeData(dataLineage.SourceSystemName, dataLineage.SourceTableName, dataLineage.SourceColumnName,
            `Table Mapping: ${dataLineage.TableMapping.Name}\nSource System: ${dataLineage.SourceSystemName}\nSource Table: ${dataLineage.SourceTableName}\nSource Column: ${dataLineage.SourceColumnName}`);
    }

    /**
     * Gets dataLineage from dataLineageService by targetColumnId. Thens adds them to the tree and draws the tree.
     * @author Collin Atkins / 12.11.17
     */
    private getDataLineage() {
        this.dataLineageService.getDataLineageByTargetColumnId(this.TargetColumn.Id)
            .subscribe(dataLineage => {
                this.DataLineageArray = dataLineage;
                if(dataLineage.length > 0){
                    this.getTableMappings(dataLineage[0].TargetTableId);
                }
                else {
                    this.reverseAddDataLineageArray();
                    this.drawTree();
                }
            }, err => console.log(err));
    }

    /**
     * Retrieves the Target Mappings for the Source Columns attached to the Target Columns
     * @param targetTableId 
     */
    private getTableMappings(targetTableId) {
        this.tableMappingService.getTableMappingsByTargetTableId(targetTableId)
            .subscribe(tableMappings => {
                for(var i = 0; i < this.DataLineageArray.length; i++){
                    this.DataLineageArray[i].TableMapping = tableMappings.find(item => item.Id == this.DataLineageArray[i].TableMapId)
                }
                this.reverseAddDataLineageArray();
                this.drawTree();
            }, err => console.log(err));
    }

    /**
     * Filters duplicate dataLineages. A duplicate lineage is one with the same target schema, table, column; without regarding the source etc.
     * @param dataLineages
     * @author Collin Atkins / 12.11.17
     */
    private filterDuplicates(dataLineages: DataLineage[]): DataLineage[] {
        let uniqueLineages: DataLineage[] = new Array<DataLineage>();
        dataLineages.forEach(dataLineage => {
            if (uniqueLineages.length == 0 || !uniqueLineages.find(dl => dataLineage.TargetSchemaName == dl.TargetSchemaName && dataLineage.TargetTableId == dl.TargetTableId && dataLineage.TargetColumnId == dl.TargetColumnId)) {
                uniqueLineages.push(dataLineage);
            }
        });
        return uniqueLineages;
    }

    /**
     * Calculates the maxOrder of the dataLineages then begins the loop to add all dataLineages
     * @author Collin Atkins / 12.11.17
     */
    private reverseAddDataLineageArray() {
        let maxOrder: number = Math.max.apply(Math, this.DataLineageArray.map(dl => dl.Order));
        this.reverseAddDataLineage(this.treeData, maxOrder);
    }

    /**
     * Creates treeData object in reverse by starting from maxOrder given and filtering all
     *      dataLineages by order and moving down the order tree each time.
     * @param order Order of dataLineages to add
     * @param parent Current parent of treeData to add children to
     * @author Collin Atkins / 12.11.17
     */
    private reverseAddDataLineage(parent: TreeData, order: number) {
        let dataLineages: DataLineage[] = this.filterDuplicates(this.DataLineageArray.filter(dl => dl.Order == order));
        if (dataLineages && dataLineages.length > 0) {
            order -= 1;
            dataLineages.forEach(dataLineage => {
                parent.children.push(this.createDataLineageNode(dataLineage));
                this.reverseAddDataLineage(parent.children[parent.children.length - 1], order);
            });
        }
        else {
            dataLineages = this.DataLineageArray.filter(dl => dl.Order == order + 1);
            dataLineages.forEach(dataLineage => {
                parent.children.push(this.createDataLineageLeafNode(dataLineage));
            });
        }
    }

    //#endregion

    //#region D3 Collapsible Tree

    @ViewChild('tree') private treeContainer: ElementRef;

    // Dimmensions of tree svg
    private margin: any = { top: 0, right: 0, bottom: 0, left: 0 };
    private transformXY: any = { x: 100, y: 0 };

    // Time length of transition
    private duration: number = 750;
    // Node index
    private i: number = 0;
    // Tree objects
    private root; private tree; private svg; private tip;

    /**
     * Toggles data children on click
     * @param d - data to toggle
     * @author Collin Atkins / 12.6.17
     */
    private click(d) {
        if (!d.children) {
            d.children = d._children;
            d._children = null;
        } else {
            d._children = d.children;
            d.children = null;
        }
        this.updateTree(d);
    }

    /**
     * Creates the d3 collapsible tree
     * @author Collin Atkins / 12.6.17
     */
    private createTree() {
        this.defineRoot();

        // d3 tree of size
        this.tree = d3.tree().size([this.height - this.margin.top - this.margin.bottom, this.width - this.margin.left - this.margin.right]);

        // svg object appended onto html
        this.svg = d3.select(this.treeContainer.nativeElement).append('svg')
            .attr('width', this.width + 100)
            .attr('height', this.height)
            .append('g')
            .attr('transform', 'translate(' + this.transformXY.x + ',' + this.transformXY.y + ')');

        this.tip = d3Tip()
            .attr('style', 'z-index: 1100 !important; position: absolute; color: rgba(255, 255, 255, 0.925);background: rgb(39, 39, 54); white-space: pre-line; font-size: 12px;')
            .html(function (d) {
                return '<span> ' + d.data.TooltipText + '</span>';
            });

        this.svg.call(this.tip);
    }

    /**
     * Sets root of hierarchy of d3 tree data
     * @author Collin Atkins / 12.6.17
     */
    private defineRoot() {
        this.root = d3.hierarchy(this.treeData, function (d) { return d.children; });
        this.root.x0 = this.height / 2;
        this.root.y0 = this.width;
    }

    private diagonal(s, d) {
        return `M ${s.y} ${s.x}
                C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;
    }

    /**
     * Chooses which mode to edit the tree
     * @author Collin Atkins / 12.6.17
     */
    private drawTree() {
        if (!this.tree || !this.root || !this.svg) {
            this.createTree();
        }
        this.updateTree();
    }

    /**
     * Draws the d3 js tree structure
     * @param source - data source to update table
     * @author Collin Atkins
     */
    private updateTree(source?) {
        // Sets source as root if no source is defined
        if (!source) {
            this.defineRoot();
            source = this.root;
        }

        // Assigns the x and y position for the nodes
        const rootData = this.tree(this.root);

        // Compute the new tree layout.
        const nodes = rootData.descendants(),
            links = rootData.descendants().slice(1);

        // Normalize for fixed-depth.
        nodes.forEach(d => { d.y = this.width - (d.depth * 180) });

        // ****************** Nodes section ***************************

        // Update the nodes...
        const node = this.svg.selectAll('g.node')
            .data(nodes, d => d.id = d.id ? d.id : ++this.i);

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node.enter().append('g')
            .attr('class', d => d.depth == 0 ? 'node root' : 'node')
            .attr('transform', function (d) { return 'translate(' + source.y0 + ',' + source.x0 + ')'; })
            .on('click', d => this.click(d))
            .on('mouseover', this.tip.show)
            .on('mouseout', this.tip.hide);

        // Add labels for the nodes
        // Seperated into multiple text elements now to support more browsers (chrome specifically)
        nodeEnter.append('text')
            .text(d => d.data.TextLine1)
            .append('tspan')
            .text(d => d.data.TextLine2)
            .attr('dy', '1.2em')
            .attr('x', '0')
            .append('tspan')
            .text(d => d.data.TextLine3)
            .attr('dy', '1.2em')
            .attr('x', '0')
            .append('rect');

        // Adds a background to the text
        
        nodeEnter.insert('rect', 'text')
            .attr('x', function () { return this.parentNode.getBBox().x - 5 })
            .attr('y', function () { return this.parentNode.getBBox().y - 5 })
            .attr('width', function () { return this.parentNode.getBBox().width + 10 })
            .attr('height', function () { return this.parentNode.getBBox().height + 10 })
            .style('fill', 'white');

        // UPDATE
        const nodeUpdate = nodeEnter.merge(node);

        // Transition to the proper position for the node
        nodeUpdate.transition()
            .duration(this.duration)
            .attr('transform', d => {
                return 'translate(' + d.y + ',' + d.x + ')';
            });

        // Updates background color based on if it contains children
        nodeUpdate.select('rect', 'text')
            .style('fill', function (d) { return d._children ? 'rgb(210, 223, 234)' : 'white' });

        // Remove any exiting nodes
        const nodeExit = node.exit().transition()
            .duration(this.duration)
            .attr('transform', function (d) {
                return 'translate(' + source.y + ',' + source.x + ')';
            })
            .remove();

        // On exit reduce the opacity of text label and outline
        nodeExit.select('text')
            .style('fill-opacity', 1e-6)
            .style('outline-style', 'none');
        // On exit reduce the opacity of the text's background
        nodeExit.select('rect')
            .style('fill-opacity', 1e-6);

        // ****************** links section ***************************

        // Update the links...
        const link = this.svg.selectAll('path.link')
            .data(links, function (d) { return d.id; });

        // Enter any new links at the parent's previous position.
        const linkEnter = link.enter().insert('path', 'g')
            .attr('class', d => d.depth == 1 ? 'link root' : 'link')
            .attr('d', d => {
                const o = { x: source.x0, y: source.y0 };
                return this.diagonal(o, o);
            });

        // UPDATE
        const linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
            .duration(this.duration)
            .attr('d', d => {
                return this.diagonal(d, d.parent);
            });

        // Remove any exiting links
        const linkExit = link.exit().transition()
            .duration(this.duration)
            .attr('d', d => {
                const o = { x: source.x0, y: source.y0 };
                return this.diagonal(o, o);
            })
            .remove();

        // Store the old positions for transition.
        nodes.forEach(function (d) {
            d.x0 = d.x;
            d.y0 = d.y;
        });

        this.svg.select('g.root').remove();
        this.svg.selectAll('path.root').remove();
    }

    //#endregion

}
