import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TeamCreateComponent  } from 'app/business-glossary/team/team-create/team-create.component'
import { Term } from 'app/models/term';
import { TeamService} from 'app/services/team.service';
import { TeamUserService} from 'app/services/team-user.services';
import { DataTableDirective } from 'angular-datatables';
import { TeamUser} from 'app/models/team-user';
import { Team } from 'app/models/team';
import { SnackBarHandler } from '../../../utilities/snackbar';
import { Subject } from 'rxjs';
import { TermService } from 'app/services/term.service';


@Component({
  selector: 'dp-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [ TeamService, TeamUserService]

})
export class TeamComponent implements OnInit {
  @ViewChild(DataTableDirective)
  //@Input() Term: Term = new Term();
  @Input() Term: Term = new Term();

private dtElement: DataTableDirective;
private dtTrigger: Subject<TeamUser[]> = new Subject();
private users: TeamUser[] = new Array<TeamUser>();
private team: Team = new Team();



  constructor(private dialog: MatDialog,
        private teamService: TeamService,
        private teamUserService: TeamUserService,
        private termService: TermService,
        private snackBarHandler: SnackBarHandler 
  ) { }

  ngOnInit() {
//this.users = this.Term.TermTeam.TeamUsers;
this.team = this.Term.TermTeam;


  }

  private deleteTeamUser(user: TeamUser){
  
    this.teamUserService.deleteTeamUser(user.TeamId, user.TeamUserId)
        .subscribe(tempuser => {
            this.snackBarHandler.open('Team User was deleted.', 'success');
            this.rerender();
            this.getTerm();
            this.dtTrigger.next();        

        }, err => {
            console.log(err);
            this.snackBarHandler.open('Team User failed to be deleted.', 'failure');
        });
}

private rerender() {
  if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
      });
  }
}

private reset(){
 
  this.rerender();
  this.getTerm();
  this.dtTrigger.next();


}

private getTermTeam(id:number){
  this.teamService.getTeamById(id)
  .subscribe(team => {
      this.team = team;
}, err => console.log(err));

}
 /**
     * @author John Crabill / 1.3.18
     */
    private openTeamCreateDialog() {
      this.dialog.open(TeamCreateComponent, {
      })
          .afterClosed().subscribe(result => {
              if (result) {

              }
          });
  }

  private getTerm(){
  
    this.termService.getTermById(this.Term.TermId)
      .subscribe(term => {
          this.Term = term;
          this.team = this.Term.TermTeam;
        
    }, err => console.log(err));
  
  }
  
  

}
