import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class SourceSystemNew {

  constructor(id, name, dbvendorname, dbhostname, dbport){
    this.ID = id;
    this.Name = name;
    this.DBVendorName = dbvendorname;
    this.DBHostName = dbhostname;
    this.DBPort = dbport;
  }


  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  ID: number;

  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  Name: string;

  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  DBVendorName: string;

  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  DBHostName: number;

  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  DBPort: number;
}
