
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar'
import { Router } from '@angular/router';

export class ErrorHandler {
    /**
     * Handles Errors that occur
     * @param error
     */

    private snackbar: SnackBarHandler;
    private router: Router

    handleError(error): Observable<never> {
        let errMsg: string;
        if (error instanceof Response) {
            switch (error.status) {
                case 401:
                    errMsg = `Not Authenticated`;
                    this.router.navigateByUrl('/login');
                    break;
                default:
                    errMsg = `${error.statusText || 'Request Failed'}`;
                    break;
            }
        } else {
            //errMsg = error.message ? error.message : error.toString();
            errMsg = error.message ? error.message : error.json()['ExceptionMessage'];
        }
        return observableThrowError(errMsg);
    }

    handleSqlError(error: any): Observable<never> {
        let errMsg: string = error.json()['ExceptionMessage'];
        return observableThrowError(errMsg);
    }

}
