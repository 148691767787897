/**
 * Dialog for Adding/Editing a Schema
 * @author Collin Atkins / 12.14.17
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { SchemaService } from 'app/services/schema.service';
import { Schema } from 'app/models/schema';

@Component({
    selector: 'edit-schema-dialog',
    templateUrl: 'edit-schema-dialog.component.html',
    styleUrls: ['edit-schema-dialog.component.scss'],
    providers: [SchemaService]
})

export class EditSchemaDialogComponent implements OnInit {

    private Schema: Schema = new Schema();
    private passwordChanged: boolean = false;
    private isNameReadonly: boolean = false;
    private DBTYPES: string[] = ['ORACLE', 'SQL SERVER'];
    
    constructor(private dialogRef: MatDialogRef<EditSchemaDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private schemaService: SchemaService, private snackBarHandler: SnackBarHandler) { }

    ngOnInit() {
        this.isNameReadonly = this.data && this.data.Schema;
        this.Schema = this.data && this.data.Schema ? Object.assign({}, this.data.Schema) : new Schema();
        this.passwordChanged = false;
    }

    private testConnection() {
        this.schemaService.testConnection(this.Schema)
            .subscribe(conn => {
                if (conn == "Success") {
                    this.snackBarHandler.open('Connection Found', 'success');
                }
                else if (conn == "Failed") {
                    this.snackBarHandler.open('Connection Failed', 'failure');
                }
            }, err => console.log(err));
    }
}