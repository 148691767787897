import { ColumnLineage } from "app/models/column-lineage";

export class TargetColumn {
    Id: number;
    EDWTableId: number;
    DatabaseName: string;
    FriendlyName: string;
    BaseDataType: string;
    Precision: number;
    Scale: number;
    CharType: string;
    FullDataType: string;
    IsNullable: boolean;
    NullableFlag: string;
    IsPrimaryKey: boolean;
    PrimaryKeyFlag: number;
    DataLength: number;
    ColumnLineages: ColumnLineage[];
    hasLink: boolean;
    show: boolean;

    constructor(tableId: number = -1) {
        this.Id = -1;
        this.EDWTableId = tableId;
        this.BaseDataType = '';
        this.IsNullable = false;
        this.IsPrimaryKey = false;
        this.show = false;
    }
}
