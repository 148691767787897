/**
 * Job History Object
 * @author Collin Atkins / 9.27.17 / Added LastRunDate for date filtering, added missing JobParams
 */
import { JobHistoryRow } from "app/models/jobhistoryrow";
import { JobParam } from "app/models/jobparam";

export class JobHistory {

    Id: number;
    TargetTable: string;
    TargetTableId: number;
    LastProcessDateTime: Date;
    LastProcessDateJulian: number;
    LastProcessTimeJulian: number;
    CurrentProcessDateTime: Date;
    CurrentProcessDateJulian: number;
    CurrentProcessTimeJulian: number;
    isNextLoadFull: boolean;
    useCDCFlag: boolean;
    truncateOnNextLoad: boolean;
    useNullValueReplacement: boolean;
    trimFields: boolean;
    DateTypeValue: number;
    FileType: string;
    ServiceName: string;
    RunNext: boolean;
    JobHistorys: JobHistoryRow[];
    FileTypeExt: string;
    nextFullLoad: string;
    runNextFlag: string;
    udcFlag: string;
    truncate: string;
    UseJulianDateFormat: boolean;
    UTId: number;
    ETId: number;
    LastRunDate: Date;
    JobParams: JobParam[];

    constructor(tableName: string = '', tableId: number = -1) {
        this.Id = -1;
        this.TargetTable = tableName;
        this.TargetTableId = tableId;
        this.CurrentProcessDateTime = null;
        this.UTId = -1;
        this.JobParams = new Array();

        this.isNextLoadFull = false;
        this.useCDCFlag = false;
        this.truncateOnNextLoad = false;
        this.useNullValueReplacement = true;
        this.trimFields = true;
        this.UseJulianDateFormat = false;
    }
}
