/**
 * Modal to add a related term
 * @author Collin Atkins / 10.4.17 / Overhauled the ui and cleaned up the code
 * @author Collin Atkins / 10.10.17/ Fixed terms not filtering related terms properly, added default select options
 */
import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { SnackBarHandler } from 'utilities/snackbar';
import { TermTypeService } from 'app/services/term-type.service';
import { TermService } from 'app/services/term.service';
import { GlossaryService } from 'app/services/glossary.service';
import { CategoryService } from 'app/services/category.service';
import { RelatedTermService } from 'app/services/related-term.service';
import { Term } from 'app/models/term';
import { RelatedTerm } from 'app/models/related-term';
import { Glossary } from 'app/models/glossary';
import { Category } from 'app/models/category';
import { TermType } from 'app/models/term-type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dp-related-term-modal',
    templateUrl: './related-term-modal.component.html',
    providers: [TermService, GlossaryService, CategoryService,
         RelatedTermService, SnackBarHandler, TermTypeService ],
    styleUrls: ['./related-term-modal.component.scss']
})

export class RelatedTermModalComponent implements OnInit {

    private currentTermRelations: Array<Term> = new Array();
    private ParentTerm: Term = new Term();
    private RelatedTerm: RelatedTerm = new RelatedTerm();
    private Glossary: Glossary = new Glossary();
    private Category: Category = new Category();
    private TermTypes: TermType[] = new Array<TermType>();
    private ReserveTermTypes: TermType[] = new Array<TermType>();
    private categories: any[];

    private DefaultCategory: Category = new Category();

    constructor(private glossaryService: GlossaryService, private categoryService: CategoryService, private relatedTermService: RelatedTermService,
        private termService: TermService, private termTypeService: TermTypeService, private snackBarHandler: SnackBarHandler,
        private dialogRef: MatDialogRef<RelatedTermModalComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

    ngOnInit() {
        if (this.data && this.data.Term.Id) {
            this.ParentTerm = this.data.Term;
            this.currentTermRelations = this.ParentTerm.RelatedTerms;

            this.RelatedTerm.RelatedTermParentId = this.ParentTerm.Id;
            this.getGlossaryById();
            this.getCategories(this.ParentTerm.Category.BusinessGlossaryId);
            this.loadTermTypes();
        } else {
            this.dialogRef.close();
            this.snackBarHandler.open('Invalid parameters', 'danger');
        }
    }

    /**
     * @author Collin Atkins
     */
    private clearFields() {
        this.ParentTerm = new Term();
        this.RelatedTerm = new RelatedTerm();
        this.Glossary = new Glossary();
        this.Category = new Category();
    }

    /**
     * Filters related terms to remove terms already related
     * @param category
     * @author Collin Atkins / 10.10.17 / Moved to its own function and fixed it splicing wrong terms
     */
    private filterRelatedTerms() {
        this.ParentTerm.RelatedTerms.forEach(parentRelatedTerm => {
            let relatedTermIndex: number = this.Category.Terms.findIndex(term => term.Id != parentRelatedTerm.Id);
            if (relatedTermIndex > 0 && this.Category.Terms.length > 0) {
                this.Category.Terms.splice(relatedTermIndex - 1, 1);
            }
        });
    }

    /**
     * Gets category by id, then filters the terms
     * @param categoryId
     * @author Collin Atkins / 10.4.17 / Added related term filter
     */
    private getCategoryById(categoryId: number) {
        if (categoryId && categoryId >= 0) {
            this.categoryService.getCategoryById(categoryId)
                .subscribe(category => {
                    this.Category.Terms = category.Terms;
                    if (this.ParentTerm.RelatedTerms && this.Category.Terms.length > 0) {
                        this.filterRelatedTerms();
                    }
                }, err => console.log(err))
        }
    }

    /**
     * Gets glossary by id
     */
    private getGlossaryById() {
        this.glossaryService.getGlossaryById(this.ParentTerm.Category.BusinessGlossaryId)
            .subscribe(glossary => {
                this.Glossary = glossary;
            }, err => console.log(err));
    }

    private getCategories(glossaryId) {
        if (glossaryId && glossaryId >= 0) {
            this.categoryService.getCategoriesByGlossaryId(glossaryId)
                .subscribe(categories => {
                    this.categories = categories;
                }, err => console.log(err));
        }
    }

    /**
     * Filters out already made relation types for that term relation
     * @author John Crabill / 12.15.17
     */
    private filterRelationType(term){
        this.TermTypes = this.ReserveTermTypes;
        var newTermTypes: TermType[] = new Array<TermType>();
        var types = new Array();

        if(this.TermTypes != null){
            for(var i = 0; i < this.TermTypes.length; i++){
                types.push({type: this.TermTypes[i].Id, value: true, position: i})
            }
        }

        if(this.currentTermRelations != null){
            for(var i = 0; i < this.currentTermRelations.length; i++){
                if(this.currentTermRelations[i].Id == term.Id){
                    for(var j = 0; j < types.length; j++){
                        if(types[j].type == this.currentTermRelations[i].TermType){
                            types[j].value = false;
                        }
                    }
                }
            }

            for(var i = 0; i < types.length; i++){
                if(types[i].value){
                    newTermTypes.push(this.ReserveTermTypes[types[i].position]);
                }
            }
            this.TermTypes = newTermTypes;
        }
    }

    /**
     * Loads the term types into the termTypes array
     */
    private loadTermTypes() {
        this.ReserveTermTypes = this.termTypeService.getTermTypes();
        this.TermTypes = this.termTypeService.getTermTypes();
    }

    /**
     * Saved relation of current term to term selected
     * @author Collin Atkins / 10.4.17 / Removed glut and moved variable saving to ngModel
     */
    private saveRelation() {
        this.relatedTermService.saveRelatedTerm(this.RelatedTerm)
            .subscribe(term => {
                this.snackBarHandler.open(`${term.ChildTerm.TermName} was saved.`, 'success');
                this.dialogRef.close(term);
            }, err => {
                this.dialogRef.close();
                console.log(err);
                this.snackBarHandler.open('Relation failed to save.', 'failure');
            });
    }

}
