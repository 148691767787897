import { Component, EventEmitter, Output } from '@angular/core';
import { SourceSystem } from 'app/models/source-system';
import { BaseTableComponent } from 'app/shared/components/base/base-table/base-table.component';

@Component({
  selector: 'dp-source-systems-table',
  templateUrl: './source-systems-table.component.html',
  styleUrls: ['./source-systems-table.component.scss']
})
export class SourceSystemsTableComponent extends BaseTableComponent {

  @Output() onTestConnection = new EventEmitter<SourceSystem>();

  testConnection(system: SourceSystem){
    this.onTestConnection.emit(system);
  }
}
