export class SourceColumn {
    Id: number;
    SourceTableId: number;
    SourceColumnId: number;
    DatabaseName: string;
    Order: number;
    FriendlyName1: string;
    FriendlyName1Conv: string;
    FriendlyName2: string;
    FriendlyName2Conv: string;
    BaseDataType: string;
    Precision: number;
    Scale: number;
    CharType: string;
    FullDataType: string;
    NullableFlag: string;
    EDWColumnLineageAlias: string;
    FilterValues: string;
    FilterOperator: string;
    SourceFilter: string;

    constructor(SourceTableId: number = -1, order: number = null) {
        this.Id = -1;
        this.SourceColumnId = -1;
        this.SourceTableId = SourceTableId;
        this.NullableFlag = 'N';
        this.Order = order;
    }
}
