// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var url = 'https://metaprism-edwservices.azurewebsites.net/';
export const environment = {
    releaseVersion: '1.1.0',
    production: true,
    serviceUrl: url //'https://api.metaprism.com/'  //'http://teamtng02.thenormandygroup.com:8311/' //'http://teamtng02.thenormandygroup.com:8311/' //'http://localhost:8311/' //'http://taacaiis01:8311/' //'http://teamtng02.thenormandygroup.com:8311/' //'http://taacaiis01:8311/' // //'http://teamtng02.thenormandygroup.com:8311/'  //dev //'http://localhost:56132/' //admin //'http://teamtng01.thenormandygroup.com:8310/' //prod
};

