/**
 * Source Column Property Editor Modal - Filter Editor Tab
 * @author Collin Atkins / 10.19.17 / Overhauled to use chips instead of a plain text field
 */
import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { MatChipInputEvent } from '@angular/material';
import { OperatorTypeService } from 'app/services/operatortype.service';
import { OperatorType } from 'app/models/operatortype';
import { ColumnLineage } from 'app/models/column-lineage';

// Key codes to delimit filter list input
const ENTER: number = 13;
const COMMA: number = 188;

@Component({
    selector: 'dp-filter-editor',
    templateUrl: './filter-editor.component.html',
    providers: [ OperatorTypeService ],
    styleUrls: ['./filter-editor.component.scss']
})

export class FilterEditorComponent implements OnInit {

    @Input() columnLineage: ColumnLineage = new ColumnLineage();
    private separatorKeysCodes = [ENTER, COMMA];
    private filters: string[] = new Array<string>();
    private operatorTypes: OperatorType[] = new Array<OperatorType>();

    constructor(private operatorTypeService: OperatorTypeService) { }

    ngOnInit() {
        this.getOperatorTypes();
        this.columnLineage.OperatorType = this.columnLineage.OperatorType || new OperatorType();
        this.filters = this.columnLineage.ColumnFilters ? this.columnLineage.ColumnFilters.split(',') : new Array<string>();
    }

    /**
     * Adds filter to array, converts array to string, then resets the input
     * @param event
     * @author Collin Atkins / 10.19.17
     */
    private addFilter(event: MatChipInputEvent) {
        let input = event.input;
        let value = event.value;

        if ((value || '').trim()) {
            this.filters.push(value.trim());
            this.columnLineage.ColumnFilters = this.filters.toString();
        }

        if (input) {
            input.value = '';
        }
    }

    /**
     * Calls OperatorTypeService to get all existing OperatingTypes
     * @author Collin Atkins / 10.20.17
     */
    private getOperatorTypes() {
        this.operatorTypeService.getOperatorTypes()
            .subscribe(operators => {
                this.operatorTypes = operators
            }, err => console.log(err));
    }


    /**
     * Removes filter from array, then converts the array to a string
     * @param filter 
     * @author Collin Atkins / 10.19.17
     */
    private removeFilter(filter: string) {
        let index = this.filters.indexOf(filter);

        if (index >= 0) {
            this.filters.splice(index, 1);
            this.columnLineage.ColumnFilters = this.filters.toString();
        }
    }

}
