
import {catchError} from 'rxjs/operators';
/**
 * Data Lineage Service
 * @author Collin Atkins / 12.5.17
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class DataLineageService {
    private apiPath = 'api/DataLineage';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets the Data Lineage by ColumnLineageId
     * @param columnLineageId
     * @author Collin Atkins / 12.5.17
     */
    getDataLineage(columnLineageId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?columnLineageId=' + columnLineageId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets the Data Lineage by targetColumnId
     * @param targetColumnId
     * @author Collin Atkins / 12.8.17
     */
    getDataLineageByTargetColumnId(targetColumnId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?targetColumnId=' + targetColumnId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
