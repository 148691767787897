
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { TeamRole } from 'app/models/team-role';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TeamRoleService {
    private apiPath = 'api/TeamRole';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Save the Team ROle
     * @param Note: Team
     */
    saveTeamRole(teamRole: TeamRole): Observable<any> {
        return this.http.put(this.requestUrl, teamRole, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }
    getTeamRoles(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));

    }

}
