
import {catchError} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserUtility } from 'utilities/user-utility';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Auth0User } from 'app/models/Auth0User';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class GlossaryService {
    private apiPath = 'api/BGBusinessGlossary';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient, private authService:AuthService) {
    }

    deleteGlossary(glossaryId): Observable<any> {
        return  this.http.delete(`${this.requestUrl}?id=${glossaryId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all Source Systems
     */
    getGlossaries(): Observable<any> {
        return this.http.get(this.requestUrl).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets a Glossary by Id with all associated Categories
     * @param id
     */
    getGlossaryById(id): Observable<any> {
        return this.http.get(`${this.requestUrl}?id=${id}`).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Save the Source System from modal
     */
    saveGlossary(glossary): Observable<any> {
        this.authService.getUserJwt().subscribe(jwt =>{
          if(glossary.Id < 0){
            glossary.CreatedUser = new Auth0User();
            glossary.CreatedUser.userId = jwt["https://metaprism.com/user_id"];
            glossary.CreatedUserId = glossary.CreatedUser.userId;
          }
          glossary.UpdatedUser = new Auth0User();
          glossary.UpdatedUser.userId = jwt["https://metaprism.com/user_id"];
          glossary.UpdatedUser.name = jwt["name"];
          glossary.UpdatedUserId = glossary.UpdatedUser.userId;
      });
        return this.http.put(this.requestUrl, glossary).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
