import { User } from "app/models/user";
import { Term } from "app/models/term";
import { Auth0User } from './Auth0User';

export class Category {

    Id: number;
    CategoryId: number;
    BusinessGlossaryId: number;
    CategoryName: string;
    CategoryDescription: string;
    CreatedDate: Date;
    UpdatedDate: Date;
    CreatedUser: Auth0User;
    UpdatedUser: Auth0User;
    ParentCategory: Category;
    UsageContext: string;
    ParentCategoryId: number;
    Terms: Term[];

    constructor(glossaryId: number = -1) {
        this.Id = -1;
        this.CategoryId = -1;
        this.BusinessGlossaryId = glossaryId;
    }
}
