
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth0User } from 'app/models/Auth0User';
import { RelatedTerm } from 'app/models/related-term';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class RelatedTermService {
    private apiPath = 'api/BGRelatedTerms';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient, private authService: AuthService) { }

    /**
     * Save the Term from modal
     * @param relatedTerm: RelatedTerm
     */
    saveRelatedTerm(relatedTerm: RelatedTerm): Observable<any> {
      this.authService.getUserJwt().subscribe(jwt =>{
        if(relatedTerm.Id < 0){
          relatedTerm.CreatedUser = new Auth0User();
          relatedTerm.CreatedUser.user_id = jwt["https://metaprism.com/user_id"];
          relatedTerm.CreatedUser.name = jwt["name"];
          relatedTerm.CreatedUser.email = jwt["email"];
          relatedTerm.CreatedUserId = relatedTerm.CreatedUser.user_id;
        }
        relatedTerm.UpdatedUser = new Auth0User();
        relatedTerm.UpdatedUser.user_id = jwt["https://metaprism.com/user_id"];
        relatedTerm.UpdatedUser.name = jwt["name"];
        relatedTerm.UpdatedUser.email = jwt["email"];
        relatedTerm.UpdatedUserId = relatedTerm.UpdatedUser.user_id;
      });
        return this.http.put(this.requestUrl, relatedTerm, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
