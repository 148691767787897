import { Component, OnInit } from '@angular/core';
import { animateText, onSideNavChange } from '../animations/animations';
import { SidenavService } from '../services/sidenav.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [ onSideNavChange, animateText]
})
export class LeftMenuComponent implements OnInit {

  public sideNavState: boolean = false;
  public linkText: boolean = false;
  private permissions = [];


  constructor(private _sidenavService: SidenavService, private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.authService.getUserPermissions().subscribe(permissions =>{
      if(permissions == undefined) 
        this.retryPermissionCall(1);
      else 
        this.permissions = permissions;
    });
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  retryPermissionCall(counter){
    this.authService.getUserPermissions().subscribe(permissions =>{
      
      if(permissions == undefined && counter < 30) 
        this.delay(1000).then(() => this.retryPermissionCall(counter + 1));
      else 
        this.permissions = permissions;
    });
  }

  showIcon(target){
    if(this.permissions == undefined) return false;
    if(this.permissions.includes("admin:user")) return true;

    if (target == 'source'){
      if(this.permissions.includes("lineageeditor:user")) return true;
    } else if (target == 'schema'){
      if(this.permissions.includes("lineageeditor:user")) return true;
    } else if (target == 'data-lineage'){
      if(this.permissions.includes("lineageeditor:user")) return true;
    } else if (target == 'business-glossary'){
      if(this.permissions.includes("businessglossary:user")) return true;
    } else if (target == 'business-glossary-publisher'){
      if(this.permissions.includes("businessglossary:user")) return true;
    } else if (target == 'global-search') {
      return true;
    } else if (target == 'filebased'){
      if(this.permissions.includes("lineageeditor:user")) return true;
    } else if (target == 'job-history') {
      if(this.permissions.includes("jobhistory:user")) return true;
    } else if (target == 'home') {
      return true;
    } else if (target == 'users') {
      //Only Admins should have access, no permission granted 
    } else if (target == 'generated-jobs') {
      //Only Admins should have access, no permission granted
    }

    return false;
  }


  public toggleNavChild() {
    this.sideNavState = !this.sideNavState;

    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)
    this._sidenavService.sideNavState$.next(this.sideNavState)
  }
  routeByKeyboard(target) {
    if (target == 'source'){
      this.router.navigateByUrl('/source');
    } else if (target == 'schema'){
      this.router.navigateByUrl('/schema');
    } else if (target == 'data-lineage'){
      this.router.navigateByUrl('/data-lineage');
    } else if (target == 'filebased'){
      this.router.navigateByUrl('/filesource');
    } else if (target == 'business-glossary'){
      this.router.navigateByUrl('/business-glossary');
    } else if (target == 'business-glossary-publisher'){
      this.router.navigateByUrl('/business-glossary-publisher');
    } else if (target == 'global-search') {
      this.router.navigateByUrl('/global-search');
    } else if (target == 'job-history') {
      this.router.navigateByUrl('/job-history');
    } else if (target == 'home') {
      this.router.navigateByUrl('/');
    } else if (target == 'users') {
      this.router.navigateByUrl('/users') 
    } else if (target == 'generated-jobs') {
      this.router.navigateByUrl('/generated-jobs')
    }
    
  }
}
