import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NotesService} from 'app/services/notes.service';
import { Note } from 'app/models/note';
import { Term } from 'app/models/term';
import { MatDialog } from '@angular/material';
import { NoteModalComponent} from 'app/business-glossary/term/notes/note-modal/note-modal.component';
import { SnackBarHandler } from '../../../../utilities/snackbar';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'dp-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [ NotesService ]
})
export class NotesComponent implements OnInit {

  @Input() Term: Term = new Term();
  @ViewChild(DataTableDirective)

  private dtElement: DataTableDirective;
  private dtTrigger: Subject<Note[]> = new Subject();
  private dtOptions: DataTables.Settings = {};

  private notes: Note[] = new Array<Note>();

  constructor(private noteService: NotesService,
    private dialog: MatDialog,
    private snackBarHandler: SnackBarHandler  ) { }

  ngOnInit() {

    /**
      this.dtOptions = {
        columnDefs: [{
          targets: [4,5],
          orderable: false
      }]

    };
    **/
    
    this.initTables();
  }
  private deleteNote(note){
      this.noteService.deleteNote(note.Id)
          .subscribe(term => {
              this.snackBarHandler.open('Note was deleted.', 'success');
              this.getNotes();
          }, err => {
              console.log(err);
              this.snackBarHandler.open('Note failed to be deleted.', 'failure');
          });
  
  }
  private initTables(){
//    this.rerender();
    this.noteService.getNotesByParentId(this.Term.Id)
    .subscribe(notes => {
        this.notes = notes;
        this.dtTrigger.next();        
    }, err => console.log(err));
    

  }
  private getNotes(){
    this.rerender();
    this.noteService.getNotesByParentId(this.Term.Id)
      .subscribe(notes => {
          this.notes = notes;
    }, err => console.log(err));

  }
 /**
     * @author Shawn Carter / 2.17.20
     */
    private openNotesDialog(term?: Term) {
      this.dialog.open(NoteModalComponent, {
          data: { Term: term},
      })
          .afterClosed().subscribe(result => {
              if (result) {
                  this.getNotes();
                  
              }
          });
  }
  private rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
        });
    }
}

}
