
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class JobDashboardService {

  private apiPath: string = 'api/EDWDashboard';
  private requestUrl = environment.serviceUrl + this.apiPath;
  private runDateString: string;
  private params: HttpParams;
  private requestOptions: {};

  constructor(private http: HttpClient) { }

  /**
   * Gets the status of Jobs on a date input by the user
   * @param runDate Run data of SSIS Packages
   */
  getJobStatus(runDate: Date): Observable<any> {
    // Set up a RequestOptions object with the runDateString parameter
    this.params = new HttpParams();
    this.params.append("runDateString", runDate.toLocaleDateString("en-US"));
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      params: this.params
    };

    // Make the "get" request to the backend, handle the response
    return this.http.get(this.requestUrl, httpOptions).pipe(
      catchError(new ErrorHandler().handleError));
  }

}
