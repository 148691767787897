import { SourceTable } from "app/models/source-table";

export class SourceMasterTableJoin extends SourceTable {

    JoinId: number;
    JoinAlias: string;

    constructor(sourceTable: SourceTable, joinId: number = null, joinAlias: string = null) {
        super();

        this.JoinId = joinId;
        this.JoinAlias = joinAlias;

        this.Id = sourceTable.Id;
        this.SourceSystemId = sourceTable.SourceSystemId;
        this.DatabaseName = sourceTable.DatabaseName;
        this.SchemaName = sourceTable.SchemaName;
        this.FriendlyName = sourceTable.FriendlyName;
        this.TablespaceName = sourceTable.TablespaceName;
        this.NumRowStats = sourceTable.NumRowStats;
        this.NumRowSelect = sourceTable.NumRowSelect;
        this.SourceColumns = sourceTable.SourceColumns;
        this.TablePrefix = sourceTable.TablePrefix;
        this.FileDelimiter = sourceTable.FileDelimiter;
        this.TextQualifier = sourceTable.TextQualifier;
        this.FileSourceUrl = sourceTable.FileSourceUrl;
        this.FirstLineHeader = sourceTable.FirstLineHeader;
        this.FileType = sourceTable.FileType;
    }

}
