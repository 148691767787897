
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth0User } from 'app/models/Auth0User';
import { Category } from 'app/models/category';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class CategoryService {
    private apiPath = 'api/BGCategory';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient, private authService: AuthService) { }

    deleteCategory(categoryId): Observable<any>{
        return this.http.delete(`${this.requestUrl}?id=${categoryId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    deleteCategoryCascade(category: Category){
        return this.http.put(this.requestUrl, category, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all Categories
     * Unused: delete if no use is found
     */
    getCategories(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets a Category by Id with all associated terms
     * @param Id
     */
    getCategoryById(Id: number): Observable<any> {
        return this.http.get(`${this.requestUrl}?id=${Id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getCategoriesByGlossaryId(Id): Observable<any> {
        return this.http.get(`${this.requestUrl}?id=${Id}&optional=${true}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Save the Source System from modal
     */
    saveCategory(category): Observable<any> {
      this.authService.getUserJwt().subscribe(jwt =>{
        if(category.Id < 0){
          category.CreatedUser = new Auth0User();
          category.CreatedUser.userId = jwt["https://metaprism.com/user_id"];
          category.CreatedUser.name = jwt["name"];
          category.CreatedUser.email = jwt["email"];
          category.CreatedUserId = category.CreatedUser.userId;
        }
        category.UpdatedUser = new Auth0User();
        category.UpdatedUser.userId = jwt["https://metaprism.com/user_id"];
        category.UpdatedUser.name = jwt["name"];
        category.UpdatedUser.email = jwt["email"];
        category.UpdatedUserId = category.UpdatedUser.userId;
      });
        return this.http.put(this.requestUrl, category, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
