import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { IColumnData, IDialogData } from "app/models/dialog-interface";
import { Glossary } from "app/models/glossary";
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Subject } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar';
import { GlossaryService } from '../../services/glossary.service';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';


@Component({
  selector: 'dp-business-glossary',
  templateUrl: './business-glossary.component.html',
  styleUrls: ['./business-glossary.component.scss'],
  providers: [GlossaryService]
})
export class BusinessGlossaryComponent {
  serviceData: any;
  ref: boolean;
  tableDetails: ITableDetails;
  editModalData: any[];
  private destroy = new Subject<void>();
  columns: IColumns[] = [
    {
      columnDef: "BusinessGlossaryId",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.BusinessGlossaryId}`
    },
    {
      columnDef: "BusinessGlossaryName",
      header: "Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.BusinessGlossaryName}`
    },
    {
      columnDef: "BusinessGlossaryDescription",
      header: "Description",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.BusinessGlossaryDescription}`
    },
    {
      columnDef: "UpdatedDate",
      header: "Last Updated",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.UpdatedDate.toString().replace("T", " ")}`
    }
  ];


  ngOnInit() {
    this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Business Glosssary"], ["business-glossary/"]);
  }

  ngOnDestroy() {
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  constructor(private glossaryService: GlossaryService, private sharedService:SharedService,
    private router: Router, private authService: AuthService, 
    private dialog: MatDialog, private snackBarHandler: SnackBarHandler, 
    private routeBreadcrumbService: RouteBreadcrumbService) {

    this.tableDetails = { routing: { routingPath: ["/business-glossary/glossary/"] }, edit: false };
    this.serviceData = this.authService.getFakeSubscribe([]);
    this.authService.authorizeSection().subscribe(permissions =>{
      if(permissions.view){
        var details = { routing: { routingPath: ["/business-glossary/glossary/"] }, edit: permissions.update }
        if(permissions.add){
          details['add'] = "Glossary";
        }
        if(permissions.delete){
          details['delete'] = "BusinessGlossaryName";
          details['deleteId'] = "BusinessGlossaryId";
        }
        this.tableDetails = details;
        this.serviceData = this.glossaryService.getGlossaries();
        this.ref = true;
      }
      else {
        this.router.navigate(['/']);
      }
    });
    this.ref = false;
  }

  resetRefresh(value: boolean) {
    this.ref = value;
  }

  editModal({ BusinessGlossaryId, BusinessGlossaryName, BusinessGlossaryDescription, UpdatedDate }: Glossary) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: BusinessGlossaryId,
        validators: []
      },
      {
        id: "BusinessGlossaryName",
        label: "Name",
        value: BusinessGlossaryName,
        validators: ["required"]
      },
      {
        id: "BusinessGlossaryDescription",
        label: "Description",
        value: BusinessGlossaryDescription,
        validators: ["required"]
      }
    ];
    let data: IDialogData = {
      description: "Edit " + BusinessGlossaryName,
      numCols: 2,
      columnData: columnData
    }
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(EditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.glossaryService.saveGlossary(result).subscribe(savedGlossary => {
          if (savedGlossary) {
            this.sharedService.sendBGUpdateRequest(); //Send request to update navigation
            this.snackBarHandler.open(`Success: Edited '${result.BusinessGlossaryName}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Glossary not edited`, 'failure'));
      }
    });

  }

  viewModal({ BusinessGlossaryId, BusinessGlossaryName, BusinessGlossaryDescription, UpdatedDate }: Glossary) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: "Id",
        value: BusinessGlossaryId,
        validators: []
      },
      {
        id: "Name",
        label: "Name",
        value: BusinessGlossaryName,
        validators: ["required"]
      },
      {
        id: "Description",
        label: "Description",
        value: BusinessGlossaryDescription,
        validators: ["required"]
      },
      {
        id: "LastUpdated",
        label: "Last Updated",
        value: UpdatedDate,
        validators: ["required"]
      }
    ];
    let data: IDialogData = {
      description: "View " + BusinessGlossaryName,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }


  addModal() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "BusinessGlossaryName",
        label: "Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "BusinessGlossaryDescription",
        label: "Description",
        value: "",
        validators: ["required"]
      }
    ];
    let data = {
      description: "Add Glossary",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(AddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.glossaryService.saveGlossary(result).subscribe(savedGlossary => {
          if (savedGlossary) {
            this.sharedService.sendBGUpdateRequest(); //Send request to update navigation
            this.snackBarHandler.open(`Success: Added '${result.BusinessGlossaryName}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Glossary not added`, 'failure'));
      }
    });

  }

  deleteRows(rows: number[]) {
    for (let row of rows) {
      this.glossaryService.deleteGlossary(row).subscribe(result => {
        this.sharedService.sendBGUpdateRequest(); //Send request to update navigation
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.ref = true;
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }

}
