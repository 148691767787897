import { Glossary } from "app/models/glossary";
import { Term } from "app/models/term";
import { Category } from "app/models/category";

export class TermLink {

    term: Term
    glossary: Glossary;
    category: Category;

    constructor() {
        this.term = new Term();
        this.glossary = new Glossary();
        this.glossary.BusinessGlossaryId = -1;
        this.category = new Category();
    }

}