
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { ColumnLineage } from 'app/models/column-lineage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class ColumnLineageService {
    private apiPath = 'api/EDWColumnLineage';
    private requestUrl = environment.serviceUrl + this.apiPath;

    private selectedLineages: ColumnLineage[];

    constructor(private http: HttpClient) { }

    /**
     * Deletees a single column lineage by Id
     * @param columnLineageId
     * @author Nash Lindley
     */
    deleteColumnLineage(columnLineageId: number): Observable<any> {
        return this.http.delete(`${this.requestUrl}?Id=${columnLineageId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Lineages given a TargetColumn
     * @param columnId target column id
     * @author Nash Lindley
     */
    getColumnLineageByColumn(columnId): Observable<any> {
        return this.http.get(this.requestUrl + '?edwColumnId=' + columnId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets ColumnLineages given a TargetColumnId
     * @param targetColumnId
     * @author Collin Atkins / 12.5.17
     */
    getColumnLineagesByTargetColumnId(targetColumnId): Observable<any> {
        return this.http.get(this.requestUrl + '?targetColumnId=' + targetColumnId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }
/**
     * Copies matching source columns to target columns
     * @param targetTable, sourceTableId
     * @author Collin Atkins / 12.5.17
     */
    CopyMapping(targetTableId, sourceTableId, tableMappingId): Observable<any> {
        return this.http.get(this.requestUrl + '?targetTableId=' + targetTableId
                + '&sourceTableId=' + sourceTableId
                + '&tableMappingId='+tableMappingId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }




    /**
     * Gets ColumnLineages given a target table name and target column name
     * @param tableName target table name
     * @param columnName target column name
     * @author Nash Lindley
     */
    getColumnLineageByTableAndColumn(tableName, columnName): Observable<any> {
        return this.http.get(this.requestUrl+ '?tableName=' + tableName + '&columnName=' + columnName + '&filter=SOURCE&detailLevel=All', httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets ColumnLineage Properties given a target table name and target column name
     * @param tableName target table name
     * @param columnName target column name
     * @author John Crabill
     */
    getColumnLineageProperties(id, targetId, sourceId): Observable<any> {
        return this.http.get(this.requestUrl + '?id=' + id + '&targetId=' + targetId + '&sourceId=' + sourceId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets ColumnLineages by TableMappingId
     * @param tableMappingId
     * @author Collin Atkins / 11.14.17
     */
    getColumnLineagesByTableMappingId(tableMappingId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?tableMappingId=' + tableMappingId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets ColumnLineages by TableMappingId and TargetColumnId
     * @param tableMappingId
     * @param targetColumnId
     * @author Collin Atkins / 11.15.17
     */
    getColumnLineagesByMappingIdAndColumnId(tableMappingId: number, targetColumnId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?tableMappingId=' + tableMappingId + '&targetColumnId=' + targetColumnId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a new ColumnLineage or updates an existing lineage
     * @param columnLineage: ColumnLineage
     * @author Nash Lindley
     */
    saveColumnLineage(columnLineage: ColumnLineage): Observable<any> {
        return this.http.put(this.requestUrl, columnLineage, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
