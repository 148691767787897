
import {startWith, finalize, map} from 'rxjs/operators';
/**
 * Dialog for Adding/Editing a Schema
 * @author Collin Atkins / 12.18.17
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { TableMapping } from 'app/models/table-mapping';
import { TableLineage } from 'app/models/table-lineage';
import { SourceTableJoin } from 'app/models/source-table-join';
import { OperatorType } from 'app/models/operatortype';
import { SourceTable } from 'app/models/source-table';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { OperatorTypeService } from 'app/services/operatortype.service';
import { TableLineageService } from 'app/services/table-lineage.service';
import { SourceTableJoinService } from 'app/services/source-table-join.service';

@Component({
    selector: 'sql-configurator-dialog',
    templateUrl: 'sql-configurator-dialog.component.html',
    styleUrls: ['sql-configurator-dialog.component.scss'],
    providers: [OperatorTypeService, SourceTableJoinService, TableLineageService]
})

export class SqlConfiguratorDialogComponent implements OnInit {

    private TableMapping: TableMapping = new TableMapping();
    private TableLineages: TableLineage[] = new Array<TableLineage>();
    private MasterTableLineage: TableLineage = new TableLineage();
    private SourceTableJoins: SourceTableJoin[] = new Array<SourceTableJoin>();
    private OperatorTypes: OperatorType[] = new Array<OperatorType>();

    private SourceTable: SourceTable = new SourceTable();
    private SourceTables: SourceTable[] = new Array<SourceTable>();
    private FilteredSourceTables: SourceTable[] = new Array<SourceTable>();
    private filteredSourceTablesObservable: Observable<SourceTable[]> = new Observable<SourceTable[]>();
    private sourceTablesControl: FormControl = new FormControl();

    constructor(private dialogRef: MatDialogRef<SqlConfiguratorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private snackBarHandler: SnackBarHandler, private operatorTypeService: OperatorTypeService, private tableLineageService: TableLineageService,
        private sourceTableJoinService: SourceTableJoinService, ) { }

    ngOnInit() {
        if (this.data && this.data.TableMapping && this.data.SourceTables) {
            this.getOperatorTypes();
            this.resetSourceTable();

            this.TableMapping = this.data && this.data.TableMapping ? Object.assign({}, this.data.TableMapping) : new TableMapping();
            this.SourceTables = this.data && this.data.SourceTables ? this.data.SourceTables.slice() : new Array<SourceTable>();//sourceTables.map(st => Object.assign({}, st));

            this.getTableLineages();
            this.getSourceTableJoins();
        } else {
            this.dialogRef.close();
            this.snackBarHandler.open('Invalid inputs', 'danger');
        }
    }

    /**
     * Creates a tableLineage from the selected sourceTable and saves it
     * @author Collin Atkins / 11.17.17
     */
    private addSourceTableJoin() {
        let tableLineage: TableLineage = new TableLineage(this.TableMapping.Id, this.TableMapping.TargetTableId, this.SourceTable.Id, this.TableMapping.SourceSystemId);
        this.tableLineageService.saveTableLineage(tableLineage)
            .subscribe(() => {
                this.snackBarHandler.open(`${this.SourceTable.FriendlyName} has been added.`, 'success');
                this.resetSourceTable();
                this.getSourceTableJoins();
            },
            err => {
                console.log(err);
                this.snackBarHandler.open(`Failed to add source table join.`, 'failure')
            });
    }

    /**
     * Maps sourceTable object to name for input
     * @param sourceTable
     * @author Collin Atkins / 10.12.17
     */
    private displaySourceTable(sourceTable: SourceTable): SourceTable | string {
        return sourceTable ? sourceTable.FriendlyName : sourceTable;
    }

    /**
     * Maps tableLineages to new array returned of SourceTableJoins
     * @author Collin Atkins / 10.17.17
     */
    private createJoins(tableLineages: TableLineage[]): SourceTableJoin[] {
        return tableLineages.map(tableLineage => {
            return new SourceTableJoin(tableLineage.Id, tableLineage.SourceTableId, tableLineage.SourceTable.FriendlyName);
        });
    }

    /**
     * Filters source tables input based on val entered
     * @param sourceTableName 
     * @author Collin Atkins / 10.12.17
     */
    private filterInput(sourceTableName: string): SourceTable[] {
        return this.FilteredSourceTables.filter(st => st.FriendlyName.toLowerCase().indexOf(sourceTableName.toLowerCase()) === 0);
    }

    /**
     * Filters master table and source table joins from SourceTables
     * @author Collin Atkins / 10.13.17
     * @author Collin Atkins / 10.16.17 / Combined filters into one function
     * @author Collin Atkins / 11.17.17 / Removed if preventing filter if master wasn't populated yet
     */
    private filterSourceTables() {
        this.FilteredSourceTables = this.SourceTables.filter(table => table.Id != this.MasterTableLineage.SourceTableId
            && !this.SourceTableJoins.some(stj => stj.SourceTableId === table.Id));

        this.filteredSourceTablesObservable = this.sourceTablesControl.valueChanges.pipe(startWith(null),
            map(input => (input && typeof (input) === 'string') ? this.filterInput(input) : this.FilteredSourceTables.slice()),);
    }

    /**
     * Calls OperatorTypeService to get all existing OperatingTypes
     * @author Nash Lindley
     */
    private getOperatorTypes() {
        this.operatorTypeService.getOperatorTypes()
            .subscribe(operators => {
                this.OperatorTypes = operators
            }, err => console.log(err));
    }

    /**
     * Gets sourceTableJoins by tableMappingId then filters source table of existing joins
     * @author Collin Atkins / 11.17.17
     */
    private getSourceTableJoins() {
        this.sourceTableJoinService.getSourceTableJoinByTableMappingId(this.TableMapping.Id)
            .subscribe(sourceTableJoins => {
                this.SourceTableJoins = sourceTableJoins;
                this.filterSourceTables();
            }, err => console.log(err));
    }

    /**
     * Gets table lineages by tableMappingId then sets the master table
     * @author Collin Atkins / 11.17.17
     */
    private getTableLineages() {
        this.tableLineageService.getTableLineagesByTableMappingId(this.TableMapping.Id)
            .subscribe(tableLineages => {
                this.TableLineages = tableLineages;
                this.setMasterTable();
            }, err => console.log(err));
    }

    /**
     * Called when key is pressed on source table field
     * @param $event
     * @author Collin Atkins / 10.12.17
     */
    private inputSourceTable($event) {
        if ($event.key === 'Backspace') {
            this.resetSourceTable();
        }
    }

    /**
     * @author Collin Atkins / 10.12.17
     */
    private resetSourceTable() {
        this.SourceTable = new SourceTable(this.TableMapping.SourceSystemId);
    }

    /**
     * Saves each sourceTableJoin given and resolves with how many were saved
     * @author Collin Atkins / 10.17.17
     */
    private saveSourceTableJoins(joins: SourceTableJoin[] = this.SourceTableJoins): Promise<number> {
        return new Promise((resolve, reject) => {
            joins.forEach((join, index) => {
                this.sourceTableJoinService.saveSourceTableJoin(join).pipe(
                    finalize(() => {
                        if (joins.length - 1 == index) {
                            resolve(joins.length);
                        }
                    }))
                    .subscribe();
            });
        });
    }

    /**
     * Creates joins from tableLineages then saves each
     * @author Collin Atkins / 10.17.17
     */
    private saveTableLineages(joins: SourceTableJoin[] = this.SourceTableJoins) {
        this.saveSourceTableJoins(joins)
            .then(count => {
                this.snackBarHandler.open(`${count} join(s) were saved.`, 'success')
                this.getSourceTableJoins();
            })
            .catch(err => {
                console.log(err);
                this.snackBarHandler.open(`Join(s) failed to save.`, 'failure')
            });
    }

    /**
     * Saves selected tableLineage as the master table, sets it as the master table, then populates joins
     * @param tableLineage 
     * @author Collin Atkins / 11.17.17
     */
    private selectMasterTable(tableLineage: TableLineage) {
        tableLineage.isSourceTableMaster = true;
        this.tableLineageService.saveTableLineage(tableLineage)
            .subscribe(() => {
                this.setMasterTable();
                this.snackBarHandler.open(`${tableLineage.SourceTable.FriendlyName} has been saved as the master table.`, 'success');
                // Doesn't save additional lineages when there was only the one master table, or joins already exist
                if (this.TableLineages.length > 0 || this.SourceTableJoins.length > 0) {
                    this.saveTableLineages(this.createJoins(this.TableLineages));
                } else {
                    this.getSourceTableJoins();
                }
            }, err => {
                console.log(err);
                tableLineage.isSourceTableMaster = false;
                this.snackBarHandler.open(`Failed to save master table.`, 'failure');
            });
    }

    /**
     * If a table lineage is a master table it splices it and sets it as the master table, then filters out the master
     * @author Collin Atkins / 11.17.17
     */
    private setMasterTable() {
        if (this.TableLineages.some(tl => tl.isSourceTableMaster)) {
            let masterIndex: number = this.TableLineages.findIndex(tl => tl.isSourceTableMaster);
            if (masterIndex >= 0) {
                this.MasterTableLineage = this.TableLineages.splice(masterIndex, 1)[0];
            }
            this.filterSourceTables();
        }
    }

}