/**
 * Calculation Editor Tab
 * @author Collin Atkins / 11.9.17 / Completed overhaul to work similar to transformation tab overhaul, changed tableLineages to joins, added replace toggle
 * @author Collin Atkins / 11.20.17 / Added source column selector list and ability to add source columns
 * @author Collin Atkins / 11.27.17 / Changed to use table-lineage-list and add source columns from it
 * @author Collin Atkins / 12.1.17 / Added before/after toggle and improved ui
 */
import { Component, Input, OnInit } from '@angular/core';
import { ColumnLineage } from 'app/models/column-lineage';
import { Calculation } from 'app/models/calculation';
import { TableLineage } from 'app/models/table-lineage';
import { SourceColumn } from 'app/models/source-column';

@Component({
    selector: 'dp-calculation-editor',
    templateUrl: './calculation-editor.component.html',
    styleUrls: ['./calculation-editor.component.scss']
})

export class CalculationEditorComponent implements OnInit {

    //#region Variables

    @Input() TableLineages: TableLineage[] = new Array<TableLineage>();
    @Input() ColumnLineage: ColumnLineage = new ColumnLineage();

    private CalculationArray: Calculation[] = new Array<Calculation>();
    private Operators: Calculation[] = new Array<Calculation>();
    private IndexSelected: number = -1;
    private numericInput: number;

    private placement: string = 'After';
    private insertMode: string = 'Insert';

    constructor() { }

    ngOnInit() {
        this.getOperators();
        if (this.ColumnLineage.ColumnCalculation) {
            this.parseCalculation();
        }
    }

    //#endregion

    //#region Main Functions

    /**
     * Adds given calculation to the calculation array, sets the index to the added calc, then saves it
     * @param calculation
     * @author Collin Atkins / 11.9.17
     * @author Collin Atkins / 12.1.17 / Added new check for placement position
     */
    private addToCalculation(calculation: Calculation) {
        if (this.IndexSelected > -1) {
            let startIndex: number = (this.insertModeIsReplace() || !this.placementIsAfter()) ? this.IndexSelected : (this.IndexSelected + 1);
            let startOfArray: Calculation[] = this.CalculationArray.slice(0, startIndex);
            let endIndex: number = startOfArray.push(calculation) + (this.insertModeIsReplace() ? 0 : -1);
            let endOfArray: Calculation[] = this.CalculationArray.slice(endIndex);
            this.CalculationArray = startOfArray.concat(endOfArray);
            this.IndexSelected += this.insertModeIsReplace() ? 0 : 1;
        } else {
            this.CalculationArray.push(calculation);
            this.IndexSelected = this.CalculationArray.length - 1;
        }
        this.saveCalculation();
    }

    /**
     * Converts calculation array to string for database storage
     * @author Collin Atkins / 11.9.17
     * @author Collin Atkins / 11.20.17 / added column else if
     */
    private calculationToString(): string {
        return this.CalculationArray.map(calculation => {
            if (this.isColumn(calculation.Name)) {
                return (calculation.AltId && calculation.AltId >= 0) ? `[${calculation.AltId}.${calculation.Id}]` : `[${calculation.Id}]`;
            } else {
                return calculation.Name;
            }
        }).join(',');
    }

    /**
     * Converts calculation array to string for evaluating
     * @author Collin Atkins / 11.9.17
     */
    private calculationToEvalString(): string {
        let returnstring: string = this.CalculationArray.map(calculation => {
            if (this.isColumn(calculation.Name)) {
                return `${calculation.Id}`;
            } else {
                return calculation.Name;
            }
        }).join(' ');
        return returnstring;
    }

    /**
     * Clears calculation array
     * @author Collin Atkins / 11.9.17
     */
    private clearCalculation() {
        this.IndexSelected = -1;
        this.CalculationArray = new Array<Calculation>();
        this.saveCalculation();
    }

    /**
     * Deletes selected calculation
     * @author Collin Atkins / 11.9.17
     */
    private deleteSelected() {
        if (this.CalculationArray.length > 0) {
            this.CalculationArray.splice(this.IndexSelected, 1);
        }
        this.IndexSelected = -1;
        this.saveCalculation();
    }

    /**
     * Inputs a source column from the source column list into the calculation
     * @param sourceColumn
     * @author Collin Atkins / 11.17.17
     */
    private inputColumn(sourceColumnInterface: any) {
        var name = '';
        var secondId = -1;
        if (sourceColumnInterface.sourceTableJoin) {
            name = `[${sourceColumnInterface.sourceTableJoin.JoinAlias}.${sourceColumnInterface.sourceColumn.DatabaseName}]`;
            secondId = sourceColumnInterface.sourceTableJoin.Id;
        } else if (sourceColumnInterface.tableLineage) {
            name = `[${sourceColumnInterface.tableLineage.SourceTable.DatabaseName}.${sourceColumnInterface.sourceColumn.DatabaseName}]`;
            secondId = sourceColumnInterface.tableLineage.Id;
        }
        this.addToCalculation(new Calculation(name, 'column', sourceColumnInterface.sourceColumn.Id, secondId));
    }

    /**
     * Inputs numeric into the calculation array
     * @param $event: KeyboardEvent
     * @author Collin Atkins / 11.9.17
     */
    private inputNumeric() {
        if (this.numericInput || this.numericInput == 0) {
            this.addToCalculation(new Calculation(this.numericInput + '', 'numeric'));
        }
    }

    /**
     * Parses column calculation string into array of calculations
     * @author Collin Atkins / 11.9.17
     */
    private parseCalculation() {
        if (this.ColumnLineage.ColumnCalculation) {
            const calculationStringArray: string[] = this.ColumnLineage.ColumnCalculation.trim().split(',');
            calculationStringArray.forEach(calcString => {
                this.CalculationArray.push(this.stringToCalculation(calcString));
            });
            this.saveCalculation();
        }
    }

    /**
     * Validates the calculation array then changes it to a string and sets the column lineage's column calculation to it
     * @author Collin Atkins / 11.9.17
     */
    private saveCalculation() {
        if (this.validateCalculation()) {
            this.ColumnLineage.ColumnCalculation = this.calculationToString();
        }
    }

    /**
     * Parses a calculation string into a calculation
     * @param calculationString
     * @author Collin Atkins / 11.9.17
     */
    private stringToCalculation(calculationString: string): Calculation {
        if (this.isColumn(calculationString)) {
            return this.parseColumnIdString(calculationString);
        } else if (Number(calculationString)) {
            return new Calculation(calculationString, 'numeric');
        } else if (this.Operators.some(op => op.Name == calculationString)) {
            return this.Operators.find(op => op.Name == calculationString);
        }
        // Should only hit this case if it isn't in the correct format. Some saved legacy calculations may be.
        else {
            return new Calculation(calculationString);
        }
    }

    //#endregion

    //#region Helper Functions=

    /**
     * Fills operator array with operators as calculation objects
     * @author Collin Atkins / 11.9.17
     */
    private getOperators() {
        let opStrings: string[] = ['(', ')', '+', '-', '*', '/'];
        opStrings.forEach((opString, index) => {
            this.Operators.push(new Calculation(opString, 'operator', index));
        });
    }

    private insertModeIsReplace(): boolean {
        return this.insertMode == 'Replace';
    }

    /**
     * @param formatString
     * @author Collin Atkins / 11.20.17
     */
    private isColumn(formatString: string): boolean {
        return formatString.startsWith('[') && formatString.endsWith(']');
    }

    /**
     * Parses string of column ids to column calculation format
     * @param columnIdString
     * @author Collin Atkins / 11.29.17
     */
    private parseColumnIdString(columnIdString: string): Calculation {
        var ids: string[] = columnIdString.substring(1, columnIdString.length - 1).split('.');
        var tableId: number = ids.length == 1 ? null : +ids[0];
        var columnId: number = ids.length == 1 ? +ids[0] : +ids[1];
        var foundTable: any = null;
        var foundColumn: SourceColumn = null

        for (let tl of this.TableLineages) {
            if ((!tableId || tl.Id == tableId) && tl.SourceTable.SourceColumns && tl.SourceTable.SourceColumns.length > 0) {
                foundColumn = tl.SourceTable.SourceColumns.find(sc => sc.Id == columnId);
                if (foundColumn) {
                    foundTable = tl;
                    break;
                }
            }
            for (let stj of tl.SourceTableJoins) {
                if ((!tableId || stj.Id == tableId) && stj.SourceTable.SourceColumns && stj.SourceTable.SourceColumns.length > 0) {
                    foundColumn = stj.SourceTable.SourceColumns.find(sc => sc.Id == columnId);
                    if (foundColumn) {
                        foundTable = stj;
                        break;
                    }
                }
            }
        }

        var name = columnIdString;
        if (foundTable && foundTable.JoinAlias) {
            name = `[${foundTable.JoinAlias}.${foundColumn.DatabaseName}]`;
        } else if (foundTable && foundTable.SourceTable.DatabaseName) {
            name = `[${foundTable.SourceTable.DatabaseName}.${foundColumn.DatabaseName}]`;
        } else if (foundColumn) {
            name = `[${foundColumn.DatabaseName}]`;
        }
        return new Calculation(name, 'column', columnId, tableId);
    }

    private placementIsAfter(): boolean {
        return this.placement == 'After';
    }

    /**
     * Validates if the calculation array is a valid calculation by evaluating it
     * @author Collin Atkins / 11.9.17
     */
    private validateCalculation(): boolean {
        try {
            eval(this.calculationToEvalString());
            return true;
        } catch (ex){
            return false;
        }
    }

    //#endregion

}
