
import {startWith, map} from 'rxjs/operators';
/**
 * Source Table Join Condition Row
 * @author Collin Atkins / 10.13.17 / Removed master source column select and old source column select and replaced with filterable selects from
 * source table and source column. Improved ui, cleaned up code, and fixed no operator type defined bug.
 * @author Collin Atkins / 10.16.17 / Added source column field and changed other selects to master, fixed op type saving error,
 * added toggle to show/hide additional fields, and other small improvements and fixes.
 * @author Collin Atkins / 10.17.17 / Added ability to select from other aliases, along with saving it. Fixed bug of not saving source column selection and
 * moved clutted ng init to seperate functions.
 */
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SnackBarHandler } from 'utilities/snackbar';
import { SourceTableJoin } from 'app/models/source-table-join';
import { SourceTableJoinConditionService } from 'app/services/source-table-join-condition.service';
import { SourceTableJoinCondition } from 'app/models/source-table-join-condition';
import { OperatorType } from 'app/models/operatortype';
import { SourceMasterTableJoin } from 'app/models/source-master-table-join';
import { SourceColumnService } from 'app/services/source-column.service';
import { SourceTable } from 'app/models/source-table';
import { SourceColumn } from 'app/models/source-column';

@Component({
    selector: 'dp-sourcetablejoincondition',
    templateUrl: './source-table-join-condition.component.html',
    providers: [SourceTableJoinConditionService, SnackBarHandler, SourceColumnService],
    styleUrls: ['./source-table-join-condition.component.scss']
})

export class SourceTableJoinConditionComponent implements OnInit {

    @Input() Joins: SourceTableJoin[] = new Array<SourceTableJoin>();
    @Input() Join: SourceTableJoin = new SourceTableJoin();
    @Input() JoinCondition: SourceTableJoinCondition = new SourceTableJoinCondition();
    @Input() SourceTables: SourceTable[] = new Array<SourceTable>();
    @Input() operatorTypes: OperatorType[] = new Array<OperatorType>();

    private SourceTable: SourceTable = new SourceTable();
    private SourceColumns: SourceColumn[] = new Array<SourceColumn>();
    private SourceMasterTables: SourceMasterTableJoin[] = new Array<SourceMasterTableJoin>();
    private SourceMasterColumns: SourceColumn[] = new Array<SourceColumn>();

    private sourceColumnsControl: FormControl = new FormControl();
    private sourceMasterTablesControl: FormControl = new FormControl();
    private sourceMasterColumnsControl: FormControl = new FormControl();
    private filteredSourceColumns: Observable<SourceColumn[]>;
    private filteredSourceMasterTables: Observable<SourceTable[]>;
    private filteredSourceMasterColumns: Observable<SourceColumn[]>;

    private showAdditionalFields: boolean = false;

    constructor(private joinConditionService: SourceTableJoinConditionService, private snackBarHandler: SnackBarHandler,
        private sourceColumnService: SourceColumnService) { }

    ngOnInit() {
        this.initObjects();
        this.initSourceTablesAndColumns();
    }

    /**
     * Adds join aliases to beginning of sourceMasterTables list for selection
     * @author Collin Atkins / 10.17.17
     */
    private addAliases() {
        this.Joins.filter(j => j != this.Join).forEach(j => {
            this.SourceMasterTables.unshift(new SourceMasterTableJoin(Object.assign({}, j.SourceTable), j.Id, j.JoinAlias));
        });
    }

    /**
     * Calls the SourceTableJoinConditionService to delete the join condition by Id
     * @author Nash Lindley
     * @author Collin Atkins / 10.16.17 / Changed emit to splice from the array
     */
    private deleteJoinCondition() {
        this.joinConditionService.deleteJoinConditions(this.JoinCondition.Id)
            .subscribe(() => {
                this.snackBarHandler.open(`Join condition was deleted.`, 'success');

                const index = this.Join.SourceTableJoinConditions.findIndex(cond => this.JoinCondition === cond);
                if (index >= 0) {
                    this.Join.SourceTableJoinConditions.splice(index, 1);
                }
            }, err => {
                console.log(err);
                this.snackBarHandler.open(`Join condition deletion failed.`, 'failure');
            });
    }

    /**
     * Maps sourceTable object to name for input
     * @param sourceTable
     * @author Collin Atkins / 9.27.17
     * @author Collin Atkins / 10.17.17 / Changed to support display selections with join aliases
     */
    private displaySourceTable(sourceTable): SourceTable | string {
        if (sourceTable) {
            if (sourceTable.JoinAlias) {
                return `${sourceTable.FriendlyName} (${sourceTable.JoinAlias})`;
            } else {
                return sourceTable.FriendlyName;
            }
        } else {
            return sourceTable;
        }
    }

    /**
     * Maps sourceColumn object to name for input
     * @param sourceColumn 
     * @author Collin Atkins / 9.27.17
     */
    private displaySourceColumn(sourceColumn: SourceColumn): SourceColumn | string {
        return sourceColumn ? sourceColumn.FriendlyName1 : sourceColumn;
    }

    /**
     * @author Collin Atkins / 10.18.17
     */
    private enableSourceColumnSelect() {
        this.filteredSourceMasterColumns = this.sourceMasterColumnsControl.valueChanges.pipe(startWith(null),
            map(input => (input && typeof (input) === 'string') ? this.filterSourceColumns(input, this.SourceMasterColumns) : this.SourceMasterColumns.slice()),);
        this.sourceMasterColumnsControl.enable();
    }

    /**
     * Filters source tables input based on val entered
     * @param sourceTableName 
     * @author Collin Atkins / 9.29.17
     */
    private filterSourceTables(sourceTableName: string): SourceTable[] {
        return this.SourceMasterTables.filter(st => st.FriendlyName.toLowerCase().indexOf(sourceTableName.toLowerCase()) === 0);
    }

    /**
     * Filters source columns input based on val entered
     * @param sourceColumnName
     * @param sourceColumns: SourceColumn[] - array to filter
     * @author Collin Atkins / 9.29.17
     * @author Collin Atkins / 10.16.17 / Added sourceColumns to be able to filter sourceColumns and sourceMasterColumns
     */
    private filterSourceColumns(sourceColumnName: string, sourceColumns: SourceColumn[]): SourceColumn[] {
        return sourceColumns.filter(sc => sc.FriendlyName1.toLowerCase().indexOf(sourceColumnName.toLowerCase()) === 0);
    }

    /**
     * @author Collin Atkins / 10.17.17
     */
    private initObjects() {
        this.SourceTable = this.Join.SourceTable || new SourceTable();
        this.SourceColumns = this.SourceTable.SourceColumns || new Array<SourceColumn>();
        this.JoinCondition.OperatorType = this.JoinCondition.OperatorType || new OperatorType();
    }

    /**
     * @author Collin Atkins / 10.17.17
     */
    private initSourceTablesAndColumns() {
        this.filteredSourceColumns = this.sourceColumnsControl.valueChanges.pipe(startWith(null),
            map(input => (input && typeof (input) === 'string') ? this.filterSourceColumns(input, this.SourceColumns) : this.SourceColumns.slice()),);

        this.SourceMasterTables = this.SourceTables.map(st => new SourceMasterTableJoin(Object.assign({}, st)));
        this.SourceMasterTables.splice(this.SourceMasterTables.findIndex(st => st.Id == this.SourceTable.Id), 1);
        this.addAliases();
        this.filteredSourceMasterTables = this.sourceMasterTablesControl.valueChanges.pipe(startWith(null),
            map(input => (input && typeof (input) === 'string') ? this.filterSourceTables(input) : this.SourceMasterTables.slice()),);

        if (this.JoinCondition.SourceMasterColumn && this.JoinCondition.SourceMasterColumn.Id >= 0) {
            let filteredSourceMasterTables: SourceMasterTableJoin[] = this.SourceMasterTables.filter(st => st.Id == this.JoinCondition.SourceMasterColumn.SourceTableId);
            if (filteredSourceMasterTables.length > 1) {
                this.JoinCondition.SourceMasterTable = filteredSourceMasterTables.find(st => this.JoinCondition.AliasId > 0 ? st.JoinId == this.JoinCondition.AliasId : !st.JoinId);
            } else {
                this.JoinCondition.SourceMasterTable = filteredSourceMasterTables[0];
            }
            this.selectSourceMasterTable(this.JoinCondition.SourceMasterTable);
        } else {
            this.sourceMasterColumnsControl.disable();
        }
    }

    /**
     * Called when key is pressed on source column field
     * @param $event
     * @author Collin Atkins / 10.6.17 / 
     */
    private inputSourceColumn($event) {
        if ($event.key === 'Backspace') {
            this.resetSourceColumn();
        }
    }

    /**
     * @author Collin Atkins / 10.6.17 / 
     */
    private inputSourceMasterTable($event) {
        if ($event.key === 'Backspace') {
            this.resetSourceMasterTable();
        }
    }

    /**
     * @author Collin Atkins / 10.6.17 / 
     */
    private inputSourceMasterColumn($event) {
        if ($event.key === 'Backspace') {
            this.resetSourceMasterColumn();
        }
    }

    /**
     * @author Collin Atkins / 10.9.17 /
     */
    private resetSourceColumn() {
        this.JoinCondition.SourceColumn = new SourceColumn();
    }

    /**
     * Custom control for reseting source table input/select
     * @author Collin Atkins / 10.9.17 /
     */
    private resetSourceMasterTable() {
        this.resetSourceMasterColumn();
        this.sourceMasterColumnsControl.disable();

        this.SourceMasterColumns = new Array<SourceColumn>();
        this.JoinCondition.SourceMasterSourceTableId = null;
        this.JoinCondition.SourceMasterTable = new SourceTable();
    }

    /**
     * @author Collin Atkins / 10.16.17 /
     */
    private resetSourceMasterColumn() {
        this.JoinCondition.SourceMasterSourceColumnId = null;
        this.JoinCondition.SourceMasterColumn = new SourceColumn();
    }

    /**
     * @author Collin Atkins / 10.17.17
     */
    private selectSourceColumn() {
        this.JoinCondition.SourceColumnId = this.JoinCondition.SourceColumn.Id;
    }

    /**
     * @param sourceMasterTable 
     * @author Collin Atkins / 10.17.17
     */
    private selectSourceMasterTable(sourceMasterTable) {
        if (sourceMasterTable) {
            if (sourceMasterTable.JoinId) {
                this.selectSourceMasterTableJoin(sourceMasterTable);
            } else {
                this.selectSourceTable(sourceMasterTable);
            }
        }
    }

    /**
     * Gets source columsn by source table id then sets the input filter on them and enables the form control
     * @author Collin Atkins / 10.13.17 /
     */
    private selectSourceTable(sourceMasterTable: SourceTable) {
        this.JoinCondition.SourceMasterSourceTableId = this.JoinCondition.SourceMasterTable.Id;
        this.JoinCondition.AliasId = -1;
        this.sourceColumnService.getSourceColumnsBySourceTable(this.JoinCondition.SourceMasterTable.Id)
            .subscribe(sourceColumns => {
                this.SourceMasterColumns = sourceColumns;
                this.enableSourceColumnSelect();
            }, err => console.log(err));
    }

    /**
     * Selections with aliases set the join cond alias id then get the source columns from Joins and enable the column select with filter
     * @param sourceMasterTable
     * @author Collin Atkins / 10.17.17
     */
    private selectSourceMasterTableJoin(sourceMasterTable: SourceMasterTableJoin) {
        this.JoinCondition.SourceMasterSourceTableId = this.JoinCondition.SourceMasterTable.Id;
        this.JoinCondition.AliasId = sourceMasterTable.JoinId;
        this.SourceMasterColumns = this.Joins.find(j => j.Id === sourceMasterTable.JoinId).SourceTable.SourceColumns;
        this.enableSourceColumnSelect();
    }

    /**
     * @author Collin Atkins / 10.16.17
     */
    private selectSourceMasterColumn() {
        this.JoinCondition.SourceMasterSourceColumnId = this.JoinCondition.SourceMasterColumn.Id;
    }

    /**
     * @author Collin Atkins / 10.6.17 / 
     */
    private toggleDBFunction() {
        this.JoinCondition.DBFunction = this.JoinCondition.DBFunction === 'Y' ? 'N' : 'Y';
    }

}
