import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class TokenService {
    cookieName = 'X-Token';

    constructor(private cookieService: CookieService) { }

    clearSessionToken() {
        this.cookieService.remove(this.cookieName);
    }

    getSessionToken(): { rawToken: string, payload: any } {
        const tokenString = this.cookieService.get(this.cookieName);
        let tokenBody: any;
        if (tokenString) {
            const tokenParts = tokenString.split('.');
            if (tokenParts && tokenParts.length === 3) {
                let tokenBodyString = tokenParts[1];
                tokenBodyString = atob(tokenBodyString);
                tokenBody = JSON.parse(tokenBodyString);
            }
        }

        return { rawToken: tokenString, payload: tokenBody };
    }

    verifyTokenPayloadIsPresent(token: { rawToken: string, payload: any }): boolean {
        return (token && token.payload.TenantId && token.payload.UserId);
    }
    
}
