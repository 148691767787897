
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class JobHistoryService {
    private apiPath = 'api/EDWCtlCDC';
    private requestUrl = environment.serviceUrl + this.apiPath;
    constructor(private http: HttpClient) { }

    /**
     * Gets all Jobs
     */
    getJobHistory(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Job by Table Name
     * @param tableName Name of the target table
     */
    getJobHistoryByTableName(tableName): Observable<any> {
        return this.http.get(`${this.requestUrl}?tableName=${tableName}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Job by Table Name
     * @param tableName Name of the target table
     */
    getJobHistoryByTableId(tableId: string, tableName: string): Observable<any> {
        return this.http.get(`${this.requestUrl}?id=${tableId}&name=${tableName}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Saves the given Job
     * @param job: JobHistory
     */
    saveJob(job): Observable<any> {
        return this.http.put(this.requestUrl, job, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
