import { Auth0User } from './Auth0User';

export class RelatedTerm {
    Id: number;
    RelatedTermId: number;
    RelatedTermParentId: number;
    RelatedTermChildId: number;
    RelatedTermTypeId: number;
    CreatedDate: Date;
    UpdatedDate: Date;
    CreatedUser: Auth0User;
    UpdatedUser: Auth0User;
    CreatedUserId: string;
    UpdatedUserId: string;

    constructor() {
        this.Id = -1;
    }

}
