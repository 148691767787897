import { Injectable } from '@angular/core'
import { Http, Response } from '@angular/http';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';

@Injectable()
export class SnackBarHandler {

    constructor(private snackBar: MatSnackBar) { }

    /**
     * Shows a custom toast message with message string, action dismissal string, and background color decided by type,
     * then auto scrolls to the bottom of the page so the message is seen.
     * @param message message to display
     * @param action action string to show on dismissal button
     * @param type success, failure, or default for green, red, or blue snackbar
     * @param duration length of time the snackbar stays open
     */
    open(message: string, type?: string, action?: string, duration?: number) {

        type = type || 'default';
        action = action || 'Dismiss';
        duration = duration || 2000;

        const config = new MatSnackBarConfig();
        config.duration = type != 'failure' ? duration : null;
       //config.duration = duration;


        config.panelClass = type === 'success' ? ['background-success'] : type === 'failure' ? ['background-danger']
            : type === 'danger' ? ['background-danger'] : ['background-primary'];

        this.snackBar.open(message, action, config);
    }

    /**
     * Closes current snackbar
     */
    close() {
        this.snackBar.dismiss();
    }
}
