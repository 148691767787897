export class TableImport {

    Id: number;
    systemId: number;
    stringTables: any[];
    SchemaName: string;
    toSchemaName: string;
    SchemaTables: string[];
    importType: string;
    TablePrefix: string;

    constructor() {
        this.stringTables = [];
        this.systemId = -1;
    }
}