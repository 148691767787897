import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { IColumnData, IDialogData, IInputType } from "app/models/dialog-interface";
import { SourceSystem } from "app/models/source-system";
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SourceSystemService } from 'app/services/source-system.service';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { SnackBarHandler } from 'utilities/snackbar';
import { SourceSystemsAddComponent } from './source-systems-add/source-systems-add.component';
import { SourceSystemsEditComponent } from './source-systems-edit/source-systems-edit.component';
import { SharedService } from 'app/services/shared.service'



@Component({
  selector: 'dp-source-systems',
  templateUrl: './source-systems.component.html',
  styleUrls: ['./source-systems.component.scss'],
  providers: [SourceSystemService, SnackBarHandler]
})
export class SourceSystemsComponent {

  serviceData: any;
  ref: boolean;
  tableDetails: ITableDetails;
  editModalData: any[];
  private destroy = new Subject<void>();

  columns: IColumns[] = [
    {
      columnDef: "Id",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Id}`
    },
    {
      columnDef: "Name",
      header: "Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Name}`
    },
    {
      columnDef: "DBVendorName",
      header: "DB Vendor Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.DBVendorName}`
    },
    {
      columnDef: "DBHostName",
      header: "DB Host Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.DBHostName}`
    },
    {
      columnDef: "DBPort",
      header: "DB Port",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.DBPort}`
    },
    {
      columnDef: "connection",
      header: "Connection",
      sortable: false,
      cell: (element: any) => ``
    }
  ];

  ngOnInit(){
    this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Source"], ["source/"]);
  }

  ngOnDestroy(){
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  constructor(
    private sourceService: SourceSystemService,
    private dialog: MatDialog,
    private snackBarHandler: SnackBarHandler,
    private router: Router,
    private authService: AuthService,
    private routeBreadcrumbService: RouteBreadcrumbService,
    private sharedService: SharedService) {
      this.tableDetails = { routing: { routingPath: ["/source/tables/"] }, edit: false };
      this.serviceData = this.authService.getFakeSubscribe([]);
      this.authService.authorizeSection().subscribe(permissions =>{
        if(permissions.view){
          var details = { routing: { routingPath: ["/source/tables/"] }, edit: permissions.update }
          if(permissions.add){
            details['add'] = "System";
          }
          if(permissions.delete)
            details['delete'] = "Name";

          this.tableDetails = details;
          this.serviceData = this.sourceService.getAllSourceSystems();
          this.ref = true;
        }
        else {
          this.router.navigate(['/']);
        }
      });
      this.ref = false;
  }

  resetRefresh(value: boolean) {
    this.ref = value;
  }

  editModal({ Id, Name, DBHostName, DBUserName, DBProvider, DBSchemaName, DBDetail1, DBInstanceId, DBVendorName, DBPort, DBPassword, DBVersion, DBDetail2, DBEncoding }: SourceSystem) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let dbType: IInputType = {type: "dropdown", dropdownOptions: ["ORACLE", "SQL SERVER"] };
    let dbEncoding: IInputType = {type: "dropdown", dropdownOptions: ["UTF-8", "UTF-16", "WIN1252"] };
    let password: IInputType = {type: 'password'};
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "StoredPassword",
        label: -1,
        value: DBPassword,
        validators: []
      },
      {
        id: "Name",
        label: "Name",
        value: Name,
        validators: ["required"]
      },
      {
        id: "DBHostName",
        label: "DB Host Name",
        value: DBHostName,
        validators: ["required"]
      },
      {
        id: "DBUserName",
        label: "DB User Name",
        value: DBUserName,
        validators: ["required"]
      },
      {
        id: "DBSchemaName",
        label: "DB Schema Name",
        value: DBSchemaName,
        validators: ["required"]
      },
      {
        id: "DBDetail1",
        label: "DB Detail 1",
        value: DBDetail1,
        validators: []
      },
      {
        id: "DBInstanceId",
        label: "DB Instance Id",
        value: DBInstanceId,
        validators: []
      },
      {
        id: "DBVendorName",
        label: "DB Vendor Name",
        value: DBVendorName,
        validators: ["required"],
        inputType: dbType
      },
      {
        id: "DBPort",
        label: "DB Port",
        value: DBPort,
        validators: []
      },
      {
        id: "DBPassword",
        label: "DB Password",
        value: "",
        validators: ["passwordOrBlank"],
        inputType: password

      },
      {
        id: "DBVersion",
        label: "DB Version",
        value: DBVersion,
        validators: ["required"]
      },
      {
        id: "DBDetail2",
        label: "DB Detail 2",
        value: DBDetail2,
        validators: []
      },
      {
        id: "DBEncoding",
        label: "DB Encoding",
        value: DBEncoding,
        validators: [],
        inputType: dbEncoding
      },
    ];
    let data: IDialogData = {
      description: "Edit " + Name,
      numCols: 2,
      columnData: columnData
    }
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(SourceSystemsEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.DBPassword = result.DBPassword != "" ? result.DBPassword : DBPassword;
        result.DBPort = this.setDefaultPorts(result.DBPort, result.DBVendorName);
        
        this.sourceService.saveSystem(result).subscribe(savedSystem => {
          if (savedSystem) {
            this.sharedService.sendSourceSystemUpdateRequest(); //Sends request to update source system nav
            this.snackBarHandler.open(`Success: Edited '${result.Name}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Source system not edited`, 'failure'));
      }
    });

  }

  viewModal({ Id, Name, DBHostName, DBUserName, DBProvider, DBSchemaName, DBDetail1, DBInstanceId, DBVendorName, DBPort, DBPassword, DBVersion, DBDetail2, DBEncoding }: SourceSystem) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let dbEncoding: IInputType = {type: "dropdown", dropdownOptions: ["UTF-8", "UTF-16", "WIN1252"] };
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "Name",
        label: "Name",
        value: Name,
        validators: ["required"]
      },
      {
        id: "DBHostName",
        label: "DB Host Name",
        value: DBHostName,
        validators: ["required"]
      },
      { 
        id: "DBUserName",
        label: "DB User Name",
        value: DBUserName,
        validators: ["required"]
      },
      {
        id: "DBSchemaName",
        label: "DB Schema Name",
        value: DBSchemaName,
        validators: ["required"]
      },
      {
        id: "DBDetail1",
        label: "DB Detail 1",
        value: DBDetail1,
        validators: []
      },
      {
        id: "DBInstanceId",
        label: "DB Instance Id",
        value: DBInstanceId,
        validators: []
      },
      {
        id: "DBVendorName",
        label: "DB Vendor Name",
        value: DBVendorName,
        validators: ["required"]
      },
      {
        id: "DBPort",
        label: "DB Port",
        value: DBPort,
        validators: ["number", "required"]
      },
      {
        id: "DBPassword",
        label: "DB Password",
        value: "",
        validators: ["passwordOrBlank"]
      },
      {
        id: "DBVersion",
        label: "DB Version",
        value: DBVersion,
        validators: ["required"]
      },
      {
        id: "DBDetail2",
        label: "DB Detail 2",
        value: DBDetail2,
        validators: []
      },
      {
        id: "DBEncoding",
        label: "DB Encoding",
        value: DBEncoding,
        validators: [],
        inputType: dbEncoding
      },
    ];
    let data: IDialogData = {
      description: "View " + Name,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }


  addModal() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let dbType: IInputType = {type: "dropdown", dropdownOptions: ["ORACLE", "SQL SERVER"] };
    let dbEncoding: IInputType = {type: "dropdown", dropdownOptions: ["UTF-8", "UTF-16", "WIN1252"] };
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: "",
        validators: []
      },
      {
        id: "Name",
        label: "Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "DBHostName",
        label: "DB Host Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "DBUserName",
        label: "DB User Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "DBSchemaName",
        label: "DB Schema Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "DBDetail1",
        label: "DB Detail 1",
        value: "",
        validators: []
      },
      {
        id: "DBInstanceId",
        label: "DB Instance Id",
        value: "",
        validators: []
      },
      {
        id: "DBVendorName",
        label: "DB Vendor Name",
        value: "",
        validators: ["required"],
        inputType: dbType
      },
      {
        id: "DBPort",
        label: "DB Port",
        value: "",
        validators: []
      },
      {
        id: "DBPassword",
        label: "DB Password",
        value: "",
        validators: ["password", "required"],
      },
      {
        id: "DBVersion",
        label: "DB Version",
        value: "",
        validators: ["required"]
      },
      {
        id: "DBDetail2",
        label: "DB Detail 2",
        value: "",
        validators: []
      },
      {
        id: "DBEncoding",
        label: "DB Encoding",
        value: "",
        validators: [],
        inputType: dbEncoding
      }
    ];
    let data = {
      description: "Add Source System",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(SourceSystemsAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.forEach(form => {
          form.DBPort = this.setDefaultPorts(form.DBPort, form.DBVendorName);
          this.sourceService.saveSystem(form).subscribe(savedSystem => {
            if (savedSystem) {
              this.sharedService.sendSourceSystemUpdateRequest(); //Sends request to update source system nav
              this.snackBarHandler.open(`Success: Added '${form.Name}'`, 'success');
              this.ref = true;
            }
          }, err => this.snackBarHandler.open(`Failure: Source system not added`, 'failure'));
        });
      }
    });

  }

  deleteRows(rows: number[]) {
    for (let row of rows) {
      this.sourceService.deleteSourceSystem(row).subscribe(result => {
        this.sharedService.sendSourceSystemUpdateRequest(); //Sends request to update source system nav
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.ref = true;
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }

  private setDefaultPorts(port, DBVendorName){
    if(port == "" || isNaN(parseFloat(port))){
      if(DBVendorName == "SQL SERVER") return 1433;
      if(DBVendorName == "ORACLE") return 1521;
    }
    else {
      return port;
    }
  }

  /**
* Tests Connection
* @author John Crabill
* @param system
*/
  testConnection(system: SourceSystem) {
    this.sourceService.testConnection(system)
      .subscribe(conn => {
        if (conn == 'Success') {
          this.snackBarHandler.open('Connection Found', 'success');
        }
        else {
          this.snackBarHandler.open('Connection Failed', 'failure');
        }
      }, err => console.log(err));
  }

}
