import { Component, OnInit, Input, ViewChild, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    templateUrl: './edit-entry-dialog-component.html',
    styleUrls: ['./edit-entry-dialog-component.scss']
  })
  export class EditEntryDialogComponent implements OnInit {


    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EditEntryDialogComponent>) {}
    keys = [];
    currentEntry = {};
    entryType = "Entry";

    ngOnInit(){
        this.entryType = this.data.title.substring(0, this.data.title.length - 1);
        this.data = this.data.data;
        this.dialogRef.disableClose = true;
        for(let key in this.data){
            if(!key.toLowerCase().includes("id")){
                let form = typeof(this.data[key]);
                let formStr = "number";
                if(form !== "number"){
                    formStr = "text";
                }
                this.keys.push({"name": key, "input-type": formStr});
            }
        }
        this.currentEntry = this.data;
    }

    updateCurrentValues(event){
        let value;
        if(event.target.type === "number"){
            value = parseFloat(event.target.value);
        }
        else{
            value = event.target.value;
        }
        this.currentEntry[event.target.name] = value;
    }

    closeDialog(save){
        if(!save){
            this.dialogRef.close()
        }
        else{
            this.dialogRef.close(this.currentEntry);
        }
    }

  }