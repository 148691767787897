import { Component } from '@angular/core';
import { TokenService } from '../services/token.service';
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'dp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent {

    constructor(private tokenService: TokenService, private authService: AuthService) { }

    private admin = true;

    ngOnInit() {
        this.authService.getUserPermissions().subscribe(permissions =>{
          if(!permissions.includes("admin:user")){
            this.admin = false;
          }
        });
    }
}
