/**
 * Object used by collapsibleTree for display in a Json like format
 * @author Collin Atkins / 12.6.17
 */

export class TreeData {
    TextLine1: string;
    TextLine2: string;
    TextLine3: string;
    TooltipText: string;
    children: TreeData[];
    ColumnLineageId: number;

    constructor(textLine1: string = '', textLine2: string = '', textLine3: string = '', tooltipText: string = '', columnLineageId: number = -1) {
        this.TextLine1 = textLine1;
        this.TextLine2 = textLine2;
        this.TextLine3 = textLine3;
        this.TooltipText = tooltipText;
        this.children = new Array<TreeData>();
        this.ColumnLineageId = columnLineageId;
    }
}
