import { Component } from '@angular/core';
import { BaseDialogComponent } from '../base/base-dialog/base-dialog.component';

@Component({
  selector: 'dp-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent extends BaseDialogComponent {

}
