
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TransformationType } from 'app/models/transformation-type';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TransformationTypeService {
    private apiPath = 'api/EDWTransformationType';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all Operator Types
     * @author Nash Lindley
     */
    getTransformationTypes(): Observable<any> {
        return this.http.get<TransformationType[]>(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
