
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Schema } from 'app/models/schema';
import { TargetTable } from 'app/models/target-table';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TableService {
    private apiPath = 'api/EDWTable';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Deletes table by id
     * @param tableId id of table
     * @author Nash Lindley
     */
    deleteTable(tableId: number): Observable<any> {
        return this.http.delete(this.requestUrl + '/?Id=' + tableId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all tables
     * @author Nash Lindley
     */
    getTables(): Observable<any>  {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all tables from a specific schema by name
     * @param schema - schema Name
     * @author Nash Lindley
     */
    getTablesbySchema(schema: Schema): Observable<any>  {
        return this.http.get(this.requestUrl + '?schemaId=' + schema.Id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all tables from a specific schema by name and filters the lineages
     * @param schema - schema Name
     * @author Nash Lindley
     */
    getTablesbySchemaFilterLineages(schema: Schema): Observable<any>  {
        return this.http.get(this.requestUrl + '?schemaId=' + schema.Id + '&filter=LINEAGES', httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all tables by id
     * @param tableId - id of table to get
     * @author Nash Lindley
     */
    getTableById(tableId: number): Observable<any>  {
        return this.http.get(this.requestUrl + '?tableId=' + tableId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Saves a table
     * @param table - table to save
     * @author Nash Lindley
     */
    saveTable(table: TargetTable): Observable<any> {
        return this.http.put(this.requestUrl, JSON.stringify(table), httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all tables from a specific schema by name
     * @param schemaName - Schema Name
     * @param id - Schema Id
     * @author John Crabill
     */
    getTablesbySchemaName(schemaName, id): Observable<any> {
        return this.http.get(this.requestUrl + '?import=true&schemaNameImport=' + schemaName + '&schemaId=' + id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets target table sql statement based on target table id and system id
     * @author Collin Atkins / 10.20.17
     */
    getTargetSql(targetId: string, systemId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?targetId=' + targetId + '&systemId=' + systemId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Calls service to execute given sql statement with sqlStatement in body to support any length
     * @param sqlStatement
     * @author Collin Atkins / 10.30.17
     */
    executeSql(sqlStatement: string, mappingId: number, maxCount: string): Observable<any> {
        let body = { sqlStatement: sqlStatement,
                mappingId: mappingId,
                maxCount: maxCount };
        return this.http.post(this.requestUrl, body, httpOptions).pipe(
            catchError(error => new ErrorHandler().handleSqlError(error)));
    }

}
