/**
 * EDWTables, Schemas
 * @author Collin Atkins / 10.11.17 / Cleaned up code, added delete, and improved ui
 */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { DataTableDirective } from 'angular-datatables';
import { IColumnData, IInputType } from 'app/models/dialog-interface';
import { Schema } from 'app/models/schema';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SchemaService } from 'app/services/schema.service';
import { TableService } from 'app/services/target-table.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { EditSchemaDialogComponent } from 'app/target/target-schema/edit-schema-dialog/edit-schema-dialog.component';
import { Subject } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar';
import { Location } from '@angular/common';

@Component({
    selector: 'dp-schemas',
    templateUrl: './schemas.component.html',
    providers: [SchemaService, TableService, SnackBarHandler],
    'styleUrls': ['./schemas.component.scss']
})

export class SchemaComponent implements OnInit, AfterViewInit {

    @ViewChild(DataTableDirective)
    private dtElement: DataTableDirective;
    private dtTrigger: Subject<Schema[]> = new Subject();
    private dtOptions: DataTables.Settings = {};
    serviceData:any;
    ref: boolean;
    tableDetails: ITableDetails;
    private Schemas: Schema[] = new Array<Schema>();
    private destroy = new Subject<void>();
    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: "Schema Id",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.Id}`
      },{
        columnDef: "DBSchemaName",
        header: "DB Schema Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.DBSchemaName}`
      },
      {
        columnDef: "DBHostName",
        header: "DB Host Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.DBHostName}`
      },
      {
        columnDef: "DBType",
        header: "DB Type",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.DBType}`
      }

    ];


    constructor(
      private schemaService: SchemaService,
      private snackBarHandler: SnackBarHandler,
      private router: Router,
      private authService: AuthService,
      private dialog: MatDialog,
      private routeBreadcrumbService: RouteBreadcrumbService,
      private location: Location) {

        this.tableDetails = { routing: { routingPath: ["/schema/"] }, edit: false };
        this.serviceData = this.authService.getFakeSubscribe([]);
        this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            var details = { routing: { routingPath: ["/schema/"] }, edit: permissions.update }
            if(permissions.add){
              details['add'] = "Target System";
              details['connect'] = true;
            }
            if(permissions.delete){
              details['delete'] = "DBSchemaName";
              details['deleteId'] = "Id";
            }


            this.tableDetails = details;
            this.serviceData = this.schemaService.getSchemas();
            this.ref = true;
          }
          else {
            this.router.navigate(['/']);
          }
        });
        this.ref = false;
      }

    ngOnInit() {
        this.dtOptions = {
            lengthMenu: [5, 10, 25, 50, 100],
            pageLength: 10,
            columnDefs: [{
                targets: [3,4,5,6],
                orderable: false
            }]
        };
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Target System"], ["schema/"]);
    }

    ngAfterViewInit(): void {
        this.location.replaceState('schema');
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
      this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
    }

    /**
     * This function handles the creation of the add dialog as well as
     * handling the addition of target schemas to the database
     */
    private addModal() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      /**
       * The IInputTypes allow the dialog to have different types of input
       */
      let dbType: IInputType = {type: "dropdown", dropdownOptions: ["ORACLE", "SQL SERVER"] };
      let password: IInputType = {type: 'password'};
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: -1,
          validators: []
        },
        {
          id: "DBSchemaName",
          label: "DB Schema Name",
          value: "",
          validators: ["required"]
        },
        {
          id: "DBHostName",
          label: "DB Host Name",
          value: "",
          validators: ["required"]
        },
        {
          id: "LoginUserName",
          label: "Login User Name",
          value: "",
          validators: ["required"]
        },
        {
          id: "LoginUserPassword",
          label: "Login User Password",
          value: "",
          validators: ["password", "required"],
          inputType: password
        },
        {
          id: "DBType",
          label: "DB Type",
          value: "",
          validators: ["required"],
          inputType: dbType
        },
        {
          id: "DBPort",
          label: "DB Port",
          value: "",
          validators: []
        },
        {
          id: "DBInstanceId",
          label: "DB Instance Id",
          value: "",
          validators: []
        },
        {
          id: "NewLine",
          label: "",
          value: "",
          validators: [],
          inputType: {type: "newLine"}
        },
        {
          id: "CreateDuplicateSourceSystem",
          label: "Create Corresponding Source System",
          value: "",
          validators: [],
          inputType: {type: "checkbox"}
        }
      ];
      let data = {
        description: "Add Target Schema",
        addTestConnectionButton: true,
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(AddComponent, dialogConfig);

      const subscribeDialog = dialogRef.componentInstance.onTestConnection.subscribe((data) => {
        this.testConnection(data);
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            result.DBPort = this.setDefaultPorts(result.DBPort, result.DBType);
            result.SchemaName = result.DBSchemaName;
            this.schemaService.saveSchema(result).subscribe(savedSchema => {
              if (savedSchema) {
                this.snackBarHandler.open(`Success: Added '${result.SchemaName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Target system not added`, 'failure'));
        }
      });
    }

    private setDefaultPorts(port, dbType){
      if(port == "" || isNaN(parseFloat(port))){
        if(dbType == "SQL SERVER") return 1433;
        if(dbType == "ORACLE") return 1521;
      }
      else {
        return port;
      }
    }

    private editModal({Id, SchemaName, DBHostName, LoginUserName, LoginUserPassword, DBProvider, DBType, DBPort, DBInstanceId, DBSchemaName}){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      /**
       * The IInputTypes allow the dialog to have different types of input
       */
      let dbType: IInputType = {type: "dropdown", dropdownOptions: ["ORACLE", "SQL SERVER"] };
      let password: IInputType = {type: 'password'};
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: Id,
          validators: []
        },
        {
          id: "DBSchemaName",
          label: "DB Schema Name",
          value: DBSchemaName,
          validators: ["required"]
        },
        {
          id: "DBHostName",
          label: "DB Host Name",
          value: DBHostName,
          validators: ["required"]
        },
        {
          id: "LoginUserName",
          label: "Login User Name",
          value: LoginUserName,
          validators: ["required"]
        },
        {
          id: "LoginUserPassword",
          label: "Login User Password",
          value: "",
          validators: ["passwordOrBlank"],
          inputType: password
        },
        {
          id: "DBType",
          label: "DB Type",
          value: DBType,
          validators: ["required"],
          inputType: dbType
        },
        {
          id: "DBPort",
          label: "DB Port",
          value: DBPort,
          validators: []
        },
        {
          id: "DBInstanceId",
          label: "DB Instance Id",
          value: DBInstanceId,
          validators: []
        }
      ];
      let data = {
        description: "Edit " + SchemaName,
        addTestConnectionButton: true,
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(EditComponent, dialogConfig);

      const subscribeDialog = dialogRef.componentInstance.onTestConnection.subscribe((data) => {
        data.Id = Id;
        this.testConnection(data);
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            result.LoginUserPassword = result.LoginUserPassword != "" ? result.LoginUserPassword : null;
            result.DBPort = this.setDefaultPorts(result.DBPort, result.DBType);
            result.SchemaName = result.DBSchemaName;
            this.schemaService.updateSchema(result).subscribe(savedSchema => {
              if (savedSchema) {
                this.snackBarHandler.open(`Success: Edited '${result.SchemaName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Target system not added`, 'failure'));
        }
      });
    }

    private deleteRows(rows: number[]){
      for (let row of rows) {
        var item = new Schema()
        item['Id'] = row;
        this.schemaService.deleteSchema(item).subscribe(result => {
          this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
          this.ref = true;
        }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
      }
    }

    resetRefresh(value: boolean) {
      this.ref = value;
    }



    /**
     * Calls tableService to delete given schema
     * @param schema - schema to be deleted
     * @author Collin Atkins / 10.11.17
     */
    private deleteSchema(schema: Schema) {
        const deletedSchema = schema;
        this.schemaService.deleteSchema(schema)
            .subscribe(() => {
                this.snackBarHandler.open(deletedSchema.SchemaName + ' was deleted.', 'success');
                this.getSchemas();
            }, err => {
                console.log(err);
                this.snackBarHandler.open('Schema failed to be deleted.', 'failure');
            });
    }

    /**
     * Gets all schemas using schemaService
     */
    private getSchemas() {
        this.rerender();

        this.schemaService.getSchemas()
            .subscribe(schemas => {
                this.Schemas = schemas;
                this.dtTrigger.next();
            }, err => {
                console.log(err);
                this.snackBarHandler.open('Failed to get Schemas:' + err, 'failure');

            });


    }

    /**
     * Testing Connection
     * @author John Crabill
     * @param schema
     */
    private testConnection(schema: Schema) {
        this.schemaService.testConnection(schema)
            .subscribe(conn => {
                if (conn == "Success") {
                    this.snackBarHandler.open('Connection Found', 'success');
                }
                else if (conn == "Failed") {
                    this.snackBarHandler.open('Connection Failed', 'failure');
                }
            }, err => console.log(err));
    }
    private rerender() {
        if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        }
    }

}
