/**
 * Table Lineage List - Used for selection of source columns on the TransformationEditor and CalculationEditor
 * @author Collin Atkins / 11.27.17
 * @author Collin Atkins / 11.28.17 / Changed to eliminate extra tier of alias names, show alias name next to source table name,
 *      and show source columns regardless if a join exist by source table
 */
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SourceTableJoin } from 'app/models/source-table-join';
import { TableLineage } from 'app/models/table-lineage';
import { SourceColumn } from 'app/models/source-column';

@Component({
    selector: 'dp-table-lineage-list',
    templateUrl: './table-lineage-list.component.html',
    styleUrls: ['./table-lineage-list.component.scss']
})

export class TableLineageListComponent {

    @Input() TableLineages: TableLineage[] = new Array<TableLineage>();
    @Input() inputDisabled: boolean = false;
    @Output() SourceColumnSelected: EventEmitter<any> = new EventEmitter();

    constructor() { }

    /**
     * @param stj 
     * @param sc 
     * @author Collin Atkins / 11.29.17
     */
    private joinSourceColumnClicked(stj: SourceTableJoin, sc: SourceColumn) {
        this.SourceColumnSelected.emit({ sourceTableJoin: stj, sourceColumn: sc });
    }

    /**
     * @param tl 
     * @param sc 
     * @author Collin Atkins / 11.29.17
     */
    private tableSourceColumnClicked(tl: TableLineage, sc: SourceColumn) {
        this.SourceColumnSelected.emit({ tableLineage: tl, sourceColumn: sc });
    }

}