
import {catchError} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserUtility } from 'utilities/user-utility';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Auth0User } from 'app/models/Auth0User';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class GlobalSearchService{
    private apiPath = 'api/GlobalSearch';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient, private authService:AuthService) {
    }

    testService (search) {
        return this.http.get(`${this.requestUrl}?search=${search}`, httpOptions).pipe(catchError(new ErrorHandler().handleError));
    }

}