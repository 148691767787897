import { Component, OnInit, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { GlobalSearchService } from 'app/services/global-search.service'
import { SnackBarHandler } from 'utilities/snackbar';
import { skip } from 'rxjs-compat/operator/skip';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'dp-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  providers: [GlobalSearchService, SnackBarHandler]
})
export class GlobalSearchComponent implements OnInit, OnChanges{
  private columns = ['result', 'location'];
  private dataSource = new MatTableDataSource;
  private tempHolder = [];
  IsTableLoading: boolean;

  constructor(private globalSearchService: GlobalSearchService,
    private authService: AuthService, private snackBarHandler: SnackBarHandler, private router: Router) {

   }

  ngOnInit() {
    this.IsTableLoading = false;
    this.authService.authorizeSection().subscribe(permissions =>{
      if(permissions.view){
      }
      else {
        this.router.navigate(['/']);
      }
    });
    this.dataSource.data = this.tempHolder;
  }
  ngOnChanges() {
    this.onKey('');
  }

  shortcutSearch($event, globalSearchInput){
    if($event.key == "Enter"){
      this.search(globalSearchInput);
    }
  }

  search(search: string) {
    this.IsTableLoading = true;
    if (search != '') {
      this.globalSearchService.testService(search).subscribe(data => {
        this.clearResults();
        if(data['results'].length != 0) {
          var temp = this.parseResult(data);
            this.dataSource.data = temp;
            this.IsTableLoading = false;
            
          } else {
            var temp2 = {
              result: 'Not Found',
              location: 'Not Found'
            }
            this.dataSource.data.push(temp2);
            this.IsTableLoading = false;

          }
          this.tempHolder = this.dataSource.data;
         this.onKey(search);
        this.IsTableLoading = false;

          }); 
      } else {

        this.clearResults();
      }
    }

  onKey(search: string) {
    this.dataSource.filter = search;
    }

  parseResult(data: any) {
      var dataArray = [];
      for (let x = 0; x < data['results'].length; x++) {
        var temp = {
          result: data['results'][x],
          location: data['locations'][x],
          urlpath: data['urlpath'][x]
        }
        if(temp['location'] == 'BG_BUSINESS_GLOSSARY')
        {
          temp['location'] = 'Business Glossaries';
        } 
        if(temp['location'] == 'BGC_CATEGORY')
        { 
          temp['location'] = 'Business Categories';
        }
        if( temp['location'] == 'BGT_TERM')
        {
          temp['location'] = 'Business Terms'
        }
        if(temp['location'] == 'JH_JOB_HISTORY') 
        {
          temp['location'] = 'Job History';
        }
        if (temp['location'] == 'SS_SOURCE_SYSTEM')
        {
          temp['location'] = 'Source Systems';
        }
        if (temp['location'] == 'ST_SOURCE_TABLE')
        {
          temp['location'] = 'Source Tables';
        }
        if(temp['location'] == 'SC_SOURCE_COLUMN')
        {
          temp['location'] = 'Source Columns';
        } 
        if(temp['location'] == 'TU_TEAM_USERS' || temp['location'] == 'TR_TEAM_ROLES') {
          temp['location'] = 'Users';
        } 
        if(temp['location'] == 'ET_EDW_TABLE') 
        {
          temp['location'] = 'Target Tables';
        }
        if(temp['location'] == 'EC_EDW_COLUMN')
        {
          temp['location'] = 'Target Columns';
        }
        if(temp['location'] == 'EDW_SCHEMA_TARGETS')
        {
          temp['location'] = 'Target Systems';
        } 
        if(temp['location'] == 'RPS_REPORTING_SOURCE')
        {
          temp['location'] = 'Filebased Sources';
        }
        if(temp['location'] == 'RP_REPORTS')
        {
          temp['location'] = 'Filebased Reports';
        }
        if(temp['location'] == 'RPC_REPORT_CALCULATIONS')
        {
          temp['location'] = 'Filebased Calculation Groups';
        }
        dataArray.push(temp);
      }
      return dataArray;
    }
  
  clearResults() {
    this.dataSource.data = [];
  }

  wait() {
    skip
  }

  navigateToResult(row) {

    if(row['location'] == 'Business Glossaries')
    {
      this.router.navigate(['/business-glossary']);
    }
    if(row['location'] == 'Source Systems') {
      this.router.navigate(['/source'])
    } 
    if(row['location'] == 'Target Systems') {
      this.router.navigate(['/schema'])
    } 
    if(row['location'] == 'Job History') {
      this.router.navigate(['/job-history'])
    }
    if(row['location'] == 'Users')
    {
      this.router.navigate(['/users'])
    }
    if(row['location'] == 'Business Terms')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Target Columns')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Target Tables')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Business Categories')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Source Tables')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Source Columns')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Filebased Sources')
    {
      this.router.navigate([row['urlpath']])

    }
    if(row['location'] == 'Filebased Calculation Groups')
    {
      this.router.navigate([row['urlpath']])

    }

    if(row['location'] == 'Filebased Reports')
    {
      this.router.navigate([row['urlpath']])

    }    

  }



  }


