import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { cloneDeep, sortBy } from "lodash";
import { tableSymbol } from "../../../decorators/column";
import { ColumnModel } from "../../../decorators/column.model";
import { TableModel } from "../../../decorators/table.model";
import { AddButtonDialogComponent } from '../add-button-dialog/add-button-dialog.component';
import { EditEntryDialogComponent } from "../edit-entry-dialog/edit-entry-dialog-component";

@Component({
  selector: 'dp-table-modified',
  templateUrl: './table-modified.component.html',
  styleUrls: ['./table-modified.component.scss']
})
export class TableModifiedComponent implements OnInit {
  private _data = [];
  private originalData = [];
  private sort: any = {};
  private _originalData: any[] = [];
  private _tableModel: TableModel;
  private testConnection;
  private updateAvailable = true;
  //used to avoid changing filter when editing/adding entry
  private returnedAddDialog = false;
  defaultNoRecords: any = 5;
  dataSource: any = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() set data(values: any[]) {
    if (values && values.length > 0) {
      this.originalData = cloneDeep(values);
      this._data = cloneDeep(values);
      this.dataSource.data = this._data;
      this.dataSource._updateChangeSubscription();
      this.dataSource.paginator = this.paginator;
      this._tableModel = this._data[0][tableSymbol];
      this.buildColumns();
      if (!this._originalData.length) {
        // Keep original order of data
        this._originalData = cloneDeep(this._data);
      }
    }
  }
  get data(): any[] {
    return this._data;
  }
  @Input() instance: any;
  @Input() noDataColumns: number;
  @Input() title: String;
  @Input() addFormItems: any[];
  @Input() parent: any;
  //needed for edit dialog to fill in values
  @Input() rawData: any[];
  @Input() entryName: String;
  @Input() hasData: boolean = true;
  //@Input() testConnectionAvailable: boolean = false;
  @Input() set testConnectionAvailable(value: boolean){
    this.testConnection = value;
    if(this._data.length != 0){
      this.buildColumns();
    }
  }

  @Input() set updateCapabilityAvailable(value: boolean){
    this.updateAvailable = value;
    if(this._data.length != 0){
      this.buildColumns();
    }
  }

  columns: ColumnModel[];
  displayedColumns: string[];
  @Output() newEntry: EventEmitter<any> = new EventEmitter();
  @Output() updatedEntry: EventEmitter<any> = new EventEmitter();
  @Output() newHierarchy: EventEmitter<any> = new EventEmitter();
  @Output() testConn: EventEmitter<any> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.displayedColumns = this._originalData.slice(0, this.defaultNoRecords);
    try{
      this.buildColumns();
    }
    catch{

    }
  }


  sortTable(colName) {
    if(colName === this.sort.active){
      if(this.sort.direction == 'asc'){
        this.sort.direction = 'desc';
      }
      else if(this.sort.direction == 'desc'){
        this.sort.direction = '';
        this.sort.active = '';
      }
      else{
        this.sort.direction = 'asc'
      }
    }
    else{
      this.sort.active = colName;
      this.sort.direction = 'asc';
    }
    let temp = this.dataSource.data;
    //this.originalData = cloneDeep(temp);
    if(temp.length > 1){
      if(typeof(this.data[0][this.sort.active]) == 'number'){
        if(this.sort.direction == 'asc'){
          temp.sort((a,b) =>  a[this.sort.active] - b[this.sort.active]);
        }
        else{
          temp.sort((a,b) =>  b[this.sort.active] - a[this.sort.active]);
        }
        this.dataSource.data = temp;
        this.dataSource._updateChangeSubscription();
      }
      else if(typeof(this.data[0][this.sort.active]) == 'string'){
        if(this.sort.direction == 'asc'){
          temp.sort((a,b) =>  a[this.sort.active].toUpperCase().localeCompare(b[this.sort.active].toUpperCase()));
        }
        else{
          temp.sort((a,b) =>  b[this.sort.active].toUpperCase().localeCompare(a[this.sort.active].toUpperCase()));
        }
        this.dataSource.data = temp;
        this.dataSource._updateChangeSubscription();
      }
      else{
        this.dataSource.data = cloneDeep(this.originalData);
        this.dataSource._updateChangeSubscription();
      }
    }
  }

  initializeSorting(){
    this.sort.active = this.columns[0].key;
    this.sort.direction = "asc";
    let temp = this.dataSource.data;
    if(temp.length > 1){
      if(typeof(this.data[0][this.sort.active]) == 'number'){
        if(this.sort.direction == 'asc'){
          temp.sort((a,b) =>  a[this.sort.active] - b[this.sort.active]);
        }
        else{
          temp.sort((a,b) =>  b[this.sort.active] - a[this.sort.active]);
        }
        this.dataSource.data = temp;
        this.dataSource._updateChangeSubscription();
      }
      else if(typeof(this.data[0][this.sort.active]) == 'string'){
        if(this.sort.direction == 'asc'){
          temp.sort((a,b) =>  a[this.sort.active].toUpperCase().localeCompare(b[this.sort.active].toUpperCase()));
        }
        else{
          temp.sort((a,b) =>  b[this.sort.active].toUpperCase().localeCompare(a[this.sort.active].toUpperCase()));
        }
        this.dataSource.data = temp;
        this.dataSource._updateChangeSubscription();
      }
      else{
        this.dataSource.data = cloneDeep(this.originalData);
        this.dataSource._updateChangeSubscription();
      }
    }
  }

  private buildColumns() {
    this.columns = this._tableModel.columns;
    this.sortColumns();
    this.displayedColumns = this.columns.map(col => col.key);
    if(this.testConnection){
      this.displayedColumns.push("testConnection");
    }
    this.displayedColumns.push("action");
    if(!this.returnedAddDialog){
      this.initializeSorting();
    }
    this.returnedAddDialog = false;
  }

  private sortColumns() {
    this.columns = sortBy(this.columns, ["order"]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(data) {
    this.dataSource =  this._originalData.slice(0, data.pageSize);
  }

  loadAddButtonDialog(element?, edit = false) {
    let rawEntry = {};
    if(element !== undefined){
      for(let i = 0; i < this.rawData.length; i++){
        if(this.rawData[i].Id === element.ID){
          rawEntry = this.rawData[i];
        }
      }
    }
    else{
      if(this.rawData != undefined) {
        for(let key in this.rawData[0]){
          rawEntry[key] = null;
        }
      }
    }
    this.dialog.open(AddButtonDialogComponent,{
        panelClass: 'dialog-no-padding',
        disableClose: true,
        data:{
            "title": this.entryName,
            "addFormItems": this.addFormItems,
            "edit": edit,
            "rawData": rawEntry,
            "viewOnly": !this.updateAvailable},
        }).afterClosed().subscribe(result => {
          if (result) {
            this.returnedAddDialog = true;
            this.newEntry.emit(result);
            // this.dataSource.data.push(result);
          }
        });
  }

  loadEditEntryDialog(entry){
    this.dialog.open(EditEntryDialogComponent, {
      data: {"data":entry, "title": this.title},
      panelClass: 'dialog-no-padding'
  })
      .afterClosed().subscribe(result => {
          if (result) {
              //return data to parent
              this.updatedEntry.emit(result);
          }
      });
  }

  navigateToSubHierarchy(element){
    this.newHierarchy.emit({"new": true, "id": element.ID, "name": element.Name});
  }

  testConnectionStatus(element){
    this.testConn.emit(element);
  }



}
