import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private BGSubject = new Subject<any>(); //Subject used for business glossary
  private SSSubject = new Subject<any>(); //Subject used for source systems

  /* Sends a request to the navigation component that
     indicates the business glossary data has been manipulated  */
  sendBGUpdateRequest(){
    this.BGSubject.next();
  }

  /* Used within the navigation component to handle the request
     and triggers the update of the BG data */
  handleBGUpdateRequest():Observable<any>{
    return this.BGSubject.asObservable();
  }

  /* Sends a request to the navigation component that
     indicates the source system data has been manipulated  */
  sendSourceSystemUpdateRequest(){
    this.SSSubject.next();
  }

  /* Used within the navigation component to handle the request
     and triggers the update of the source system data */
  handleSourceSystemUpdateRequest():Observable<any>{
    return this.SSSubject.asObservable();
  }
}
