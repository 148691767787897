import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTable, MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { IColumnData, IDialogData } from "app/models/dialog-interface";
import { IChecked, IColumns, ITableDetails } from "app/models/table-interfaces";
import { DeleteComponent } from "../../delete/delete.component";

/**
 * BaseTableComponent contains all the functions needed for the implementation of a
 * generic table. The generic table's html is located in TableComponent which
 * extends BaseTableComponent.
 */
@Component({
  selector: 'dp-base-table',
  template: ``,
  styleUrls: ['./base-table.component.scss']
})
export class BaseTableComponent implements OnInit {
  /** Encapsulates the sorting, filtering, pagination, and data retrieval logic */
  dataSource: any;
  /** Referrs to the delete button for the table. True means disabled, false means enabled*/
  isDisabled: boolean = true;
  /** A dictionary of all items in the table that indexes by id and has with a name and a checked
   * status data associated with it*/
  checkedItems: { [id: number]: IChecked } = {};
  _ref: boolean;
  /** Gets the private boolean flag that is responsible for triggering the refresh and returning back
   * to false
   */
  get ref(): boolean {
    return this._ref;
  }
  /** Holds the column definition id, the displayed column header string, the sortable flag used to determine if the column should sort,
   * and a cell which takes a row and gets the cell value corresponding to each id in the row */
  displayedColumns: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  // INPUTS

  /** Holds the observable data that is returned from the given service */
  @Input() serviceData: any;
  /** Holds an array of column structures */
  @Input() columns: IColumns[];
  /** Holds the options for the table's functions */
  @Input() tableDetails: ITableDetails;
  @Input()
  set ref(value: boolean) {
    this._ref = value;
    if (value == true) {
      this.updateTable();
    }
  }
  @Input() minWidthVar: any = null;
  IsTableLoading: boolean = true;

  @Output() onAddRow = new EventEmitter<any[]>();
  @Output() onEditItem = new EventEmitter();
  @Output() onViewItem = new EventEmitter();
  @Output() onDeleteRows = new EventEmitter<number[]>();
  @Output() onUpdateTable = new EventEmitter<boolean>();
  @Output() onSelectRow = new EventEmitter();
  @Output() onSelectRows = new EventEmitter();
  @Output() onChecked = new EventEmitter<any>();
  @Output() DataLoaded = new EventEmitter<boolean>();
  
  private checkToggle: boolean;
  private disableCheck: boolean;

  constructor(
    public router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.checkToggle = true;
    this.disableCheck = false;
    this.setTableData();
    this.updateTableDetails();
  }

  updateTableDetails(){
    this.displayedColumns = [];
    if (this.tableDetails.delete) {
      this.displayedColumns.push("delete");
    }

    if(this.tableDetails.checkbox){
      this.displayedColumns.push("checkbox");
    }

    if (this.tableDetails.edit) {
      this.columns.map(c => this.displayedColumns.push(c.columnDef));
      this.displayedColumns.push("edit");

    } else {
      this.columns.map(c => this.displayedColumns.push(c.columnDef));
      if(!this.tableDetails.noView){
        this.displayedColumns.push("view");
      }
    }

    /**
       *  If true adds test connection to the table
       *
       * Inserted with splice for table styling
       * this will ensure it's always the second
       * to last item added to the table
       */
      if(this.tableDetails.connect){
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0 , "connect");
      }

      if (this.tableDetails.toggleButton) {
          this.displayedColumns.push("toggleButton");
      }
      if (this.tableDetails.downloadButton) {
        this.displayedColumns.push("downloadButton");
      }
  }

  toggleAll(){
    if(this.checkToggle && this.disableCheck == false){
      for (let key of Object.keys(this.checkedItems)) {
          this.checkedItems[key].checked = true;
      }
      this.checkToggle = false;
      this.onChecked.emit(this.checkedItems);
    }
    else if(this.checkToggle == false && this.disableCheck == false){
      for (let key of Object.keys(this.checkedItems)) {
        this.checkedItems[key].checked = false;
      }
      this.checkToggle = true;
    }
    this.onChecked.emit(this.checkedItems);
  }

  /** Notifies the implementation that there has been a request to add a row */
  addRow() {
    this.onAddRow.emit();
  }

  /** Allows table to filter
   * @param filterValue
  */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    /**
     * Disables check all toggle when the filter has no matches
     */
    if(this.dataSource.filteredData.length == 0){
      this.disableCheck = true;
    }
    else{
      this.disableCheck = false;
    }
  }

  /** Goes through the whole array of IChecked objects to see if any are checked */
  checksPresent() {
    let temp: boolean = true;
    for (let key of Object.keys(this.checkedItems)) {
      if (this.checkedItems[key].checked == true) {
        temp = false;
        break;
      }
    }
    this.isDisabled = temp;
    return temp;
  }

  /** Sets the configurations for the delete dialog and opens it */
  deleteRows() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "",
        label: "",
        value: "",
        validators: [],
        checkedVals: this.checkedItems,
        nameId: this.tableDetails.delete
      }
    ];
    let data: IDialogData = {
      description: "Confirm",
      numCols: 0,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(DeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onDeleteRows.emit(result);
      }
    });
  }

  /**
   * Notifies the implementation that there has been a request to edit a row
   * @param item
   */
  editItem(item: any[]) {
    this.onEditItem.emit(item);
  }

  /**
   * Notifies the implementation that there has been a request to select a row
   * @param item
   */
  selectItem(item: any[]) {
    this.onSelectRow.emit(item);
  }

  selectItems(){
    let selectedItems = [];
    for (let key of Object.keys(this.checkedItems)) {
      if (this.checkedItems[key].checked == true) {
        selectedItems.push(this.checkedItems[key].id);
      }
    }
    this.onSelectRows.emit(selectedItems);
  }

  /**
 * Redirects to the specified route
 * @param system
 */
  goToRoute(id: string) {
    if (this.tableDetails.routing) {
      if (!this.tableDetails.routing.firstId) {
        this.router.navigate([this.tableDetails.routing.routingPath[0], id]);
      } else {
        this.router.navigate([this.tableDetails.routing.routingPath[0], this.tableDetails.routing.firstId, this.tableDetails.routing.routingPath[1], id]);
      }
    }
  }

  /**
   * Takes an item and returns whether or not it is checked using its id
   * @param item
   */
  isChecked(item: any): boolean {
    var idValue = this.tableDetails.deleteId != null ? item[this.tableDetails.deleteId] : item.Id;
    if (this.checkedItems[idValue].checked == true) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Configures the table data for the material table and instantiates checked items
   */
  setTableData() {
    this.serviceData.subscribe(res => {
      if(res == null) res = [];
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.IsTableLoading = false;
      this.dataSource.sortingDataAccessor = (item, property) => {
        return typeof item[property] === "string" ? item[property].toLowerCase() : item[property];
      };
      
      var filterColumns = this.columns.filter(column => column.filterable == true);
      if(filterColumns.length > 0){
        this.dataSource.filterPredicate = (item, filter) => {
          for (var i = 0; i < filterColumns.length; i++) {
            var column = filterColumns[i];
            var itemProperty = column.subColumnDef != null && item[column.columnDef] != null
              ? item[column.columnDef][column.subColumnDef] : item[column.columnDef];
            var filterItem = typeof itemProperty === "string" ? itemProperty.toLowerCase() : itemProperty + '';
            if(filterItem.includes(filter)){
              return item;
            }
          }
          return null;
        };
      }

      this.dataSource.sort = this.sort;
      if (this.tableDetails.delete || this.tableDetails.checkbox) {
        this.checkedItems = {};
        res.map((item) => {
          var idValue = this.tableDetails.deleteId != null ? item[this.tableDetails.deleteId] : item.Id;
          this.checkedItems[idValue] = { id: idValue, checked: false, name: item[this.tableDetails.delete] };
        });
      }
    }, err => {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        return typeof item[property] === "string" ? item[property].toLowerCase() : item[property];
      };
      this.dataSource.sort = this.sort;
    });
  }

  /**
   * Toggles the check mark for an item. If no checks present, disables the delete button
   * @param item
   */
  toggleCheck(item: any) {
    var idValue = this.tableDetails.deleteId != null ? item[this.tableDetails.deleteId] : item.Id;
    if (this.checkedItems[idValue].checked == false) {
      this.isDisabled = false;
      this.checkedItems[idValue].checked = true;
    } else {
      this.checkedItems[idValue].checked = !this.checkedItems[idValue].checked;
      this.checksPresent();
    }

    this.onChecked.emit(this.checkedItems);
  }

  /**
   * Resets the table data and returns a flag to show that the update has been completed
   */
  updateTable() {
    this.updateTableDetails();
    this.setTableData();
    this.checksPresent();
    this.onUpdateTable.emit(false);
  }

  /**
   * Notifies the implementation that there has been a request to view a row
   * @param item
   */
  viewItem(item: any[]) {
    this.onViewItem.emit(item);
  }
}
