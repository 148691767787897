import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ReportingSource } from 'app/models/ReportingSource';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class ReportSourceService {
    private apiPath = 'api/ReportingSource';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets All Sources
     */
     getSources(): Observable<any> {
        return this.http.get(`${this.requestUrl}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Source by Id
     */
    getSourceById(id): Observable<any> {
        return this.http.get(`${this.requestUrl}?id=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets list of Sources
     */
    getSourcePagination(paginated, page, rowsPerPage): Observable<any> {
        return this.http.get(`${this.requestUrl}?paginated=${paginated}
            &page=${page}&rowsPerPage=${rowsPerPage}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds or updates a Source
     * @param source 
     */
    saveSource(source: ReportingSource): Observable<any> {
        return this.http.put<ReportingSource>(this.requestUrl, source, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Deletes Source by Id
     * @param id 
     */
    deleteSource(id) {
        return this.http.delete(`${this.requestUrl}?id=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}