import { OperatorType } from "app/models/operatortype";
import { SourceTable } from "app/models/source-table";
import { SourceColumn } from "app/models/source-column";

export class SourceTableJoinCondition {

    Id: number;
    SourceTableJoinConditionId: number;
    SourceTableJoinId: number;
    SourceMasterSourceTableId: number;
    SourceMasterTable: SourceTable;
    SourceMasterSourceColumnId: number;
    SourceMasterColumn: SourceColumn;
    SourceColumn: SourceColumn;
    SourceColumnId: number;
    SourceColumValues: string;
    ParentSourceTableJoinId: number;
    ParentConnector: string;
    OperatorType: OperatorType;
    JoinOrder: number;
    GroupingValue: string;
    DBFunction: string;
    AliasId: number;

    constructor(joinId: number = -1) {
        this.Id = -1;
        this.SourceTableJoinConditionId = -1;
        this.AliasId = -1;
        this.SourceTableJoinId = joinId;
        this.JoinOrder = null;
        this.DBFunction = 'N';
        this.GroupingValue = null;
        this.ParentConnector = null;
        this.SourceMasterColumn = new SourceColumn();
        this.SourceColumn = new SourceColumn();
        this.OperatorType = new OperatorType();
    }

}
