
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SourceColumn } from 'app/models/source-column';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SourceColumnService {
    private apiPath = 'api/EDWSourceColumn';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all the SourceColumns of a given SourceTable
     * @param tableId number - id of source table
     */
    getSourceColumnsBySourceTable(tableId: number): Observable<any> {
        return this.http.get(`${this.requestUrl}?tableId=${tableId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

        /**
     * Gets all the SourceColumns of a given SourceTable
     * @param columnId number - id of source column
     */
    getSourceColumnById(columnId: number): Observable<any> {
      return this.http.get(`${this.requestUrl}?Id=${columnId}`, httpOptions).pipe(
          catchError(new ErrorHandler().handleError));
  }

    /**
     * Gets all the SourceColumns of a given SourceTable
     * @param tableId number - id of source table
     */
    getAllSourceColumns(): Observable<any> {
        return this.http.get(`${this.requestUrl}?getAll=true`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getDataTypes(databaseType: string): Observable<any> {
      return this.http.get(`${this.requestUrl}?databaseType=${encodeURIComponent(databaseType)}`, httpOptions).pipe(
        catchError(new ErrorHandler().handleError));
    }

    /**
     * Save the Source System from modal
     * @param SourceColumn SourceColumn - source column to save
     */
    saveColumn(SourceColumn: SourceColumn): Observable<any> {
        return this.http.put(this.requestUrl, SourceColumn, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    deleteSourceColumn(id: number): Observable<any> {
      return this.http.delete(`${this.requestUrl}?Id=${id}`, httpOptions).pipe(
        catchError(new ErrorHandler().handleError));
    }

}
