/**
 * Error Information Modal
 * @author David Fink / 4.16.18 /
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'dp-error-information-modal',
  templateUrl: './error-information-modal.component.html',
  styleUrls: ['./error-information-modal.component.scss']
})

export class ErrorInformationModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ErrorInformationModalComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit() {
  }

}
