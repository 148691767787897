/**
 * Source Column Properties Modal Editor - Properties tab
 */
import { Component, Input, OnInit } from '@angular/core';
import { ColumnLineage } from 'app/models/column-lineage';
import { ColumnLineageService } from 'app/services/column-lineage.service';

@Component({
    selector: 'dp-column-lineage-properties',
    templateUrl: './column-lineage-properties.component.html',
    providers: [ColumnLineageService]
})

export class ColumnLineagePropertiesComponent implements OnInit {

    @Input() columnLineage: ColumnLineage = new ColumnLineage();

    private cdcDate: boolean = false;
    private cdcTime: boolean = false;
    private lookup: boolean = false;

    constructor(private columnLineageService: ColumnLineageService) { }

    ngOnInit() {
        this.getCDC();
    }

    /**
     * Get the params from the Database
     * @author John Crabill 
     */
    private getCDC() {
        this.columnLineageService.getColumnLineageProperties(this.columnLineage.Id, this.columnLineage.EDWColumnId, this.columnLineage.SourceColumnId)
            .subscribe(columnLineage => {
                this.columnLineage.CdcDateFlag = columnLineage.CdcDateFlag || 'N';
                this.columnLineage.CdcTimeFlag = columnLineage.CdcTimeFlag || 'N';
                this.columnLineage.UseAsLookupKey = columnLineage.UseAsLookupKey || 'N';
                this.cdcDate = this.columnLineage.CdcDateFlag == 'Y';
                this.cdcTime = this.columnLineage.CdcTimeFlag == 'Y';
                this.lookup = this.columnLineage.UseAsLookupKey == 'Y';
            },
            err => {
                console.log(err);
            });
    }

    /**
     * @param flag 
     * @author Collin Atkins 
     */
    private toggleTime(flag: boolean) {
        this.columnLineage.CdcTimeFlag = flag ? 'Y' : 'N';
    }

    /**
     * @param flag 
     * @author Collin Atkins 
     */
    private toggleDate(flag: boolean) {
        this.columnLineage.CdcDateFlag = flag ? 'Y' : 'N';
    }

    /**
     * @param flag
     * @author Collin Atkins  
     */
    private toggleLookup(flag: boolean) {
        this.columnLineage.UseAsLookupKey = flag ? 'Y' : 'N';
    }

}
