import { Schema } from 'app/models/schema';
import { Term } from 'app/models/term';
import { TargetTable } from 'app/models/target-table';
import { TargetColumn } from 'app/models/target-column';

export class Link {

    term: Term;
    schema: Schema;
    targetTable: TargetTable;
    targetColumn: TargetColumn;

    constructor(term?: Term) {
        this.term = term || new Term();
        this.schema = new Schema;
        this.targetTable = new TargetTable();
        this.targetTable.Id = -1;
        this.targetColumn = new TargetColumn(-1);
        this.targetColumn.Id = -1;
    }

}