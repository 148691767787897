import { Report } from 'app/models/Report';

export class ReportCalculation {
    ReportCalculationGroupId: string;
    ReportCalculationTableName: string;
    ReportCalculationReportId: string;
    ReportCalculationWorkspaceId: string;
    ReportCalculationSourceLocation: string;
    ReportCalculationSourceData: string;
    ReportCalculationSourceType: string;
    ReportCalculationStep: number;
    ReportCalculationType: string;
    ReportCalculationCode: string;
    CreatedDate: Date;
    UpdatedDate: Date;
    CreatedUserId: number;
    UpdatedUserId: number;
    report: Report;
}