import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import { IColumnData, IDialogData, IInputType } from "app/models/dialog-interface";
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { Term } from 'app/models/term';
import { AuthService } from 'app/services/auth.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import { takeUntil } from 'rxjs/operators';
import { SnackBarHandler } from 'utilities/snackbar';
import { Note } from '../../models/note';
import { RelatedTermNew } from '../../resources/RelatedTermNew';
import { TeamUserNew } from '../../resources/TeamUsersNew';
import { CategoryService } from '../../services/category.service';
import { GlossaryService } from '../../services/glossary.service';
import { NotesService } from '../../services/notes.service';
import { SchemaService } from '../../services/schema.service';
import { TargetColumnService } from '../../services/target-column.service';
import { TableService } from '../../services/target-table.service';
import { TeamService } from '../../services/team.service';
import { TermTypeService } from '../../services/term-type.service';
import { TermService } from '../../services/term.service';
import { RelatedTermService } from 'app/services/related-term.service';
import { TeamRoleService }  from 'app/services/team-roles.service';
import { TeamUserService } from 'app/services/team-user.services';
import { Team } from "app/models/team";
import { RelatedTermModalComponent } from 'app/business-glossary/term/related-term-modal/related-term-modal.component';
import { TeamCreateComponent  } from 'app/business-glossary/team/team-create/team-create.component'
import { Auth0ManagementApiService } from 'app/services/auth0-management-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mp-process-flow',
  templateUrl: './process-flow.component.html',
  styleUrls: ['./process-flow.component.scss'],
  providers: [GlossaryService, CategoryService, TermService, TeamService, TeamUserService, TeamRoleService, RelatedTermModalComponent, RelatedTermService, NotesService, SchemaService, TableService, TargetColumnService, TermTypeService]
})
export class ProcessFlowComponent implements OnInit {
  glossaryId: number = -1;
  categoryId: number = -1;
  termId: number = -1;
  teamId: number;
  rawData: any = {
    TermTechnicalDefinition: null,
    TermBusinessDefinition: null,
    TermBusinessRule: null,
    TermDataQualityIssues: null,
    TermName: null
  };
  hierarchy: any = {};
  teamLoad = false;

  relatedTermRef: boolean;
  termNotesRef: boolean;
  teamRef: boolean;

  relatedTermServiceData: any;
  termNotesServiceData: any;
  teamServiceData: any;

  private termName: Observable<Term>;
  private destroy = new Subject<void>();
  targetSystemId: any;
  targetTableId: any;
  targetColumnId: any;
  private permissions = {};

  tableRelatedTermsDetails: ITableDetails;
  tableTermNotesDetails: ITableDetails;
  tableTeamDetails: ITableDetails;

  relationDropdown: IInputType = {
    type: "dropdown",
    dropdownOptions: []
  }
  termRoleDropdown: IInputType = {
    type: "dropdown",
    dropdownOptions: []
  }
  usersDropdown: IInputType = {
    type: "dropdown",
    dropdownOptions: []
  }

  relatedTermsColumns: IColumns[] = [
    {
      columnDef: "Id",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Id}`
    },
    {
      columnDef: "Name",
      header: "Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Name}`
    },
    {
      columnDef: "Description",
      header: "Description",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Description}`
    },
    {
      columnDef: "Relation",
      header: "Relation",
      sortable: true,
      cell: (element: any) => `${element.Relation}`
    }
  ];

  termNotesColumns: IColumns[] = [
    {
      columnDef: "NoteNote",
      header: "Note",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.NoteNote}`
    },
    {
      columnDef: "CreatedUser",
      subColumnDef: "name",
      header: "Created By",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.CreatedUser != null ? element.CreatedUser.name : ""}`
    },
    {
      columnDef: "CreatedDate",
      header: "Created Date",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.CreatedDate.toString().replace("T", " ")}`
    }
  ];

  teamColumns: IColumns[] = [
    {
      columnDef: "Name",
      header: "Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Name}`
    },
    {
      columnDef: "TeamRoleName",
      header: "Role",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.TeamRoleName}`
    }
  ];

  constructor(private route: ActivatedRoute, private termService : TermService, private teamService: TeamService, private router: Router,
     private glossaryService: GlossaryService, private categoryService: CategoryService, private notesService: NotesService, private schemaService: SchemaService,
     private relatedTermService: RelatedTermService, private targetTableService: TableService, private dialog: MatDialog, private targetColumnService: TargetColumnService,
     private teamUserService: TeamUserService, private teamRoleService: TeamRoleService, private authService: AuthService, private termTypeService: TermTypeService, private snackBarHandler: SnackBarHandler,
     private routeBreadcrumbService: RouteBreadcrumbService, private auth0Service: Auth0ManagementApiService){
      this.glossaryId = Number(this.route.snapshot.paramMap.get('id'));
      this.categoryId = Number(this.route.snapshot.paramMap.get('id2'));
      this.termId = Number(this.route.snapshot.paramMap.get('id3'));
      this.termTypes = this.termTypeService.getTermTypes();
      this.termName = this.termService.getTermById(this.termId);
      var routingPath = ["/business-glossary/glossary/" + this.glossaryId + "/category/" + this.categoryId + "/term/" + this.termId];

      this.tableRelatedTermsDetails = { routing: {routingPath: routingPath}, edit: false };
      this.tableTermNotesDetails = { edit: false };
      this.tableTeamDetails = { routing: {routingPath: routingPath}, edit: false };

      this.relatedTermServiceData = this.getFakeSubscribe([]);
      this.termNotesServiceData = this.authService.getFakeSubscribe([]);
      this.teamServiceData = this.getFakeSubscribe([]);

      this.authService.authorizeSection().subscribe(permissions =>{
        this.permissions = permissions;
        if(permissions.view){
          var relatedDetails = { routing: {routingPath: routingPath}, edit: permissions.update }
          var termNotesDetails = { edit: false }
          var teamDetails = { routing: {routingPath: routingPath}, edit: false }
          if(permissions.add){
            relatedDetails['add'] = "Related Term";
            termNotesDetails['add'] = "Note";
            teamDetails['add'] = "Member";
          }
          if(permissions.delete){
            relatedDetails['delete'] = "Name";
            termNotesDetails['delete'] = "Name";
            teamDetails['delete'] = "Name";
          }

          this.tableRelatedTermsDetails  = relatedDetails;
          this.tableTermNotesDetails = termNotesDetails;
          this.tableTeamDetails = teamDetails;
          this.termNotesServiceData = this.notesService.getNotesByParentId(this.termId);
          this.retrieveTerm();
          this.getTeams();
          this.termNotesRef = true;
        }
        else {
          this.router.navigate(['/']);
        }
      });
      this.termNotesRef = false;
     };

  data: any;
  definitionsData: any;
  targetColumnData: any;
  termTypes: any[];
  relatedTerms: any[] = [];
  users: any[] = [];
  teamRoles: any[] = [];
  hasRelatedTerms = false;
  hasTeam = false;
  team: any = {"TeamName": '', "TeamId": -1};

  rawRelatedTerms : any[];
  teamMembers: any[] = [];
  currentProcess = "Definitions";
  targetSystems = [];
  targetTables = [];
  filteredTargetTables = [];
  targetColumns = [];
  teams: Team[] = [];

  ngOnInit() {
    this.targetTableService.getTables().subscribe(data => {
      if (data) {
        this.targetTables = data;
        this.filteredTargetTables = [];
      }
    }, err => {
      console.error(err);
    })

    this.schemaService.getSchemas().subscribe(data => {
      if (data) {
        this.targetSystems = data;
      }
    }, err => {
      console.error(err);
    });

    this.termName.pipe(takeUntil(this.destroy)).subscribe(term => {
      this.categoryService.getCategoryById(this.categoryId).pipe(takeUntil(this.destroy)).subscribe(category => {
        this.glossaryService.getGlossaryById(this.glossaryId).subscribe(glossary => {
          this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Business Glosssary", glossary.BusinessGlossaryName, category.CategoryName, term.TermName], ["business-glossary/", "business-glossary/glossary/" + this.glossaryId, "business-glossary/glossary/" + this.glossaryId + "/category/" + this.categoryId, "business-glossary/glossary/" + this.glossaryId + "/category/" + this.categoryId + "/term/" + this.termId]);
        })
      })
    })

    this.authService.getUserJwt().subscribe(jwt =>{
      this.auth0Service.getUsers(jwt["https://metaprism.com/tenant_id"]).subscribe(users =>{
        this.users = users;
        this.usersDropdown.dropdownOptions = [];
        for(var i = 0; i < this.users.length; i++){
          this.usersDropdown.dropdownOptions.push(this.users[i].name);
        }
        this.usersDropdown.dropdownOptions.sort();
      });
    });

    this.teamRoleService.getTeamRoles().subscribe(teamroles => {
      this.teamRoles = teamroles;
      this.termRoleDropdown.dropdownOptions = [];
        for(var i = 0; i < this.teamRoles.length; i++){
          this.termRoleDropdown.dropdownOptions.push(this.teamRoles[i].TeamRoleName);
        }
        this.termRoleDropdown.dropdownOptions.sort();
    }, err => console.error(err));
  }

  ngOnDestroy() {
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  retrieveTerm() {
    this.termService.getTermById(this.termId).subscribe(data => {
      this.rawData = data;
      //needed to get relatedTerm
      this.relatedTerms = [];
      if(data){
        if(this.rawData.RelatedTerms){
          this.hasRelatedTerms = true;
          this.relatedTerms = [];
          this.rawRelatedTerms = this.rawData.RelatedTerms;
          for(let i = 0; i < this.rawData.RelatedTerms.length; i++){
            let type = "";
            for(let j = 0; j < this.termTypes.length; j++){
              if(this.rawRelatedTerms[i].TermType === this.termTypes[j].Id){
                type = this.termTypes[j].TermType;
              }
            }
            this.relatedTerms.push(new RelatedTermNew(this.rawRelatedTerms[i].RelationId, this.termId, this.rawRelatedTerms[i].Id, this.rawRelatedTerms[i].TermName, this.rawRelatedTerms[i].TermDescription, type, this.rawRelatedTerms[i].TermType))
          }
        }
      }
      this.relationDropdown.dropdownOptions = [];
      for(var i = 0; i < this.termTypes.length; i++){
        this.relationDropdown.dropdownOptions.push(this.termTypes[i].TermType);
      }
      this.relatedTermServiceData = this.getFakeSubscribe(this.relatedTerms);
      this.resetRelatedTermsRefresh(true);

      this.getTeamforTerm();

      if(this.rawData.targetColumn != 0){
        this.retrieveTargetColumn(this.rawData.targetColumn);
      }
      
      this.data = this.rawData;
      this.definitionsData = this.rawData;
      this.targetColumnData = this.rawData;
    }, err => {
      console.error(err);
    });
  }

  getTeamforTerm(){
    this.teamId = this.rawData.TermTeamId;
    this.teamService.getTeamById(this.teamId).subscribe(data =>{
        if(data){
          this.hasTeam = true;
          this.team = data;
          this.teamMembers = [];
          if(this.team.TeamUsers != null)
            for(let i = 0; i < this.team.TeamUsers.length; i++){
              if(this.team.TeamUsers[i].TeamUserUser == null)
              {
                /// User does not exist
              } 
              else 
              {
                this.teamMembers.push(new TeamUserNew(this.team.TeamUsers[i].TeamUserId, this.team.TeamUsers[i].TeamUserUser.name, this.team.TeamUsers[i].TeamUserRole.TeamRoleName));          
              }
           }
        }
        this.teamServiceData = this.getFakeSubscribe(this.teamMembers);
        this.resetTeamsRefresh(true);
    }, err => {
        this.teamServiceData = this.getFakeSubscribe([]);
        console.error(err);
    });
  }

  getFakeSubscribe(array): Observable<any> {
    return Observable.of(array);
  }

  retrieveTargetColumn(targetColumnId){
    this.targetColumnService.getColumnById(targetColumnId).subscribe(data =>{
      if(data){
        this.targetColumnId = targetColumnId + "";
        this.targetTableId = data[0].EDWTableId + "";
        if(this.targetTables.length > 0){
          this.targetSystemId = this.targetTables.find(x => x.Id == this.targetTableId).SchemaId;
          this.filteredTargetTables = this.targetTables.filter(x => x.SchemaId == this.targetSystemId);

          this.targetColumnService.getTargetColumnsByTargetTable(this.targetTableId).subscribe(data =>{
            if(data){
              this.targetColumns = data;
            }
          }, err =>{
            console.error(err);
          });
        }
      }
    }, err =>{
      console.error(err);
    })
  }

  getTeams() {
    this.teamService.getTeams().subscribe(teams => {
      this.teams = teams;
      this.teams = this.teams.sort();
      this.teamLoad = true;
    }, err => {
      this.teamLoad = true;
      console.error(err)
    });
  }

  changeSelected(event) {
    this.currentProcess = event.srcElement.innerText;
  }

  updateDefinitionsData(event) {
    this.definitionsData[event.target.name] = event.target.value;
    this.data[event.target.name] = event.target.value;
  }

  updateTeam(event) {
    this.team[event.target.name] = event.target.value;
  }

  updateTargetColumnData(event) {
    this.targetColumnData[event.target.name] = event.target.value;
    this.data[event.target.name] = event.target.value;
  }

  saveProcess(){
    this.rawData.targetColumn = this.targetColumnId;
    this.termService.saveTerm(this.rawData).subscribe(savedTerm => {
      if (savedTerm) {
        this.snackBarHandler.open(`Success: Term saved`, 'success');
      }
    }, err => this.snackBarHandler.open(`Failure: Term not saved`, 'failure'));
  }

  changeSystemFilter(event){
    this.targetSystemId = event.value;
    this.filteredTargetTables = this.targetTables.filter(x => x.SchemaId == this.targetSystemId);
  }

  changeTableFilter(event){
    this.targetTableId = event.value;
    this.targetColumnService.getTargetColumnsByTargetTable(this.targetTableId).subscribe(data =>{
      if(data){
        this.targetColumns = data;
      }
    }, err =>{
      console.error(err);
    });
  }

  changeColumnId(event){
    this.targetColumnId = event.value;
  }

  //Related Terms

  resetRelatedTermsRefresh(value: boolean) {
    this.relatedTermRef = value;
  }

  editRelatedTermsModal({ Id, Name, RelatedTermParentId, RelatedTermChildId, Relation }: RelatedTermNew) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "RelatedTermParentId",
        label: -1,
        value: RelatedTermParentId,
        validators: []
      },
      {
        id: "RelatedTermChildId",
        label: -1,
        value: RelatedTermChildId,
        validators: []
      },
      {
        id: "Relation",
        label: "Relation",
        value: Relation,
        validators: ["required"],
        inputType: this.relationDropdown
      }
    ];
    let data: IDialogData = {
      description: "Edit " + Name,
      numCols: 2,
      columnData: columnData
    }
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(EditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.RelatedTermTypeId = result.Relation != "" ? this.termTypes.find(x => x.TermType == result.Relation).Id : 0;
        this.relatedTermService.saveRelatedTerm(result).subscribe(savedRelatedTerm => {
          if (savedRelatedTerm) {
            this.snackBarHandler.open(`Success: Edited Related Term`, 'success');
            this.relatedTermRef = true;
            this.retrieveTerm();
          }
        }, err => this.snackBarHandler.open(`Failure: Related Term not edited`, 'failure'));
      }
    });

  }

  viewRelatedTermsModal({ Id, Name, Description, Relation }: RelatedTermNew) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "Name",
        label: "Name",
        value: Name,
        validators: ["required"]
      },
      {
        id: "Description",
        label: "Description",
        value: Description,
        validators: ["required"]
      },
      {
        id: "Relation",
        label: "Relation",
        value: Relation,
        validators: ["required"]
      }
    ];
    let data: IDialogData = {
      description: "View " + Name,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }


  addRelatedTermsModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { Term: this.rawData};
    const dialogRef = this.dialog.open(RelatedTermModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.relatedTermRef = true;
        this.retrieveTerm();
      }
    });

  }

  deleteRelatedTermsRows(rows: number[]) {
    for (let row of rows) {
      this.termService.deleteRelationTerm(row).subscribe(result => {
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.relatedTermRef = true;
        this.retrieveTerm();
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }


  //Notes

  resetTermNotesRefresh(value: boolean) {
    this.termNotesRef = value;
  }

  viewTermNotesModal({ Id, NoteNote, CreatedUser, CreatedDate }: Note) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "ParentId",
        label: -1,
        value: this.termId,
        validators: []
      },
      {
        id: "NoteNote",
        label: "Note",
        value: NoteNote,
        validators: ["required"]
      },
      {
        id: "CreatedBy",
        label: "Created By",
        value: CreatedUser != null ? CreatedUser.name : "",
        validators: ["required"]
      },
      {
        id: "CreatedDate",
        label: "Created Date",
        value: CreatedDate,
        validators: ["required"]
      }
    ];
    let data: IDialogData = {
      description: "View Note",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }


  addTermNotesModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "ParentId",
        label: -1,
        value: this.termId,
        validators: []
      },
      {
        id: "NoteNote",
        label: "Note",
        value: "",
        validators: ["required"]
      }
    ];
    let data = {
      description: "Add Term Note",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(AddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notesService.saveNote(result).subscribe(savedNote => {
          if (savedNote) {
            this.snackBarHandler.open(`Success: Added '${result.NoteNote}'`, 'success');
            this.termNotesRef = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Note not added`, 'failure'));
      }
    });

  }

  deleteTermNotesRows(rows: number[]) {
    for (let row of rows) {
      this.notesService.deleteNote(row).subscribe(result => {
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.termNotesRef = true;
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }

  //Teams
  
  resetTeamsRefresh(value: boolean) {
    this.teamRef = value;
  }

  viewTeamsModal({ Name, TeamRoleName }: TeamUserNew) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Name",
        label: "Name",
        value: Name,
        validators: ["required"]
      },
      {
        id: "TeamRole",
        label: "Role",
        value: TeamRoleName,
        validators: ["required"]
      }
    ];
    let data: IDialogData = {
      description: "View Team Member",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }

  manageTeamModel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = {term: this.rawData, teams: this.teams, team: this.team};
    const dialogRef = this.dialog.open(TeamCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Checks to see if a Term or a Team
        if(result.Id == this.termId){
          this.teamId = result.TermTeamId;
          this.rawData.TermTeamId = this.teamId;
          this.team = result.TermTeam;
          this.getTeamforTerm();
          this.saveProcess();

          if(this.teams.find(x => x.TeamId == result.TermTeamId) == null){
            this.teams.push(result.TermTeam);
          }
        }
        else {
          this.teams.push(result);
        }
      }
    });
  }

  addTeamsModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "TeamId",
        label: -1,
        value: this.teamId,
        validators: []
      },
      {
        id: "TeamUser",
        label: "Name",
        value: "",
        validators: ["required"],
        inputType: this.usersDropdown
      },
      {
        id: "TeamRole",
        label: "Role",
        value: "",
        validators: ["required"],
        inputType: this.termRoleDropdown
      }
    ];
    let data = {
      description: "Add Team Member",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(AddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.TeamUserId = result.TeamUser != "" ? this.users.find(x => x.name == result.TeamUser).user_id : 0;
        result.TeamRoleId = result.TeamRole != "" ? this.teamRoles.find(x => x.TeamRoleName == result.TeamRole).Id : 0;
        this.teamUserService.saveTeamUser(result).subscribe(savedNote => {
          if (savedNote) {
            this.snackBarHandler.open(`Success: Added '${result.TeamUser}'`, 'success');
            this.teamRef = true;
            this.getTeamforTerm();
          }
        }, err => this.snackBarHandler.open(`Failure: Team Member not added`, 'failure'));
      }
    });

  }

  deleteTeamsRows(rows) {
    for (let row of rows) {
      this.teamUserService.deleteTeamUser(this.teamId, row.id).subscribe(result => {
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.teamRef = true;
        this.getTeamforTerm();
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }

}
