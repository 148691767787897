import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { MatAccordionDisplayMode, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { Category } from 'app/models/category';
import { Glossary } from 'app/models/glossary';
import { SourceColumn } from 'app/models/source-column';
import { SourceSystem } from 'app/models/source-system';
import { SourceTable } from 'app/models/source-table';
import { Term } from 'app/models/term';
import { CategoryService } from 'app/services/category.service';
import { GlossaryService } from 'app/services/glossary.service';
import { SourceColumnService } from 'app/services/source-column.service';
import { SourceSystemService } from 'app/services/source-system.service';
import { SourceTableService } from 'app/services/source-table.service';
import { SharedService } from 'app/services/shared.service';
import { TermService } from 'app/services/term.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';


class INav {
  name: string;
  id: number;
  expanded: boolean;
  nextLvl: any;

  constructor(name: string, id: number, nextLvl: any) {
    this.name = name;
    this.id = id;
    this.expanded = false;
    this.nextLvl = nextLvl;
  }
}

@Component({
  selector: 'dp-nav-explorer-new',
  templateUrl: './nav-explorer-new.component.html',
  styleUrls: ['./nav-explorer-new.component.scss'],
  providers: [SourceSystemService, SourceTableService, SourceColumnService, GlossaryService, CategoryService, TermService]
})
export class NavExplorerNewComponent implements OnInit {

  searchTerm: string = "";
  flat: MatAccordionDisplayMode = "flat";
  icons: string[] = [];
  private data = new BehaviorSubject<INav[]>([]);
  data$ = this.data.asObservable();

  preventSingleClick = false;
  timer: any;
  delay: Number;

  updateBGNavSubscription: Subscription; //Subscription used to handle BG nav updates
  updateSourceSystemNavSubscription: Subscription; //Subscription used to handle source system nav updates



  @Input() pageType: string;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sourceSystemService: SourceSystemService,
    private sourceTableService: SourceTableService,
    private sourceColumnsService: SourceColumnService,
    private glossaryService: GlossaryService,
    private categoryService: CategoryService,
    private termService: TermService,
    private sharedService: SharedService,
    private router: Router) {

      /* When called from the BG view this will update the navigation data */
      this.updateBGNavSubscription = this.sharedService.handleBGUpdateRequest().subscribe(()=>{
        this.getBusinessGlossaries();
      })

      /* When called from the Source System view this will update the navigation data */
      this.updateSourceSystemNavSubscription = this.sharedService.handleSourceSystemUpdateRequest().subscribe(()=>{
        this.getSourceSystems();
      })
  }

  ngOnInit() {
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons.svg`));
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['pageType'];
    if (change.currentValue === "Source") {
      this.icons = ["System", "Table", "Column"];
      this.getSourceSystems();

    } else if (change.currentValue === "Business") {
      this.icons = ["Glossary", "Category", "Term"];
      this.getBusinessGlossaries();

    }
  }

  getSourceSystems() {
    this.sourceSystemService.getAllSourceSystems()
      .pipe(map((sources: SourceSystem[]) => {
        return sources.map((source: SourceSystem) => {
          return new INav(source.Name, source.Id, null);
        })
      }))
      .subscribe(result => {
        this.data.next(result);
      })
  }


  getBusinessGlossaries() {
    this.glossaryService.getGlossaries()
      .pipe(map((glossaries: Glossary[]) => {
        return glossaries.map((glossary: Glossary) => {
          return new INav(glossary.BusinessGlossaryName, glossary.Id, null);
        })
      }))
      .subscribe(result => {
        this.data.next(result);
      })
  }

  getSourceTables(id: number) {
    return this.sourceTableService.getSourceTablesBySourceSystem(id).pipe(map((tables: SourceTable[]) => {
      return tables.map((table: SourceTable) => {
        return new INav(table.DatabaseName, table.Id, null);
      });
    }));
  }

  getBusinessCategories(id: number) {
    return this.categoryService.getCategoriesByGlossaryId(id).pipe(map((categories: Category[]) => {
      return categories.map((category: Category) => {
        return new INav(category.CategoryName, category.Id, null);
      });
    }));
  }

  getSourceColumns(id: number) {
    return this.sourceColumnsService.getSourceColumnsBySourceTable(id).pipe(map((columns: SourceColumn[]) => {
      return columns.map((column: SourceColumn) => {
        return new INav(column.DatabaseName, column.Id, null);
      });
    }));
  }

  getBusinessTerms(id: number) {
    return this.termService.getTermByCategoryId(id).pipe(map((terms: Term[]) => {
      return terms.map((term: Term) => {
        return new INav(term.TermName, term.Id, null);
      });
    }));
  }

  navigateToSecondLevel(name: string, id: number, expanded: boolean) {
    this.preventSingleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSingleClick) {
        const newData = this.data.getValue().map(item => {
          if (item.id == id) {
            let nav = new INav(name, id, item.nextLvl);
            if (!item.nextLvl) {
              if (this.pageType === "Source") {
                this.getSourceTables(id).subscribe(resp => {
                  nav.nextLvl = resp;
                }, error => {}
                );
              } else if (this.pageType === "Business") {
                this.getBusinessCategories(id).subscribe(resp => {
                  nav.nextLvl = resp;
                },  error => {}
                );
              }
            }
            nav.expanded = !expanded;
            item = nav;
          } else if (item.id == id && item.nextLvl != null) {
          }
          return item;
        }, error => {});
        this.data.next(newData);
      }
    }, delay);

  }

  navigateToThirdLevel(name: string, id: number, prevId: number, expanded: boolean) {

    this.preventSingleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSingleClick) {

        const newData = this.data.getValue().map(item => {
          if (item.id == prevId && item.nextLvl != null) {
            item.nextLvl.forEach((val, index) => {
              if (val.id == id) {
                let nav = new INav(name, id, val.nextLvl);
                if (!val.nextLvl) {
                  if (this.pageType === "Source") {
                    this.getSourceColumns(id).subscribe(resp => {
                      nav.nextLvl = resp;
                    }, error => {});
                  } else if (this.pageType === "Business") {
                    this.getBusinessTerms(id).subscribe(resp => {
                      nav.nextLvl = resp;
                    }, error => {});
                  }
                }
                nav.expanded = !expanded;
                item.nextLvl[index] = nav;
              }
            })
          }
          return item;
        }, error => {});
        this.data.next(newData);
      }
    }, delay);

  }

  navigateToRoute(id: number, level: number) {
    this.preventSingleClick = true;
    clearTimeout(this.timer);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if (this.pageType === "Source") {
      switch (level) {
        case 0: {
          this.router.navigate(["/source/tables/" + id]);
          break;
        }
        case 1: {
          this.sourceTableService.getSourceTableById(id).subscribe(resp => {
            this.router.navigate(["/source/tables/" + resp.SourceSystemId + "/columns/" + id])
          });
          break;
        }
        case 2: {
          this.sourceColumnsService.getSourceColumnById(id).subscribe(column => {
            this.sourceTableService.getSourceTableById(column.SourceTableId).subscribe(table => {
              this.router.navigate(["/source/tables/" +  table.SourceSystemId + "/columns/" + table.Id])

            })
          });
          break;
        }
      }
    } else if (this.pageType === "Business") {
      switch (level) {
        case 0: {
          this.router.navigate(["/business-glossary/glossary/" + id]);
          break;
        }
        case 1: {
          this.categoryService.getCategoryById(id).subscribe((category: Category) => {
            this.router.navigate(["/business-glossary/glossary/" + category.BusinessGlossaryId + "/category/" + id])
          });
          break;
        }
        case 2: {
          this.termService.getTermById(id).subscribe((term: Term) => {
            this.router.navigate(["/business-glossary/glossary/" + term.Category.BusinessGlossaryId + "/category/" + term.CategoryId + "/term/" + id])
          });
          break;
        }
      }
    }
  }




}
