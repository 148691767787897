import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { ReportService } from 'app/services/reporting-report.service';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';

@Component({
    selector: 'import-reports-dialog',
    templateUrl: 'import-reports-dialog.component.html',
    styleUrls: ['import-reports-dialog.component.scss'],
    providers: [ReportService]
})

export class ImportReportsDialogComponent implements OnInit {

    @ViewChild(DataTableDirective)

    private importArray = [];
    private reports = [];
    private hasChecked: boolean;

    tableDetails: ITableDetails;
    serviceData: any;
    ref: boolean;

    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: 'Temp Id',
        sortable: true,
        cell: (element: any) => `${element.Id}`
      },
      {
        columnDef: "ReportName",
        header: 'Report Name',
        sortable: true,
        cell: (element: any) => `${element.ReportName}`
      },
      {
        columnDef: "ReportCategory",
        header: 'Report Category',
        sortable: true,
        cell: (element: any) => `${element.ReportCategory}`
      }
    ];

    constructor(private dialogRef: MatDialogRef<ImportReportsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private reportService: ReportService, private snackBarHandler: SnackBarHandler) {
        }

    ngOnInit() {
        this.getReports();
        this.serviceData = this.getFakeSubscribe([]);
        this.tableDetails = {routing: null, edit: false, connect:false, noView: true, checkbox:true};
        this.hasChecked = false;
    }

    /**
     * Created because the dp-table component needs a observable
     * This allows the manipulation of data before insertinging it
     * into the dp-table component
     */
    getFakeSubscribe(array): Observable<any> {
      return Observable.of(array);
    }

    private resetRefresh(value: boolean){
      this.ref = value;
    }

    /**
     * Gets a list of items from the table and
     * enables or disables the save button depending
     * on if one is checked or not
     */
    private isChecked(checkedTables){
        this.importArray = checkedTables;
        let checkedCount = 0;
        for (let key of Object.keys(checkedTables)) {
          if(checkedTables[key].checked == true){
            checkedCount++;
          }
        }
        if(checkedCount > 0){
          this.hasChecked = true;
        }
        else{
          this.hasChecked = false;
        }
    }

    private getReports() {
        this.reportService.retrieveReportsFromSourceLocation(this.data.SourceId).subscribe(reports => {
          this.serviceData = this.getFakeSubscribe(reports);
          this.reports = reports;
          this.resetRefresh(true);
        }, err => {
            this.dialogRef.close();
            this.snackBarHandler.open('Connection to reports failed.', 'failure')
        });
    }

    private saveReports() {
        const trueImport = [];
        for (let key of Object.keys(this.importArray)) {
          if(this.importArray[key].checked == true){
            var report = this.reports.filter(x => x.Id == this.importArray[key].id)[0];
            report.Id = 0
            trueImport.push(report);
          }
        }
        this.dialogRef.close(trueImport);
    }
}
