/**
 * Add/Edit User Modal
 * @author Collin Atkins
 *
 * TODO - Add old user password field for edit, and verify it matches on save. Encrypt password before sending to service.
 */
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { SnackBarHandler } from '../../../../utilities/snackbar';
import { UserService } from 'app/services/user.service';
import { Role } from 'app/models/role';
import { User } from 'app/models/user';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Auth0ManagementApiService } from 'app/services/auth0-management-api.service';
import { Auth0User } from 'app/models/Auth0User';
import { AuthService } from 'app/services/auth.service'
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
    selector: 'dp-add-user-modal',
    templateUrl: './add-user-modal.component.html',
    styleUrls: ['./add-user-modal.component.scss'],
    providers: [UserService, SnackBarHandler]
})

export class AddUserModalComponent implements OnInit {
    showPassword1 = false;
    showPassword2 = false;
    private Auth0User: Auth0User = new Auth0User();
    nameControl: FormControl = new FormControl();
    emailControl: FormControl = new FormControl();
    password1Control: FormControl = new FormControl("", this.customValidator(/^$|(?=.{8,50})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/, 
    "Requires 8 characters and 3 types of: uppercase, lowercase, numbers or special characters"));
    password2Control: FormControl = new FormControl("", this.sameValue());

    constructor(private snackBarHandler: SnackBarHandler,
        private dialogRef: MatDialogRef<AddUserModalComponent>,
        private auth0Service: Auth0ManagementApiService,
        private authService: AuthService,
        @Inject(MAT_DIALOG_DATA) private data) { }

    ngOnInit() {
        if (this.data) {
            this.setUserName(this.Auth0User);
        } else {
            this.dialogRef.close();
            this.snackBarHandler.open('Invalid parameters', 'danger');
        }
    }

    /**
     * Calls Auth0ManagementAPIService to edit existing user
     */
    private editUser(auth0User: Auth0User) {
        var name = this.nameControl.value;
        auth0User.name = name;
        auth0User.email = this.emailControl.value;
        
        if(this.password1Control.value != "")
            auth0User.password = this.password1Control.value

        this.auth0Service.saveUpdatedUser(auth0User).subscribe(auth0User => {
            this.snackBarHandler.open(name + ' has been saved.', 'success');
            this.dialogRef.close(auth0User);
        }, err => {
            this.dialogRef.close();
            console.log(err);
            this.snackBarHandler.open('Failed to edit user.', 'failure');
        });
    }

    /**
     * Calls Auth0ManagementAPIService to set a user's name
     */
    private setUserName(auth0User: Auth0User): string{
        this.authService.getUserId()
            .subscribe(userId => {
                auth0User.user_id = userId;
                this.auth0Service.getUserDetails(userId)
                    .subscribe(user => {
                        this.authService.getUserJwt().subscribe(jwt => {
                            auth0User.name = user.name;
                            auth0User.email = user.email;
                            auth0User.tenantName = jwt["https://metaprism.com/tenant_id"];
                            this.nameControl.setValue(user.name);
                            this.emailControl.setValue(user.email);
                        });
                    }, err => {
                        console.log(err);
                    });
            });
            return auth0User.name;

        }

          /**
   * Returns true if there is an error for the input field, false otherwise
   * @param controlName
   */
  hasError(controlName: FormControl): boolean {
    this.password2Control.updateValueAndValidity();
    let result = false;
    let controlErrors: ValidationErrors = controlName.errors;
    if (controlErrors != null) {
      result = true;
    }
    return result;
  }

  sameValue(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  
        (control.value == "" && this.password1Control.value  == "") ||
        control.value == this.password1Control.value 
            ? null : {sameValue: control.value};
    }

  customValidator(nameRe: RegExp, errorName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!nameRe.test(control.value)) {
        let temp = {};
        temp[errorName] = true;
        return temp;
      }
    };
  }

  
    
}

