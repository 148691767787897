import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { IColumnData, IInputType } from 'app/models/dialog-interface';
import { ReportCalculation } from 'app/models/ReportCalculation';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { ReportCalculationService } from 'app/services/reporting-calculation.service';
import { TableService } from 'app/services/target-table.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { Subject } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'dp-filebased-source-calculation',
    templateUrl: './calculation.component.html',
    providers: [ReportCalculationService, TableService, SnackBarHandler],
    'styleUrls': ['./calculation.component.scss']
})

export class FileBasedSourceCalculation implements OnInit {

    @ViewChild(DataTableDirective)
    powerUser:boolean = true;
    id: string;
    id2: string;
    id3: string;
    serviceData:any;
    ref: boolean;
    tableDetails: ITableDetails;
    private destroy = new Subject<void>();
    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: "Id",
        sortable: true,
        cell: (element: any) => `${element.Id}`
      },
      {
        columnDef: "ReportCalculationStep",
        header: "Step",
        sortable: true,
        cell: (element: any) => `${element.ReportCalculationStep}`
      },
      {
        columnDef: "ReportCalculationType",
        header: "Type",
        sortable: true,
        cell: (element: any) => `${element.ReportCalculationType}`
      },
      {
        columnDef: "ReportCalculationTableName",
        header: "Table Name",
        sortable: true,
        cell: (element: any) => `${element.ReportCalculationTableName}`
      },
      {
        columnDef: "ReportCalculationCode",
        header: "Function",
        sortable: true,
        cell: (element: any) => `${element.ReportCalculationCode}`
      }
    ];


    constructor(
      private route: ActivatedRoute, 
      private reportCalculationService: ReportCalculationService,
      private snackBarHandler: SnackBarHandler,
      private router: Router,
      private authService: AuthService,
      private dialog: MatDialog,
      private routeBreadcrumbService: RouteBreadcrumbService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.id2 = this.route.snapshot.paramMap.get('id2');
        this.id3 = this.route.snapshot.paramMap.get('id3');

        this.tableDetails = { edit: false };
        this.serviceData = this.authService.getFakeSubscribe([]);
        this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            var details = { edit: permissions.update }
            if(permissions.add){
              details['add'] = "Calculation";
            }
            if(permissions.delete){
              details['delete'] = "ReportCalculationType";
              details['deleteId'] = "Id";
            }

            this.tableDetails = details;
            this.serviceData = this.reportCalculationService.getReportCalculationsByReportIds(this.id2, this.id3);
            this.ref = true;
          }
          else {
            this.router.navigate(['/']);
          }
        });
        this.ref = false;
      }

    ngOnInit() {
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Filebased Calculations", this.id2], ["filesource/", "filesource/reports/" + this.id]);
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
      this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
    }

    /**
      * This function handles the creation of the add dialog as well as
      * handling the addition of sources to the database
      */
     private addModal() {
      var firstCalculation = new ReportCalculation();
      //Retrieves information necessary from other calculations in group
      this.serviceData.subscribe(res => {
        firstCalculation = res[0];
      });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      let multiLine: IInputType = {type: 'multiline'};
      
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: -1,
          validators: []
        },
        {
          id: "ReportCalculationStep",
          label: "Step",
          value: "",
          validators: ["required"]
        },
        {
          id: "ReportCalculationType",
          label: "Type",
          value: "",
          validators: ["required"]
        },
        {
          id: "ReportCalculationCode",
          label: "Function",
          value: "",
          validators: ["required"],
          inputType: multiLine
        }
      ];
      let data = {
        description: "Add Source",
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(AddComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            result.ReportCalculationGroupId = firstCalculation.ReportCalculationGroupId;
            result.ReportCalculationReportId = firstCalculation.ReportCalculationReportId;
            result.ReportCalculationSourceData = firstCalculation.ReportCalculationSourceData;
            result.ReportCalculationSourceLocation = firstCalculation.ReportCalculationSourceLocation;
            result.ReportCalculationSourceType = firstCalculation.ReportCalculationSourceType;
            result.ReportCalculationTableName = firstCalculation.ReportCalculationTableName;
            result.ReportCalculationWorkspaceId = firstCalculation.ReportCalculationWorkspaceId;
            this.reportCalculationService.saveReportCalculation(result).subscribe(savedSource => {
              if (savedSource) {
                this.snackBarHandler.open(`Success: Added Step '${result.ReportCalculationStep}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Calculation not added`, 'failure'));
        }
      });
    }

    private editModal(calculation: ReportCalculation){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      let multiLine: IInputType = {type: 'multiline'};

      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: -1,
          validators: []
        },
        {
          id: "ReportCalculationStep",
          label: "Step",
          value: calculation.ReportCalculationStep,
          validators: ["required"]
        },
        {
          id: "ReportCalculationType",
          label: "Type",
          value: calculation.ReportCalculationType,
          validators: ["required"]
        },
        {
          id: "ReportCalculationCode",
          label: "Function",
          value: calculation.ReportCalculationCode,
          validators: ["required"],
          inputType: multiLine
        }
      ];
      let data = {
        description: "Edit Step " + calculation.ReportCalculationStep,
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(EditComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          calculation.ReportCalculationStep = result.ReportCalculationStep;
          calculation.ReportCalculationType = result.ReportCalculationType;
          calculation.ReportCalculationCode = result.ReportCalculationCode;
            this.reportCalculationService.saveReportCalculation(calculation).subscribe(savedGroup => {
              if (savedGroup) {
                this.snackBarHandler.open(`Success: Edited Step '${result.ReportCalculationStep}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Calculation not edited`, 'failure'));
        }
      });
    }

    private deleteRows(rows: ReportCalculation[]){
      for (let row of rows) {
        this.reportCalculationService.deleteReportCalculation(row).subscribe(result => {
          this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
          this.ref = true;
        }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
      }
    }

    resetRefresh(value: boolean) {
      this.ref = value;
    }
}