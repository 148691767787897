
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { TableLineage } from 'app/models/table-lineage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TableLineageService {
    private apiPath = 'api/EDWTableLineage';
    private apiPathSSIS = 'api/SSISGenerator';
    private lineageRequestUrl = environment.serviceUrl + this.apiPath;
    private ssisRequestUrl = environment.serviceUrl + this.apiPathSSIS;

    constructor(private http: HttpClient) { }

    /**
     * Deletes a Table Lineage by Id
     * @param tableLineageId
     * @author Nash Lindley
     */
    deleteTableLineageById(tableLineageId: number): Observable<any> {
        return this.http.delete(`${this.lineageRequestUrl}?Id=${tableLineageId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets TableLineages given a TargetTable and SourceSystem
     * @param tableId - target table id
     * @param systemId - source system id
     * @author Nash Lindley
     */
    getTableLineageByTableAndSystem(tableId: number, systemId: number): Observable<any> {
        return this.http.get(this.lineageRequestUrl + '?TargetTableId=' + tableId + '&SourceSystemId=' + systemId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets All Columns of tableLineages
     * @param tableId - target table id
     * @param systemId - source system id
     * @author Nash Lindley
     */
    getTableLineageAll(tableId: number, systemId: number): Observable<any> {
        return this.http.get(`${this.lineageRequestUrl}?TargetTableId=${tableId}&SourceSystemId=${systemId}&Type=All`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Numerics of tableLineages
     * @param tableId - target table id
     * @param systemId - source system id
     * @author Nash Lindley
     */
    getTableLineageNumerics(tableId: number, systemId: number): Observable<any> {
        return this.http.get(`${this.lineageRequestUrl}?TargetTableId=${tableId}&SourceSystemId=${systemId}&Type=Numeric`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets table lineages by tableMappingId given
     * @param tableMappingId
     * @author Collin Atkins / 11.16.17
     */
    getTableLineagesByTableMappingId(tableMappingId: number): Observable<any> {
        return this.http.get(`${this.lineageRequestUrl}?tableMappingId=${tableMappingId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Links to SSIS
     * @param targetTableId
     * @author John Crabill
     */
    linkToSSIS(targetTableId: number): Observable<any> {
        return this.http.put(this.ssisRequestUrl, targetTableId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Saves given table lineage
     * @param tableLineage
     * @author Nash Lindley
     */
    saveTableLineage(tableLineage: TableLineage): Observable<any> {
        return this.http.put(this.lineageRequestUrl, tableLineage, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
