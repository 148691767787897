import { TargetTable } from "app/models/target-table";

export class Schema {

    Id: number;
    SchemaName: string;
    LoginUserName: string;
    LoginUserPassword: string;
    DBHostName: string;
    DBProvider: string;
    DBSchemaName: string;
    DBType: string;
    TablePrefix: string;
    tableCount: number;
    TargetTables: TargetTable[];
    show: boolean;
    CreateDuplicateSourceSystem: string;

    constructor() {
        this.Id = -1;
        this.TargetTables = new Array<TargetTable>();
        this.show = false;
    }

}
