import { Component, OnInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarHandler } from '../../../../utilities/snackbar';
import { TermService } from 'app/services/term.service';
import { TermTypeService } from 'app/services/term-type.service';
import { TargetColumnService } from 'app/services/target-column.service';
import { TableService } from 'app/services/target-table.service';
import { TermStatusService } from 'app/services/term-status.service';
import { PriorityService } from 'app/services/priority.service';
import { Term } from 'app/models/term';
import { TermStatus } from 'app/models/term-status';
import { TermType } from 'app/models/term-type';
import { Priority } from 'app/models/priority';
import { TargetColumn } from 'app/models/target-column';
import { TargetTable } from 'app/models/target-table';
import { MatDialog } from '@angular/material';
import { RelatedTermModalComponent } from 'app/business-glossary/term/related-term-modal/related-term-modal.component';
import { TargetLinkModalComponent } from 'app/business-glossary/term/target-column-link-modal/target-column-link-modal.component';


@Component({
  selector: 'dp-term-details',
  templateUrl: './term-details.component.html',
  providers: [TermService, TermTypeService, TargetColumnService, TableService,
      TermStatusService,PriorityService],
  styleUrls: ['./term-details.component.scss']
})
export class TermDetailsComponent implements OnInit {


  private Priorties: Priority[] = new Array<Priority>();
  private TermTypes: TermType[] = new Array<TermType>();
  private TermStatuses: TermStatus[] = new Array<TermStatus>();

  private dtElement: DataTableDirective;
  private dtTrigger: Subject<Term[]> = new Subject();
  private dtOptions: DataTables.Settings = {};

  private Term: Term = new Term();

  private TargetTable: TargetTable = new TargetTable();
  private TargetColumn: TargetColumn = new TargetColumn();




  constructor(private priorityService: PriorityService, private termTypeService: TermTypeService,
    private termStatusService: TermStatusService,private termService: TermService,
    private targetColumnService: TargetColumnService, private targetTableService: TableService,
    private route: ActivatedRoute, private router: Router ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.Term.Id = +params['id'];
  });
    this.dtOptions = {
      columnDefs: [{
          targets: [4,5],
          orderable: false
      }]
  };


    this.getTermPriorities();
    this.getTermTypes();
    this.getTermStatus();
    this.getTerm();
  }

/**
     * Calls the termType Service to get the term types
     */
    private getTermPriorities() {

      this.priorityService.getPriority()
      .subscribe(p => {
          this.Priorties = p;
      }, err => console.log(err));

  }

 /**
     * Calls the termType Service to get the term types
     */
    private getTermTypes() {
      this.TermTypes = this.termTypeService.getTermTypes();
  }

  /**
   * Calls the termStatus Service to get the term statuses
   */
  private getTermStatus() {
      this.TermStatuses = this.termStatusService.getTermStatus()
  }

  /**
     * Gets term by id
     */
    private getTerm() {
      this.rerender();

      this.termService.getTermById(this.Term.Id)
          .subscribe(term => {
              this.Term = term;
              this.getNameByColumn(this.Term.targetColumn);
              this.dtTrigger.next();
          }, err => console.log(err));
  }
  private rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
        });
    }
}
 /**
     * Getting table columns, currently initialized to 0, so does not run on that
     * @author John Crabill
     */
    private getNameByColumn(columnId: number) {
      if (columnId && columnId >= 0) {
          this.targetColumnService.getColumnById(columnId)
              .subscribe(column => {
                  this.TargetColumn = column[0];
                  this.getNameByTable(this.TargetColumn.EDWTableId);
              }, err => console.log(err));
      }
  }

  /**
     * @param tableId
     * @author John Crabill
     */
    private getNameByTable(tableId: number) {
      this.targetTableService.getTableById(tableId)
          .subscribe(table => {
              this.TargetTable = table[0];
          }, err => console.log(err));
  }






}
