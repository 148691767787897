export class TransformationType {
    Id: number;
    TT_Id: number;
    Type: string;
    TypeDesc: string;
    Format: string;

    constructor(name: string = null, type: string = null, id: number = -1, ttId: number = -1) {
        this.Id = id;
        this.TT_Id = ttId;
        this.Type = type || name;
        this.TypeDesc = type || name;
        this.Format = name;
    }

}
