/**
 * Table Mapping - Getter, selector, and controls for Table Mappings
 * @author Collin Atkins
 */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TargetTable } from 'app/models/target-table';
import { TableMappingService } from 'app/services/table-mapping.service';
import { TableMapping } from 'app/models/table-mapping';

@Component({
    selector: 'dp-table-mapping',
    templateUrl: './table-mapping.component.html',
    providers: [ TableMappingService ],
    styleUrls: ['./table-mapping.component.scss']
})

export class TableMappingComponent implements OnInit {

    @Input() TargetTable: TargetTable = new TargetTable();
    @Input() permissions: Object;
    @Output() TableMappingSelected = new EventEmitter();

    private TableMappings: TableMapping[] = new Array<TableMapping>();
    private SelectedTableMapping: TableMapping = new TableMapping();

    constructor(private tableMappingService: TableMappingService) { }

    ngOnInit() {
        if (window.location.hash) {
            this.SelectedTableMapping.Id = +window.location.hash.split('#tableMappingId=')[1];
        }
        this.getTableMappings();
    }

    /**
     * Calls service to get all table mappings
     * @author Collin Atkins / 11.10.11
     */
    private getTableMappings() {
        this.tableMappingService.getTableMappingsByTargetTableId(this.TargetTable.Id)
            .subscribe(tableMappings => {
                this.TableMappings = tableMappings;
                if (this.SelectedTableMapping.Id >= 0) {
                    this.selectTableMapping(this.TableMappings.find(sys => sys.Id == this.SelectedTableMapping.Id));
                } else {
                    this.selectTableMapping(this.TableMappings[0]);
                }
            }, err => console.log(err));
    }

    /**
     * Replaces current url with url concatenated with tableMappingId
     * @author Collin Atkins / 11.15.17
     */
    private replaceUrl() {
        if (window.location.hash && +window.location.hash.split('#tableMappingId=')[1] != this.SelectedTableMapping.Id) {
            window.location.hash = 'tableMappingId=' + this.SelectedTableMapping.Id;
        }
        else if (!window.location.hash) {
            window.location.hash = 'tableMappingId=' + this.SelectedTableMapping.Id;
        }
    }

    /**
     * Resets tableMapping and selects the default
     * @author Collin Atkins / 11.15.17
     */
    private resetTableMapping() {
        this.SelectedTableMapping = new TableMapping(this.TargetTable.Id);
        this.selectTableMapping();
    }

    /**
     * Selects tableMapping given then emits selected event
     * @param tableMapping
     * @author Collin Atkins / 11.15.17
     */
    private selectTableMapping(tableMapping: TableMapping = this.SelectedTableMapping) {
        this.SelectedTableMapping = tableMapping;
        this.replaceUrl();
        this.TableMappingSelected.emit(tableMapping);
    }

}
