import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'exclude'
})
export class ExcludeObjectPipe implements PipeTransform {

    /**
     * Excludes an object if it is found in another given array
     * (Both arrays must have an Id field)
     * @param array 
     * @param search
     * @author Nash Lindley
     */
    transform(array: any, search: any): any {
        if (!array || !search) {
            return array;
        }
        return array.filter(function (arr) {
            return arr.Id !== search.Id;
        })
    }
}
