/**
 * View Lineage Tree Modal
 * @author Collin Atkins / 10.25.17
 */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'dp-lineage-tree-modal',
    templateUrl: './lineage-tree-modal.component.html',
    styleUrls: ['./lineage-tree-modal.component.scss'],
})

export class LineageTreeModalComponent {

    @Input() TargetColumn;

    constructor() { }

    ngOnInit() { }

    /**
     * Modal Variables
     */
    visible: boolean = false;
    private visibleAnimate: boolean = false;

    /**
     * Modal Functions
     */

    /**
     * Modal Show
     */
    show() {
        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    /**
     * Modal Hide
     */
    hide() {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
    }

    /**
     * Click Modal Event
     */
    onContainerClicked(event: MouseEvent) {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }

}