import { Component, OnInit, Output , ViewChild, EventEmitter, Inject } from '@angular/core';

import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { SnackBarHandler } from '../../../../utilities/snackbar';
import { Team } from "app/models/team";
import { TeamUser } from  "app/models/team-user"
import { Term } from 'app/models/term';
import { TermService } from 'app/services/term.service';
import { TeamService } from 'app/services/team.service';
import { TeamRoleService }  from 'app/services/team-roles.service';
import { AuthService } from 'app/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'dp-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.scss'],
  providers: [TeamRoleService, TeamService,SnackBarHandler,TermService]
})
export class TeamCreateComponent implements OnInit {
  @Output() refreshIt = new EventEmitter();
  @ViewChild(DataTableDirective)

  private Team: Team = new Team();
  private teams: Team[] = new Array<Team>();
  private Term: Term = new Term();

  /**
  * Modal Variables
  */
  visible: boolean = false;
  private visibleAnimate: boolean = false;

  constructor(private snackBarHandler: SnackBarHandler, private teamService: TeamService,
        private authService: AuthService, private dialogRef: MatDialogRef<TeamCreateComponent>, @Inject(MAT_DIALOG_DATA) private data
  ) { }

  color = 'primary';
  checked = false;
  create = false;
  pagetitle = 'Pick a Team';


  ngOnInit() {
    this.Term = this.data.term;
    this.teams = this.data.teams;

    if(this.data.team.TeamId != -1 && this.data.team.TeamId != 0){
      this.Team = this.data.team;
    }
    else {
      this.Team = new Team();
    }
  }

  private UnassignTeam(){
    this.Team = new Team();
    this.Term.TermTeam = this.Team;
    this.Term.TermTeamId = 0;
    this.dialogRef.close(this.Term);
  }

  changeTeam(_team: Team){
      this.Team = _team;
      this.checked = true;
  }

  createTeam()
  {
    this.checked = false;
    this.create = true;
    this.pagetitle = 'Create a Team';
    this.Team = new Team();
  }

  private saveTeam(){
    var teamsusers: TeamUser [] = new Array<TeamUser>();

    this.Team.TeamUsers =  teamsusers;
    this.authService.getUserId().subscribe(id =>{
      this.Team.TeamOwnerUserId = id
    });

    this.teamService.saveTeam(this.Team).subscribe(tempteam => {
      this.snackBarHandler.open(`${this.Team.TeamName} was saved.`, 'success');
      this.Team.Id = tempteam.Id;
      this.Team.TeamId = tempteam.Id;
      if(this.Team.Id > 0 && this.checked){
        // assing team to term.
        this.Term.TermTeam = this.Team;
        this.Term.TermTeamId = this.Team.TeamId;
        this.dialogRef.close(this.Term);
      }
      else {
        this.dialogRef.close(this.Team);
      }
    }, err => {
      console.error(err);
      this.snackBarHandler.open('Team Failed.' +  err, 'failure');
    });
  }
}
