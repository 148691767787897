/**
 * Column Lineage
 * @author Collin Atkins / 10.9.17 / Added initialization of many target col/table, source col/table/sys, and their respective ids
 */
import { TargetTable } from 'app/models/target-table';
import { OperatorType } from 'app/models/operatortype';
import { SourceColumn } from 'app/models/source-column';
import { SummaryType } from 'app/models/summary-type';
import { TargetColumn } from 'app/models/target-column';
import { SourceTable } from 'app/models/source-table';
import { SourceSystem } from 'app/models/source-system';
import { DataLineage } from 'app/models/datalineage';

export class ColumnLineage {

    Id: number;
    EDWColumnId: number;
    SourceColumnId: number;
    SourceTableId: number;
    TargetTableId: number;
    SourceColumns: SourceColumn[];
    SourceTableAlias: string;
    SourceSystemId: number;
    ColumnCalculation: string;
    CdcDateFlag: string;
    CdcTimeFlag: string;
    UseAsLookupKey: string;
    ColumnFilters: string;
    Summaries: SummaryType[];
    Transformation: string;
    TargetColumn: TargetColumn;
    TargetTable: TargetTable;
    SourceTable: SourceTable;
    SourceColumn: SourceColumn;
    SourceSystem: SourceSystem;
    Order: number;
    OperatorType: OperatorType;
    OperatorTypeId: number;
    TableMappingId: number;
    DataLineage: DataLineage[];

    constructor(columnId: number = -1, systemId: number = -1, tableId: number = -1, tableMappingId: number = -1) {
        this.Id = -1;
        this.SourceColumnId = -1;
        this.SourceTableId = -1;
        this.EDWColumnId = columnId;
        this.SourceSystemId = systemId;
        this.TargetTableId = tableId;
        this.TableMappingId = tableMappingId;
        this.TargetColumn = new TargetColumn();
        this.TargetTable = new TargetTable();
        this.SourceTable = new SourceTable();
        this.SourceColumn = new SourceColumn();
        this.SourceSystem = new SourceSystem();
        this.Summaries = [new SummaryType()];
        this.OperatorType = new OperatorType();
        this.DataLineage = new Array<DataLineage>();
    }

}
