import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogComponent } from '../base/base-dialog/base-dialog.component';

@Component({
  selector: 'dp-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent extends BaseDialogComponent {
  stringRowsToDelete: any[] = [];
  idsToDelete: number[] = [];
  stringReturn: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef, data);
    let checkedVals = this.data.columnData[0].checkedVals;
    let nameId = this.data.columnData[0].nameId;

    for (var key in checkedVals) {
      let value = checkedVals[key].checked;
      if (value == true) {
        if(key != null && isNaN(Number(key))){
          this.stringReturn = true;
        }
        this.stringRowsToDelete.push({ id: key, name: checkedVals[key].name });
        this.idsToDelete.push(Number(key));
      }
    }

  }

  findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

  onClick(): void {
    if(this.stringReturn){
      this.dialogRef.close(this.stringRowsToDelete);
    }
    else {
      this.dialogRef.close(this.idsToDelete);
    }
  }

}
