import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IDialogData } from "app/models/dialog-interface";
import { Schema } from 'app/models/schema';

@Component({
  selector: 'dp-base-component',
  template: ``,
  styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent {

  /** Empty form group to be populated dynamically */
  form: FormGroup = new FormGroup({});
  /** The title of the dialog*/
  description: string;
  /** The number of columns in the dialog. If set to 0, the form is not used */
  numCols: number;
  /** The dialog form structure with numCols columns */
  colArrays: any[];
  /** Not set unless there is data for checked items(numCols == 0)*/
  columnData: any;
  /** Additional Data used for the Dialog */
  additionalData: any[];
  /** key value pairs where the key is the id and the value is whether or not that item is hidden */
  passwordIds: any = {};
  /** Toggle for Showing Test Connection */
  addTestConnectionButton: boolean = false;


  @Output() onSaveChanges = new EventEmitter<any>();
  @Output() onTestConnection = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.additionalData = data.additionalData == null ? [] : data.additionalData;
    let dataArray: any[] = [];
    this.description = data.description;
    this.numCols = data.numCols;
    this.addTestConnectionButton = data.addTestConnectionButton;
    if (this.numCols > 0) {
      let numberValidator = this.customValidator(/^[0-9]+$/, "numberError");
      let passwordValidator = this.customValidator(/^(?!.* ).{1,50}$/, "passwordError");
      let passwordOrBlankValidator = this.customValidator(/^(?!.* ).{0,50}$/, "passwordError");
      let passwordStrengthValidator = this.customValidator(/(?=.{8,50})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/, 
        "Requires 8 characters and 3 types of: uppercase, lowercase, numbers or special characters");
      let requiredValidator = Validators.required;
      let currValidators = [];
      for (let key of data.columnData) {
        currValidators = [];
        for (let validator of key.validators) {
          switch (validator) {
            case "number": {
              currValidators.push(numberValidator);
              break;
            }
            case "password": {
              currValidators.push(passwordValidator);
              this.passwordIds[key.id] = true;
              break;
            }
            case "passwordOrBlank": {
              currValidators.push(passwordOrBlankValidator);
              this.passwordIds[key.id] = true;
              break;
            }
            case "passwordStrength": {
              currValidators.push(passwordStrengthValidator);
              this.passwordIds[key.id] = true;
              break;
            }
            case "required": {
              currValidators.push(requiredValidator);
              key.label = key.label + "*";
              break;
            }
            default: {
              break;
            }
          }
        }
        this.form.addControl(key.id, new FormControl(key.value, Validators.compose(currValidators)));
        if (key.label != -1) {
          if(key.inputType){
            switch(key.inputType.type){
              case "dropdown":{
                dataArray.push({type: key.inputType.type, options: key.inputType.dropdownOptions, id: key.id, label: key.label, value: key.value});
                break;
              }
              case "multidropdown":{
                dataArray.push({type: key.inputType.type, options: key.inputType.dropdownOptions, id: key.id, label: key.label, value: key.value});
                break;
              }
              default: {
                dataArray.push({type: key.inputType.type, id: key.id, label: key.label, value: key.value});
                break;
              }
            }

          } else{
            dataArray.push({ id: key.id, label: key.label, value: key.value});
          }
        }
      }
      this.colArrays = this.groupArray(dataArray, this.numCols);
    } else {
      this.columnData = data.columnData;
    }
  }
  /**
   * Creates a cusotm validation string to be used to determine which error is present
   * @param nameRe
   * @param errorName
   */
  customValidator(nameRe: RegExp, errorName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!nameRe.test(control.value)) {
        let temp = {};
        temp[errorName] = true;
        return temp;
      }
    };
  }

  /**
   * Returns at the last error string present in a list of errors
   * @param controlName
   */
  public getError(controlName: string, localForm: FormGroup = this.form): string {
    let errorName = "";
    let errorString = "";
    let controlErrors: ValidationErrors = localForm.get(controlName).errors;
    Object.keys(controlErrors).forEach(keyError => {
      errorName = keyError.toString();
    });

    switch (errorName) {
      case "numberError": {
        errorString = "Field may only contain numbers";
        break;
      }
      case "passwordError": {
        errorString = "Password may not contain spaces and must be less than 50 characters";
        break;
      }
      case "required": {
        errorString = "Field is required";
        break;
      }
      default: {
        errorString = "Error: " + errorName
        break;
      }
    }
    return errorString;
  }

  /**
   * Groups the given array into 'count' number of columns
   * @param array
   * @param count
   */
  groupArray(array: any[], count: number): any[] {
    const result: any = [];
    array.forEach((x, index) => {
      if (index % count == 0) {
        result.push([])
      };
      result[result.length - 1].push(x);
    });
    return result;
  }

  /**
   * Returns true if there is an error for the input field, false otherwise
   * @param controlName
   */
  hasError(controlName: string, localForm: FormGroup = this.form): boolean {
    let result = false;
    let controlErrors: ValidationErrors = localForm.controls[controlName].errors;
    if (controlErrors != null) {
      result = true;
    }
    return result;
  }

  /**
   * Closes the dialog wihtout sending data
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Closes the dialof and sends data
   */
  save(): void {
    this.dialogRef.close(this.form.value);
  }

  testConnection() {
    let schema = new Schema();
    Object.keys(this.form.controls).forEach(key => {
      if(key !== "Id"){
        schema[key] = this.form.controls[key].value;
      }
    });
    this.onTestConnection.emit(schema);
  }

}

