import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { Subject } from 'rxjs';
import { TokenService } from '../services/token.service';

@Component({
  selector: 'dp-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss']
})

export class AdminDashboardComponent {

  private admin = true;
  private destroy = new Subject<void>();

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
    private routeBreadcrumbService: RouteBreadcrumbService) { }

  ngOnInit() {
    this.authService.getUserJwt().subscribe(jwt => {
      if (!jwt["https://metaprism.com/user_permissions"].includes("admin:user")) {
        this.admin = false;
        this.router.navigate(['/']);
      }
    });
    this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Administration"], ["administration/"]);
  }

  ngOnDestroy() {
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }
}
