/**
 * Transformation Editor Tab
 * @author Collin Atkins / 11.8.17 / Completed overhaul and changed tableLineages to joins
 * @author Collin Atkins / 11.9.17 / Moved getJoins to parent tab component for reduced calls, added filter and sort to buttons, 
 *      cleaned up code and commented, fixed andorin bug
 * @author Collin Atkins / 11.20.17 / Added in source column choices, added input of col/join names to strings,
 *      added feature to show/hide list of joins/cols, fixed alignment of joins/cols, added feature to progress selection on enter
 * @author Collin Atkins / 11.27.17 / Changed fundamentals to how transformation is parsed by using defined BaseTransformation as a tree
 *      with TransformationArrayDisplayed as the flattened transformation for display. Changed how Case is to be in Case, When, Then, Else, End format.
 *      Added feature to nest When functions inside Case statements. Added feature to nest string/date functions. Added feature for DateAdd with new
 *      button and function to parse. Added table-lineage-list component as a tree to select source columns. 
 * @author Collin Atkins / 11.30.17 / Changed columns to parse as table.column name and be inputed at that. Rewrote disabling to be less restrictive and generally only
 *      disallow inputs on numerics, grammar, and other special cases. Fixed Case nesting to carry over values from Then. Fixed many small bugs.
 *      Added new buttons for DateToJulian, Second, Minute, Hour.
 * @author Collin Atkins / 12.1.17 / Fixed chrome input/date parsing bug. Fixed operator functions not being parsed correctly.
 */
import { Component, Input, OnInit } from '@angular/core';
import { TransformationTypeService } from 'app/services/transformation-type.service';
import { ColumnLineage } from 'app/models/column-lineage';
import { TableLineage } from 'app/models/table-lineage';
import { Transformation } from 'app/models/transformation';
import { TransformationType } from 'app/models/transformation-type';
import { SourceColumn } from 'app/models/source-column';

@Component({
    selector: 'dp-transformation-editor',
    templateUrl: './transformation-editor.component.html',
    providers: [TransformationTypeService],
    styleUrls: ['./transformation-editor.component.scss']
})

export class TransformationEditorComponent implements OnInit {

    //#region Variables

    @Input() TableLineages: TableLineage[] = new Array<TableLineage>();
    @Input() ColumnLineage: ColumnLineage = new ColumnLineage();

    /**
     * Base Transformation; Root of the Transformation tree
     */
    private BaseTransformation: Transformation = new Transformation();
    /**
     * Flattened BaseTransformation to be displayed in the view box
     */
    private TransformationArrayDisplayed: Transformation[] = new Array<Transformation>();

    private TransformationSelected: Transformation = new Transformation();
    /**
     * Keeps track of specific index in the case of two or more identical transformations
     */
    private SelectedIndex: number = -1;

    private TransformationTypes: TransformationType[] = new Array<TransformationType>();
    private conditionalTransformationTypes: TransformationType[] = new Array<TransformationType>();
    private functionTransformationTypes: TransformationType[] = new Array<TransformationType>();
    private dateFunctionTransformationTypes: TransformationType[] = new Array<TransformationType>();
    private operatorTransformationTypes: TransformationType[] = new Array<TransformationType>();

    // Inputs from numeric, string, and date form inputs
    private stringInput: string;
    private numericInput: number;

    //#endregion

    constructor(private transformTypeService: TransformationTypeService) { }

    ngOnInit() {
        this.getTransformationTypes();
    }

    //#region Main Functions

    /**
     * Adds given transformation type to transformation array
     * @param type 
     * @author Collin Atkins / 11.7.17
     * @author Collin Atkins / 11.27.17 / Added in check and new transform for datetype, added feature to add nested string/date functions,
     *      condensed some by using replaceSelected, moved setSelection to after transform save
     * @author Collin Atkins / 11.29.17 / Changed checks for replacing transforms as generic functions or cases and inputs rather than specific.
     *      Added checks to allow passing of data into added Case functions. Fixed bug of allowing carrying over values into DateAdd function.
     *      Fixed columns being throw into stringToTransformation which caused them to not save with id.
     */
    private addToTransformation(type: TransformationType) {
        let transform: Transformation = this.typeIsInputType(type) && !this.typeIsValue(type) ? new Transformation(type) : this.stringToTransformation(type.Format);
        if (this.typeIsWhenOrElse(type)) {
            if (!(this.hasType(this.TransformationSelected.Children, 'ELSE') && this.typeIsElse(type))) {
                let index: number = this.hasType(this.TransformationSelected.Children, 'ELSE') ? this.TransformationSelected.Children.length - 2 : this.TransformationSelected.Children.length - 1;
                let arrayStart = this.TransformationSelected.Children.slice(0, index);
                arrayStart.push(transform);
                this.TransformationSelected.Children = arrayStart.concat(this.TransformationSelected.Children.slice(index));
            }
        }
        // If it has a parent or it is a function with similar children then it replaces the old with the new
        else if (this.TransformationSelected.Parent || this.typeIsFunctionOrCase(this.TransformationSelected.Type) || this.typeIsOperatorFunction(type)) {
            // Special case for operator functions where it is pushed rather than replaced
            if (this.typeIsOperatorFunction(type)) {
                let index = this.TransformationSelected.Parent.Children.indexOf(this.TransformationSelected) + 2;
                let arrayStart = this.TransformationSelected.Parent.Children.slice(0, index);
                arrayStart.push(transform);
                this.TransformationSelected.Parent.Children = arrayStart.concat(this.TransformationSelected.Parent.Children.slice(index));
            } else {
                // DateAdd has a special type and no children should be carried to it
                if (!this.typeIsDateAdd(type)) {
                    if (this.typeIsFunctionOrCase(type)) {
                        // Creates special DateType transform for DateAdd
                        if (this.typeIsDateType(this.TransformationSelected.Type)) {
                            transform = this.getStandardTransformation(transform.Type.Type.toUpperCase(), this.TransformationSelected.Type.TypeDesc);
                        } else if (this.typeIsCase(type)) {
                            // Moves old value to after THEN transform
                            transform.Children[0].Children[this.findThenIndex(transform) + 1] =
                                (!this.typeIsFunctionOrCase(this.TransformationSelected.Type) && this.TransformationSelected.Children.some(t => this.typeIsInputType(t.Type)))
                                    ? this.TransformationSelected.Children.find(t => this.typeIsInputType(t.Type))
                                    : this.TransformationSelected;
                        } else {
                            // Moves old value to new replacement function
                            transform.Children[transform.Children.findIndex(t => this.typeIsEmpty(t.Type))] =
                                (!this.typeIsFunctionOrCase(this.TransformationSelected.Type) && this.TransformationSelected.Children.some(t => this.typeIsInputType(t.Type)))
                                    ? this.TransformationSelected.Children.find(t => this.typeIsInputType(t.Type))
                                    : this.TransformationSelected;
                        }
                    } else {
                        transform.Type.TypeDesc = this.TransformationSelected.Type.TypeDesc;
                    }
                }
                this.replaceSelected(transform);
            }
        }
        // Else it doesn't have a parent, it creates a new transform with the given type
        else {
            this.BaseTransformation = transform;
            this.TransformationSelected = transform;
        }

        this.saveTransformation();

        // Moves selection to next empty transformation for easier/quicker entering
        if (this.hasEmptyValue()) {
            this.setSelection(this.TransformationArrayDisplayed.find(t => this.typeIsEmpty(t.Type)));
        }
    }

    /**
     * Clears the BaseTransformation
     * @author Collin Atkins / 11.7.17
     */
    private clearTransformation() {
        this.BaseTransformation = new Transformation();
        this.setSelection(this.BaseTransformation);
        this.saveTransformation();
    }

    /**
     * Converts BaseTransformation to TransformationArrayDisplayed for the view
     * @param parent 
     * @author Collin Atkins / 11.22.17
     */
    private convertBaseToDisplay(parent?: Transformation) {
        if (!parent) {
            this.TransformationArrayDisplayed = new Array<Transformation>();
            this.BaseTransformation.Parent = null;
            if (!this.BaseTransformIsEmpty()) {
                this.convertBaseToDisplay(this.BaseTransformation);
            }
        } else {
            this.TransformationArrayDisplayed.push(parent);
            parent.Children.forEach(child => {
                child.Parent = parent;
                this.convertBaseToDisplay(child);
            });
        }
    }

    /**
     * Coverts ArrayDisplay to BaseTransformation
     * @author Collin Atkins / 11.28.17
     */
    private convertDisplayToBase() {
        this.BaseTransformation = this.TransformationArrayDisplayed.shift();
        this.convertParentsChildren(this.BaseTransformation);
    }

    /**
     * Based on the values in ArrayDisplayed it will recursively assign children to their assumed parents
     * @param parent 
     * @author Collin Atkins / 11.28.17
     * @author Collin Atkins / 12.1.17 / Added fix to handle operator functions correctly, as they are the only possible child not included in the default children
     */
    private convertParentsChildren(parent: Transformation) {
        for (var child of parent.Children) {
            if (this.TransformationArrayDisplayed.length == 0) {
                break;
            }
            var transform: Transformation = this.TransformationArrayDisplayed.shift();
            if (this.typeIsOperatorFunction(transform.Type)) {
                parent.Children.push(transform);
            }
            child.Type = transform.Type;
            child.Children = transform.Children;
            if (transform.Children.length > 0) {
                this.convertParentsChildren(child);
            }
        }
    }

    /**
     * Deletes selected transformation, replaces with empty 'enter' transformation if it is a child
     * @author Collin Atkins / 11.7.17
     * @author Collin Atkins / 11.27.17 / Added replacement checks for nested functions
     * @author Collin Atkins / 11.30.17 / Changed if check to only clear if there are no functions/case as children. 
     *      Changed replacement checks to find functions/input types rather than specific types. Added failsafe.
     *      Added feature to keep value after THEN after CASE delete.
     */
    private deleteSelected() {
        // If it has a parent or doesn't have a parent and contains a function then it replaces the selected transformation
        if (this.TransformationSelected.Parent || ((!this.TransformationSelected.Parent && this.TransformationSelected.Children.some(child => this.typeIsFunctionOrCase(child.Type)))
            || (!this.TransformationSelected.Parent && this.typeIsCase(this.TransformationSelected.Type) && this.typeIsFunctionOrCase(this.TransformationSelected.Children[0].Children[this.findThenIndex(this.TransformationSelected) + 1].Type)))) {
            // Only case where it deletes without replacement
            if (this.typeIsOperatorFunction(this.TransformationSelected.Type)) {
                this.TransformationSelected.Parent.Children.splice(this.TransformationSelected.Parent.Children.indexOf(this.TransformationSelected), 1);
            } else {
                let replacement: Transformation;
                if (this.typeIsDateAdd(this.TransformationSelected.Type)) {
                    replacement = this.getEmptyTransformation('value');
                } else if (this.typeIsCase(this.TransformationSelected.Type)) {
                    replacement = this.TransformationSelected.Children[0].Children[this.findThenIndex(this.TransformationSelected) + 1];
                } else if (this.typeIsFunction(this.TransformationSelected.Type)) {
                    replacement = this.TransformationSelected.Children.some(t => this.typeIsFunctionOrCase(t.Type))
                        ? this.TransformationSelected.Children.find(t => this.typeIsFunctionOrCase(t.Type))
                        : this.TransformationSelected.Children.find(t => this.typeIsInputType(t.Type));
                } else {
                    replacement = this.getEmptyTransformation(this.TransformationSelected.Type.TypeDesc);
                }

                if (replacement) {
                    this.replaceSelected(replacement);
                } else { // Failsafe
                    if (this.TransformationSelected.Parent) {
                        this.replaceSelected(this.getEmptyTransformation('value'));
                    } else {
                        this.clearTransformation
                    }
                }
            }
        } else {
            this.clearTransformation();
        }

        this.saveTransformation();
    }

    /**
     * Calls the TransformationTypeService to get all TranformationTypes, sorts them, and then calls to parse the transformation
     * @author Collin Atkins / 11.20.17
     */
    private getTransformationTypes() {
        this.transformTypeService.getTransformationTypes()
            .subscribe(types => {
                this.TransformationTypes = types;
                this.seperateTransformationTypes();
                if (this.ColumnLineage.Transformation) {
                    this.parseTransformation();
                }
            }, err => console.log(err));
    }

    /**
     * Inputs a source column from the source column list into the transformation if the selection is valid
     * @param sourceColumn 
     * @author Collin Atkins / 11.17.17
     * @author Collin Atkins / 11.29.17 / New feature for two part names
     */
    private inputColumn(sourceColumnInterface: any) {
        if (!(this.typeIsNumeric(this.TransformationSelected.Type) || this.typeIsOperator(this.TransformationSelected.Type))) {
            var name = '';
            var secondId = -1;
            if (sourceColumnInterface.sourceTableJoin) {
                name = `[${sourceColumnInterface.sourceTableJoin.JoinAlias}.${sourceColumnInterface.sourceColumn.DatabaseName}]`;
                secondId = sourceColumnInterface.sourceTableJoin.Id;
            } else if (sourceColumnInterface.tableLineage) {
                name = `[${sourceColumnInterface.tableLineage.SourceTable.DatabaseName}.${sourceColumnInterface.sourceColumn.DatabaseName}]`;
                secondId = sourceColumnInterface.tableLineage.Id;
            }
            this.addToTransformation(new TransformationType(name, 'column', sourceColumnInterface.sourceColumn.Id, secondId));
        }
    }

    /**
     * Inputs numeric into the transformation array
     * @author Collin Atkins / 11.7.17
     */
    private inputNumeric() {
        if (this.numericInput || this.numericInput == 0) {
            this.addToTransformation(new TransformationType(this.numericInput + '', 'numeric'));
        }
    }

    /**
     * Inputs string into the transformation array
     * @author Collin Atkins / 11.7.17
     */
    private inputString() {
        if (this.stringInput) {
            this.addToTransformation(new TransformationType(`'${this.stringInput}'`, 'string'));
        }
    }

    /**
     * Inputs date into transformation
     * @param date 
     * @author Collin Atkins / 11.7.17
     */
    private inputDate(date: Date) {
        if (date) {
            this.addToTransformation(new TransformationType(date.toISOString(), 'date'));
        }
    }

    /**
     * Parses transform string into array of transformations
     * @author Collin Atkins / 11.7.17
     * @author Collin Atkins / 11.28.17 / Overhauled to fix many bugs; now using convertDisplayToBase()
     */
    private parseTransformation() {
        const transformationStringArray: string[] = this.ColumnLineage.Transformation.trim().split('|');

        this.TransformationArrayDisplayed = this.stringArrayToTransformationArray(transformationStringArray);
        this.convertDisplayToBase();

        this.saveTransformation();
        this.setSelection(this.BaseTransformation);
    }

    /**
     * Replaces selected with replacement transformation given
     * @param replacement 
     * @author Collin Atkins / 11.22.17
     */
    private replaceSelected(replacement: Transformation) {
        if (replacement) {
            if (this.TransformationSelected.Parent) {
                this.TransformationSelected.Parent.Children[this.TransformationSelected.Parent.Children.indexOf(this.TransformationSelected)] = replacement;
            }
            else {
                this.BaseTransformation = replacement;
            }
            this.TransformationSelected = replacement;
        }
    }

    /**
     * Calls convert for display, then sets the column lineage's transformation if valid
     * @author Collin Atkins / 11.8.17
     */
    private saveTransformation() {
        this.convertBaseToDisplay();
        if (!this.hasEmptyValue()) {
            this.ColumnLineage.Transformation = this.BaseTransformIsEmpty() ? null : this.transformationToString();
        }
    }

    /**
     * Sets transform as TransformSelected, if no index is given it finds the index of selected
     * @param transform
     * @param index 
     * @author Collin Atkins / 11.22.17
     */
    private setSelection(transform: Transformation, index: number = -1) {
        this.TransformationSelected = transform;
        if (index < 0) {
            index = this.TransformationArrayDisplayed.findIndex(t => t == this.TransformationSelected);
        }
        this.SelectedIndex = index;
    }

    /**
     * Parses a transformString into a Transformation
     * @param transformString 
     * @author Collin Atkins / 11.7.17
     * @author Collin Atkins / 11.27.17 / Added else if for datetype
     */
    private stringToTransformation(transformString: string): Transformation {
        if (this.stringIsColumn(transformString)) {
            return this.parseColumnIdString(transformString);
        } else if (this.stringIsString(transformString)) {
            return this.getStandardTransformation(transformString, 'value');
        } else if (this.stringIsParseableDate(transformString)) {
            return this.getStandardTransformation(transformString, 'value');
        } else if (Number(transformString)) {
            return this.getStandardTransformation(transformString, 'numeric');
        } else if (this.stringIsDateType(transformString)) {
            return this.getStandardTransformation(transformString, 'datetype');
        } else {
            let type: TransformationType = this.TransformationTypes.find(type => transformString == type.Format || transformString == type.Type);
            if (type) {
                return this.populateChildren(new Transformation(type));
            }
        }

        return this.getStandardTransformation(transformString);
    }

    /**
     * Converts array of strings into array of transformations
     * @param transformStringArray 
     * @author Collin Atkins / 11.28.17
     */
    private stringArrayToTransformationArray(transformStringArray: string[]): Transformation[] {
        var tempTransformArray: Transformation[] = new Array<Transformation>();
        transformStringArray.forEach(transformString => {
            tempTransformArray.push(this.stringToTransformation(transformString));
        });
        return tempTransformArray;
    }

    /**
     * Returns TransformationArrayDisplayed as a joined string
     * @author Collin Atkins / 11.7.17
     */
    private transformationToString(): string {
        return this.TransformationArrayDisplayed.map(transform => {
            if (this.stringIsColumn(transform.Type.Format)) {
                return (transform.Type.TT_Id && transform.Type.TT_Id >= 0) ? `[${transform.Type.TT_Id}.${transform.Type.Id}]` : `[${transform.Type.Id}]`;
            } else {
                return transform.Type.Format;
            }
        }).join('|');
    }

    //#endregion

    //#region Helper Functions

    /**
     * Finds index of then in case transform
     * @param transform 
     * @author Collin Atkins / 11.30.17
     */
    private findThenIndex(transform: Transformation): number {
        if (this.typeIsCase(transform.Type) && transform.Children.length > 0) {
            let whenIndex: number = transform.Children.findIndex(t => t.Type.Format == 'WHEN');
            if (whenIndex >= 0) {
                return transform.Children[whenIndex].Children.findIndex(t => this.typeIsThen(t.Type));
            }
        }
        return -1;
    }

    /**
     * Returns an empty transformaton based on typeString given
     * @param typeString 
     * @author Collin Atkins / 11.7.17
     * @author Collin Atkins / 11.27.17 / Added datetype
     */
    private getEmptyTransformation(typeString: string = 'value'): Transformation {
        switch (typeString.toLowerCase().trim()) {
            case 'string':
                return this.getStandardTransformation('ENTER STRING', 'string');
            case 'numeric':
            case 'number':
                return this.getStandardTransformation('ENTER NUMBER', 'numeric');
            case 'operator':
                return this.getStandardTransformation('ENTER OPERATOR', 'operator');
            case 'date':
                return this.getStandardTransformation('ENTER DATE', 'date');
            case 'datetype':
                return this.getStandardTransformation('ENTER DATE TYPE', 'datetype');
            case 'column':
                return this.getStandardTransformation('ENTER COLUMN', 'column');
            default:
                return this.getStandardTransformation('ENTER VALUE', 'value');
        }
    }

    /**
     * @author Collin Atkins / 11.28.17
     */
    private getWhenTransform(): Transformation {
        let thenTransform = new Transformation(new TransformationType('THEN', 'conditional'));

        let whenChildren = [this.getEmptyTransformation('value'), this.getEmptyTransformation('operator'), this.getEmptyTransformation('value'), thenTransform, this.getEmptyTransformation('value')];
        return new Transformation(this.TransformationTypes.find(type => type.Type == 'WHEN'), null, whenChildren);
    }

    /**
     * Gets a new transformation
     * @param format - format/name of transformation
     * @param type - type/typeDesc of transformation
     * @author Collin Atkins / 11.7.17
     */
    private getStandardTransformation(format: string, type: string = null): Transformation {
        return new Transformation(new TransformationType(format, type));
    }

    /**
     * Parses string of column ids to column transformation format
     * @param columnIdString 
     * @author Collin Atkins / 11.29.17
     */
    private parseColumnIdString(columnIdString: string): Transformation {
        var ids: string[] = columnIdString.substring(1, columnIdString.length - 1).split('.');
        var tableId: number = ids.length == 1 ? null : +ids[0];
        var columnId: number = ids.length == 1 ? +ids[0] : +ids[1];
        var foundTable: any = null;
        var foundColumn: SourceColumn = null

        for (let tl of this.TableLineages) {
            if ((!tableId || tl.Id == tableId) && tl.SourceTable.SourceColumns && tl.SourceTable.SourceColumns.length > 0) {
                foundColumn = tl.SourceTable.SourceColumns.find(sc => sc.Id == columnId);
                if (foundColumn) {
                    foundTable = tl;
                    break;
                }
            }
            for (let stj of tl.SourceTableJoins) {
                if ((!tableId || stj.Id == tableId) && stj.SourceTable.SourceColumns && stj.SourceTable.SourceColumns.length > 0) {
                    foundColumn = stj.SourceTable.SourceColumns.find(sc => sc.Id == columnId);
                    if (foundColumn) {
                        foundTable = stj;
                        break;
                    }
                }
            }
        }

        var name = columnIdString;
        if (foundTable && foundTable.JoinAlias) {
            name = `[${foundTable.JoinAlias}.${foundColumn.DatabaseName}]`;
        } else if (foundTable && foundTable.SourceTable.DatabaseName) {
            name = `[${foundTable.SourceTable.DatabaseName}.${foundColumn.DatabaseName}]`;
        } else if (foundColumn) {
            name = `[${foundColumn.DatabaseName}]`;
        }
        return new Transformation(new TransformationType(name, 'value', columnId, tableId));
    }

    /**
     * Populates a transformation's children with the default
     * @param transform 
     * @author Collin Atkins / 11.7.17
     */
    private populateChildren(transform: Transformation): Transformation {
        switch (transform.Type.Type) {
            case 'Year':
            case 'Month':
            case 'Day':
            case 'Hour':
            case 'Minute':
            case 'Second': {
                transform.Children = [this.getEmptyTransformation('value'), this.getStandardTransformation(')')];
                break;
            }
            case 'DateAdd': {
                transform.Children = [this.getEmptyTransformation('datetype'), this.getStandardTransformation(','), this.getEmptyTransformation('number'), this.getStandardTransformation(','), this.getEmptyTransformation('value'), this.getStandardTransformation(')')];
                break;
            }
            case 'Substring': {
                transform.Children = [this.getEmptyTransformation('value'), this.getStandardTransformation(','), this.getEmptyTransformation('number'), this.getStandardTransformation(','), this.getEmptyTransformation('number'), this.getStandardTransformation(')')];
                break;
            }
            case 'Nvl':
            case 'format':
            case 'Concat': {
                transform.Children = [this.getEmptyTransformation('value'), this.getStandardTransformation(','), this.getEmptyTransformation('value'), this.getStandardTransformation(')')];
                break;
            }
            case 'DateToJulian':
            case 'JulianToDate':
            case 'JulianDateToSqlDateInt':
            case 'Trim':
            case 'str':
            case 'IsNumeric': {
                transform.Children = [this.getEmptyTransformation('value'), this.getStandardTransformation(')')];
                break;
            }
            case 'Left':
            case 'Right': {
                transform.Children = [this.getEmptyTransformation('value'), this.getStandardTransformation(','), this.getEmptyTransformation('number'), this.getStandardTransformation(')')];
                break;
            }
            case 'and':
            case 'or': {
                transform.Children = [this.getEmptyTransformation('value'), this.getEmptyTransformation('operator'), this.getEmptyTransformation('value')];
                break;
            }
            case 'ELSE':
            case 'in': {
                transform.Children = [this.getEmptyTransformation('value')];
                break;
            }
            case 'WHEN': {
                transform.Children = this.getWhenTransform().Children;
                break;
            }
            case 'CASE': {
                let whenTransform = this.getWhenTransform();
                let endTransform = this.getStandardTransformation('END');
                transform.Children = [whenTransform, endTransform];
                break;
            }
            default: {
                transform.Children = [];
                break;
            }
        }

        return transform;
    }

    /**
     * Returns boolean based on if the selected transformation is deletable
     * @author Collin Atkins / 11.7.17
     */
    private selectedIsDeletable(): boolean {
        let selectedType = this.TransformationSelected.Type;
        if (!this.TransformationSelected.Parent) {
            return true;
        }
        else if (selectedType && selectedType.Format) {
            if (this.typeIsWhenOrElse(selectedType)) {
                return !this.hasType(this.TransformationSelected.Parent.Children, selectedType.Type);
            } else {
                return !this.typeIsGrammar(selectedType) && !this.typeIsEmpty(selectedType);
            }
        } else {
            return false;
        }
    }

    /**
     * Seperates transformationTypes into different arrays for different button menu categorization
     * @author Collin Atkins / 11.30.17
     */
    private seperateTransformationTypes() {
        this.conditionalTransformationTypes = this.TransformationTypes.filter(type => this.typeIsConditional(type));
        this.dateFunctionTransformationTypes = this.sortDateFunctions(this.TransformationTypes.filter(type => this.typeIsDateFunction(type)));
        this.functionTransformationTypes = this.TransformationTypes.filter(type => this.typeIsFunction(type) && !this.typeIsDateFunction(type) && type.Type != 'CurrentDate');
        this.operatorTransformationTypes = this.TransformationTypes.filter(type => this.typeIsOperator(type));
    }

    /**
     * Sorts date functions by StandardDateFunctions  > OtherDateFunctions
     * @param dateFunctions 
     * @author Collin Atkins / 11.30.17
     */
    private sortDateFunctions(dateFunctions: TransformationType[]): TransformationType[] {
        return dateFunctions.sort((a, b) => {
            if (this.typeIsStandardDateFunction(a)) {
                return 1;
            } else if (this.typeIsStandardDateFunction(b)) {
                return -1;
            } else {
                return 0;
            }
        }).reverse();
    }

    //#endregion

    //#region Checker Functions - Check if given is x

    private BaseTransformIsEmpty(): boolean {
        return !this.BaseTransformation || (!this.BaseTransformation.Parent && this.BaseTransformation.Children.length == 0 && !this.BaseTransformation.Type.Type);
    }

    /**
     * Based on selected transformation, returns if type of button given is disabled
     * @param type - TransformationType of button
     * @author Collin Atkins / 11.7.17
     * @author Collin Atkins / 11.29.17 / Reworked to not be so restrictive. 
     *      Only really disabled for operators, numerics, grammar, and some other special circumstances.
     */
    private buttonIsDisabled(type: TransformationType): boolean {
        if (this.typeIsWhenOrElse(type)) {
            return !(this.TransformationSelected.Type && this.typeIsCase(this.TransformationSelected.Type) && !(this.typeIsElse(type) && this.hasType(this.TransformationSelected.Children, 'ELSE')));
        } else if (this.typeIsOperator(type)) {
            return this.inputOperatorIsDisabled();
        } else if (this.typeIsDateType(this.TransformationSelected.Type)) {
            return !(this.typeIsStandardDateFunction(type));
        } else {
            return this.inputIsDisabled();
        }
    }

    /**
     * @author Collin Atkins / 11.30.17
     */
    private inputIsDisabled(): boolean {
        return this.typeIsNumeric(this.TransformationSelected.Type) || this.typeIsOperator(this.TransformationSelected.Type)
            || this.typeIsGrammar(this.TransformationSelected.Type) || this.typeIsWhenOrElse(this.TransformationSelected.Type)
            || this.typeIsDateType(this.TransformationSelected.Type);
    }

    private inputDateFunctionIsDisabled(): boolean {
        return !(!this.inputIsDisabled() || this.typeIsDateType(this.TransformationSelected.Type));
    }

    private inputFunctionOrConditionalIsDisabled(): boolean {
        return this.inputIsDisabled() || this.typeIsDateType(this.TransformationSelected.Type);
    }

    private inputOperatorIsDisabled(): boolean {
        return !(this.typeIsOperator(this.TransformationSelected.Type));
    }

    /**
     * Returns true if the Transformation Array contains an 'empty'/'enter x' transformation
     * @author Collin Atkins / 11.20.17
     */
    private hasEmptyValue(): boolean {
        return this.TransformationArrayDisplayed.some(transform => this.typeIsEmpty(transform.Type));
    }

    /**
     * Returns true if given transformation array has a transformation of given type
     * @param transformationArray
     * @param typeString 
     * @author Collin Atkins / 11.27.17
     */
    private hasType(transformationArray: Transformation[], typeString: string): boolean {
        return transformationArray.some(t => t.Type.Type == typeString);
    }

    /**
     * Type format includes 'ENTER'; denotes that it requires input
     * @param type 
     * @author Collin Atkins / 11.9.17 
     */
    private typeIsEmpty(type: TransformationType): boolean {
        return !type.Format || type.Format.includes('ENTER');
    }

    private typeIsValue(type: TransformationType) {
        return type.TypeDesc && type.TypeDesc == 'value';
    }

    private typeIsColumn(type: TransformationType) {
        return type.TypeDesc && type.TypeDesc == 'column';
    }

    private typeIsNumeric(type: TransformationType) {
        return type.TypeDesc && type.TypeDesc.toLowerCase().includes('num');
    }

    private typeIsString(type: TransformationType): boolean {
        return type.Type == 'string';
    }

    private typeIsDate(type: TransformationType): boolean {
        return type.Type == 'date';
    }

    /**
     * Input types: value, column, string, numeric, date
     * @param type 
     * @author Collin Atkins / 11.29.17
     */
    private typeIsInputType(type: TransformationType) {
        return type.TypeDesc && (this.typeIsValue(type) || this.typeIsColumn(type) || this.typeIsString(type) || this.typeIsNumeric(type) || this.typeIsDate(type));
    }

    private typeIsOperator(type: TransformationType) {
        return type.TypeDesc && type.TypeDesc.toLowerCase().includes('operator');
    }

    private typeIsDateType(type: TransformationType): boolean {
        return type.Type == 'datetype';
    }

    private typeIsFunction(type: TransformationType): boolean {
        return type.TypeDesc == 'function';
    }

    private typeIsConditional(type: TransformationType): boolean {
        return type.TypeDesc == 'conditional';
    }

    private typeIsFunctionOrConditional(type: TransformationType): boolean {
        return this.typeIsConditional(type) || this.typeIsFunction(type);
    }

    private typeIsFunctionOrCase(type: TransformationType): boolean {
        return this.typeIsFunction(type) || this.typeIsCase(type);
    }

    private typeIsCase(type: TransformationType): boolean {
        return this.typeIsConditional(type) && type.Type && type.Type == 'CASE';
    }

    /**
     * Date functions: second, minute, day, month, year, DateToJulian, JulianToDate, DateAdd
     * @param type 
     * @author Collin Atkins / 11.27.17
     */
    private typeIsDateFunction(type: TransformationType): boolean {
        return this.typeIsFunction(type) && (this.typeIsStandardDateFunction(type) || this.typeIsDateAdd(type) || type.Type == 'JulianToDate' || type.Type == 'DateToJulian' || type.Type == 'JulianDateToSqlDateInt');
    }

    /**
     * Standard Date functions: second, minute, day, month, year
     * @param type 
     * @author Collin Atkins / 11.27.17
     */
    private typeIsStandardDateFunction(type: TransformationType): boolean {
        return this.typeIsFunction(type) && (type.Type == 'Year' || type.Type == 'Month' || type.Type == 'Day' || type.Type == 'Hour' || type.Type == 'Minute' || type.Type == 'Second');
    }

    private typeIsDateAdd(type: TransformationType): boolean {
        return this.typeIsFunction(type) && type.Type == 'DateAdd';
    }

    private typeIsElse(type: TransformationType): boolean {
        return type.Type == 'ELSE';
    }

    /**
     * Grammar: , ) THEN END
     * @param type 
     * @author Collin Atkins / 11.7.17
     */
    private typeIsGrammar(type: TransformationType): boolean {
        return type.Format == ',' || type.Format == ')' || type.Format == 'THEN' || type.Format == 'END';
    }

    /**
     * Operator functions: and, or, in
     * @param type 
     * @author Collin Atkins / 11.7.17
     */
    private typeIsOperatorFunction(type: TransformationType): boolean {
        return this.typeIsOperator(type) && (type.Format == 'and' || type.Format == 'or' || type.Format == 'in');
    }

    /**
     * String functions: substring, concat, trim, nvl, right, left
     * @param type 
     * @author Collin Atkins / 11.27.17
     */
    private typeIsStringFunction(type: TransformationType): boolean {
        return this.typeIsFunction(type) && (type.Type == 'Substring' || type.Type == 'Concat' || type.Type == 'Trim' || type.Type == 'Nvl' || type.Type == 'Right' || type.Type == 'Left');
    }

    private typeIsThen(type: TransformationType): boolean {
        return type.Format == 'THEN';
    }

    private typeIsWhen(type: TransformationType): boolean {
        return type.Type == 'WHEN';
    }

    private typeIsWhenOrElse(type: TransformationType): boolean {
        return this.typeIsWhen(type) || this.typeIsElse(type);
    }

    /**
     * String starts with '[' and ends with ']'
     * @param formatString 
     * @author Collin Atkins / 11.7.17
     */
    private stringIsColumn(formatString: string): boolean {
        return formatString ? formatString.startsWith('[') && formatString.endsWith(']') : false;
    }

    /**
     * String can be parsed as a date
     * @param formatString 
     * @author Collin Atkins / 11.9.17
     * @author Collin Atkins / 11.30.17 / Added additional checks for Chrome
     */
    private stringIsParseableDate(formatString: any): boolean {
        if (!this.stringIsString(formatString) && isNaN(formatString)) {
            let parsedDate = Date.parse(formatString);
            if (parsedDate && !isNaN(parsedDate)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Datetype strings are 'YEAR', 'MONTH', 'DAY'
     * @param transformString 
     * @author Collin Atkins / 11.27.17
     */
    private stringIsDateType(transformString: string): boolean {
        return transformString == 'YEAR' || transformString == 'MONTH' || transformString == 'DAY' || transformString == 'HOUR' || transformString == 'MINUTE' || transformString == 'SECOND';
    }

    /**
     * Starts and ends with a ', ex: 'string'
     * @param transformString 
     * @author Collin Atkins / 11.27.17
     */
    private stringIsString(transformString: string): boolean {
        return transformString.startsWith("'") && transformString.endsWith("'");
    }

    //#endregion

}
