import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatIconRegistry } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { IColumnData, IDialogData } from "app/models/dialog-interface";
import { SourceSystem } from 'app/models/source-system';
import { SourceTable } from 'app/models/source-table';
import { DomSanitizer } from '@angular/platform-browser';
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SourceSystemService } from 'app/services/source-system.service';
import { SourceTableService } from 'app/services/source-table.service';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarHandler } from 'utilities/snackbar';
import { SourceTablesAddComponent } from "./source-tables-add/source-tables-add.component";
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { SourceImportTableService } from 'app/services/source-import.service';
import { ImportSourceTablesDialogComponent } from 'app/source/source-tables/source-tables-import/import-source-tables-dialog.component';


@Component({
  selector: 'dp-source-tables',
  templateUrl: './source-tables.component.html',
  styleUrls: ['./source-tables.component.scss'],
  providers: [SourceTableService, SnackBarHandler, SourceSystemService, SourceImportTableService]
})
export class SourceTablesComponent implements OnInit {

  serviceData: any;
  ref: boolean;
  enableImport:boolean = false;
  id: string;
  tableDetails: ITableDetails;
  editModalData: any[];
  systemName: Observable<SourceSystem>;
  sourceSystem: SourceSystem;
  private destroy = new Subject<void>();


  columns: IColumns[] = [
    {
      columnDef: "Id",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Id}`
    },
    {
      columnDef: "DatabaseName",
      header: "Database Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.DatabaseName}`
    },
    {
      columnDef: "FriendlyName",
      header: "Friendly Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.FriendlyName}`
    },
    {
      columnDef: "TablePrefix",
      header: "Table Prefix",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.TablePrefix}`
    },
    {
      columnDef: "SchemaName",
      header: "Schema Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.SchemaName}`
    },
    {
      columnDef: "TablespaceName",
      header: "Tablespace Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.TablespaceName}`
    }
  ];

  ngOnInit(){
    this.systemName.pipe(takeUntil(this.destroy)).subscribe(system => {
      this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Source", system.Name], ["source/", "source/tables/" + system.Id]);
      this.sourceSystem = system;
    })

  }

  ngOnDestroy(){
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  constructor(
    private route: ActivatedRoute,
    private tableService: SourceTableService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private snackBarHandler: SnackBarHandler,
    private sourceService: SourceSystemService,
    private routeBreadcrumbService: RouteBreadcrumbService,
    private sharedService: SharedService,
    private sourceImportService: SourceImportTableService,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon('importTables', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/pictures/Import_Tables.svg'));
      this.id = this.route.snapshot.paramMap.get('id');
      let routingPath = ["/source/tables/","columns"];
      this.systemName = this.sourceService.getSourceSystemById(Number(this.id));

      this.tableDetails = { routing: {routingPath: routingPath, firstId: this.id}, edit: false };
      this.serviceData = this.authService.getFakeSubscribe([]);
      this.authService.authorizeSection().subscribe(permissions =>{
        if(permissions.view){
          var details = { routing: {routingPath: routingPath, firstId: this.id}, edit: permissions.update }
          if(permissions.add){
            details['add'] = "Table";
            this.enableImport = true;
          }
          if(permissions.delete)
            details['delete'] = "DatabaseName";

          this.tableDetails = details;
          this.serviceData = this.tableService.getSourceTablesBySourceSystem(Number(this.id));
          this.ref = true;
        }
        else {
          this.router.navigate(['/']);
        }
      });
      this.ref = false;
  }

  resetRefresh(value: boolean) {
    this.ref = value;
  }

  private openImportTablesDialog() {
    this.dialog.open(ImportSourceTablesDialogComponent, {
        panelClass: 'custom-dialog-container',
        data: { SourceSystem: this.sourceSystem },
    })
        .afterClosed().subscribe(result => {
            if (result) {
              this.snackBarHandler.open('Starting Table Import', 'success');
              this.importTables(result.trueImport, result.tablePrefix);
            }
        });
  }

  private importTables(importedTables, tablePrefix) {
    var schema = {
      Id: importedTables.Id,
      SchemaTables: importedTables.stringTables,
      TablePrefix: tablePrefix
    }
    this.sourceImportService.importTables(schema)
            .subscribe(schemas => {
              this.resetRefresh(true);
            }, err => {
                this.snackBarHandler.open('Could not Import Source Tables', 'failure')
            });
  }

  editModal({ Id, TablePrefix, DatabaseName, FriendlyName, SchemaName, TablespaceName }: SourceTable) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "SourceSystemId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "DatabaseName",
        label: "Database Name",
        value: DatabaseName,
        validators: ["required"]
      },
      {
        id: "FriendlyName",
        label: "Friendly Name",
        value: FriendlyName,
        validators: ["required"]
      },
      {
        id: "TablePrefix",
        label: "Table Prefix",
        value: TablePrefix,
        validators: [],
      },
      {
        id: "SchemaName",
        label: "Schema Name",
        value: SchemaName,
        validators: ["required"]
      },
      {
        id: "TablespaceName",
        label: "Tablespace Name",
        value: TablespaceName,
        validators: []
      }
    ];
    let data: IDialogData = {
      description: "Edit " + DatabaseName,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(EditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tableService.saveTable(result).subscribe(savedTable => {
          if (savedTable) {
            this.sharedService.sendSourceSystemUpdateRequest(); //Send request to update the source system nav
            this.snackBarHandler.open(`Success: Edited '${result.DatabaseName}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Source table not edited`, 'failure'));
      }
    });

  }

  viewModal({ Id, TablePrefix, DatabaseName, FriendlyName, SchemaName, TablespaceName }: SourceTable) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "SourceSystemId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "DatabaseName",
        label: "Database Name",
        value: DatabaseName,
        validators: ["required"]
      },
      {
        id: "FriendlyName",
        label: "Friendly Name",
        value: FriendlyName,
        validators: ["required"]
      },
      {
        id: "TablePrefix",
        label: "Table Prefix",
        value: TablePrefix,
        validators: []
      },
      {
        id: "SchemaName",
        label: "Schema Name",
        value: SchemaName,
        validators: ["required"]
      },
      {
        id: "TablespaceName",
        label: "Tablespace Name",
        value: TablespaceName,
        validators: []
      }
    ];
    let data: IDialogData = {
      description: "View " + DatabaseName,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);

  }


  addModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: "",
        validators: []
      },
      {
        id: "SourceSystemId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "DatabaseName",
        label: "Database Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "FriendlyName",
        label: "Friendly Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "TablePrefix",
        label: "Table Prefix",
        value: "",
        validators: []
      },
      {
        id: "SchemaName",
        label: "Schema Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "TablespaceName",
        label: "Tablespace Name",
        value: "",
        validators: []
      }
    ];
    let data: IDialogData = {
      description: "Add Source Table",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(SourceTablesAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.forEach(form => {
          this.tableService.saveTable(form).subscribe(savedTable => {
            if (savedTable) {
              this.sharedService.sendSourceSystemUpdateRequest(); //Sends request to update source system nav
              this.snackBarHandler.open(`Success: Added '${form.FriendlyName}'`, 'success');
              this.ref = true;
            }
          }, err => this.snackBarHandler.open(`Failure: Source table not added`, 'failure'));
        });
      }
    });

  }

  deleteRows(rows: number[]) {
    for (let row of rows) {
      this.tableService.deleteSourceTable(row).subscribe(result => {
        this.sharedService.sendSourceSystemUpdateRequest(); //Sends request to update source system nav
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.ref = true;
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
      this.ref = true;

    }
  }

}
