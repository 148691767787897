import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { ReportCalculationService } from 'app/services/reporting-calculation.service';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';

@Component({
    selector: 'import-calculations-dialog',
    templateUrl: 'import-calculations-dialog.component.html',
    styleUrls: ['import-calculations-dialog.component.scss'],
    providers: [ReportCalculationService]
})

export class ImportCalculationsDialogComponent implements OnInit {

    @ViewChild(DataTableDirective)

    private importArray = [];
    private calculations = [];
    private calculationGroups = [];
    private hasChecked: boolean;

    tableDetails: ITableDetails;
    serviceData: any;
    ref: boolean;

    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: 'Temp Id',
        sortable: true,
        cell: (element: any) => `${element.Id}`
      },
      {
        columnDef: "ReportCalculationTableName",
        header: 'Calculation Table Name',
        sortable: true,
        cell: (element: any) => `${element.ReportCalculationTableName}`
      },
      {
        columnDef: "ReportCalculationGroupId",
        header: 'Group Id',
        sortable: true,
        cell: (element: any) => `${element.ReportCalculationGroupId}`
      }
    ];

    constructor(private dialogRef: MatDialogRef<ImportCalculationsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private calculationService: ReportCalculationService, private snackBarHandler: SnackBarHandler) {
        }

    ngOnInit() {
        this.getReportCalculations();
        this.serviceData = this.getFakeSubscribe([]);
        this.tableDetails = {routing: null, edit: false, connect:false, noView: true, checkbox:true};
        this.hasChecked = false;
    }

    /**
     * Created because the dp-table component needs a observable
     * This allows the manipulation of data before insertinging it
     * into the dp-table component
     */
    getFakeSubscribe(array): Observable<any> {
      return Observable.of(array);
    }

    private resetRefresh(value: boolean){
      this.ref = value;
    }

    /**
     * Gets a list of items from the table and
     * enables or disables the save button depending
     * on if one is checked or not
     */
    private isChecked(checkedTables){
        this.importArray = checkedTables;
        let checkedCount = 0;
        for (let key of Object.keys(checkedTables)) {
          if(checkedTables[key].checked == true){
            checkedCount++;
          }
        }
        if(checkedCount > 0){
          this.hasChecked = true;
        }
        else{
          this.hasChecked = false;
        }
    }

    private getReportCalculations() {
        this.calculationService.getReportCalculationsFromSource(this.data.SourceId, this.data.ReportId).subscribe(calculations => {
          this.calculationGroups = [];
          for(var i = 0; i < calculations.length; i++){
            if(this.calculationGroups.filter(calc => calc.ReportCalculationGroupId == calculations[i].ReportCalculationGroupId).length == 0)
              this.calculationGroups.push(calculations[i]);
          }
          if(this.calculationGroups.length == 0){

            this.dialogRef.close();
            this.snackBarHandler.open('No calculations were found that could be imported.')
          }
          this.serviceData = this.getFakeSubscribe(this.calculationGroups);
          this.calculations = calculations;
          this.resetRefresh(true);
        }, err => {
            this.dialogRef.close();
            this.snackBarHandler.open('Connection to calculations failed.', 'failure')
        });
    }

    private saveCalculations() {
        const trueGroupImport = [];
        const trueImport = [];
        for (let key of Object.keys(this.importArray)) {
          if(this.importArray[key].checked == true){
            var report = this.calculations.filter(x => x.Id == this.importArray[key].id)[0];
            trueGroupImport.push(report);
          }
        }

        for(var i = 0; i < this.calculations.length; i++){
          if(trueGroupImport.filter(calc => calc.ReportCalculationGroupId == this.calculations[i].ReportCalculationGroupId).length != 0){
            trueImport.push(this.calculations[i]);
          }
        }

        this.dialogRef.close(trueImport);
    }
}
