
import {finalize} from 'rxjs/operators';
/**
 * Row of column lineage
 * @author Collin Atkins / 9.27.17 / Overhauled UI
 * @author Collin Atkins / 10.3.17 / Refactored term link check to use one call
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TargetColumnPipe } from 'app/pipes/target-column-pipe';
import { SnackBarHandler } from 'utilities/snackbar';
import { ColumnLineageService } from 'app/services/column-lineage.service';
import { TargetColumnService } from 'app/services/target-column.service';
import { TargetColumn } from 'app/models/target-column';
import { TableMapping } from 'app/models/table-mapping';
import { SourceTable } from 'app/models/source-table';
import { ColumnLineage } from 'app/models/column-lineage';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { EditTargetColumnDialogComponent } from 'app/target/target-table/target-column/edit-target-column-dialog/edit-target-column-dialog.component';
import { TermLinkModalComponent } from 'app/target/target-table/target-column/term-link-modal/term-link-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { LineageTreeModalComponent } from 'app/datalineage/lineage-tree-modal/lineage-tree-modal.component';

@Component({
    selector: 'dp-target-table-column',
    templateUrl: './target-table-column.component.html',
    styleUrls: ['./target-table-column.component.scss'],
    providers: [TargetColumnService, SnackBarHandler, ColumnLineageService]
})

export class TargetTableColumnComponent implements OnInit {

    @Input() TargetColumn: TargetColumn = new TargetColumn();
    @Input() TableMapping: TableMapping = new TableMapping();
    @Input() SourceTables: SourceTable[] = new Array<SourceTable>();
    @Input() permissions: Object;
    @Input() fullDataTypes: Object;
    @Output() targetColumnChanges = new EventEmitter();

    constructor(private targetColumnService: TargetColumnService, private snackBarHandler: SnackBarHandler, private columnLineageService: ColumnLineageService,
        private dialog: MatDialog, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
            /**
             * Adds the custom icons to the MatIconRegistry so that the can be referenced in the front end
             */
            this.matIconRegistry.addSvgIcon('dataLineage',this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictures/Data_Lineage_Icon_with_Container.svg"));
            this.matIconRegistry.addSvgIcon('editTarget',this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictures/Edit_Icon_with_Container.svg"));
            this.matIconRegistry.addSvgIcon('businessGlossary',this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictures/Business_Glossary_Icon_with_Container.svg"));
        }

    ngOnInit() { }

    /**
     * Adds a new blank ColumnLineage to the TargetColumn
     * @author Nash Lindley
     * @author Collin Atkins / 9.27.17 / Added check to make sure last columnLineage is filled
     */
    private addColumnLineage() {
        if (this.TargetColumn.ColumnLineages.length == 0 || this.TargetColumn.ColumnLineages[this.TargetColumn.ColumnLineages.length - 1].SourceColumnId >= 0) {
            this.TargetColumn.ColumnLineages.push(new ColumnLineage(this.TargetColumn.Id, this.TableMapping.SourceSystemId, this.TableMapping.TargetTableId, this.TableMapping.Id));
        }
    }

    /**
     * Deletes Target Column
     * @author John Crabill
     */
    private deleteTargetColumn() {
        this.targetColumnService.deleteColumn(this.TargetColumn.Id)
            .subscribe(column => {
                this.snackBarHandler.open(`${this.TargetColumn.FriendlyName} has been deleted.`, 'success');
                this.targetColumnChanges.emit();
            }, err => {
                console.log(err);
                this.snackBarHandler.open(`Target Column deletion failed.`, 'failure');
            });
    }

    /**
     * Calls service to get column lineages by table mapping id and target column id, then pushes on a new column lineage if needed
     * @author Collin Atkins / 11.15.17
     */
    private getColumnLineagesByMappingIdAndColumnId() {
        this.columnLineageService.getColumnLineagesByMappingIdAndColumnId(this.TableMapping.Id, this.TargetColumn.Id).pipe(
            finalize(() => {
                this.pushNewColumnLineage();
            }))
            .subscribe(columnLineages => {
                this.TargetColumn.ColumnLineages = columnLineages;
            }, err => {
                this.TargetColumn.ColumnLineages = new Array<ColumnLineage>();
            });
    }

    /**
     * @author Collin Atkins / 12.18.17
     */
    private openEditTargetColumnDialog() {
        this.dialog.open(EditTargetColumnDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: { TargetColumn: this.TargetColumn, permissions: this.permissions, fullDataTypes: this.fullDataTypes },
        })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.targetColumnChanges.emit();
                }
            });
    }

    /**
     * @author John Crabill / 1.3.18
     */
    private openTermLinkDialog(targetColumn?: TargetColumn) {
        this.dialog.open(TermLinkModalComponent, {
            panelClass:'custom-dialog-container',
            data: { TargetColumn: this.TargetColumn },
        })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.TargetColumn.hasLink = true;
                }
            });
    }

    /**
     * Pushes a new column lineage onto the target column's column lineage array
     * @author Collin Atkins / 11.15.17
     */
    private pushNewColumnLineage() {
        if (this.requiresNewColumnLineage()) {
            this.TargetColumn.ColumnLineages.push(new ColumnLineage(this.TargetColumn.Id, this.TableMapping.SourceSystemId, this.TableMapping.TargetTableId, this.TableMapping.Id));
        }
    }

    /**
     * Checks if the column lineage array requireds a new column lineage; this is when it doesn't have a blank one
     * @author Collin Atkins / 11.15.17
     */
    private requiresNewColumnLineage(): boolean {
        if (this.TargetColumn.ColumnLineages.length == 0) {
            return true;
        } else {
            let lastColumnLineage: ColumnLineage = this.TargetColumn.ColumnLineages[this.TargetColumn.ColumnLineages.length - 1];
            if (lastColumnLineage.SourceTableId >= 0 && lastColumnLineage.SourceColumnId >= 0) {
                return true;
            }
        }
        return false;
    }

}
