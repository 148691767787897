
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserUtility } from 'utilities/user-utility';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { Note } from 'app/models/note';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Auth0User } from 'app/models/Auth0User';
import { AuthService } from './auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class NotesService {
    private apiPath = 'api/Notes';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient, private authService: AuthService) { }

    /**
     * Save the Note
     * @param Note: Notes
     */
    saveNote(note: Note): Observable<any> {
      this.authService.getUserJwt().subscribe(jwt =>{
        if(note.Id < 0){
          note.CreatedUser = new Auth0User();
          note.CreatedUser.user_id = jwt["https://metaprism.com/user_id"];
        }
        note.UpdatedUser = new Auth0User();
        note.UpdatedUser.user_id = jwt["https://metaprism.com/user_id"];
        note.UpdatedUser.name = jwt["name"];
        note.CreatedUserId = note.CreatedUser.user_id;
      });
        return this.http.put(this.requestUrl, note, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }
    getNotesByParentId(id:number): Observable<any> {
        return this.http.get(this.requestUrl + '/?parentId=' + id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));

    }
    deleteNote(noteid): Observable<any> {
        return this.http.delete(`${this.requestUrl}?id=${noteid}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }



}
