import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TermModalComponent } from 'app/business-glossary/term/term-modal/term-modal.component';
import { Term } from 'app/models/term';
import { TermStatus } from 'app/models/term-status';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { TermStatusService } from 'app/services/term-status.service';
import { TermService } from 'app/services/term.service';
import { Subject } from 'rxjs';
import { GlossaryService } from '../services/glossary.service';
import { AuthService } from 'app/services/auth.service';

class FilterOptions {
  name: string;
  selected: boolean;
  data: any;
}

@Component({
  selector: 'dp-business-glossary-workflow',
  templateUrl: './business-glossary-workflow.component.html',
  styleUrls: ['./business-glossary-workflow.component.scss'],
  providers: [
    SnackBarHandler,
    TermService,
    TermStatusService,
    GlossaryService
  ]
})

export class BusinessGlossaryWorkflowComponent implements OnInit {

  private originalTermList: Term[][];
  private originalProposedTermList: Term[][];
  private originalAllTermList: Term[][];
  termList: Term[][];
  proposedTermList: Term[][];
  allTermList: Term[][];

  selected = "terms";

  private terms: Term[];
  private proposedTerms: Term[];
  private statuses: TermStatus[];
  private glossaries: any[];
  private permissions = {};

  readonly defaultFilterOptions: FilterOptions = { name: 'No Filter', selected: true, data: null };
  filterOptions: FilterOptions[];
  currentFilterOptions: FilterOptions;
  private destroy = new Subject<void>();

  constructor(
    private glossaryService: GlossaryService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private location: Location,
    private route: Router,
    private authService: AuthService,
    private snackbar: SnackBarHandler,
    private termService: TermService,
    private termStatusService: TermStatusService,
    private routeBreadcrumbService: RouteBreadcrumbService) {
    this.originalTermList = [[], [], []];
    this.originalProposedTermList = [[], [], []];
    this.originalAllTermList = [[], [], []];
    this.termList = [[], [], []];
    this.proposedTermList = [[], [], []];
    this.allTermList = [[], [], []];

    this.terms = [];
    this.proposedTerms = [];
    this.statuses = [];

    this.filterOptions = [this.defaultFilterOptions];
    this.currentFilterOptions = this.filterOptions[0];
  }

  ngOnInit(): void {
    this.authService.authorizeSection().subscribe(permissions =>{
      this.permissions = permissions;
      if(permissions.view){
        this.statuses = this.getTermStatuses();
        this.getGlossaries();
      }
      else {
        this.route.navigate(['/']);
      }
    });
    this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Business Glossary Publisher"], ["business-glossary-publisher/"]);
  }

  getGlossaries(){
    this.glossaryService.getGlossaries().subscribe(glossaries => {
      this.glossaries = glossaries;
      this.loadView();
    }, err => {
        this.loadView();
    });
  }

  ngOnDestroy() {
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  applyFilter(filterValue: string) {
    this.allTermList = JSON.parse(JSON.stringify(this.originalAllTermList));
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    for(var i = 0; i < this.allTermList.length; i++){
      this.allTermList[i] = this.allTermList[i].filter(item => item.TermName.trim().toLowerCase().includes(filterValue));
    }
  }

  handleClickEvent(event): void {
    let dialogRef = this.dialog.open(TermModalComponent, { data: { Term: event.term } });
  }

  private loadView(): void {
    const queryParams: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);

    let url = this.route.createUrlTree([], { queryParams: queryParams });
    this.location.go(url.toString());

    this.reinitializeTermLists();
    this.termService.getTerms(false).subscribe(terms => {
      this.terms = terms as Term[];
      this.populateSwimlanes();
    }, err => {
      this.outputErrors('Error: Could Not Reload View', err) 
    });
  }

  private getTermStatuses(): TermStatus[] {
    return this.termStatusService.getTermStatus();
  }

  private populateSwimlanes(): void {
    if (this.terms !== null && this.statuses !== null) {
      let tempAllTermList: Term[][] = [[], [], []];

      for (let index = 0; index < this.terms.length;index++){
        let term = this.terms[index]

        switch (term.TermStatus) {
          case '2': {
            tempAllTermList[0].push(term);
            break;
          }
          case '3': {
            tempAllTermList[1].push(term);
            break;
          }
          case '4': {
            tempAllTermList[2].push(term);
            break;
          }
          default: {
            break;
          }
        }
      };

      this.allTermList = tempAllTermList;
      this.originalAllTermList = tempAllTermList;
    }
  }

  private filterProposedTasks(): void {
    if (this.proposedTerms !== null) {
      let tempTermList: Term[][] = [[], [], []];

      this.proposedTerms.forEach((term, index, terms) => {
        let tempTerm = new Term();
        tempTerm.clone(term);

        this.proposedTermList = tempTermList;
        this.originalProposedTermList = tempTermList;
      });
    }
  }

  private reinitializeTermLists(): void {
    this.originalTermList = [[], [], []];
    this.originalProposedTermList = [[], [], []];
    this.originalAllTermList = [[], [], []];
  }

  private outputErrors(friendlyErrorMessage: string, errorObj?: Error): void {
    this.snackbar.open(friendlyErrorMessage, 'failure');

    if (errorObj !== null) {
      console.error(errorObj);
    }
  }

}
