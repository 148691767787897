
import {catchError} from 'rxjs/operators';
/**
 * Service to retrieve history of a job
 * @author Collin Atkins / 10.23.17
 */
import { Injectable } from '@angular/core';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class HistoryService {
    private apiPath = 'api/EDWJobHistory';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Get history of a job by target table name
     * @param tableName
     * @author Collin Atkins / 10.23.17
     */
    getHistoryByTableName(tableName: string): Observable<any> {
        return this.http.get(this.requestUrl + '?tableName=' + tableName).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
