
/**
 * Column Lineage Edtior - Main Page
 * @author Collin Atkins / 9.29.17 / Added removal of selections after successful delete and made speed improvements
 * @author Collin Atkins / 10.3.17 / Refactored term link check to use one call
 * @author Collin Atkins / 10.9.17 / Fixed bug of system selection loading incorrect/using old data from previous select
 */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ColumnLineage } from 'app/models/column-lineage';
import { Schema } from 'app/models/schema';
import { SourceTable } from 'app/models/source-table';
import { TableMapping } from 'app/models/table-mapping';
import { TargetColumn } from 'app/models/target-column';
import { TargetTable } from 'app/models/target-table';
import { ColumnLineageService } from 'app/services/column-lineage.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SourceTableService } from 'app/services/source-table.service';
import { SSISGeneratorService } from 'app/services/ssis-generator.service';
import { TargetColumnService } from 'app/services/target-column.service';
import { TableService } from 'app/services/target-table.service';
import { TermService } from 'app/services/term.service';
import { SqlConfiguratorDialogComponent } from 'app/target/target-table/sql-configurator/sql-configurator-dialog.component';
import { SqlViewDialogComponent } from 'app/target/target-table/sql-view-dialog/sql-view-dialog.component';
import { EditTargetColumnDialogComponent } from 'app/target/target-table/target-column/edit-target-column-dialog/edit-target-column-dialog.component';
import { EditTableDialogComponent } from 'app/target/target-table/edit-table-dialog/edit-table-dialog.component';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SnackBarHandler } from 'utilities/snackbar';
import { JobEditorModalComponent } from '../../jobhistory/job-editor-modal/job-editor-modal.component';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { SourceColumnService } from 'app/services/source-column.service';
import { SchemaService } from '../../services/schema.service';


@Component({
    selector: 'dp-target-table',
    templateUrl: './target-table.component.html',
    providers: [SchemaService, SourceColumnService, TargetColumnService, SourceTableService, ColumnLineageService, SnackBarHandler, SSISGeneratorService, TermService, TableService],
    styleUrls: ['./target-table.component.scss']
})

export class TargetTableComponent implements OnInit {

    private TargetTable: TargetTable = new TargetTable();
    private TableMapping: TableMapping = new TableMapping();

    private fullDataTypes: any[];
    private dataTypesAreLoaded: boolean = false;

    private TargetColumns: TargetColumn[] = new Array<TargetColumn>();
    private ColumnLineages: ColumnLineage[] = new Array<ColumnLineage>();
    private SourceTables: SourceTable[] = new Array<SourceTable>();
    private destroy = new Subject<void>();
    private Schema: Schema = new Schema();
    private permissions = {};


    // Pagination
    private searchText: string;
    private page: number = -1;

    constructor(
      private route: ActivatedRoute,
      private columnService: SourceColumnService,
      private targetColumnService: TargetColumnService,
      private schemaService: SchemaService,
      private sourceTableService: SourceTableService,
      private columnLineageService: ColumnLineageService,
      private snackbar: SnackBarHandler,
      private ssisGeneratorService: SSISGeneratorService,
      private termService: TermService,
      private dialog: MatDialog,
      private router: Router,
      private authService: AuthService,
      private tableService: TableService,
      private routeBreadcrumbService: RouteBreadcrumbService) { }

    /**
     * Gets tableId and name from url params
     * Loads fresh target column
     * @author Nash Lindley
     * @author Collin Atkins / 9.29.17 / Edited to accept qparams and removed getTable call
     * @author John Crabill / 10.27.17 / Added getTable call to get back url go back just to Table
     */
    ngOnInit() {
        this.authService.authorizeSection().subscribe(permissions =>{
            this.permissions = permissions;
            if(permissions.view){
                this.route.params.subscribe(params => {
                    this.Schema.Id = params['id'];
                    this.TargetTable.Id = +params['id2'];

                    this.schemaService.getSchemaById(this.Schema.Id).subscribe(schema => {
                        this.getDataTypes(schema.DBType);
                    });
                });
                this.getTargetTableById();
                this.getTargetColumnsByTargetTable();
            }
            else {
              this.router.navigate(['/']);
            }
        });
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Target Systems"], ["target-systems/"]);
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
      this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
    }

    /**
     * Clears columnLineages object and all targetColumn's columnLineages
     * @author Collin Atkins / 11.14.17
     */
    private clearColumnLineages() {
        this.ColumnLineages = new Array<ColumnLineage>();
        this.TargetColumns.forEach(tc => {
            tc.ColumnLineages = new Array<ColumnLineage>();
        });
    }

    /**
     * Gets all target column terms to find if any have links
     * @author Collin Atkins / 10.5.17 /
     */
    private getAllTargetColumnTerms(targetColumns: TargetColumn[]) {
        this.termService.getAllTargetColumnTerms()
            .subscribe((columnIds: Object[])=> {
                targetColumns.forEach(targetColumn => {
                    targetColumn.hasLink = columnIds.some(colId => colId === targetColumn.Id);
                });
                this.TargetColumns = targetColumns;
            }, err => console.log(err));
    }

    /**
     * Gets Column Lineages by TableMappingId then calls mapColumnLineages
     * @author Collin Atkins / 11.14.17
     */
    private getColumnLineages() {
        this.columnLineageService.getColumnLineagesByTableMappingId(this.TableMapping.Id).pipe(
            finalize(() => this.mapColumnLineages()))
            .subscribe(columnLineages => {
                this.ColumnLineages = columnLineages;
            });
    }

    /**
     * Gets Target Columns by TargetTableId, then calls getAllTargetColumnTerms
     * @author Collin Atkins / 11.14.17
     */
    private getTargetColumnsByTargetTable() {
        this.targetColumnService.getTargetColumnsByTargetTable(this.TargetTable.Id)
            .subscribe(targetColumns => {
                this.TargetColumns = targetColumns;
                this.mapColumnLineages();
                this.getAllTargetColumnTerms(this.TargetColumns);
            }, err => console.log(err));
    }

    /**
     * Gets Target Columns by TargetTableId, then calls getAllTargetColumnTerms
     * @author Collin Atkins / 11.14.17
     */
    private getTargetColumnsByTargetTableAndRemap() {
        this.targetColumnService.getTargetColumnsByTargetTable(this.TargetTable.Id)
            .subscribe(targetColumns => {
                this.TargetColumns = targetColumns;
                this.getAllTargetColumnTerms(this.TargetColumns);
                this.SelectTableMapping(this.TableMapping);
            }, err => console.log(err));
    }

    /**
     * Gets Target Table by id
     * @author Collin Atkins / 11.14.17
     */
    private getTargetTableById() {
        this.tableService.getTableById(this.TargetTable.Id)
            .subscribe(table => {
                this.TargetTable = table[0];
            }, err => console.log(err));
    }

    /**
     * Loads SourceTables by SourceSystemId and stores them in the SourceTableService to be accessed later
     * @author Nash Lindley
     */
    private getSourceTables() {
        this.sourceTableService.getSourceTablesBySourceSystem(this.TableMapping.SourceSystemId)
            .subscribe(sourceTables => {
                this.SourceTables = sourceTables;
            }, err => {
                console.log(err);
                this.snackbar.open('Failed to find any Source Tables.', 'failure');
            });
    }

    /**
     * Link to SSIS
     * @author John Crabill
     */
    private linkTargetTable() {
        this.ssisGeneratorService.linkToSSIS(this.TargetTable.Id)
            .subscribe(targetColumns => {
                this.snackbar.open('SSIS Connection successful', 'success');
            }, err => {
                this.snackbar.open(`SSIS Connection failed`, 'failure');
                console.log(err);
            });
    }

    /**
     * Maps column lineages to their respective target columns
     * @author Collin Atkins / 11.14.17
     */
    private mapColumnLineages() {
        this.TargetColumns.forEach(tc => {
            if (this.ColumnLineages.length > 0 && this.ColumnLineages.some(cl => cl.EDWColumnId == tc.Id)) {
                tc.ColumnLineages = this.ColumnLineages.filter(cl => cl.EDWColumnId == tc.Id);
                tc.ColumnLineages = tc.ColumnLineages.map(cl => {
                    cl.SourceSystemId = this.TableMapping.SourceSystemId;
                    return cl;
                });
            }
            tc.ColumnLineages.push(new ColumnLineage(tc.Id, this.TableMapping.SourceSystemId, this.TargetTable.Id, this.TableMapping.Id));
        });
    }

    /**
     * @author Collin Atkins / 12.18.17
     */
    private openEditTargetColumnDialog() {
        if(this.dataTypesAreLoaded){
            this.dialog.open(EditTargetColumnDialogComponent, {
                panelClass:"custom-dialog-container",
                data: { TargetTableId: this.TargetTable.Id, permissions: this.permissions, fullDataTypes: this.fullDataTypes },
            })
                .afterClosed().subscribe(result => {
                    if (result) {
                        this.getTargetColumnsByTargetTableAndRemap();
                    }
                }); 
        }
    }

    /**
     * Gets target sql statement then opens sql statement view dialog if it is found
     * @author Collin Atkins / 10.20.17
     */
    private openSqlConfiguratorDialog() {
        this.dialog.open(SqlConfiguratorDialogComponent, {
            width: '80%',
            data: { TableMapping: this.TableMapping, SourceTables: this.SourceTables, permissions: this.permissions }
        });
    }

    /**
     * Gets target sql statement then opens sql statement view dialog if it is found
     * @author Collin Atkins / 10.20.17
     */
    private openSqlViewDialog() {
        this.tableService.getTargetSql(this.TargetTable.Id + '', this.TableMapping.Id)
            .subscribe(sqlStatement => {
                this.dialog.open(SqlViewDialogComponent, {
                    width: '80%',
                    data: { SqlStatement: sqlStatement, TargetTable: this.TargetTable, TableMapping: this.TableMapping }
                });
            }, err => {
                this.snackbar.open(`No SQL Statement Found`, 'failure');
            });
    }

    /**
     * Sets tableMapping, then getsColumnLineages and sourceTables
     * @param tableMapping
     * @author Collin Atkins / 11.14.17
     */
    private SelectTableMapping(tableMapping: TableMapping) {
        this.resetTableMappings();
        this.TableMapping = tableMapping;
        if (tableMapping.Id >= 0) {
            this.getColumnLineages();
            this.getSourceTables();
        }
    }

    /**
     * Resets to a blank table mapping with all objects retrieved from tableMapping data reset
     * @author Collin Atkins / 11.14.17
     */
    private resetTableMappings() {
        this.clearColumnLineages();
        this.TableMapping = new TableMapping();
        this.SourceTables = new Array<SourceTable>();
    }

    private getDataTypes(databaseType: string) {
        this.columnService.getDataTypes(databaseType).subscribe(result => {
            this.fullDataTypes = result;
            this.dataTypesAreLoaded = true;
        }, err => this.snackbar.open(`Failure: Could not retrieve Data Types`, 'failure'));
      }

    private viewJobHistory(job){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = "1060px";
        dialogConfig.panelClass = "custom-dialog-container";
        dialogConfig.data = { job: job, permissions: this.permissions };

        const dialogRef = this.dialog.open(JobEditorModalComponent, dialogConfig);
    }

}
