import { Injectable } from '@angular/core';
import { TermType } from 'app/models/term-type';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TermTypeService {
    private apiPath = 'api/BGTermType';

    termTypes: TermType[] = [{ Id: 1, TermId: 1, TermType: 'NOT SAME AS'},
                             { Id: 2, TermId: 2, TermType: 'SEE ALSO' },
                             { Id: 3, TermId: 3, TermType: 'CALCULATED FROM' }];

    constructor(private http: HttpClient) { }

    getTermTypes() {
        return this.termTypes;
    }

}
