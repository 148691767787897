import { SourceTableJoinCondition } from 'app/models/source-table-join-condition';
import { SourceTable } from 'app/models/source-table';

export class SourceTableJoin {

    Id: number;
    SourceTableJoinId: number;
    EDWTableLineageId: number;
    SourceTableId: number;
    JoinAlias: string;
    JoinType: string;
    JoinDescription: string;
    SourceTable: SourceTable;
    SourceTableJoinConditions: SourceTableJoinCondition[];
    show: boolean;

    constructor(tableLineageId: number = -1, sourceTableId: number = -1, joinAlias: string = null) {
        this.Id = -1;
        this.SourceTableJoinId = -1;
        this.EDWTableLineageId = tableLineageId;
        this.SourceTableId = sourceTableId;
        this.JoinAlias = joinAlias;
        this.JoinType = 'LEFT OUTER'
        this.SourceTable = new SourceTable();
        this.SourceTableJoinConditions = new Array<SourceTableJoinCondition>();
        this.show = false;
    }
}
