import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dp-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  private router: Router;
  private pathMappings: Object;
  private routerPath: string;
  private pathArray: string[];

  breadcrumbs: any = [
    {
    showBreadcrumb: true,
    breadcrumbVisual: 'Home',
    breadcrumbLink: '/',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    },
    {
    showBreadcrumb: false,
    breadcrumbVisual: '',
    breadcrumbLink: '',
    breadcrumbClass: ''
    }
  ];

  constructor(private _router: Router) {
    this.router = _router;
    this.pathMappings = {
      "": "Home",
      "businessglossary": "Business Glossary",
      "business-glossary-publisher": "Business Glossary Publisher",
      "source": "Source",
      "sourcesystem": "Source Systems",
      "sourcetable": "Source System",
      "sourcecolumn": "Source Table",
      "admin": "Administration",
      "users": "Users",
      "generatedjobs": "Generated Jobs",
      "jobhistory": "Job History",
      "schema": "Enterprise Data Warehouse Tables",
      "table": "Lineage Editor",
      "dataLineage": "Data Lineage",
      "job-dashboard": "Job Dashboard"
    };
  }

  ngOnInit() {
    // Grab the current URL from Angular's router and create an array of the parts of that path
    this.routerPath = this.router.url;
    this.pathArray = this.routerPath.split('\/');

    var breadcrumbNavs = document.querySelectorAll("[id='breadcrumb-nav']");

    for (var i = 0; i < breadcrumbNavs.length; i++) {
      this.buildBreadcrumb(breadcrumbNavs[i]);
    }
  }

  // Dynamically builds the navigation structure from sections of the current router path
  buildBreadcrumb(breadcrumbNav) {
    var path: string = '/'; // Set the path variable to initially point to the application root
    var pathSectionLabel: string = ''; // Set the path section label to an empty string

    // Iterate over the path array to process the path section-by-section
    for (var i = 1; i < this.pathArray.length; i++) {
      /* Append the path section to the path and set pathSection label to the
      path section so it can be manipulated to a more human-readable label. */
      path += this.pathArray[i] + '/';
      pathSectionLabel = this.pathArray[i];

      if (pathSectionLabel !== '') {
        /* Check if the path section is in the path mappings object, and use
        the mapped string as the anchor element's label if so. Otherwise,
        proceed to manipulate the path section into a more human-readable
        label. */
        if (this.pathMappings[pathSectionLabel]) {
          this.breadcrumbs[i].breadcrumbVisual = this.pathMappings[pathSectionLabel];
        } else {
          /* Replace any dashes in the path section label with a space, remove
          any query components from the path section label, and set the inner
          HTML of the anchor element to the manipulated path section label. */
          pathSectionLabel = pathSectionLabel.replace(/-/g, ' ');
          pathSectionLabel = pathSectionLabel.replace(/\?.*$/g, '');

          //For if there are #TableMappings to remove from Breadcrumb
          if(i + 1 == this.pathArray.length && this.pathArray[1] == 'schema'){
            pathSectionLabel = pathSectionLabel.split("#")[0];
          }
          this.breadcrumbs[i].breadcrumbVisual = pathSectionLabel;
        }

        this.breadcrumbs[i].showBreadcrumb = true;
        if(this.allowListOfBreadcrumbs(this.pathArray[1], this.pathArray[i])){
          // Set the HTML anchor element's href to the path
          this.breadcrumbs[i].breadcrumbLink = path;
        }
        else {
          this.breadcrumbs[i].breadcrumbClass = "disabled";
        }
      }
    }
  }

  allowListOfBreadcrumbs(source: string, currentPath: string){
    switch(source) {
      case 'source': { 
        if(currentPath == 'tables' || currentPath == 'columns') return false;
      } 
      case 'schema': { 
        if(currentPath == 'table') return false;
      } 
      case 'filesource': { 
        if(currentPath == 'reports' || currentPath == 'calculations' || currentPath == 'group') return false;
      } 
      case 'business-glossary': { 
        if(currentPath == 'glossary' || currentPath == 'category' || currentPath == 'term') return false;
      } 
      default: { 
        return true;
      }
    }
  }
}
