import { Component } from '@angular/core';
import { BaseDialogComponent } from '../base/base-dialog/base-dialog.component';

@Component({
  selector: 'dp-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseDialogComponent {
}
