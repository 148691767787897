import { Category } from "app/models/category";
import { Auth0User } from './Auth0User';

export class Glossary {
    Id: number;
    BusinessGlossaryId: number;
    BusinessGlossaryDescription;
    BusinessGlossaryName: string;
    Categories: Category[];
    CreatedDate: Date;
    UpdatedDate: Date;
    CreatedUser: Auth0User;
    UpdatedUser: Auth0User;
    CreatedUserId: string;
    UpdatedUserId: string;

    constructor() {
        this.Id = -1;
        this.Categories = new Array();
    }
}
