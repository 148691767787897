
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SourceTableJoin } from 'app/models/source-table-join';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SourceTableJoinService {
    private apiPath = 'api/EDWSourceTableJoins';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Deletes a SourceTableJoin by Id
     * @param Id id of a SourceTableJoin
     * @author Nash Lindley
     */
    deleteSourceTableJoin(Id) {
        return this.http.delete(`${this.requestUrl}?Id=${Id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets ColumnLineages given a TargetColumn, SourceSystem, and SourceTable
     * @param tableId TargetTable.Id
     * @param systemId SourceSystem.Id
     * @param sourceTableId SourceTable.Id
     * @author Nash Lindley
     */
    getSourceTableJoinBySourceTable(tableId, systemId, sourceTableId) {
        return this.http.get(`${this.requestUrl}?TargetTableId=${tableId}&SourceSystemId=${systemId}&SourceTableId=${sourceTableId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets ColumnLineages given a TargetColumn and SourceSystem
     * @param tableId TargetTable.Id
     * @param systemId SourceSystem.Id
     * @author Nash Lindley
     */
    getSourceTableJoinByTableAndSystem(tableId, systemId): Observable<any> {
        return this.http.get(`${this.requestUrl}?TargetTableId=${tableId}&SourceSystemId=${systemId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets source table joins by tableMappingId given
     * @param tableMappingId
     * @author Collin Atkins / 11.16.17
     */
    getSourceTableJoinByTableMappingId(tableMappingId: number): Observable<any> {
        return this.http.get(`${this.requestUrl}?tableMappingId=${tableMappingId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a new ColumnLineage or updates an existing lineage
     * @param lineage: ColumnLineage
     * @author Nash Lindley
     */
    saveSourceTableJoin(join): Observable<any> {
        return this.http.put<SourceTableJoin>(this.requestUrl, join, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
