/**
 * Column Lineage Editor Dialog
 * @author Collin Atkins / 12.14.17
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { SourceTableJoin } from 'app/models/source-table-join';
import { TableLineageService } from 'app/services/table-lineage.service';
import { TableMapping } from 'app/models/table-mapping';
import { ColumnLineage } from 'app/models/column-lineage';
import { TableLineage } from 'app/models/table-lineage';

@Component({
    selector: 'column-lineage-editor-dialog',
    templateUrl: 'column-lineage-editor-dialog.component.html',
    styleUrls: ['column-lineage-editor-dialog.component.scss'],
    providers: [TableLineageService]
})

export class ColumnLineageEditorDialogComponent implements OnInit {

    private TableMapping: TableMapping = new TableMapping();
    private ColumnLineage: ColumnLineage = new ColumnLineage();
    private TableLineages: TableLineage[];
    private Joins: SourceTableJoin[];
    private selectedTab: string;

    constructor(private dialogRef: MatDialogRef<ColumnLineageEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private snackBarHandler: SnackBarHandler, private tableLineageService: TableLineageService) { }

    ngOnInit() {
        if (this.data && this.data.TableMapping && this.data.ColumnLineage) {
            this.TableMapping = Object.assign({}, this.data.TableMapping);
            this.ColumnLineage = Object.assign({}, this.data.ColumnLineage);
            this.getTableLineages();
        } else {
            this.snackBarHandler.open('Missing parameters', 'failure');
            this.dialogRef.close();
        }
    }

    /**
     * Gets table lineages by tableMappingId
     * @author Collin Atkins / 11.17.17
     */
    private getTableLineages() {
        this.tableLineageService.getTableLineagesByTableMappingId(this.TableMapping.Id)
            .subscribe(tableLineages => {
                this.TableLineages = tableLineages;
                this.getSourceTableJoins();
            }, err => {
                this.TableLineages = new Array<TableLineage>();
            });
    }


    /**
     * Gets the sourceTableJoins from the TableLineages by sourceTableId and tableMappingId of the columnLineage
     * @author Collin Atkins / 11.27.17
     */
    private getSourceTableJoins() {
        let tableLineage: TableLineage = this.TableLineages.find(tl => tl.SourceTableId == this.ColumnLineage.SourceTableId && tl.TableMappingId == this.ColumnLineage.TableMappingId);
        this.Joins = (tableLineage && tableLineage.SourceTableJoins) ? tableLineage.SourceTableJoins : new Array<SourceTableJoin>();
    }

    /**
     * A column lineage can only have either a calculation or transformation; keeps the one with priority
     * @author Collin Atkins / 11.9.17
     */
    private replaceTransformationOrCalculation() {
        if (this.selectedTab == 'Calculation') {
            this.ColumnLineage.Transformation = null;
        } else if (this.selectedTab == 'Transformation') {
            this.ColumnLineage.ColumnCalculation = null;
        } else {
            if (this.ColumnLineage.Transformation && this.ColumnLineage.ColumnCalculation) {
                this.ColumnLineage.ColumnCalculation = null;
            }
        }
    }

    /**
     * Sets the active tab title
     */
    private setTitle(title) {
        this.selectedTab = title;
    }

    /**
     * Saves on dialog closed with a result
     * @author Collin Atkins / 12.14.17
     */
    private saveColumnLineage() {
        this.replaceTransformationOrCalculation();
        this.dialogRef.close(this.ColumnLineage);
    }

}