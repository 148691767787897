/**
 * Dialog for Importing Tables from a Schema's Connection
 * @author Collin Atkins / 12.15.17
 */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { SourceImport } from 'app/models/source-import';
import { TableImport } from 'app/models/import-schema';
import { SchemaService } from 'app/services/schema.service';
import { Schema } from 'app/models/schema';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';

@Component({
    selector: 'import-tables-dialog',
    templateUrl: 'import-tables-dialog.component.html',
    styleUrls: ['import-tables-dialog.component.scss'],
    providers: [SchemaService]
})

export class ImportTablesDialogComponent implements OnInit {

    @ViewChild(DataTableDirective)
    private dtElement: DataTableDirective;
    private dtTrigger: Subject<TableImport[]> = new Subject();

    private Schema: Schema = new Schema();
    private sourceImport: SourceImport = new SourceImport();
    private selectAllButton: boolean = false;
    private tablePrefix: string = "";
    private importArray: any[];
    private hasChecked: boolean;
    private dbSchemaName: string = "";

    tableDetails: ITableDetails;
    serviceData: any;
    ref: boolean;

    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: "Table Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.Id}`
      }
    ];

    constructor(private dialogRef: MatDialogRef<ImportTablesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private schemaService: SchemaService, private snackBarHandler: SnackBarHandler) {


        }

    ngOnInit() {
        this.Schema = this.data && this.data.Schema ? Object.assign({}, this.data.Schema) : new Schema();
        this.clearTable();
        this.setPrefix();
        this.getTables();
        this.serviceData = this.getFakeSubscribe([]);
        this.tableDetails = {routing: null, edit: false, connect:false, noView: true, checkbox:true, paginatorSize: 5};
        this.hasChecked = false;
    }

    /**
     * Created because the dp-table component needs a observable
     * This allows the manipulation of data before insertinging it
     * into the dp-table component
     */
    getFakeSubscribe(array): Observable<any> {
      return Observable.of(array);
    }

    private resetRefresh(value: boolean){
      this.ref = value;
    }

    /**
     * Gets a list of items from the table and
     * enables or disables the save button depending
     * on if one is checked or not
     */
    private isChecked(checkedTables){
        this.importArray = checkedTables;
        let checkedCount = 0;
        for (let key of Object.keys(checkedTables)) {
          if(checkedTables[key].checked == true){
            checkedCount++;
          }
        }
        if(checkedCount > 0){
          this.hasChecked = true;
        }
        else{
          this.hasChecked = false;
        }
    }

    /**
     * For empty modal
     */
    private clearTable() {
        this.sourceImport = new SourceImport();
        this.selectAllButton = false;
    }

    /**
     * @param imports
     */
    private createDictionary(imports) {
        this.sourceImport.Id = imports.Id;
        this.sourceImport.SchemaTables = new Array();
        for (let i = 0; i < imports.SchemaTables.length; i++) {
            this.sourceImport.SchemaTables.push({ Id: imports.SchemaTables[i], value: false });
        }
        this.sourceImport.SchemaTables = this.sourceImport.SchemaTables.sort(function(a, b) { 
            return ((a.Id < b.Id) ? -1 : ((a.Id > b.Id) ? 1 : 0));
        });
        this.serviceData = this.getFakeSubscribe(this.sourceImport.SchemaTables);
        this.resetRefresh(true);
    }

    /**
     * @author Collin Atkins / 10.16.17
     */
    private filterSelected(): any[] {
        return this.sourceImport.SchemaTables.filter(table => table.value);
    }

    /**
     * Uses schema service to get tables by schema connection
     */
    private getTables() {
        this.rerender();
        this.schemaService.getTablesBySchemaId(this.Schema.Id)
            .subscribe(schemas => {
                this.dbSchemaName = schemas.SchemaName;
                this.resetRefresh(true);
                this.createDictionary(schemas);
                this.dtTrigger.next();
            }, err => {
                this.dialogRef.close();
                this.snackBarHandler.open('Connection to schema failed.', 'failure')
            });
    }

    /**
     * Set prefix in import
     * @author Collin Atkins / 10.24.17
     */
    private setPrefix() {
        if (this.Schema) {
            if(this.Schema.DBSchemaName == "")
                this.tablePrefix = this.Schema.DBSchemaName;
            else 
                this.tablePrefix = this.Schema.DBSchemaName + '.';
            if (this.Schema && this.Schema.DBType && this.Schema.DBType.toLowerCase().trim() == 'sql server') {
                this.tablePrefix += 'dbo.';
            }
        }
    }

    /**
     * Calls schema service to import tables
     * @param importedTables tables to import
     */
    private importTables(importedTables) {
        importedTables.schemaName = this.dbSchemaName;
        importedTables.DBType = this.Schema.DBType;
        importedTables.importType = "target";
        importedTables.TablePrefix = this.tablePrefix;

        this.schemaService.importTables(importedTables)
            .subscribe(data => {
                this.dialogRef.close(data);
                this.snackBarHandler.open('Table Import completed', 'success');
            }, err => {
                console.log(err);
                this.dialogRef.close();
                this.snackBarHandler.open('Table Import failed' + err, 'failure');
            });
    }

    /**
     * Sets up the object then calls importTables with it
     */
    private saveTables() {
        const trueImport = new TableImport();
        trueImport.Id = this.sourceImport.Id;
        for (let key of Object.keys(this.importArray)) {
          if(this.importArray[key].checked == true){
            trueImport.stringTables.push(this.importArray[key].id);
          }
        }

        this.dialogRef.close({trueImport: trueImport, tablePrefix: this.tablePrefix});
    }

    /**
     * @author Collin Atkins
     */
    private selectAll() {
        this.sourceImport.SchemaTables.forEach(table => {
            table.value = this.selectAllButton;
        });
    }

    private rerender() {
        if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        }
    }

}
