import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SourceColumnsAddComponent } from './source-columns/source-columns-add/source-columns-add.component';
import { SourceColumnsComponent } from './source-columns/source-columns.component';
import { SourceSystemNewService } from './source-system.service';
import { DndDirective } from './source-systems/source-systems-add/dnd.directive';
import { SourceSystemsAddComponent } from './source-systems/source-systems-add/source-systems-add.component';
import { SourceSystemsEditComponent } from "./source-systems/source-systems-edit/source-systems-edit.component";
import { SourceSystemsTableComponent } from './source-systems/source-systems-table/source-systems-table.component';
import { SourceSystemsComponent } from './source-systems/source-systems.component';
import { SourceTablesAddComponent } from "./source-tables/source-tables-add/source-tables-add.component";
import { SourceTablesComponent } from './source-tables/source-tables.component';
@NgModule({
  declarations: [SourceSystemsComponent, SourceTablesComponent, SourceColumnsComponent, SourceSystemsTableComponent, SourceSystemsAddComponent, DndDirective, SourceTablesAddComponent, SourceColumnsAddComponent, SourceSystemsEditComponent],
  providers: [ SourceSystemNewService ],
  entryComponents: [SourceSystemsAddComponent, SourceTablesAddComponent, SourceColumnsAddComponent, SourceSystemsEditComponent],
  imports: [
    CommonModule, SharedModule, ReactiveFormsModule, FormsModule
  ]
})
export class SourceSystemNewModule { }
