
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { TableMapping } from 'app/models/table-mapping';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TableMappingService {
    private apiPath = 'api/EDWTableMapping';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all table mappings
     * @author Collin Atkins / 11.10.11
     */
    getTableMappings(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all table mappings
     * @author Collin Atkins / 11.10.11
     */
    getTableMappingsByTargetTableId(targetTableId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?targetTableId=' + targetTableId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Saves a table mapping
     * @author Collin Atkins / 11.10.11
     */
    saveTableMapping(tableMapping: TableMapping): Observable<any> {
        return this.http.put<TableMapping>(this.requestUrl, tableMapping, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
