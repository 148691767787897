/**
 * Dialog for Adding/Editing a Target Table
 * @author Collin Atkins / 12.15.17
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { TargetColumn } from 'app/models/target-column';
import { TargetColumnService } from 'app/services/target-column.service';
import { TargetTable } from 'app/models/target-table';

@Component({
    selector: 'edit-target-column-dialog',
    templateUrl: 'edit-target-column-dialog.component.html',
    styleUrls: ['edit-target-column-dialog.component.scss'],
    providers: [TargetColumnService]
})

export class EditTargetColumnDialogComponent implements OnInit {

    private TargetTableId: number;
    private TargetColumn: TargetColumn = new TargetColumn();
    private fullDataTypes: any[];
    private dataTypes;

    private showScaleLength: boolean = false;
    private showPrecisonLength: boolean = false;

    constructor(private dialogRef: MatDialogRef<EditTargetColumnDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private snackbar: SnackBarHandler, private targetColumnService: TargetColumnService) { }

    ngOnInit() {
        if (this.data && (this.data.TargetTableId || this.data.TargetColumn)) {
            this.TargetTableId = this.data.TargetTableId ? this.data.TargetTableId : -1;
            this.TargetColumn = this.data.TargetColumn ? Object.assign({}, this.data.TargetColumn) : new TargetColumn(this.TargetTableId);
            this.fullDataTypes = this.data.fullDataTypes;
            this.loadDataTypes();
            this.generateFullDataType();
        } else {
            this.dialogRef.close();
            this.snackbar.open('Invalid parameters');
        }
    }

    private fillFlags() {
        this.TargetColumn.NullableFlag = this.TargetColumn.IsNullable ? 'Y' : 'N';
        this.TargetColumn.PrimaryKeyFlag = this.TargetColumn.IsPrimaryKey ? 1 : 0;
    }

    /**
     * Autofills data in target column depending form filled out
     * @param this.targetColumn TargetColumn
     * @author Nash Lindley
     */
    private generateFullDataType() {
        let result = this.TargetColumn.BaseDataType;
        if (result) {
            var foundType = this.fullDataTypes.find(item => item.Name == result);
            var fullDataType = "";
            if(foundType.HasPrecision && foundType.HasScale){
                fullDataType = this.setFullDataType(this.TargetColumn.BaseDataType, this.TargetColumn.Precision, this.TargetColumn.Scale);
                this.showScaleLength = true;
                this.showPrecisonLength = true;
            }
            else if(foundType.HasPrecision){
                fullDataType = this.setFullDataType(this.TargetColumn.BaseDataType, this.TargetColumn.Precision, null);
                this.TargetColumn.Scale = null;
                this.showScaleLength = false;
                this.showPrecisonLength = true;
            }
            else if(foundType.HasScale){
                fullDataType = this.setFullDataType(this.TargetColumn.BaseDataType, null, this.TargetColumn.Scale);
                this.TargetColumn.Precision = null;
                this.showScaleLength = true;
                this.showPrecisonLength = false;
            }
            else {
                fullDataType = this.setFullDataType(this.TargetColumn.BaseDataType, this.TargetColumn.Precision, null);
                this.TargetColumn.Scale = null;
                this.showScaleLength = false;
                this.showPrecisonLength = true;
            }
            this.TargetColumn.FullDataType = fullDataType;
        }
    }

    setFullDataType(baseDataType, precisionOrLength, scale){
        var fullDataType = ""
        if (precisionOrLength && scale) {
          fullDataType = `${baseDataType}(${precisionOrLength},${scale})`;
        } 
        else if(precisionOrLength){
          fullDataType = `${baseDataType}(${precisionOrLength})`;
        }
        else {
          fullDataType = `${baseDataType}`;
        }
        return fullDataType;
      }

    /**
     * Returns all datatypes in this case for an oracle database;
     * @author Nash Lindley
     */
    private loadDataTypes() {
        const map = require("array-map");
        this.dataTypes = map(this.fullDataTypes, function (dataType) {
            return dataType.Name;
          });
        this.dataTypes.sort();
    }

    /**
     * Calls TargetColumnService to Add or Update a TargetColumn
     * @author Nash Lindley
     */
    private saveTargetColumn() {
        this.fillFlags();
        this.targetColumnService.saveTargetColumn(this.TargetColumn)
            .subscribe(targetcolumn => {
                this.dialogRef.close(this.TargetColumn);
                this.snackbar.open(`${this.TargetColumn.FriendlyName} was saved.`, 'success');
            }, err => {
                console.log(err);
                this.dialogRef.close();
                this.snackbar.open(`Target Column failed to be saved.`, 'failure');
            });
    }

}