import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class RelatedTermNew {

  constructor(id, parentId, childId, name, description, relation, relationId){
    this.Id = id;
    this.RelatedTermParentId = parentId;
    this.RelatedTermChildId = childId;
    this.RelatedTermTypeId = relationId;
    this.Name = name;
    this.Description = description;
    this.Relation = relation;
  }


  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  Id: number;
  
  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  RelatedTermParentId: number;

  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  RelatedTermChildId: number;

  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  RelatedTermTypeId: number;

  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  Name: string;

  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  Description: string;


  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  Relation: string;

  
}
