export class Auth0User {
  user_id: string;
  email: string;
  name: string;
  password?: string;
  tenantName?: string;
  selected: boolean;
  teamRoleId: number;
  roleString: string;
  currentRoleNames?: string[];
  currentRoleIds?: string[];
  previousRoleIds?: string[];

  constructor() {
      this.selected=false;
      this.teamRoleId =-1;
      this.roleString = "";
  }
}
