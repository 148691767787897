
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SourceTable } from 'app/models/source-table';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SourceTableService {
    private apiPath = 'api/EDWSourceTable';
    private requestUrl = environment.serviceUrl + this.apiPath;

    sourceTables: SourceTable[];

    constructor(private http: HttpClient) { }

    /**
     * Gets a source table by id
     * @param Id
     * @author Collin Atkins / 10.20.17
     */
    getSourceTableById(Id: number): Observable<any> {
        return this.http.get(`${this.requestUrl}?Id=${Id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the SourceTables of a given SourceSystem
     * @param systemId number - id of source system
     */
    getSourceTablesBySourceSystem(systemId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?systemId=' + systemId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the SourceTables of a given SourceSystem
     * @param systemId number - id of source system
     */
    getAllSourceTables(): Observable<any> {
        return this.http.get(this.requestUrl + '?getAll=' + "true", httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getSourceTablesBySourceSystemImport(systemId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?systemTableId=' + systemId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Save the Source Table from modal
     * @param SourceTable SourceTable - source table to save
     */
    saveTable(SourceTable: SourceTable): Observable<any> {
        return this.http.put(this.requestUrl, SourceTable, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Loads all the SourceTables into the local variable sourceTables
     * @param systemId id of source system
     */
    loadSourceTables(systemId) {
        this.getSourceTablesBySourceSystem(systemId)
            .subscribe(sourceTables => {
                this.sourceTables = sourceTables;
            });
    }

    deleteSourceTable(id: number): Observable<any> {
      return this.http.delete(`${this.requestUrl}?Id=${id}`, httpOptions).pipe(
        catchError(new ErrorHandler().handleError));
    }

}
