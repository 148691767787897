
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserUtility } from 'utilities/user-utility';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { Team } from 'app/models/team';
import { TeamUser } from 'app/models/team-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Auth0User } from 'app/models/Auth0User';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TeamService {
    private apiPath = 'api/Team';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient, private authService:AuthService) { }

    /**
     * Save the Team
     * @param Note: Team
     */
    saveTeam(team: Team): Observable<any> {
        return this.http.put(this.requestUrl, team, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }
    getTeams(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));

    }
    getTeamById(id: number): Observable<any> {
        return this.http.get(this.requestUrl + '/?id=' + id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));

    }




}
