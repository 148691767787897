import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({

    templateUrl: './add-button-dialog.component.html',
    styleUrls: ['./add-button-dialog.component.scss']
  })
  export class AddButtonDialogComponent implements OnInit {


    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddButtonDialogComponent>) {}
    keys = [];
    currentEntry = {};
    title = "";
    isEdit = false;
    rawData;
    isViewOnly = false;
    mappedValues = {};
    parentEnforced = false;
    originalFormFieldsData = [];

    ngOnInit(){
        if(this.data.edit){
            this.isEdit = true;
            if(this.data.viewOnly){
                this.isViewOnly = true;
            }
        }
        this.rawData = this.data.rawData;
        this.title = this.data.title;
        this.data = this.data.addFormItems;
        this.originalFormFieldsData = JSON.parse(JSON.stringify(this.data));
        // get visible values of dropdowns with mappings
        if(this.isViewOnly){
            for(let i = 0; i < this.data.length; i++){
                if(this.data[i].DropdownValsMapping != null && this.data[i].DropdownValsMapping != undefined){
                    for(let j = 0; j < this.data[i].DropdownValsMapping.length; j++){
                        if(this.data[i].DropdownValsMapping[j] === this.rawData[this.data[i]["fieldName"]]){
                            this.mappedValues[this.data[i]["fieldName"]] = this.data[i].inputVals[j];
                        }
                    }
                }
            }
        }
        this.dialogRef.disableClose = true;
        for(let key in this.data){
            this.keys.push({"name": this.data[key].inputText, "input-type": this.data[key].inputType, "hint-text":  this.data[key].hintText,
             "field-name": this.data[key].fieldName, "inputVals": this.data[key].inputVals,
             "dropdown-mapping": this.data[key].DropdownValsMapping, "child": this.data[key].child,
             "parentingVals": this.data[key].parentingVals, "parent": this.data[key].parent});
        }
    }

    addNewValues(event){
        let value;
        if(event.target.type === "number"){
            value = parseFloat(event.target.value);
        }
        else{
            value = event.target.value;
        }
        this.currentEntry[event.target.name] = value;
    }

    addNewValuesFromSelect(event){
        //use for loop to iterate through data to verify if there are any parent/child relationships on this select and react if there are
        for(let i = 0; i < this.data.length; i++){
            if(this.data[i]['fieldName'] === event.source._id){
                if(this.data[i].parent != null && this.data[i].parent != undefined){
                    for(let j = 0; j < this.data.length; j++){
                        if(this.data[i].parent === this.data[j]['fieldName']){
                            for(let m = 0; m < this.data[i]['DropdownValsMapping'].length; m++){
                                if(event.value === this.data[i]['DropdownValsMapping'][m]){
                                    this.currentEntry[this.data[j]['fieldName']] = this.data[i]['parentingVals'][m];
                                    this.parentEnforced = true;
                                }
                            }
                        }
                    }
                }
                else{
                    if(this.data[i].child != null && this.data[i].child != undefined){
                        this.data = JSON.parse(JSON.stringify(this.originalFormFieldsData));
                        for(let j = 0; j < this.data.length; j++){
                            if(this.data[i].child === this.data[j]['fieldName']){
                                for(let m = 0; m < this.data[j]['DropdownValsMapping'].length; m++){
                                    if(event.value !== this.data[j]['parentingVals'][m]){
                                        //eliminate mat-option
                                        this.data[j].DropdownValsMapping.slice(m, 1);
                                        this.data[j].inputVals.splice(m, 1);
                                        this.data[j].parentingVals.splice(m, 1);
                                        m--;
                                        this.data[j]['DropdownValsMapping'].length = this.data[j]['DropdownValsMapping'].length - 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        this.currentEntry[event.source._id] = event.value;
        this.keys = [];
        for(let key in this.data){
            this.keys.push({"name": this.data[key].inputText, "input-type": this.data[key].inputType, "hint-text":  this.data[key].hintText,
             "field-name": this.data[key].fieldName, "inputVals": this.data[key].inputVals,
             "dropdown-mapping": this.data[key].DropdownValsMapping, "child": this.data[key].child,
             "parentingVals": this.data[key].parentingVals, "parent": this.data[key].parent});
        }
        this.parentEnforced = true;
    }

    closeDialog(save){
        if(!save){
            this.dialogRef.close()
        }
        else{
            //checking to ensure required fields have values
            let checked = true;
            for(let i = 0; i < this.keys.length; i++){
                if(this.keys[i]["hint-text"] === "*Required" && this.currentEntry[this.keys[i]["field-name"]] == null){
                    this.keys[i]["requiredError"] = true;
                    checked = false;
                }
                else{
                    this.keys[i]["requiredError"] = false;
                }
            }
            if(checked){
                this.dialogRef.close(this.currentEntry);
            }
        }
    }

  }
