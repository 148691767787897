import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatIconRegistry } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DomSanitizer } from '@angular/platform-browser';
import { IColumnData } from 'app/models/dialog-interface';
import { Report } from 'app/models/Report';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { ReportService } from 'app/services/reporting-report.service';
import { TableService } from 'app/services/target-table.service';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { Subject } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar';
import { ImportReportsDialogComponent } from './import-reports-dialog/import-reports-dialog.component';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'dp-filebased-source-report',
    templateUrl: './report.component.html',
    providers: [ReportService, TableService, SnackBarHandler],
    'styleUrls': ['./report.component.scss']
})

export class FileBasedSourceReport implements OnInit {

    @ViewChild(DataTableDirective)
    powerUser:boolean = true;
    id: string;
    serviceData:any;
    ref: boolean;
    enableImport:boolean = false;
    tableDetails: ITableDetails;
    private destroy = new Subject<void>();
    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: "Id",
        sortable: true,
        cell: (element: any) => `${element.Id}`
      },
      {
        columnDef: "ReportName",
        header: "Report Name",
        sortable: true,
        cell: (element: any) => `${element.ReportName}`
      },
      {
        columnDef: "ReportCategory",
        header: "Report Category",
        sortable: true,
        cell: (element: any) => `${element.ReportCategory}`
      },
      {
        columnDef: "ReportType",
        header: "Report Type",
        sortable: true,
        cell: (element: any) => `${element.ReportType}`
      },

    ];

    constructor(
      private route: ActivatedRoute, 
      private reportService: ReportService,
      private snackBarHandler: SnackBarHandler,
      private router: Router,
      private authService: AuthService,
      private dialog: MatDialog,
      private routeBreadcrumbService: RouteBreadcrumbService,
      private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.id = this.route.snapshot.paramMap.get('id');

        this.tableDetails = { routing: { routingPath: [`/filesource/reports/${this.id}/calculations/`] }, edit: false };
        this.serviceData = this.authService.getFakeSubscribe([]);
        this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            var details = { routing: { routingPath: [`/filesource/reports/${this.id}/calculations/`] }, edit: permissions.update }
            if(permissions.add){
              this.enableImport = true;
            }
            if(permissions.delete)
              details['delete'] = "ReportName";

            this.tableDetails = details;
            this.serviceData = this.reportService.getReportBySourceId(this.id);
            this.ref = true;
          }
          else {
            this.router.navigate(['/']);
          }
        });

        this.ref = false;
        this.matIconRegistry.addSvgIcon('importReports', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/pictures/Import_Tables.svg'));
      }

    ngOnInit() {
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Filebased Reports"], ["filesource/"]);
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
      this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
    }

    private openImportReportsDialog() {
      this.dialog.open(ImportReportsDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: { SourceId: this.id },
      })
          .afterClosed().subscribe(result => {
              if (result) {
                this.snackBarHandler.open('Starting Report Import', 'success');

                this.reportService.putReportsDataFromSource(result).subscribe(savedReports => {
                    this.snackBarHandler.open(`Success: Reports Imported'`, 'success');
                    this.ref = true;
                }, err => this.snackBarHandler.open(`Failure: Reports not Imported`, 'failure'));
              }
          });
    }   

    private editModal(row: Report){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";

      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: -1,
          validators: []
        },
        {
          id: "ReportName",
          label: "Report Name",
          value: row.ReportName,
          validators: ["required"]
        },
        {
          id: "ReportCategory",
          label: "Report Category",
          value: row.ReportCategory,
          validators: ["required"]
        }
      ];
      let data = {
        description: "Edit " + row.ReportName,
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(EditComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            row.ReportName = result.ReportName;
            row.ReportCategory = result.ReportCategory;
            this.reportService.saveReport(row).subscribe(savedReport => {
              if (savedReport) {
                this.snackBarHandler.open(`Success: Edited '${result.ReportName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Report not updated`, 'failure'));
        }
      });
    }

    private deleteRows(rows: Report[]){
      for (let row of rows) {
        this.reportService.deleteReport(row).subscribe(result => {
          this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
          this.ref = true;
        }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
      }
    }

    resetRefresh(value: boolean) {
      this.ref = value;
    }
}