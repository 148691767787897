import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'targetColumn' })
export class TargetColumnPipe implements PipeTransform {

    /**
     * Filters the list of target columns based on the text that was entered in the filter field
     * @param targetColumns list of all target columnes
     * @param searchText text to filter on
     * @author Nash Lindley
     */
    transform(targetColumns: any, searchText: any): any {
        if (searchText == null) {
            return targetColumns;
        }

        return targetColumns.filter(function (targetColumn) {
            return targetColumn.DatabaseName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        })
    }
}
