import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,

  NativeDateModule
} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { AddUserModalComponent } from 'app/admin/users/add-user-modal/add-user-modal.component';
import { CalculationEditorComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/calculation-editor/calculation-editor.component';
import { ColumnLineageEditorDialogComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/column-lineage-editor-dialog.component';
import { ColumnLineagePropertiesComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/column-lineage-properties/column-lineage-properties.component';
import { FilterEditorComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/filter-editor/filter-editor.component';
import { JoinAliasSelectorComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/join-alias-selector/join-alias-selector.component';
import { SummarySelectorComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/summary-selector/summary-selector.component';
import { TableLineageListComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/table-lineage-list/table-lineage-list.component';
import { TransformationEditorComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/transformation-editor/transformation-editor.component';
import { LineageTreeModalComponent } from 'app/datalineage/lineage-tree-modal/lineage-tree-modal.component';
import { FooterComponent } from 'app/footer/footer.component';
import { ColumnLineageRowComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-row.component';
import { TableMappingModalComponent } from 'app/target/target-table/lineage/table-mapping/table-mapping-modal/table-mapping-modal.component';
import { TableMappingComponent } from 'app/target/target-table/lineage/table-mapping/table-mapping.component';
import { NotesComponent } from 'app/business-glossary/term/notes/notes.component';
import { ExcludeTransformationTypePipe } from 'app/pipes/exclude-transformation-type.pipe';
import { SchemaComponent } from 'app/target/schemas/schemas.component';
import { PriorityService } from 'app/services/priority.service';
import { TokenService } from 'app/services/token.service';
import { UserService } from 'app/services/user.service';
import { SourceTableJoinConditionComponent } from 'app/target/target-table/sql-configurator/source-table-join/source-table-join-condition/source-table-join-condition.component';
import { SourceTableJoinComponent } from 'app/target/target-table/sql-configurator/source-table-join/source-table-join.component';
import { SqlConfiguratorDialogComponent } from 'app/target/target-table/sql-configurator/sql-configurator-dialog.component';
import { SqlViewDialogComponent } from 'app/target/target-table/sql-view-dialog/sql-view-dialog.component';
import { EditTargetColumnDialogComponent } from 'app/target/target-table/target-column/edit-target-column-dialog/edit-target-column-dialog.component';
import { TargetTableColumnComponent } from 'app/target/target-table/target-column/target-table-column.component';
import { TermLinkModalComponent } from 'app/target/target-table/target-column/term-link-modal/term-link-modal.component';
import { EditSchemaDialogComponent } from 'app/target/target-schema/edit-schema-dialog/edit-schema-dialog.component';
import { ImportTablesDialogComponent } from 'app/target/target-schema/import-tables-dialog/import-tables-dialog.component';
import { ImportReportsDialogComponent } from 'app/filebased/filebased-report/import-reports-dialog/import-reports-dialog.component';
import { ImportCalculationsDialogComponent } from 'app/filebased/filebased-calculation-group/import-calculations-dialog/import-calculations-dialog.component';
import { TargetSchemaComponent } from 'app/target/target-schema/target-schema.component';
import { EditTableDialogComponent } from 'app/target/target-table/edit-table-dialog/edit-table-dialog.component';
import { TargetTableComponent } from 'app/target/target-table/target-table.component';
import 'hammerjs';
import { CookieModule, CookieService } from 'ngx-cookie';
import { NgxPaginationModule } from 'ngx-pagination';
import { SnackBarHandler } from 'utilities/snackbar';
import { UserUtility } from 'utilities/user-utility';
import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from '../app/left-menu/left-menu.component';
import { AdminDashboardComponent } from './admin/admindashboard.component';
import { GeneratedJobsComponent } from './admin/generatedjobs/generatedjobs.component';
import { UsersComponent } from './admin/users/users.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BusinessGlossaryNewModule } from './business-glossary/business-glossary.module';
import { RelatedTermModalComponent } from './business-glossary/term/related-term-modal/related-term-modal.component';
import { TargetLinkModalComponent } from './business-glossary/term/target-column-link-modal/target-column-link-modal.component';
import { TermDetailsComponent } from './business-glossary/term/term-details/term-details.component';
import { TermModalComponent } from './business-glossary/term/term-modal/term-modal.component';
import { TermComponent } from './business-glossary/term/term.component';
import { BusinessGlossaryWorkflowComponent } from './business-glossary-workflow/business-glossary-workflow.component';
import { TermGroupComponent } from './business-glossary-workflow/term-group/term-group.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CollapsibleTreeComponent } from './datalineage/collapsible-tree/collapsible-tree.component';
import { DatalineageComponent } from './datalineage/datalineage.component';
import { DeleteModalComponent } from './delete/delete-modal.component';
import { ErrorInformationModalComponent } from './job-dashboard/error-information-modal/error-information-modal.component';
import { JobDashboardComponent } from './job-dashboard/job-dashboard.component';
import { JobEditorModalComponent } from './jobhistory/job-editor-modal/job-editor-modal.component';
import { JobHistoryComponent } from './jobhistory/jobhistory.component';
import { NavExplorerNewComponent } from './nav-explorer-new/nav-explorer-new.component';
import { NoteModalComponent } from './business-glossary/term/notes/note-modal/note-modal.component';
import { ExcludeObjectPipe } from './pipes/exclude-object.pipe';
import { TargetColumnPipe } from './pipes/target-column-pipe';
import { TermStatusPipe } from './pipes/term-status.pipe';
import { TermTypePipe } from './pipes/term-type.pipe';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { RoleService } from './services/role.service';
import { SidenavService } from './services/sidenav.service';
import { SourceSystemService } from './services/source-system.service';
import { SourceTableService } from './services/source-table.service';
import { SharedRoutingModule } from './shared/shared-routing.module';
import { SharedModule } from "./shared/shared.module";
import { SourceSystemNewModule } from "./source/source-system.module";
import { TeamCreateComponent } from './business-glossary/team/team-create/team-create.component';
import { TeamComponent } from './business-glossary/team/team.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { FileBasedSource } from './filebased/filebased-source/source.component';
import { FileBasedSourceReport } from './filebased/filebased-report/report.component';
import { FileBasedSourceCalculationGroup } from './filebased/filebased-calculation-group/calculation-group.component';
import { FileBasedSourceCalculation } from './filebased/filebased-calculations/calculation.component';
import { ImportSourceTablesDialogComponent } from 'app/source/source-tables/source-tables-import/import-source-tables-dialog.component';

@NgModule({
  declarations: [
    // APP
    AppComponent,

    // HEADER/FOOTER
    HeaderComponent,
    FooterComponent,
    LeftMenuComponent,

    // DASHBOARD
    DashboardComponent,

    // GLOSSARY
    TermComponent,
    TermModalComponent,
    RelatedTermModalComponent,
    TargetLinkModalComponent,
    TermDetailsComponent,
    NoteModalComponent,

    // GLOSSARY PUBLISHER
    BusinessGlossaryWorkflowComponent,

    // ADMIN
    AdminDashboardComponent,
    UsersComponent,
    AddUserModalComponent,
    GeneratedJobsComponent,

    // JOB HISTORY
    JobHistoryComponent,
    JobEditorModalComponent,

    // TARGET
    SchemaComponent,
    TargetSchemaComponent,
    EditSchemaDialogComponent,
    EditTableDialogComponent,
    ImportTablesDialogComponent,
    EditTargetColumnDialogComponent,

    // FILEBASED
    FileBasedSource,
    FileBasedSourceReport,
    FileBasedSourceCalculationGroup,
    ImportReportsDialogComponent,
    ImportCalculationsDialogComponent,
    FileBasedSourceCalculation,

    // LINEAGE EDITOR
    ColumnLineageEditorDialogComponent,
    TermLinkModalComponent,
    TargetTableComponent,
    TargetTableColumnComponent,
    ColumnLineageRowComponent,
    SqlViewDialogComponent,
    TableMappingComponent,
    TableMappingModalComponent,

    // SQL CONFIGURATOR
    SqlConfiguratorDialogComponent,
    SourceTableJoinComponent,
    SourceTableJoinConditionComponent,

    // COLUMN LINEAGE EDITOR
    JoinAliasSelectorComponent,
    FilterEditorComponent,
    TransformationEditorComponent,
    CalculationEditorComponent,
    SummarySelectorComponent,
    ColumnLineagePropertiesComponent,
    TableLineageListComponent,

    // DATA LINEAGE
    DatalineageComponent,
    CollapsibleTreeComponent,
    LineageTreeModalComponent,

    // PIPES
    TargetColumnPipe,
    ExcludeObjectPipe,
    ExcludeTransformationTypePipe,
    TermTypePipe,
    TermStatusPipe,

    // DELETE
    DeleteModalComponent,

    // JOB DASHBOARD
    JobDashboardComponent,
    ErrorInformationModalComponent,
    TermGroupComponent,
    TermDetailsComponent,
    NotesComponent,
    NoteModalComponent,
    TeamComponent,
    TeamCreateComponent,

    // NAV EXPLORER
    NavExplorerNewComponent,

    GlobalSearchComponent,

    ImportSourceTablesDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    DragDropModule,
    HttpClientModule,
    NgxPaginationModule,
    //#region Angular Material Modules - required to be imported individually as of release 2.0.0-beta.12
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatIconModule,
    MatOptionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatSidenavModule,
    NativeDateModule,
    MatTabsModule,
    MatTooltipModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatRippleModule,
    MatFormFieldModule,
    MatCardModule,
    MatToolbarModule,
    MatListModule,
    SharedModule,
    SharedRoutingModule,
    SourceSystemNewModule,
    BusinessGlossaryNewModule,
    MatProgressSpinnerModule,
    //#endregion
    CookieModule.forRoot(),
  ],
  entryComponents: [
    JobEditorModalComponent,
    SqlViewDialogComponent,
    EditSchemaDialogComponent,
    EditTableDialogComponent,
    ColumnLineageEditorDialogComponent,
    ImportTablesDialogComponent,
    ImportSourceTablesDialogComponent,
    ImportReportsDialogComponent,
    ImportCalculationsDialogComponent,
    SqlConfiguratorDialogComponent,
    EditTargetColumnDialogComponent,
    RelatedTermModalComponent,
    TermModalComponent,
    TargetLinkModalComponent,
    TermLinkModalComponent,
    AddUserModalComponent,
    ErrorInformationModalComponent,
    TermDetailsComponent,
    NoteModalComponent,
    TeamCreateComponent
  ],
  providers: [
    SourceSystemService,
    SourceTableService,
    CookieService,
    TokenService,
    SnackBarHandler,
    UserUtility,
    UserService,
    RoleService,
    SidenavService,
    PriorityService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
