/**
 * Add/Edit Term Link Modal
 * @author Collin Atkins / 10.2-3.17 / Modified to get bg items on show rather than init; also cleaned up code, ui, and sped up considerably
 */
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { SnackBarHandler } from '../../../../../utilities/snackbar';
import { GlossaryService } from 'app/services/glossary.service';
import { CategoryService } from 'app/services/category.service';
import { TermStatusService } from 'app/services/term-status.service';
import { TermService } from 'app/services/term.service';
import { TargetColumn } from 'app/models/target-column';
import { TermLink } from 'app/models/term-link';
import { Glossary } from 'app/models/glossary';
import { Category } from 'app/models/category';
import { TermStatus } from 'app/models/term-status';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dp-term-link-modal',
    templateUrl: './term-link-modal.component.html',
    styleUrls: ['./term-link-modal.component.scss'],
    providers: [SnackBarHandler, GlossaryService, CategoryService, TermStatusService, TermService]
})

export class TermLinkModalComponent implements OnInit {

    private targetColumn: TargetColumn = new TargetColumn();
    private target: TermLink = new TermLink();
    private glossaries: Glossary[] = new Array();
    private categories: Category[] = new Array();
    private termStatuses: TermStatus[] = new Array();

    private DefaultGlossary: Glossary = new Glossary();
    private DefaultCategory: Category = new Category();

    constructor(private glossaryService: GlossaryService, private categoryService: CategoryService, private termStatusService: TermStatusService,
        private termService: TermService, private snackBarHandler: SnackBarHandler, private dialogRef: MatDialogRef<TermLinkModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data) { }

    ngOnInit() {
        if (this.data) {
            this.targetColumn = this.data.TargetColumn;

            this.clearLink();
            if (this.targetColumn.hasLink) {
                this.loadLink();
            }
        } else {
            this.dialogRef.close();
            this.snackBarHandler.open('Invalid parameters', 'danger');
        }
    }

    /**
     * Clears local variables for empty modal
     * @author John Crabill
     * @author Collin Atkins / 10.2.17 / Added if statements to not reload same glossaries/terms
     */
    private clearLink() {
        this.target = new TermLink();
        this.categories = new Array();

        if (this.glossaries.length <= 0) {
            this.getGlossaries();
        }

        if (this.termStatuses.length <= 0) {
            this.getTermStatus();
        }
    }

    /**
     * @author Collin Atkins / 10.3.17 / Fills in term category with chosen category
     */
    private fillInTerm() {
        this.target.term.targetColumn = this.targetColumn.Id;
        this.target.term.Category = this.target.category;
        this.target.term.CategoryId = this.target.category.Id;
    }

    /**
     * Retrieves glossaries
     * @author John Crabill
     */
    private getGlossaries() {
        this.glossaryService.getGlossaries()
            .subscribe(glossaries => {
                this.glossaries = glossaries;
            }, err => console.log(err));
    }

    /**
     * Retrieves categories
     * @author John Crabill
     * @author Collin Atkins / 10.3.17 / Removed hasCategory bool
     */
    private getCategories(glossaryId) {
        if (glossaryId && glossaryId >= 0) {
            this.categoryService.getCategoriesByGlossaryId(glossaryId)
                .subscribe(categories => {
                    this.categories = categories;
                }, err => console.log(err));
        }
    }

    /**
     * Calls the termStatus Service to get the term statuses
     * @author John Crabill
     */
    private getTermStatus() {
        this.termStatuses = this.termStatusService.getTermStatus();
    }

    /**
     * Saves link
     * @author John Crabill
     * @author Collin Atkins / 10.2.17 / Removed field checks; added through html5 forms
     */
    private saveLink() {
        this.fillInTerm();
        this.termService.saveTerm(this.target.term)
            .subscribe(tables => {
                this.snackBarHandler.open(`Term Link was saved.`, 'success');
                this.dialogRef.close(tables);
            }, err => {
                console.log(err); 
                this.dialogRef.close(); 
            });
    }

    /**
     * @author John Crabill
     * @author Collin Atkins / 10.3.17 / Removed a lot of glut and extra calls
     */
    private loadLink() {
        this.termService.getTermByTargetColumn(this.targetColumn.Id)
            .subscribe(term => {
                this.target.term = term[0];
                this.categoryService.getCategoriesByGlossaryId(this.target.term.Category.BusinessGlossaryId)
                    .subscribe(categories => {
                        this.categories = categories;
                        this.target.glossary = this.glossaries.find(g => g.Id === this.target.term.Category.BusinessGlossaryId 
                            || g.BusinessGlossaryId === this.target.term.Category.BusinessGlossaryId);
                        this.target.category = this.categories.find(c => c.Id === this.target.term.Category.Id);
                    }, err => console.log(err));
            }, err => console.log(err));
    }

    private resetGlossary() {
        this.target.category = new Category();
        this.categories = new Array();
        this.target.glossary = new Glossary();
    }

    private resetCategory() {
        this.target.category = new Category();
    }

}
