 import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatDialog, MatDialogConfig } from '@angular/material';
 import { DataTableDirective } from 'angular-datatables';
 import { IColumnData, IInputType } from 'app/models/dialog-interface';
 import { ReportingSource } from 'app/models/ReportingSource';
 import { IColumns, ITableDetails } from 'app/models/table-interfaces';
 import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
 import { ReportSourceService } from 'app/services/reporting-source.service';
 import { TableService } from 'app/services/target-table.service';
 import { AddComponent } from 'app/shared/components/add/add.component';
 import { EditComponent } from 'app/shared/components/edit/edit.component';
 import { Subject } from 'rxjs';
 import { SnackBarHandler } from 'utilities/snackbar';
 import { Router } from '@angular/router';
 import { AuthService } from 'app/services/auth.service';
 
 @Component({
     selector: 'dp-filebased-source',
     templateUrl: './source.component.html',
     providers: [ReportSourceService, TableService, SnackBarHandler],
     'styleUrls': ['./source.component.scss']
 })
 
 export class FileBasedSource implements OnInit {
 
     @ViewChild(DataTableDirective)
     powerUser:boolean = true;
     serviceData:any;
     ref: boolean;
     tableDetails: ITableDetails;
     private destroy = new Subject<void>();
     columns: IColumns[] = [
       {
         columnDef: "Id",
         header: "Id",
         sortable: true,
         cell: (element: any) => `${element.Id}`
       },
       {
         columnDef: "ReportingSourceName",
         header: "Source Name",
         sortable: true,
         cell: (element: any) => `${element.ReportingSourceName}`
       },
       {
         columnDef: "ReportingSourceType",
         header: "Source Type",
         sortable: true,
         cell: (element: any) => `${element.ReportingSourceType}`
       },
       {
         columnDef: "ReportingTenantID",
         header: "Tenant ID",
         sortable: true,
         cell: (element: any) => `${element.ReportingTenantID}`
       },
       {
         columnDef: "ReportingClientId",
         header: "Client ID",
         sortable: true,
         cell: (element: any) => `${element.ReportingClientId}`
       }
     ]; 
 
     constructor(
       private reportSourceService: ReportSourceService,
       private snackBarHandler: SnackBarHandler,
       private router: Router,
       private authService: AuthService,
       private dialog: MatDialog,
       private routeBreadcrumbService: RouteBreadcrumbService) {
         this.tableDetails = { routing: { routingPath: ["/filesource/reports/"] }, edit: false};
         this.serviceData = this.authService.getFakeSubscribe([]);
         this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            var details = { routing: { routingPath: ["/filesource/reports/"] }, edit: permissions.update }
            if(permissions.add){
              details['add'] = "Source";
            }
            if(permissions.delete)
              details['delete'] = "ReportingSourceName";
  
            this.tableDetails = details;
            this.serviceData = this.reportSourceService.getSources();
            this.ref = true;
          }
          else {
            this.router.navigate(['/']);
          }
         });
         this.ref = false;
       }
 
     ngOnInit() {
         this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Filebased Sources"], ["filesource/"]);
     }
 
     ngOnDestroy(){
       this.destroy.next;
       this.destroy.complete;
       this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
     }
 
     /**
      * This function handles the creation of the add dialog as well as
      * handling the addition of sources to the database
      */
     private addModal() {
       const dialogConfig = new MatDialogConfig();
       dialogConfig.disableClose = true;
       dialogConfig.autoFocus = true;
       dialogConfig.panelClass = "custom-dialog-container";
       /**
        * The IInputTypes allow the dialog to have different types of input
        */
       let dbType: IInputType = {type: "dropdown", dropdownOptions: ["Power BI"] };
       let password: IInputType = {type: 'password'};
       let columnData: IColumnData[] = [
         {
           id: "Id",
           label: -1,
           value: -1,
           validators: []
         },
         {
           id: "ReportingSourceName",
           label: "Source Name",
           value: "",
           validators: ["required"]
         },
         {
           id: "ReportingSourceType",
           label: "Source Type",
           value: "",
           validators: ["required"],
           inputType: dbType
         },
         {
           id: "ReportingApplicationId",
           label: "Azure Application ID",
           value: "",
           validators: ["required"]
         },
         {
           id: "ReportingApplicationSecret",
           label: "Azure Application Secret",
           value: "",
           validators: ["password", "required"],
           inputType: password
         },
         {
           id: "ReportingTenantID",
           label: "Azure Application Tenant ID",
           value: "",
           validators: ["required"]
         },
         {
           id: "ReportingClientId",
           label: "Azure Application Client ID",
           value: "",
           validators: ["required"]
         }
       ];
       let data = {
         description: "Add Source",
         numCols: 2,
         columnData: columnData
       };
       dialogConfig.data = data;
 
       const dialogRef = this.dialog.open(AddComponent, dialogConfig);
 
       dialogRef.afterClosed().subscribe(result => {
         if (result) {
            if(result.ReportingSourceType == 'Power BI'){
              result.ReportingAuthorityURL = 'https://login.microsoftonline.com/common/';
              result.ReportingBaseRestURL = 'https://api.powerbi.com/';
              result.ReportingResponseType = 'code';
              result.ReportingSourceURL = 'https://analysis.windows.net/powerbi/api';
            }
            this.reportSourceService.saveSource(result).subscribe(savedSource => {
              if (savedSource) {
                this.snackBarHandler.open(`Success: Added '${result.ReportingSourceName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Source not added`, 'failure'));
         }
       });
     }
 
     private editModal({Id, ReportingSourceName, ReportingSourceType, ReportingApplicationId, ReportingApplicationSecret, ReportingTenantID, ReportingClientId}){
       const dialogConfig = new MatDialogConfig();
       dialogConfig.disableClose = true;
       dialogConfig.autoFocus = true;
       dialogConfig.panelClass = "custom-dialog-container";
       /**
        * The IInputTypes allow the dialog to have different types of input
        */
       let dbType: IInputType = {type: "dropdown", dropdownOptions: ["Power BI"] };
       let password: IInputType = {type: 'password'};
       let readOnly: IInputType = {type: 'display'}
       let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: Id,
          validators: []
        },
        {
          id: "ReportingSourceName",
          label: "Source Name",
          value: ReportingSourceName,
          validators: ["required"]
        },
        {
          id: "ReportingSourceType",
          label: "Source Type",
          value: ReportingSourceType,
          validators: ["required"],
          inputType: dbType
        },
        {
          id: "ReportingApplicationId",
          label: "Azure Application ID",
          value: ReportingApplicationId,
          validators: ["required"]
        },
        {
          id: "ReportingApplicationSecret",
          label: "Azure Application Secret",
          value: "",
          validators: ["passwordOrBlank"],
          inputType: password
        },
        {
          id: "ReportingTenantID",
          label: "Azure Application Tenant ID",
          value: ReportingTenantID,
          validators: ["required"]
        },
        {
          id: "ReportingClientId",
          label: "Azure Application Client ID",
          value: ReportingClientId,
          validators: ["required"]
        }
       ];
       let data = {
         description: "Edit " + ReportingSourceName,
         numCols: 2,
         columnData: columnData
       };
       dialogConfig.data = data;
 
       const dialogRef = this.dialog.open(EditComponent, dialogConfig);
 
       dialogRef.afterClosed().subscribe(result => {
         if (result) {
            result.ReportingApplicationSecret = result.ReportingApplicationSecret != "" ? result.ReportingApplicationSecret : ReportingApplicationSecret;
            if(result.ReportingSourceType == 'Power BI'){
              result.ReportingAuthorityURL = 'https://login.microsoftonline.com/common/';
              result.ReportingBaseRestURL = 'https://api.powerbi.com/';
              result.ReportingResponseType = 'code';
              result.ReportingSourceURL = 'https://analysis.windows.net/powerbi/api';
            }
            this.reportSourceService.saveSource(result).subscribe(savedSource => {
              if (savedSource) {
                this.snackBarHandler.open(`Success: Edited '${result.ReportingSourceName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Source not edited`, 'failure'));
         }
       });
     }
 
     private deleteRows(rows: ReportingSource[]){
       for (let row of rows) {
         this.reportSourceService.deleteSource(row).subscribe(result => {
           this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
           this.ref = true;
         }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
       }
     }
 
     resetRefresh(value: boolean) {
       this.ref = value;
     }
 }
 