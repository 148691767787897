import { Component, OnInit,Inject } from '@angular/core';
import { Note } from 'app/models/note';
import { Term } from 'app/models/term';
import { NotesService}  from 'app/services/notes.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';


@Component({
  selector: 'dp-note-modal',
  templateUrl: './note-modal.component.html',
  providers: [NotesService],
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit {

private Note: Note = new Note();
private Term: Term = new Term();


  constructor(@Inject(MAT_DIALOG_DATA) private data, 
      private noteService: NotesService,
      private snackBarHandler: SnackBarHandler,
      private dialogRef: MatDialogRef<NoteModalComponent>,) { 

  }

  ngOnInit() {

    this.Term = this.data.Term;

  }

  private saveNote() {
    this.Note.ParentId = this.Term.Id;
    this.noteService.saveNote(this.Note)
        .subscribe(note => {
            this.snackBarHandler.open('Note saved.', 'success');
            this.dialogRef.close(note);
        }, err => {
            //this.dialogRef.close();
            console.log(err);
            this.snackBarHandler.open('Notes failed to save.' + err, 'failure');
        });
}

}
