
import {finalize} from 'rxjs/operators';
/**
 * Job History Editor Modal
 * @author Collin Atkins / 9.27.17 / Added date filtering, datatables, cleaned up code, and improved speed
 * @author Collin Atkins / 9.28.17 / Removed date filtering, datatables. Fixed many param bugs, and drastically improved speed.
 * @author Collin Atkins / 10.12.17 / Added initialization, load functions, removed params from show to fix bugs.
 * @author John Crabill / 10.18.17 / Fixes render issues
 * @author Collin Atkins / 10.25.17 / Changes to load/show to always call getJobByTableName
 */
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { SnackBarHandler } from 'utilities/snackbar';
import { JobParamService } from 'app/services/jobparam.service';
import { JobHistoryService } from 'app/services/jobhistory.service';
import { UpsertService } from 'app/services/upsert.service';
import { HistoryService } from 'app/services/history.service';
import { JobHistory } from 'app/models/jobhistory';
import { Upsert } from 'app/models/upsert';
import { JobParam } from 'app/models/jobparam';
import { TargetTable } from 'app/models/target-table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IDialogData } from "app/models/dialog-interface";
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { Observable, Subject } from 'rxjs';

const PARAM_NULL: string = 'GLOBAL_NULL_REPLACEMENT';
const PARAM_CONCAT: string = 'GLOBAL_CONCAT_CHAR';

@Component({
    selector: 'dp-job-editor-modal',
    templateUrl: './job-editor-modal.component.html',
    providers: [JobHistoryService, JobParamService, SnackBarHandler, UpsertService, HistoryService],
    styleUrls: ['./job-editor-modal.component.scss']
})

export class JobEditorModalComponent implements OnInit {

    serviceData: any;
    tableDetails: ITableDetails;
    ref: boolean;

    paramServiceData: any;
    paramTableDetails: ITableDetails;
    paramRef: boolean;
    location: number = 1;

    @Output() jobCreated = new EventEmitter();

    private Job: JobHistory = new JobHistory();
    private Upserts: Upsert[] = new Array<Upsert>();
    private AddJobParams: JobParam[] = new Array<JobParam>();
    private newJob = false;

    constructor(private jobService: JobHistoryService, private jobParamService: JobParamService, private historyService: HistoryService,
        public dialogRef: MatDialogRef<any>, private snackbar: SnackBarHandler, private upsertService: UpsertService, @Inject(MAT_DIALOG_DATA) private data) {
            this.tableDetails = {routing: null, edit: false, noView: true, noFilter: true};
            this.paramTableDetails = {routing: null, edit: false, noView: true, noPaginator: true, noFilter: true, toggleButton: "Parameter" };
            this.serviceData = this.getFakeSubscribe([]);
            this.paramServiceData = this.getFakeSubscribe([]);
        }

    columns: IColumns[] = [
        {
        columnDef: "Id",
        header: "ID",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.Id}`
        },
        {
        columnDef: "RunDateDateTime",
        header: "Run Date",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.RunDateDateTime.toString().split('T')[0]}`
        },
        {
        columnDef: "Status",
        header: "Status",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.Status != null ? element.Status : ''}`
        },
        {
        columnDef: "RecordCount",
        header: "Record Count",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.RecordCount}`
        },
        {
        columnDef: "ErrorCount",
        header: "Error Count",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.ErrorCount}`
        },
        {
        columnDef: "ExtractCount",
        header: "Extract Count",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.ExtractCount}`
        },
        {
        columnDef: "InsertRowCount",
        header: "Rows Inserted",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.InsertRowCount}`
        },
        {
        columnDef: "UpdateRowCount",
        header: "Rows Updated",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.UpdateRowCount}`
        },
        {
        columnDef: "ExecStartTime",
        header: "Exec Start Time",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.ExecStartTime != null ? element.ExecStartTime.toString().split('T')[0] : ''}`
        },
        {
        columnDef: "ExecStopTime",
        header: "Exec Stop Time",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.ExecStopTime != null ? element.ExecStopTime.toString().split('T')[0] : ''}`
        },
        {
        columnDef: "RunTime",
        header: "RunTime",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.RunTime != null ? element.RunTime : ''}`
        }
    ];

    paramColumns: IColumns[] = [
        {
        columnDef: "JobName",
        header: "Name",
        sortable: true,
        cell: (element: any) => `${element.JobName}`
        },
        {
        columnDef: "paramName",
        header: "Type",
        sortable: true,
        cell: (element: any) => `${element.paramName}`
        }
    ];

    ngOnInit() { 
        var job = this.data.job;
        this.show(job);
    }

    tileChange(tile: string){
        var history = document.getElementById("History");
        var paramters = document.getElementById("Parameters");
        var editor = document.getElementById("Editor");
        var paramters2 = document.getElementById("Parameters2");
        var editor2 = document.getElementById("Editor2");

        history.classList.remove("current");
        paramters.classList.remove("current");
        editor.classList.remove("current");
        paramters2.classList.remove("current");
        editor2.classList.remove("current");

        switch(tile){
            case "History":
                this.location = 0;
                history.classList.add("current");
                break;
            case "Parameters":
                this.location = 1;
                paramters.classList.add("current");
                paramters2.classList.add("current");
                break;
            case "Editor":
                this.location = 2;
                editor.classList.add("current");
                editor2.classList.add("current");
                break;
        }

    }

    resetJobsHistoryRefresh(value: boolean) {
        this.ref = value;
    }

    resetJobsParamRefresh(value: boolean) {
        this.paramRef = value;
    }

    getFakeSubscribe(array): Observable<any> {
        return Observable.of(array);
    }

    /**
     * From Default table, either adds or deletes job based on Parameter
     * @param param 
     */
    private editJob(param){
        if(param.Parameter == null){
            this.addJob(param);
        }
        else {
            this.deleteJob(param);
        }
    }

    /**
     * Calls the Job Param Service to add the selected paramname
     * @param param 
     * @author Collin Atkins / 9.28.17 / Added get call after creation
     */
    private addJob(param: JobParam) {
        var num = param.paramName == PARAM_NULL ? 1 : 2;
        this.jobParamService.createJobParams(param.JobName, param.paramName, num, this.Job.ETId)
            .subscribe(() => {
                this.getJobParams();
                this.snackbar.open(param.paramName + ' was successfully added.', 'success');
            }, err => {
                console.log(err);
                this.snackbar.open('Failed to add job param.', 'failure');
            });
    }

    /**
     * Deletes the selected jobParam
     * @param param 
     * @author Collin Atkins / 9.28.17 / Added get call after deletion
     */
    private deleteJob(param: JobParam) {
        this.jobParamService.deleteJobParam(param)
            .subscribe(() => {
                this.getJobParams();
                this.snackbar.open(param.paramName + ' was successfully deleted.', 'success');
            }, err => {
                console.log(err);
                this.snackbar.open('Failed to delete job param.', 'failure');
            });
    }

    /**
     * Fills job parameters with default params
     * @param params 
     * @author John Crabill / 10.18.17 / Fixes rerender and deletions
     * @author John Crabill / 1.08.18 / Adds the Job.JobParams to fix rendering issue
     */
    private fillJobParams(params: JobParam[] = Array<JobParam>()) {
        this.AddJobParams = new Array<JobParam>();

        if(this.Job.JobParams){
            for(var i = 0; i < this.Job.JobParams.length; i++){
                this.AddJobParams.push(this.Job.JobParams[i]);
            }
        }

        if (!params || params.findIndex(p => p.paramName == PARAM_NULL) == -1) {
            let globalReplace: JobParam = new JobParam();
            globalReplace.JobName = this.Job.TargetTable;
            globalReplace.paramName = PARAM_NULL;
            this.AddJobParams.push(globalReplace);
        }
        if (!params || params.findIndex(p => p.paramName == PARAM_CONCAT) == -1) {
            let globalConcat: JobParam = new JobParam();
            globalConcat.JobName = this.Job.TargetTable;
            globalConcat.paramName = PARAM_CONCAT;
            this.AddJobParams.push(globalConcat);
        }

        this.paramServiceData = this.getFakeSubscribe(this.AddJobParams);
        this.resetJobsParamRefresh(true);
    }

    /**
     * Gets the Target Tables job using jobService
     * @author Collin Atkins / 9.28.17 /
     */
    private getJobByTableName(tableName: string) {
        this.jobService.getJobHistoryByTableName(tableName).pipe(
            finalize(() => {
                this.fillJobParams(this.Job.JobParams);
            }))
            .subscribe(job => {
                this.Job = job;
            }, err => {
                this.newJob = true;
                this.Job = new JobHistory(tableName);
            });
    }

    /**
     * Gets the Target Tables job using jobService
     * @author John Crabill / 1.08.18 /
     */
    private getJobByTableId(tableName: string, tableId: number) {
        this.jobService.getJobHistoryByTableId(tableId + "", tableName).pipe(
            finalize(() => {
                this.fillJobParams(this.Job.JobParams);
            }))
            .subscribe(job => {
                if(job == null){
                    this.newJob = true;
                    this.Job = new JobHistory(tableName, tableId);
                }
                else {
                    this.newJob = false;
                    this.Job = job;
                    this.serviceData = this.getFakeSubscribe(this.Job.JobHistorys);
                    this.resetJobsHistoryRefresh(true);

                    if(this.Job.JobHistorys && this.Job.JobHistorys.length > 0){
                        this.tileChange("History");
                    }
                }
            }, err => {
                this.newJob = true;
                this.Job = new JobHistory(tableName, tableId);
            });
    }

    /**
     * @author John Crabill / 10.18.17 / Fixes rerender and deletions
     */
    private getJobParams() {
        this.jobParamService.getJobParams(this.Job.ETId).pipe(
            finalize(() => {
                this.fillJobParams(this.Job.JobParams);
            }))
            .subscribe(params => {
                this.Job.JobParams = params;
            }, err => {
                this.Job.JobParams = new Array<JobParam>();
            });
    }

    /**
     * Calls the upsert Service to get all the upsert Types
     * @author Collin Atkins
     */
    private getUpserts() {
        if (!this.Upserts || this.Upserts.length == 0) {
            this.upsertService.getUpserts()
                .subscribe(upserts => {
                    this.Upserts = upserts;
                }, err => console.log(err));
        }
    }

    /**
     * Saves the current job using jobService
     */
    private saveJob() {
        this.jobService.saveJob(this.Job)
            .subscribe(job => {
                this.newJob = false;
                this.Job = job;
                this.snackbar.open('Job was successfully saved.', 'success');
            }, err => {
                console.log(err);
                this.snackbar.open('Failed to save job.', 'failure');
            });
    }

    /**
     * Modal Variables
     */
    visible: boolean = false;
    private visibleAnimate: boolean = false;

    /**
     * Modal Functions
     * @author Collin Atkins / 9.28.17 / Moved initialization here
     * @author John Crabill / 1.08.18 /
     */
    show(targetTable) {
        this.getUpserts();
        if(targetTable.TargetTable != undefined){
            //In Job History 
            this.getJobByTableId(targetTable.TargetTable, targetTable.ETId);
        }
        else {
            //In Target Tables
            this.getJobByTableId(targetTable.DatabaseName, targetTable.Id);
        }

        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    hide() {
        this.dialogRef.close();
    }

    onContainerClicked(event: MouseEvent) {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }

}
