import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { SourceSystem } from 'app/models/source-system';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SourceSystemNewService {
    private apiPath = 'api/EDWSourceSystem';
    private apiConn = 'api/Connection';
    private requestUrl = environment.serviceUrl + this.apiPath;
    private connUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }
    /**
     * Gets all Source Systems Data
     * @author Julia Hoge / 6.23.20
     */
    getAllSourceSystemsData() {
        return this.http.get(this.requestUrl);
    }

}