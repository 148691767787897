import { SourceTableJoin } from 'app/models/source-table-join';
import { SourceTable } from 'app/models/source-table';

export class TableLineage {

    Id: number;
    TargetTableId: number
    SourceTableId: number;
    SourceTable: SourceTable;
    TableMappingId: number;
    isSourceTableMaster: boolean;
    SourceTableJoins: SourceTableJoin[];
    show: boolean;

    constructor(tableMappingId: number = -1, targetTableId: number = -1, sourceTableId: number = -1, sourceSystemId: number = -1) {
        this.Id = -1;
        this.TargetTableId = targetTableId;
        this.SourceTableId = sourceTableId;
        this.SourceTable = new SourceTable(sourceSystemId, sourceTableId);
        this.TableMappingId = tableMappingId;
        this.isSourceTableMaster = false;
        this.SourceTableJoins = new Array<SourceTableJoin>();
        this.show = false;
    }

}
