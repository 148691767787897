
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class UserService {
    private apiPath = 'api/EDWUser';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all Users
     */
    getUsers(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getUser(id): Observable<any> {
        return this.http.get(this.requestUrl + '/?Id=' + id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }



    /**
     * Saves a new User
     * @param User
     */
    saveUser(User): Observable<any> {
        return this.http.put(this.requestUrl, JSON.stringify(User), httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Deletes a User by Id
     * @param Id id of a user
     */
    deleteUser(Id): Observable<any> {
        return this.http.delete(this.requestUrl + '/?Id=' + Id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
