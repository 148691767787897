
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserUtility } from 'utilities/user-utility';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Term } from 'app/models/term';
import { AuthService } from './auth.service';
import { Auth0User } from 'app/models/Auth0User';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TermService {
    private apiPath = 'api/BGTerm';
    private apiRelationPath = 'api/BGRelatedTerms';
    private termRequestUrl = environment.serviceUrl + this.apiPath;
    private relationRequestUrl = environment.serviceUrl + this.apiRelationPath;

    constructor(private http: HttpClient, private authService: AuthService) { }

    deleteTerm(termId): Observable<any> {
        return this.http.delete(`${this.termRequestUrl}?id=${termId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    deleteRelationTerm(relationTermId): Observable<any> {
        return this.http.delete(`${this.relationRequestUrl}?id=${relationTermId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all Glossary Terms.
     */
    getAllTerms(): Observable<any>{
        return this.http.get<Term[]>(`${this.termRequestUrl}?paginated=false&page=0&rowsPerPage=25`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getTermByCategoryId(categoryId): Observable<any> {
        return this.http.get(`${this.termRequestUrl}?categoryId=` + categoryId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Glossary Terms based on the includeDrafting parameter
     * @param includeDrafting: boolean
     */
    getTerms(includeDrafting) {
        return this.http.get(`${this.termRequestUrl}?includeDrafting=${includeDrafting}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getTermsByStatus(status: number, useStatus: boolean): Observable<any>    {
        return this.http.get<Term[]>(`${this.termRequestUrl}?status=${status}&useStatus=${useStatus}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Glossary Terms based on the active parameter
     * @param active: boolean
     */
    getTermById(termId: number): Observable<any> {
        return this.http.get<Term>(`${this.termRequestUrl}?id=${termId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getTermByTargetColumn(targetColumnId: number) {
        return this.http.get(`${this.termRequestUrl}?target=${targetColumnId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getAllTargetColumnTerms() {
        return this.http.get(`${this.termRequestUrl}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Save the Term from modal
     * @param term
     */
    saveTerm(term): Observable<any> {
      this.authService.getUserJwt().subscribe(jwt =>{
        if(term.Id < 0){
          term.CreatedUser = new Auth0User();
          term.CreatedUser.userId = jwt["https://metaprism.com/user_id"];
          term.CreatedUserId = term.CreatedUser.userId;
        }
        term.UpdatedUser = new Auth0User();
        term.UpdatedUser.userId = jwt["https://metaprism.com/user_id"];
        term.UpdatedUser.name = jwt["name"];
        term.UpdatedUserId = term.UpdatedUser.userId;
      });
        return this.http.put(this.termRequestUrl, term, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    saveShortTerm(term): Observable<any> {
        return this.http.post(`${this.termRequestUrl}?shortTask=true`, term, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }
}
