/**
 * Dialog for Adding/Editing a Target Table
 * @author Collin Atkins / 12.15.17
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { Schema } from 'app/models/schema';
import { TableService } from 'app/services/target-table.service';
import { TargetTable } from 'app/models/target-table';

@Component({
    selector: 'edit-table-dialog',
    templateUrl: 'edit-table-dialog.component.html',
    styleUrls: ['edit-table-dialog.component.scss'],
    providers: [TableService]
})

export class EditTableDialogComponent implements OnInit {

    private ParentSchema: Schema = new Schema();
    private Table: TargetTable = new TargetTable();

    constructor(private dialogRef: MatDialogRef<EditTableDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private snackBarHandler: SnackBarHandler, private tableService: TableService) { }

    ngOnInit() {
        this.ParentSchema = this.data && this.data.Schema ? Object.assign({}, this.data.Schema) : new Schema();
        this.Table = this.data && this.data.Table ? Object.assign({}, this.data.Table) : new TargetTable();
        this.Table.SchemaName = this.ParentSchema.SchemaName;
        this.fillTablePrefix();
    }

    /**
     * @author Collin Atkins / 10.24.17
     */
    private fillTablePrefix() {
        if (!this.Table.TablePrefix && this.ParentSchema && this.ParentSchema.DBType) {
            if(this.ParentSchema.DBSchemaName == "")
                this.Table.TablePrefix = this.ParentSchema.DBSchemaName;
            else 
                this.Table.TablePrefix = this.ParentSchema.SchemaName + '.';
            if (this.ParentSchema.DBType.toLowerCase().trim() === 'sql server') {
                this.Table.TablePrefix += 'dbo.';
            }
        }
    }

    /**
     * Saves table using tableService saveTable
     * @author Collin Atkins / 10.11.17 / Moved filling of table prefix to own function and commented out routing
     */
    private saveTable() {
        this.tableService.saveTable(this.Table)
            .subscribe(table => {
                this.dialogRef.close(table);
                this.snackBarHandler.open(this.Table.DatabaseName + ' has been saved.', 'success');
            }, err => {
                console.log(err);
                this.dialogRef.close();
                this.snackBarHandler.open('Target Table failed to save.', 'failure');
            });
    }

}