/**
 * Join Alias Selector - Tab of column lineage editor modal
 * @author Collin Atkins / 10.17.17 / Bug fix to operatorType, removed show property, changed to icon toggle, fixed spacing, changed select to checkbox
 */
import { Component, Input, OnInit } from '@angular/core';
import { SourceTableJoin } from 'app/models/source-table-join';
import { SourceTableJoinService } from 'app/services/source-table-join.service';
import { ColumnLineage } from 'app/models/column-lineage';

@Component({
    selector: 'dp-join-alias-selector',
    templateUrl: './join-alias-selector.component.html',
    providers: [SourceTableJoinService],
    styleUrls: ['./join-alias-selector.component.scss']
})

export class JoinAliasSelectorComponent implements OnInit {

    @Input() Joins: SourceTableJoin[] = new Array<SourceTableJoin>();
    @Input() columnLineage: ColumnLineage = new ColumnLineage();
    private showAdditionalFields: boolean = false;

    constructor(private sourceTableJoinService: SourceTableJoinService) { }

    ngOnInit() { }

    /**
     * Checks if this join is the alias selected
     * @param join 
     * @author Collin Atkins / 10.17.17
     */
    private joinChecked(join): boolean {
        return this.columnLineage.SourceTableAlias == join.Id;
    }

    /**
     * Sets the columnLineage's sourceTableAlias to the selected join
     * @param join
     */
    private selectJoin(join) {
        this.columnLineage.SourceTableAlias = join.Id;
    }

}
