import { TransformationType } from "app/models/transformation-type";

export class Transformation {
    Type: TransformationType;
    Parent: Transformation;
    Children: Transformation[];

    constructor(type: TransformationType = null, parent: Transformation = null, children: Transformation[] = new Array<Transformation>()) {
        this.Type = type || new TransformationType();
        this.Parent = parent;
        this.Children = children;
    }
}