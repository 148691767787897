import { Column } from "../decorators/column";
import { autoserializeAs } from "cerializr";

export class TeamUserNew {

  constructor(id, name, role){
    this.Id = id;
    this.Name = name;
    this.TeamRoleName = role;
  }


  @autoserializeAs(Number)
  @Column({
    canSort: true,
  })
  Id: number;

  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  Name: string;

  @autoserializeAs(String)
  @Column({
    canSort: true,
  })
  TeamRoleName: string;

  
}
