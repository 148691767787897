import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { IColumnData, IDialogData } from "app/models/dialog-interface";
import { SourceColumn } from 'app/models/source-column';
import { SourceTable } from 'app/models/source-table';
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SourceColumnService } from 'app/services/source-column.service';
import { SourceSystemService } from 'app/services/source-system.service';
import { SourceTableService } from 'app/services/source-table.service';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarHandler } from 'utilities/snackbar';
import { SourceColumnsAddComponent } from './source-columns-add/source-columns-add.component';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'dp-source-columns-new',
  templateUrl: './source-columns.component.html',
  styleUrls: ['./source-columns.component.scss'],
  providers: [SourceColumnService, SnackBarHandler, SourceTableService]
})
export class SourceColumnsComponent implements OnInit {

  serviceData: any;
  ref: boolean;
  id: string;
  tableDetails: ITableDetails;
  editModalData: any[];
  dataTypes: any[];
  fullDataTypes: any[];
  dataTypesAreLoaded: boolean = false;
  tableName: Observable<SourceTable>;
  private destroy = new Subject<void>();

  columns: IColumns[] = [
    {
      columnDef: "Id",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Id}`
    },
    {
      columnDef: "Order",
      header: "Order",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.Order}`
    },
    {
      columnDef: "DatabaseName",
      header: "Database Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.DatabaseName}`
    },
    {
      columnDef: "FriendlyName1",
      header: "Friendly Name 1",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.FriendlyName1}`
    },
    {
      columnDef: "FriendlyName2",
      header: "Friendly Name 2",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.FriendlyName2}`
    },
    {
      columnDef: "FullDataType",
      header: "Data Type",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.FullDataType}`
    },
    {
      columnDef: "NullableFlag",
      header: "Nullable",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.NullableFlag}`
    }
  ];


  ngOnInit(){
    this.tableName.pipe(takeUntil(this.destroy)).subscribe(table => {
      this.systemService.getSourceSystemById(table.SourceSystemId).subscribe(system => {
        this.getDataTypes(system.DBVendorName);
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Source", system.Name ,table.FriendlyName], ["source/","source/tables/" + table.SourceSystemId ,"source/tables/" + table.SourceSystemId + "/columns/" + table.Id]);
      })
    })
  }

  ngOnDestroy(){
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  constructor(
    private route: ActivatedRoute,
    private columnService: SourceColumnService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private snackBarHandler: SnackBarHandler,
    private tableService: SourceTableService,
    private routeBreadcrumbService: RouteBreadcrumbService,
    private systemService: SourceSystemService,
    private sharedService: SharedService) {
      this.id = this.route.snapshot.paramMap.get('id2');
      this.tableName = this.tableService.getSourceTableById(Number(this.id));

      this.tableDetails = {  edit: false };
      this.serviceData = this.authService.getFakeSubscribe([]);
      this.authService.authorizeSection().subscribe(permissions =>{
        if(permissions.view){
          var details = {  edit: permissions.update }
          if(permissions.add){
            details['add'] = "Column";
          }
          if(permissions.delete)
            details['delete'] = "DatabaseName";

          this.tableDetails = details;
          this.serviceData = this.columnService.getSourceColumnsBySourceTable(Number(this.id));
          this.ref = true;
        }
        else {
          this.router.navigate(['/']);
        }
      });
      this.ref = false;
  }

  resetRefresh(value: boolean) {
    this.ref = value;
  }

  editModal({ Id,  Order, FriendlyName1, FriendlyName2, BaseDataType, Precision, DatabaseName, FriendlyName1Conv, FriendlyName2Conv, FullDataType, Scale, CharType, NullableFlag }: SourceColumn) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "SourceTableId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "Order",
        label: "Order",
        value: Order,
        validators: ["required"]
      },
      {
        id: "DatabaseName",
        label: "Database Name",
        value: DatabaseName,
        validators: []
      },
      {
        id: "FriendlyName1",
        label: "Friendly Name 1",
        value: FriendlyName1,
        validators: ["required"]
      },
      {
        id: "FriendlyName2",
        label: "Friendly Name 2",
        value: FriendlyName2,
        validators: []
      },
      {
        id: "FriendlyName1Conv",
        label: "Friendly Name 1 Converted",
        value: FriendlyName1Conv,
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "FriendlyName2Conv",
        label: "Friendly Name 2 Converted",
        value: FriendlyName2Conv,
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "BaseDataType",
        label: "Base Data Type",
        value: BaseDataType,
        validators: ["required"],
        inputType: {type: "dropdown", dropdownOptions: this.dataTypes}
      },
      {
        id: "Precision",
        label: "Precision",
        value: Precision,
        validators: []
      },
      {
        id: "Scale",
        label: "Scale",
        value: Scale,
        validators: []
      },
      {
        id: "FullDataType",
        label: "Full Data Type",
        value: FullDataType,
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "NullableFlag",
        label: "Nullable",
        value: NullableFlag,
        validators: [],
        inputType: {type: "checkbox"}
      },
    ]
    let data: IDialogData = {
      description: "Edit " + DatabaseName,
      numCols: 2,
      columnData: columnData,
      additionalData: this.fullDataTypes
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(SourceColumnsAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.columnService.saveColumn(result).subscribe(savedTable => {
          if (savedTable) {
            this.sharedService.sendSourceSystemUpdateRequest() //Sends request to update the source system nav
            this.snackBarHandler.open(`Success: Edited '${result.FriendlyName1}'`, 'success');
            this.ref = true;
          }
        }, err => this.snackBarHandler.open(`Failure: Column not edited`, 'failure'));
      }
    });

  }

  viewModal({ Id,  Order, FriendlyName1, FriendlyName2, BaseDataType, Precision, DatabaseName, FriendlyName1Conv, FriendlyName2Conv, FullDataType, Scale, NullableFlag, CharType}: SourceColumn) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: Id,
        validators: []
      },
      {
        id: "SourceTableId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "Order",
        label: "Order",
        value: Order,
        validators: ["required"]
      },
      {
        id: "DatabaseName",
        label: "Database Name",
        value: DatabaseName,
        validators: []
      },
      {
        id: "FriendlyName1",
        label: "Friendly Name 1",
        value: FriendlyName1,
        validators: ["required"]
      },
      {
        id: "FriendlyName2",
        label: "Friendly Name 2",
        value: FriendlyName2,
        validators: []
      },
      {
        id: "FriendlyName1Conv",
        label: "Friendly Name 1 Converted",
        value: FriendlyName1Conv,
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "FriendlyName2Conv",
        label: "Friendly Name 2 Converted",
        value: FriendlyName2Conv,
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "BaseDataType",
        label: "Base Data Type",
        value: BaseDataType,
        validators: ["required"],
        inputType: {type: "dropdown", dropdownOptions: this.dataTypes}
      },
      {
        id: "Precision",
        label: "Precision",
        value: Precision,
        validators: []
      },
      {
        id: "Scale",
        label: "Scale",
        value: Scale,
        validators: []
      },
      {
        id: "FullDataType",
        label: "Full Data Type",
        value: FullDataType,
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "NullableFlag",
        label: "Nullable",
        value: NullableFlag,
        validators: [],
        inputType: {type: "checkbox"}
      },
    ];
    let data: IDialogData = {
      description: "View " + DatabaseName,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ViewComponent, dialogConfig);
  }


  addModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: "",
        validators: []
      },
      {
        id: "SourceTableId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "Order",
        label: "Order",
        value: "",
        validators: ["required"]
      },
      {
        id: "DatabaseName",
        label: "Database Name",
        value: "",
        validators: []
      },
      {
        id: "FriendlyName1",
        label: "Friendly Name 1",
        value: "",
        validators: ["required"]
      },
      {
        id: "FriendlyName2",
        label: "Friendly Name 2",
        value: "",
        validators: []
      },
      {
        id: "FriendlyName1Conv",
        label: "Friendly Name 1 Converted",
        value: "",
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "FriendlyName2Conv",
        label: "Friendly Name 2 Converted",
        value: "",
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "BaseDataType",
        label: "Base Data Type",
        value: "",
        validators: ["required"],
        inputType: {type: "dropdown", dropdownOptions: this.dataTypes}
      },
      {
        id: "Precision",
        label: "Precision",
        value: "",
        validators: []
      },
      {
        id: "Scale",
        label: "Scale",
        value: "",
        validators: []
      },
      {
        id: "FullDataType",
        label: "Full Data Type",
        value: "",
        validators: [],
        inputType: {type: "display"}
      },
      {
        id: "NullableFlag",
        label: "Nullable",
        value: "N",
        validators: [],
        inputType: {type: "checkbox"}
      },
    ];
    let data: IDialogData = {
      description: "Add Source Column",
      numCols: 2,
      columnData: columnData,
      additionalData: this.fullDataTypes
    };
    dialogConfig.data = data;

    if(this.dataTypesAreLoaded){
      const dialogRef = this.dialog.open(SourceColumnsAddComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.columnService.saveColumn(result).subscribe(savedSystem => {
            if (savedSystem) {
              this.sharedService.sendSourceSystemUpdateRequest();//Sends request to update source system nav
              this.snackBarHandler.open(`Success: Added '${result.FriendlyName1}'`, 'success');
              this.ref = true;
            }
          }, err => this.snackBarHandler.open(`Failure: Column not added`, 'failure'));
        }
      });
    }

  }

  getDataTypes(databaseType: string) {
    this.columnService.getDataTypes(databaseType).subscribe(result => {
      const map = require("array-map");
      this.dataTypes = map(result, function (dataType) {
        return dataType.Name;
      });
      this.fullDataTypes = result;
      this.dataTypes.sort();
      this.dataTypesAreLoaded = true;
    }, err => this.snackBarHandler.open(`Failure: Could not retrieve Data Types`, 'failure'));
  }

  deleteRows(rows: number[]) {
    for (let row of rows) {
      this.columnService.deleteSourceColumn(row).subscribe(result => {
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.ref = true;
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
    }
  }

}
