
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Upsert } from 'app/models/upsert';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class UpsertService {
    private apiPath = 'api/EDWUpsert';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets the Upsert Types
     */
    getUpserts(): Observable<any> {
        return this.http.get<Upsert[]>(`${this.requestUrl}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
