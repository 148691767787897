import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean|UrlTree> | boolean {
    return this.auth.isAuthenticated.pipe(
      concatMap(() => this.auth.handleAuthCallback()),
      concatMap((result: { loggedIn: boolean, targetUrl: string }) => {
        return iif(() => result.loggedIn, of(true),
        this.auth.login(state.url)
          .pipe(map(() => false)))
    }));
  }

}
