import { Component, OnInit, Input, Output } from "@angular/core";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'mp-new-breadcrumb',
  templateUrl: './breadcrumb-new.component.html',
  styleUrls: ['./breadcrumb-new.component.scss']
})
export class BreadcrumbNewComponent implements OnInit {
  @Input() baseCrumb: String;
  @Input() parent: any;

  @Output() newHierarchy: EventEmitter<any> = new EventEmitter();
  @Output() oldHierarchy: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

  navigateToParent(parentOfParent?, parentOfParentOfParent?){
    if(parentOfParent){
        if(parentOfParentOfParent){
            this.oldHierarchy.emit({"new": false, "parentOfParent": true, "parent3": true});
        }
        else{
            this.oldHierarchy.emit({"new": false, "parentOfParent": true, "parent3": false});
        }
    }
    else{
      this.oldHierarchy.emit({"new": false, "parentOfParent": false, "parent3": false});
    }
  }



}