/**
 * Job Dashboard View
 * @author David Fink / 4.13.18 /
 */
import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DataTableDirective } from 'angular-datatables';
import { JobDashboardRow } from 'app/models/job-dashboard-row';
import { JobDashboardService } from 'app/services/job-dashboard.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { Subject } from 'rxjs';
import { ErrorInformationModalComponent } from './error-information-modal/error-information-modal.component';

/**
 * @export
 * @class JobDashboardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dp-job-dashboard',
  templateUrl: './job-dashboard.component.html',
  styleUrls: ['./job-dashboard.component.scss'],
  providers: [JobDashboardService]
})

export class JobDashboardComponent implements OnInit {

  @ViewChildren(DataTableDirective)
  private dtElement: DataTableDirective;
  private dtTrigger: Subject<JobDashboardRow[]> = new Subject();
  private dtOptions: DataTables.Settings = {};

  private jobs: JobDashboardRow[] = new Array<JobDashboardRow>();
  private backupJobs: JobDashboardRow[] = new Array<JobDashboardRow>();

  private runDate: Date = new Date();
  private destroy = new Subject<void>();

  constructor(
    private jobDashboardService: JobDashboardService,
    private dialog: MatDialog,
    private routeBreadcrumbService: RouteBreadcrumbService) { }

  ngOnInit() {
    this.dtOptions = {
      columnDefs: [{
        targets: [1, 3],
        orderable: false
      }]
    };

    this.setRunDate(new Date());
    this.getJobStatus(this.runDate);
    this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Users"], ["users/"]);

  }

  ngOnDestroy() {
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  /**
     * @param date
     * @author Collin Atkins / 9.27.17 /
     */
  private formatDate(date: Date): string {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  /**
   * @author David Fink / 4.13.18 /
   */
  private filterByDate(runDate: Date) {
    this.jobs = this.backupJobs;
    this.rerender();
    this.getJobStatus(runDate);
    this.dtTrigger.next();
  }

  /**
     * Calls JobDashboardService to get job statuses
     * @author David Fink / 4.13.18 /
     */
  private getJobStatus(runDate: Date) {
    this.jobDashboardService.getJobStatus(runDate)
      .subscribe(jobs => {
        this.jobs = jobs;
        this.backupJobs = jobs;
        this.dtTrigger.next();
      }, err => console.log(err));
  }

  private rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    }
  }

  /**
     * Resets the selected date to the current date (today)
     * @author David Fink / 4.13.18 /
     */
  private resetDateFilter() {
    this.runDate = new Date();

    this.rerender();
    this.jobs = this.backupJobs;
    this.dtTrigger.next();
  }

  /**
   * @param date
   * @author David Fink / 4.13.18 /
   */
  setRunDate(date: Date) {
    this.runDate = date;
  }

  openErrorInformationDialog(data: String) {
    this.dialog.open(ErrorInformationModalComponent, { data: data });
  }
}
