import { User } from "app/models/user";
import { Auth0User } from './Auth0User';

export class Note {

        Id: number;
        NoteId: number;
        ParentId: number;
        NoteNote: string;
        NoteCreatedUser: string;
        SequenceNumber: number;
        CreatedDate: Date;
        UpdatedDate: Date;
        CreatedUser: Auth0User;
        UpdatedUser: Auth0User;
        CreatedUserId: string;


        constructor() {
            this.Id = -1;
        }
    }
