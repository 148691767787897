import { SourceColumn } from "app/models/source-column";

export class SourceTable {

    Id: number;
    SourceSystemId: number;
    DatabaseName: string;
    SchemaName: string;
    FriendlyName: string;
    TablespaceName: string;
    NumRowStats: number;
    NumRowSelect: number;
    SourceColumns: SourceColumn[];
    TablePrefix: string;

    FileDelimiter: string;
    TextQualifier: string;
    FileSourceUrl: string;
    FirstLineHeader: boolean;
    FileType: string;

    constructor(sourceSystemId: number = -1, sourceTableId: number = -1) {
        this.Id = sourceTableId;
        this.SourceSystemId = sourceSystemId;
    }
}
