import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ReportCalculation } from 'app/models/ReportCalculation';
import { AccessTokenReq } from 'app/models/AccessTokenReq';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class ReportCalculationService {
    private apiPath = 'api/ReportCalculation';
    private apiGroupPath = 'api/ReportCalculationGroup';
    private requestUrl = environment.serviceUrl + this.apiPath;
    private requestGroupUrl = environment.serviceUrl + this.apiGroupPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets Report Calculation by Report Id
     */
     getReportCalculationsByReportIds(reportId, groupId): Observable<any> {
        return this.http.get(`${this.requestUrl}?groupId=${groupId}&reportId=${reportId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Report Calculation by Report Id
     */
     getReportCalculationGroupsByReportId(reportId): Observable<any> {
        return this.http.get(`${this.requestGroupUrl}?reportId=${reportId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets Report Calculation from Source
     */
    getReportCalculationsFromSource(sourceId, reportId): Observable<any> {
        return this.http.get(`${this.requestUrl}?reportId=${reportId}&sourceId=${sourceId}&fromSourceLocation=true`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets list of Report Calculations
     */
    getReportCalculationPagination(paginated, page, rowsPerPage): Observable<any> {
        return this.http.get(`${this.requestUrl}?paginated=${paginated}
            &page=${page}&rowsPerPage=${rowsPerPage}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    
    /**
     * Adds or updates a Report Calculation
     * @param reportCalculation 
     */
    saveReportCalculation(reportCalculation: ReportCalculation): Observable<any> {
        return this.http.put<ReportCalculation>(this.requestUrl, reportCalculation, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds or updates a Report Calculation Group
     * @param reportCalculation 
     */
     saveReportCalculationGroup(reportCalculation: ReportCalculation): Observable<any> {
        return this.http.put<ReportCalculation>(this.requestGroupUrl, reportCalculation, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a report calculation for a source (In particular just Power BI currently)
     * @param source 
     */
    putReportCalculationFromSourceWithToken(accessToken, clientId): Observable<any> {
        return this.http.put(this.requestUrl, {accessToken: accessToken, clientId: clientId, userId: -1}, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a report calculation for a source without access token
     * @param source 
     */
    putReportCalculationFromSource(accessTokenReq: AccessTokenReq, alternateSource: boolean): Observable<any> {
        return this.http.put<AccessTokenReq>(`${this.requestUrl}?alternateSource=${alternateSource}`, accessTokenReq, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a report for a source (In particular just Power BI currently)
     * @param source 
     */
     putCalculationsDataFromSource(calculations): Observable<any> {
        return this.http.put(`${this.requestGroupUrl}?isList=true`, calculations, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Deletes Report Calculation by Id
     * @param id 
     */
    deleteReportCalculation(id) {
        return this.http.delete(`${this.requestUrl}?id=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Deletes Report Calculation Group by Id
     * @param groupId 
     */
     deleteReportCalculationGroup(groupId) {
        return this.http.delete(`${this.requestGroupUrl}?groupId=${groupId}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}