import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin/admindashboard.component';
import { GeneratedJobsComponent } from './admin/generatedjobs/generatedjobs.component';
import { UsersComponent } from './admin/users/users.component';
import { BusinessGlossaryComponent } from './business-glossary/business-glossary-component/business-glossary.component';
import { CategoryComponent } from './business-glossary/category-component/category.component';
import { GlossaryComponent } from './business-glossary/glossary-component/glossary.component';
import { ProcessFlowComponent } from './business-glossary/process-flow-component/process-flow.component';
import { BusinessGlossaryWorkflowComponent } from './business-glossary-workflow/business-glossary-workflow.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DatalineageComponent } from './datalineage/datalineage.component';
import { AuthGuard } from './guards/auth.guard';
import { JobDashboardComponent } from './job-dashboard/job-dashboard.component';
import { JobHistoryComponent } from './jobhistory/jobhistory.component';
import { SchemaComponent } from './target/schemas/schemas.component';
import { SourceColumnsComponent } from './source/source-columns/source-columns.component';
import { SourceSystemsComponent } from './source/source-systems/source-systems.component';
import { SourceTablesComponent } from './source/source-tables/source-tables.component';
import { TargetSchemaComponent } from './target/target-schema/target-schema.component';
import { TargetTableComponent } from './target/target-table/target-table.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { FileBasedSource } from './filebased/filebased-source/source.component';
import { FileBasedSourceReport } from './filebased/filebased-report/report.component';
import { FileBasedSourceCalculationGroup } from './filebased/filebased-calculation-group/calculation-group.component';
import { FileBasedSourceCalculation } from './filebased/filebased-calculations/calculation.component';

const ROUTES: Routes = [
  {
    path: '',
    component: SchemaComponent,
    canActivate: [AuthGuard],
    data: {title: 'Home - MetaPrism'}
  },
  {
    path: 'business-glossary',
    children: [
      {
        path: '', component: BusinessGlossaryComponent,
        data: {title: 'Business Glossary - MetaPrism'}
      },
      {
        path: 'glossary/:id',
        children: [
          {
            path: '', component: GlossaryComponent,
            data: {title: 'Categories - MetaPrism'}
          },
          {
            path: 'category/:id2',
            children: [
              {
                path: '', component: CategoryComponent,
                data: {title: 'Terms - MetaPrism'}
              },
              {
                path: 'term/:id3', component: ProcessFlowComponent,
                data: {title: 'Term - MetaPrism'}
              }
            ]
          }
        ]
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'source',
    children: [
      {
        path: '',
        component: SourceSystemsComponent,
        data: {title: 'Source Systems - MetaPrism'}
      },
      {
        path: 'tables/:id',
        children: [
          {
            path: '',
            component: SourceTablesComponent,
            data: {title: 'Source Tables - MetaPrism'}
          },
          {
            path: 'columns/:id2',
            children: [
              {
                path: '',
                component: SourceColumnsComponent,
                data: {title: 'Source Columns - MetaPrism'}
              }
            ]
          }
        ]
      }
    ],
    canActivate: [AuthGuard],
    data: {title: 'Source Systems - MetaPrism'}
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {title: 'Users - MetaPrism'}
  },
  /*{
    path: 'generated-jobs',
    component: GeneratedJobsComponent,
    canActivate: [AuthGuard],
    data: {title: 'Generated Jobs - MetaPrism'}
  },*/
  {
    path: 'administration',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
    data: {title: 'Administration Dashboard - MetaPrism'}
  },
  /*{
    path: 'job-history',
    component: JobHistoryComponent,
    canActivate: [AuthGuard],
    data: {title: 'Job History - MetaPrism'}
  },
  {
    path: 'job-dashboard',
    component: JobDashboardComponent,
    canActivate: [AuthGuard],
    data: {title: 'Job Dashboard - MetaPrism'}
  },*/
  {
    path: 'schema',
    children: [
      {
        path: '', component: SchemaComponent,
        data: {title: 'Target Systems - MetaPrism'}
      },
      {
        path: ':id',
        children: [
          {
            path: '', component: TargetSchemaComponent,
            data: {title: 'Target Tables - MetaPrism'}
          },
          {
            path: 'table/:id2',
            children: [
              {
                path: '', component: TargetTableComponent,
                data: {title: 'Target Columns - MetaPrism'}
              }
            ]
          }
        ]
      },
    ],
    canActivate: [AuthGuard],
},
{
  path: 'filesource',
  children: [
    {
      path: '', component: FileBasedSource,
      data: {title: 'Filebased Source - MetaPrism'}
    },
    {
      path: 'reports/:id',
      children: [
        {
          path: '', component: FileBasedSourceReport,
          data: {title: 'Filebased Reports - MetaPrism'}
        },
        {
          path: 'calculations/:id2',
          children: [
            {
              path: '', component: FileBasedSourceCalculationGroup,
              data: {title: 'Filebased Calculation Groups - MetaPrism'}
            },
            {
              path: 'group/:id3',
              children: [
                {
                  path: '', component: FileBasedSourceCalculation,
                  data: {title: 'Filebased Calculations - MetaPrism'}
                }
              ]
            }
          ]
        }
      ]
    },
  ],
  canActivate: [AuthGuard],
},
  {
    path: 'data-lineage',
    component: DatalineageComponent,
    canActivate: [AuthGuard],
    data: {title: 'Data Lineage - MetaPrism'}
  },
  {
    path: 'business-glossary-publisher',
    component: BusinessGlossaryWorkflowComponent,
    canActivate: [AuthGuard],
    data: {title: 'Business Glossary Publisher - MetaPrism'}
  },
  {
    path: 'global-search',
    component: GlobalSearchComponent,
    canActivate: [AuthGuard],
    data: {title: 'Global Search - MetaPrism'}
  },
]

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
