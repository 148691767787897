import { TargetColumn } from "app/models/target-column";

export class TargetTable {
    Id: number;
    DatabaseName: string;
    SchemaName: string;
    TablePrefix: string;
    TargetColumns: TargetColumn[];
    show: boolean;

    constructor(parentSchemaName: string = null) {
        this.Id = -1;
        this.SchemaName = parentSchemaName;
        this.TargetColumns = new Array<TargetColumn>();
        this.show = false
    }
}
