/**
 * Job History Table
 * @author Collin Atkins / 9.27.17 / Changed object of filtering to RunDate, changed httprequest, cleaned code
 * @author Collin Atkins / 10.23.17 / Significantly reduced load time by changing job history retrieve to modal load, adding a column to job history
 *  for run date, and having job run date populated when a job is ran
 */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { JobHistory } from 'app/models/jobhistory';
import { JobHistoryService } from 'app/services/jobhistory.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { JobEditorModalComponent } from './job-editor-modal/job-editor-modal.component';
import { Location } from '@angular/common';

import { AccessTokenReq } from 'app/models/AccessTokenReq';

@Component({
    selector: 'dp-jobhistory',
    templateUrl: './jobhistory.component.html',
    styleUrls: ['./jobhistory.component.scss'],
    providers: [JobHistoryService]
})

export class JobHistoryComponent implements OnInit, AfterViewInit {

    @ViewChild(DataTableDirective)
    private jobs: JobHistory[] = new Array<JobHistory>();
    private backupJobs: JobHistory[] = new Array<JobHistory>();

    private startDate: Date = null;
    private endDate: Date = new Date();
    private permissions = {};

    serviceData: any;
    tableDetails: ITableDetails;
    ref: boolean;

    constructor(
      private jobService: JobHistoryService, private dialog: MatDialog,
      private routeBreadcrumbService: RouteBreadcrumbService, 
      private router: Router,
      private authService: AuthService,
      private location: Location) { 
        this.tableDetails = {routing: null, edit: false};
        this.serviceData = this.getFakeSubscribe([]);
    }

    columns: IColumns[] = [
        {
        columnDef: "TargetTable",
        header: "Job Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.TargetTable}`
        },
        {
        columnDef: "LastProcessDateTime",
        header: "Last Process Date",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.LastProcessDateTime.toString().replace("T", " ")}`
        },
        {
        columnDef: "LastRunDate",
        header: "Last Run Date",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.LastRunDate == null ? "" : element.LastRunDate.toString().replace("T", " ")}`
        },
        {
        columnDef: "isNextLoadFull",
        header: "Full Update",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.isNextLoadFull}`
        }
    ];

    ngOnInit() {
        this.authService.authorizeSection().subscribe(permissions =>{
            this.permissions = permissions;
            if(permissions.view){
                this.setEndDate(new Date());
                this.getJobHistory();
            }
            else {
              this.router.navigate(['/']);
            }
        });
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Job History"], ["job-history/"]);
    }

    ngAfterViewInit(): void {
		this.location.replaceState('job-history');
	}

    resetJobsRefresh(value: boolean) {
        this.ref = value;
    }

    getFakeSubscribe(array): Observable<any> {
        return Observable.of(array);
    }

    /**
     * @author Collin Atkins / 9.27.17 /
     */
    private filterByDate() {
        this.jobs = this.backupJobs;
        this.jobs = this.jobs.filter(j => new Date(j.LastRunDate) >= this.startDate && new Date(j.LastRunDate) <= this.endDate);
        this.serviceData = this.getFakeSubscribe(this.jobs);
        this.resetJobsRefresh(true);
        (<HTMLInputElement>document.getElementById("searchBar")).value = "";
    }

    /**
     * Calls JobHistoryService to get job history
     * @author Collin Atkins / 9.27.17 / Added LastRunDate recorded to object for filtering
     */
    private getJobHistory() {
        this.jobService.getJobHistory()
            .subscribe(jobs => {
                this.jobs = jobs;
                this.backupJobs = jobs;
                this.serviceData = this.getFakeSubscribe(this.jobs);
                this.resetJobsRefresh(true);
            }, err => console.log(err));
    }

    /**
     * Resets the date objects and sets jobs to backupJobs (unfiltered)
     * @author Collin Atkins / 9.27.17 /
     */
    private resetDateFilter() {
        this.startDate = null;
        this.setEndDate(new Date());

        this.jobs = this.backupJobs;
        this.serviceData = this.getFakeSubscribe(this.jobs);
        this.resetJobsRefresh(true);
        (<HTMLInputElement>document.getElementById("searchBar")).value = "";
    }

    /**
     * @param date
     * @author Collin Atkins / 9.25.17 /
     */
    private setEndDate(date: Date) {
        this.endDate = date;
        // Max hour, minute, second of day
        this.endDate.setHours(23, 59, 59, 59);
    }

    /**
     * @param date
     * @author Collin Atkins / 9.25.17 /
     */
    private setStartDate(date: Date) {
        this.startDate = date;
    }

    private viewModal(job){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = "1060px";
        dialogConfig.panelClass = "custom-dialog-container";
        dialogConfig.data = { job: job, permissions: this.permissions };

        const dialogRef = this.dialog.open(JobEditorModalComponent, dialogConfig);
    }

}
