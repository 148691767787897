/**
 * Modal for general deletion
 * @author John Crabill / 10.16.17 / Creation
 */

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'dp-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss'],
    providers: []
})

export class DeleteModalComponent implements OnInit {

    @Input() name: string;
    @Output() deleteIt = new EventEmitter();

    constructor() { }

    ngOnInit() {
        if (!this.name) {
            this.name = 'it';
        }
    }

    /**
     * Agrees to delete
     * @author John Crabill
     */
    private deleteItem() {
        this.deleteIt.emit();
    }

    /**
     * Modal Variables
     */
    visible: boolean = false;
    private visibleAnimate: boolean = false;

    /**
     * Modal Functions
     */

    /**
     * Modal Show
     */
    show() {
        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    /**
     * Modal Hide
     */
    hide() {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
    }


    /**
     * Click Modal Event
     */
    onContainerClicked(event: MouseEvent) {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }
}
