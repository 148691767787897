/**
 * User Administration
 * @author Collin Atkins
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { Auth0User } from 'app/models/Auth0User';
import { IColumnData, IDialogData, IInputType } from "app/models/dialog-interface";
import { AuthService } from 'app/services/auth.service';
import { Auth0ManagementApiService } from 'app/services/auth0-management-api.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { UserService } from 'app/services/user.service';
import { Observable, Subject } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar';
import { TokenService } from '../../services/token.service';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';
import { isError } from 'util';

@Component({
    selector: 'dp-users',
    templateUrl: './users.component.html',
    providers: [UserService, SnackBarHandler, AuthService],
    styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    private dtTrigger: Subject<Auth0User[]> = new Subject();
    private dtOptions: DataTables.Settings = {};

    tableDetails: ITableDetails;
    serviceData:any;
    ref:boolean;
    powerUser:boolean = true;

    columns: IColumns[] = [
      {
        columnDef: "name",
        header: "Full Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.name}`
      },
      {
        columnDef: "email",
        header: "Username",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.email}`
      },
      {
        columnDef: "roleString",
        header: "Role(s)",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.roleString}`
      }

    ];

    private users: Auth0User[] = new Array<Auth0User>();
    private roles:any = [];
    private destroy = new Subject<void>();
    private tenantName = "";

    constructor(
      private userService: UserService,
      private authService: AuthService,
      private auth0Service: Auth0ManagementApiService,
      private snackBarHandler: SnackBarHandler,
      private tokenService: TokenService,
      private router: Router,
      private dialog: MatDialog,
      private routeBreadcrumbService: RouteBreadcrumbService) { 
        this.tableDetails = this.powerUser ? { routing: { routingPath: [`/Home/Users/`] }, add: "User", edit: true, delete: "name", deleteId: "user_id"} : { routing: { routingPath: [`/Home/Users/`] }, edit: false };
        this.serviceData = this.getFakeSubscribe([]);
      }

    getFakeSubscribe(array): Observable<any> {
        return Observable.of(array);
    }

    resetRefresh(value: boolean) {
      this.ref = value;
    }

    ngOnInit() {
        this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            this.getUsers();
          }
          else {
            this.router.navigate(['/']);
          }
        });
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Users"], ["users/"]);
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
      this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
    }

    private deleteUsers(users) {
      for (let user of users) {
        this.auth0Service.deleteUser(user.id)
            .subscribe(() => {
                this.getUsers();
            }, err => {
                console.log(err);
                this.snackBarHandler.open('User deletion failed.', 'failure');
            });
      }
    }

    /**
     * Initializes the users table and then calls get roles
     */
    private getUsers() {
      this.authService.getUserJwt().subscribe(jwt => {
        this.tenantName = jwt["https://metaprism.com/tenant_id"];
        this.auth0Service.getUsers(jwt["https://metaprism.com/tenant_id"]).subscribe(users =>{
          this.users = users;
          this.loadRoleStrings();
        });
      });
    }

    // Load roles and creates the role string for each specific user for display
    private loadRoleStrings(){
      if(this.users){
        this.auth0Service.getRoles("(Production)").subscribe(authRoles =>{
          this.roles = authRoles;
            for(let i = 0; i < authRoles.length; i++){
              this.auth0Service.getRoleUsers(authRoles[i].roleId).subscribe(roleUsers =>{
                for(let j = 0; j < this.users.length; j++){
                  for(let k = 0; k < roleUsers.length; k++){
                    if(this.users[j].user_id == roleUsers[k].user_id){
                      if(this.users[j].currentRoleIds == null) this.users[j].currentRoleIds = []
                      if(this.users[j].currentRoleNames == null) this.users[j].currentRoleNames = []
                      if(this.users[j].previousRoleIds == null) this.users[j].previousRoleIds = []

                      this.users[j].currentRoleIds.push(authRoles[i].roleId);
                      this.users[j].currentRoleNames.push(authRoles[i].roleName);
                      this.users[j].previousRoleIds.push(authRoles[i].roleId);

                      if(this.users[j].roleString == ""){
                        this.users[j].roleString += authRoles[i].roleName.replace(" (Production)","");
                      }
                      else{
                        this.users[j].roleString += ", " + authRoles[i].roleName.replace(" (Production)","");
                      }
                    }
                  }
                }
              });
            }
        });
      }
      //Apply role string to user objects in table data source
      this.serviceData = this.getFakeSubscribe(this.users);
      this.ref = true;
    }

    addModal() {
      var roleSelection = [];
      for(var i = 0; i < this.roles.length; i++){
        roleSelection.push(this.roles[i].roleName)
      }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      let roles: IInputType = {type: "multidropdown", dropdownOptions: roleSelection };
      let password: IInputType = {type: 'password'};
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: -1,
          validators: []
        },
        {
          id: "name",
          label: "Name",
          value: "",
          validators: ["required"]
        },
        {
          id: "email",
          label: "Email",
          value: "",
          validators: ["required"]
        },
        {
          id: "password",
          label: "Password",
          value: "",
          validators: ["password", "required", "passwordStrength"],
          inputType: password
        },
        {
          id: "roles",
          label: "Roles",
          value: "",
          validators: [],
          inputType: roles
        }
      ];
      let data = {
        description: "Add User",
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;
  
      const dialogRef = this.dialog.open(AddComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          result.tenantName = this.tenantName;
          result.currentRoleIds = [];
          for(var i = 0; i < result.roles.length; i++){
            result.currentRoleIds.push(this.roles.filter(role => role.roleName == result.roles[i])[0].roleId)
          }
          this.auth0Service.createUser(result).subscribe(savedUser => {
            if (savedUser) {
              this.snackBarHandler.open(`Success: Added '${result.name}'`, 'success');
              this.getUsers();
            }
          }, err => this.snackBarHandler.open(`Failure: User not added`, 'failure'));
        }
      });
    }

    editModal(user) {
      var roleSelection = [];
      for(var i = 0; i < this.roles.length; i++){
        roleSelection.push(this.roles[i].roleName)
      }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      let roles: IInputType = {type: "multidropdown", dropdownOptions: roleSelection };
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: user.user_id,
          validators: []
        },
        {
          id: "name",
          label: "Name",
          value: user.name,
          validators: ["required"]
        },
        {
          id: "email",
          label: "Email",
          value: user.email,
          validators: ["required"]
        },
        {
          id: "roles",
          label: "Roles",
          value: user.currentRoleNames,
          validators: [],
          inputType: roles
        }
      ];
      let data: IDialogData = {
        description: "Edit " + user.name,
        numCols: 2,
        columnData: columnData
      }
      dialogConfig.data = data;
  
      const dialogRef = this.dialog.open(EditComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          result.tenantName = this.tenantName;
          result.user_id = user.user_id;
          result.currentRoleIds = [];
          result.previousRoleIds = user.previousRoleIds;
          if(result.roles != null)
            for(var i = 0; i < result.roles.length; i++){
              result.currentRoleIds.push(this.roles.filter(role => role.roleName == result.roles[i])[0].roleId)
            }
          this.auth0Service.saveUpdatedUser(result).subscribe(savedUser => {
            if (savedUser) {
              this.snackBarHandler.open(`Success: Edited '${result.name}'`, 'success');
              this.getUsers();
            }
          }, err => this.snackBarHandler.open(`Failure: User not edited`, 'failure'));
        }
      });
    }
}
