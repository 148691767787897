/**
 * Term
 * @author Collin Atkins / 10.4.17 / Overhauled ui and refactored code
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarHandler } from '../../../utilities/snackbar';
import { TermService } from 'app/services/term.service';
import { TermTypeService } from 'app/services/term-type.service';
import { TargetColumnService } from 'app/services/target-column.service';
import { TableService } from 'app/services/target-table.service';
import { TermStatusService } from 'app/services/term-status.service';
import { PriorityService } from 'app/services/priority.service';
import { Term } from 'app/models/term';
import { TermStatus } from 'app/models/term-status';
import { TermType } from 'app/models/term-type';
import { Priority } from 'app/models/priority';
import { TargetColumn } from 'app/models/target-column';
import { TargetTable } from 'app/models/target-table';
import { MatDialog } from '@angular/material';
import { RelatedTermModalComponent } from 'app/business-glossary/term/related-term-modal/related-term-modal.component';
import { TermModalComponent } from 'app/business-glossary/term/term-modal/term-modal.component';
import { TargetLinkModalComponent } from 'app/business-glossary/term/target-column-link-modal/target-column-link-modal.component';


@Component({
    selector: 'dp-terms',
    templateUrl: './term.component.html',
    providers: [TermService, TermTypeService, TargetColumnService, TableService, TermStatusService,PriorityService],
    styleUrls: ['./term.component.scss']
})

export class TermComponent implements OnInit {

    @ViewChild(DataTableDirective)
    private dtElement: DataTableDirective;
    private dtTrigger: Subject<Term[]> = new Subject();
    private dtOptions: DataTables.Settings = {};

    private Term: Term = new Term();
    private TermStatuses: TermStatus[] = new Array<TermStatus>();
    private TermTypes: TermType[] = new Array<TermType>();
    private Priorties: Priority[] = new Array<Priority>();

    private TargetTable: TargetTable = new TargetTable();
    private TargetColumn: TargetColumn = new TargetColumn();

    constructor(private route: ActivatedRoute, private router: Router, private termService: TermService,
        private targetTableService: TableService, private termTypeService: TermTypeService,
        private targetColumnService: TargetColumnService,private priorityService: PriorityService,
        private termStatusService: TermStatusService, private snackBarHandler: SnackBarHandler,
        private dialog: MatDialog) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
    };

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.Term.Id = +params['id3'];
        });

        this.route.queryParams.subscribe(qparams => {
            this.Term.TermName = qparams['name'];
            this.Term.Category.CategoryId =qparams['CategoryId'];
            this.Term.Category.Id = this.Term.Category.CategoryId;

        });

        this.dtOptions = {
            columnDefs: [{
                targets: [4,5],
                orderable: false
            }]
        };

        this.getTermTypes();
        this.getTermStatus();
        if(this.Term.Id > 0){
            this.getTerm();
        }

        this.getTermPriorities();

    }

    /**
     * Deletes term and routes back to parent category
     */
    private deleteTerm() {
        this.termService.deleteTerm(this.Term.Id)
            .subscribe(glossary => {
                this.snackBarHandler.open(`${this.Term.TermName} was deleted.`, 'success');
                this.router.navigate(['/businessglossary/category/', this.Term.Category.Id]);
            }, err => {
                console.log(err);
                this.snackBarHandler.open(`Term deletion failed.`, 'failure');
            });
    }

    /**
     * @param relationTerm
     */
    private deleteRelationTerm(relationTerm) {
        this.termService.deleteRelationTerm(relationTerm.RelationId)
            .subscribe(term => {
                this.snackBarHandler.open('Relation was deleted.', 'success');
                this.getTerm();
            }, err => {
                console.log(err);
                this.snackBarHandler.open('Relation failed to be deleted.', 'failure');
            });
    }


    /**
     * Calls the termType Service to get the term types
     */
    private getTermPriorities() {

        this.priorityService.getPriority()
        .subscribe(p => {
            this.Priorties = p;
        }, err => console.log(err));

    }


    /**
     * Calls the termType Service to get the term types
     */
    private getTermTypes() {
        this.TermTypes = this.termTypeService.getTermTypes();
    }

    /**
     * Calls the termStatus Service to get the term statuses
     */
    private getTermStatus() {
        this.TermStatuses = this.termStatusService.getTermStatus()
    }

    /**
     * Gets term by id
     */
    private getTerm() {
        this.rerender();

        this.termService.getTermById(this.Term.Id)
            .subscribe(term => {
                this.Term = term;
                this.getNameByColumn(this.Term.targetColumn);
                this.dtTrigger.next();
            }, err => console.log(err));
    }

    /**
     * Getting table columns, currently initialized to 0, so does not run on that
     * @author John Crabill
     */
    private getNameByColumn(columnId: number) {
        if (columnId && columnId >= 0) {
            this.targetColumnService.getColumnById(columnId)
                .subscribe(column => {
                    this.TargetColumn = column[0];
                    this.getNameByTable(this.TargetColumn.EDWTableId);
                }, err => console.log(err));
        }
    }

    /**
     * @param tableId
     * @author John Crabill
     */
    private getNameByTable(tableId: number) {
        this.targetTableService.getTableById(tableId)
            .subscribe(table => {
                this.TargetTable = table[0];
            }, err => console.log(err));
    }

    /**
     * @author Collin Atkins / 10.4.17 /
     */
    private gotoTargetTable(relatedTerm: Term) {
        this.router.navigate(['/schema/table/' + this.TargetColumn.EDWTableId]);
    }

    private rerender() {
        if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        }
    }

    /**
     * @author John Crabill / 1.2.18
     */
    private openAddRelatedTermDialog(term?: Term) {
        this.dialog.open(RelatedTermModalComponent, {
            data: { Term: term},
        })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.getTerm();
                }
            });
    }

    /**
     * @author John Crabill / 1.3.18
     */
    private openEditTermDialog(term?: Term) {
        this.dialog.open(TermModalComponent, {
            data: { Term: term},
        })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.getTerm();
                }
            });
    }


    private saveTerm(term: Term) {
        this.termService.saveTerm(term)
            .subscribe(term => {
                this.Term= term;

                this.snackBarHandler.open(`${term.TermName} was saved.`, 'success');
                 }, err => {
                console.log(err);
                     this.snackBarHandler.open(`Term failed to save.`, 'failure');
            });

    }

    /**
     * @author John Crabill / 1.3.18
     */
    private openTermLinkDialog(term?: Term) {
        this.dialog.open(TargetLinkModalComponent, {
            data: { Term: term},
        })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.getTerm();
                }
            });
    }

}
