import { Component, Input, OnInit } from '@angular/core';
import { SummaryTypeService } from 'app/services/summary-type.service';
import { SummaryService } from 'app/services/summary.service';
import { ColumnLineage } from 'app/models/column-lineage';
import { SummaryType } from 'app/models/summary-type';

@Component({
    selector: 'dp-summary-selector',
    templateUrl: './summary-selector.component.html',
    providers: [SummaryTypeService, SummaryService],
    styleUrls: ['./summary-selector.component.scss']
})

export class SummarySelectorComponent implements OnInit {

    @Input() columnLineage: ColumnLineage = new ColumnLineage();

    private summaryTypes: SummaryType[] = new Array<SummaryType>()

    constructor(private summaryTypeService: SummaryTypeService, private summaryService: SummaryService) { }

    ngOnInit() {
        this.columnLineage.Summaries = new Array<SummaryType>()
        this.getSummaries();
    }

    /**
     * Calls the SummaryTypeService to get all available SummaryTypes
     */
    private getSummaryTypes() {
        this.summaryTypeService.getSummaryTypes()
            .subscribe(summaryTypes => { this.summaryTypes = summaryTypes; }
            , err => console.log(err));
    }

    /**
     * Calls Get Summary Service to get the summary for this column lineage
     * @author Nash Lindley /9/28/2017
     */
    private getSummaries() {
        this.summaryService.getLineageSummaries(this.columnLineage.Id)
            .subscribe(summaries => {
                this.columnLineage.Summaries = summaries || [new SummaryType()];
                this.getSummaryTypes();
            }, err => {
                console.log(err);
                this.getSummaryTypes();
            });
    }

    /**
     * Adds or removes a role from roleSelected
     * @param role
     */
    private summaryChose(summary: SummaryType) {
        let index: number = -1;
        index = this.columnLineage.Summaries.findIndex(selected => summary.Id === selected.Id);
        index > -1 ? this.columnLineage.Summaries.splice(index, 1) : this.columnLineage.Summaries.push(summary);
    }

    /**
     * Returns bool of if summary is in summarySelected
     * @param role
     */
    private summaryExists(summary: SummaryType): boolean {
        if (this.columnLineage.Summaries) {
            return this.columnLineage.Summaries.find(selected => summary.Id === selected.Id) ? true : false;
        } else {
            return false;
        }
    }

}
