import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'termStatus'
})
export class TermStatusPipe implements PipeTransform {

    /**
     * Takes and Id and displays the Name of the termtype
     * @param Id 
     * @param array TermStatus[]
     * @author Nash Lindley
     */
    transform(Id: any, array: any): any {
        if (!Id || array.length === 0) {
            return Id;
        }
        return array.find(arr => arr.Id === Id).Status;
    }
}
