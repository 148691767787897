
import {catchError} from 'rxjs/operators';
/**
 * Job Param Service
 * @author Collin Atkins / 9.28.17 / Added get job params
 */
import { Injectable } from '@angular/core';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { Observable } from 'rxjs';
import { JobParam } from 'app/models/jobparam';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class JobParamService {
    private apiPath = 'api/EDWJobParam';
    private requestUrl = environment.serviceUrl + this.apiPath;
    constructor(private http: HttpClient) { }

    /**
     * @author Collin Atkins / 9.28.17 /
     */
    getJobParams(tableId: number): Observable<any> {
        return this.http.get(`${this.requestUrl}?paramId=${tableId + ""}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Saves the given Job
     * @param jobParam: job
     */
    createJobParams(jobParam, paramName, num, id): Observable<any> {
        var table = new JobParam();
        table.JobName = jobParam;
        table.paramName = paramName;
        table.ParamOrder = num;
        table.Id = id;
        return this.http.put(this.requestUrl, table, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    deleteJobParam(param): Observable<any> {
        var data = "jobId=" + param.ETId + "&paramName=" + param.paramName;

        return this.http.delete(this.requestUrl + "?" + data, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
