import { Component, EventEmitter, Output } from '@angular/core';
import { BaseTableComponent } from "../../components/base/base-table/base-table.component";

/**
 * @template T
 * @extends {BaseTableComponent<T>}
 */
@Component({
  selector: 'dp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent extends BaseTableComponent {

  @Output() onTestConnection = new EventEmitter<any>();
  /** This method triggers the test connection method in the parent component */
  testConnection(row: any){
    this.onTestConnection.emit(row);
  }

}
