export class OperatorType {

    OperatorTypeId: number;
    OperatorType: string;
    OperatorTypeDescription: string;
    OperatorTypeSymbol: string;

    constructor() {
        this.OperatorTypeId = -1;
    }
}
