import { Category } from "app/models/category";
import { Glossary } from "app/models/glossary";
import { User } from "app/models/user";
import { Team } from "app/models/team";
import { Auth0User } from './Auth0User';

export class Term {

    Id: number;
    TermId: number;
    TermName: string;
    TermDescription: string;
    TermStatus: string;
    TermType: number;
    CreatedDate: Date;
    UpdatedDate: Date;
    Category: Category;
    Glossary: Glossary;
    CategoryId: number;
    CreatedUser: Auth0User;
    UpdatedUser: Auth0User;
    RelatedTerms: Term[];
    targetColumn: number;
    TermTag: string;
    TermTeam: Team;
    TermTeamId: number

    constructor(categoryId: number = -1) {
        this.Id = -1;
        this.TermId = this.Id;
        this.Category = new Category(categoryId);
        this.Category.Id = categoryId;
        this.TermName = '';
        this.TermDescription = '';
        this.TermTag='';
        this.RelatedTerms = new Array();
    }

    clone(sourceTerm: Term)  {
        this.Id = sourceTerm.Id;
        this.TermId = sourceTerm.TermId;
        this.TermName = sourceTerm.TermName;
        this.TermDescription = sourceTerm.TermDescription;
        this.TermStatus = sourceTerm.TermStatus;
        this.TermType = sourceTerm.TermType;
        this.CreatedDate = sourceTerm.CreatedDate;
        this.UpdatedDate = sourceTerm.UpdatedDate;
        this.Category = sourceTerm.Category;
        this.Glossary = sourceTerm.Glossary;
        this.CategoryId = sourceTerm.CategoryId;
        this.CreatedUser = sourceTerm.CreatedUser;
        this.UpdatedUser = sourceTerm.UpdatedUser;
        this.RelatedTerms = sourceTerm.RelatedTerms;
        this.targetColumn = sourceTerm.targetColumn;
    }

}
