import {User} from "app/models/user";
import { TeamUser } from "app/models/team-user";
import { Auth0User } from './Auth0User';

export class Team {
    Id: number;
    TeamId: number;
    TeamName: string;
    TeamDescription: string;
    TeamOwnerUserId: string;
    TeamOwnerUser: Auth0User;
    CreatedUser: Auth0User;
    TeamUsers: TeamUser[];
    constructor() {
        this.Id = -1;
        this.TeamId =-1;
        this.TeamName = "";
        this.TeamDescription="";
        this.TeamOwnerUser  = new Auth0User();
        this.TeamOwnerUserId = "";

    }
}
