import { Inject, Injectable } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Auth0User } from 'app/models/Auth0User';


@Injectable()
export class UserUtility {

    constructor(private authService: AuthService) { }

    /**
     * loads the user into the object
     * loads CreatedUser if objects's id < 0
     * loads UpdatedUSer no matter what
     * only works for objects with created and updated users
     * @param object
     */
    loadUser(object) {
        this.authService.getUserJwt().subscribe(jwt =>{
            if(object.Id < 0){
              object.CreatedUser = new Auth0User();
              object.CreatedUser.userId = jwt["https://metaprism.com/user_id"];
            }
            object.UpdatedUser = new Auth0User();
            object.UpdatedUser.userId = jwt["https://metaprism.com/user_id"];
            object.UpdatedUser.name = jwt["name"];
        });
        return object;
    }
}
