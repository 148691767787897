/**
 * Modal for adding a target column link to a term
 * @author Collin Atkins / 10.5.17 / Overhauled ui, significantly reduced complexity, improved speed, and fixed validation
 * @author Collin Atkins / 10.10.17 / Added default selection options and reset other selects below on select
 */
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { SnackBarHandler } from '../../../../utilities/snackbar';
import { SchemaService } from 'app/services/schema.service';
import { Schema } from 'app/models/schema';
import { TargetColumnService } from 'app/services/target-column.service';
import { TermService } from 'app/services/term.service';
import { Term } from 'app/models/term';
import { Link } from 'app/models/link';
import { TargetTable } from 'app/models/target-table';
import { TargetColumn } from 'app/models/target-column';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dp-target-link-modal',
    templateUrl: './target-column-link-modal.component.html',
    styleUrls: ['./target-column-link-modal.component.scss'],
    providers: [SchemaService, TargetColumnService, TermService, SnackBarHandler]
})

export class TargetLinkModalComponent implements OnInit {

    private Term: Term = new Term();
    private Target: Link = new Link();
    private Schemas: Schema[] = new Array<Schema>();
    private Tables: TargetTable[] = new Array<TargetTable>();
    private Columns: TargetColumn[] = new Array<TargetColumn>();

    constructor(private schemaService: SchemaService, private termService: TermService, private targetColumnService: TargetColumnService, 
        private snackBarHandler: SnackBarHandler, private dialogRef: MatDialogRef<TargetLinkModalComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

    ngOnInit() { 
        if (this.data && this.data.Term.Id) {
            this.Term = Object.assign({}, this.data.Term);
            this.clearLink();
        this.getSchemas();
        } else {
            this.dialogRef.close();
            this.snackBarHandler.open('Invalid parameters', 'danger');
        }
    }

    /**
     * For empty modal
     * @author John Crabill
     * @author Collin Atkins / 10.5.17 / Removed all extra initialization
     */
    private clearLink() {
        this.Target = new Link(this.Term);
        this.Schemas = new Array<Schema>();
        this.Tables = new Array<TargetTable>();
        this.Columns = new Array<TargetColumn>();
    }

    /**
     * Retrieving Schemas
     * @author John Crabill
     * @author Collin Atkins / 10.5.17 / Removed initialization
     */
    private getSchemas() {
        this.schemaService.getSchemas()
            .subscribe(schemas => {
                this.Schemas = schemas;
            }, err => console.log(err));
    }

    /**
     * Gets Target Tables after narrowing tables that have Target Columns
     * @author John Crabill
     * @author Collin Atkins / 10.5.17 / Overhauled to remove glut and created new service call
     */
    private getTargetTable(schemaId: number) {
        if (schemaId) {
            this.targetColumnService.getDistincTablesBySchemaName(schemaId)
                .subscribe(tables => {
                    this.Tables = tables;
                }, err => console.log(err));
        }
    }

    /**
     * General Get Target Column, does not display columns that are already linked
     * @param targetTableId
     * @author John Crabill
     * @author Collin Atkins / 10.5.17 / Overhauled to remove glut and created new service call
     */
    private getTargetColumn(targetTableId: number) {
        if (targetTableId) {
            this.targetColumnService.getTargetColumnsByTargetTableFilterLinked(targetTableId)
                .subscribe(columns => {
                    this.Columns = columns;
                }, err => console.log(err));
        }
    }

    private resetTable() {
        this.Tables = new Array<TargetTable>();
        this.Target.targetTable = new TargetTable();
    }

    private resetColumn() {
        this.Columns = new Array<TargetColumn>();
        this.Target.targetColumn = new TargetColumn();
    }

    /**
     * Saves link
     * @author John Crabill
     *  @author Collin Atkins / 10.5.17 / Added snackbar feedback
     */
    private saveLink() {
        this.Target.term.targetColumn = this.Target.targetColumn.Id;

        this.termService.saveTerm(this.Target.term)
            .subscribe(tables => {
                this.snackBarHandler.open(`Target Column has been linked.`, 'success');
                this.dialogRef.close(tables);
            }, err => {
                console.log(err);
                this.dialogRef.close();
                this.snackBarHandler.open(`Failed to link target column.`, 'failure');
            });
    }

}
