
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataHandler } from 'utilities/data';
import { ErrorHandler } from 'utilities/error';
import { Schema } from 'app/models/schema';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SchemaService {
    private apiPath = 'api/EDWSchemaTargets';
    private apiPathImport = 'api/EDWSchemaImport';
    private apiConn = 'api/Connection';
    private targetRequestUrl = environment.serviceUrl + this.apiPath;
    private importRequestUrl = environment.serviceUrl + this.apiPathImport;
    private connRequestUrl = environment.serviceUrl + this.apiConn;
    constructor(private http: HttpClient) { }

    /**
     * Deletes schema by name
     * @param schema - schema to be deleted by name
     */
    deleteSchema(schema: Schema): Observable<any> {
        return this.http.delete(this.targetRequestUrl + '?schemaId=' + schema.Id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all Schemas
     */
    getSchemas(): Observable<any> {
        return this.http.get(this.targetRequestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets a schema by name
     * @author Collin Atkins / 10.11.17
     */
    getSchemaById(id: number): Observable<any> {
        return this.http.get(`${this.targetRequestUrl}?id=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all tables from the schema connection
     */
    getTablesBySchemaId(id: number): Observable<any> {
        return this.http.get(`${this.importRequestUrl}?schemaId=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all schemas with TargetTables and TargetColumns as children
     * @author Collin Atkins / 12.5.17
     */
    getSchemasWithChildren(): Observable<any> {
        return this.http.get(`${this.targetRequestUrl}?withChildren=${true}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Saves a schema
     * @param schema - schema to be saved
     */
    saveSchema(schema: Schema): Observable<any> {
        return this.http.put(`${this.targetRequestUrl}?update=${false}`, JSON.stringify(schema), httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Updates a schema
     * @param schema - schema to be updatec
     */
    updateSchema(schema: Schema): Observable<any> {
      return this.http.put(`${this.targetRequestUrl}?update=${true}`, JSON.stringify(schema), httpOptions).pipe(
          catchError(new ErrorHandler().handleError));
  }

    importTables(tables): Observable<any> {
        return this.http.put(this.importRequestUrl, tables, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }


    /**
     * Tests the connection
     * @author John Crabill
     * @param Schema Schema - Schema to check
     */
    testConnection(schema: Schema) {
        return this.http.put(this.connRequestUrl, schema, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
