import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import { Category } from 'app/models/category';
import { IColumnData, IDialogData, IInputType } from "app/models/dialog-interface";
import { Glossary } from 'app/models/glossary';
import { IColumns, ITableDetails } from "app/models/table-interfaces";
import { GlossaryService } from 'app/services/glossary.service';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { ViewComponent } from 'app/shared/components/view/view.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarHandler } from 'utilities/snackbar';
import { CategoryService } from '../../services/category.service';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';


@Component({
  selector: 'dp-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss'],
  providers: [ CategoryService, GlossaryService ]
})
export class GlossaryComponent {

  serviceData: any;
  id: string;
  ref: boolean;
  tableDetails: ITableDetails;
  editModalData: any[];
  parentCategoryDropdown: IInputType = {
    type: "dropdown",
    dropdownOptions: []
  }
  glossaryName: Observable<Glossary>;
  private destroy = new Subject<void>();
  otherCategories: any = [];

  columns: IColumns[] = [
    {
      columnDef: "CategoryId",
      header: "Id",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.CategoryId}`
    },
    {
      columnDef: "CategoryName",
      header: "Name",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.CategoryName}`
    },
    {
      columnDef: "CategoryDescription",
      header: "Description",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.CategoryDescription}`
    },
    {
      columnDef: "UsageContext",
      header: "Usage Context",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.UsageContext}`
    },
    {
      columnDef: "ParentCategory",
      subColumnDef: "CategoryName",
      header: "Parent Category",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.ParentCategory != null ? element.ParentCategory.CategoryName : "" }`
    },
    {
      columnDef: "UpdatedDate",
      header: "Last Updated",
      sortable: true,
      filterable: true,
      cell: (element: any) => `${element.UpdatedDate.toString().replace("T", " ")}`
    }
  ];

  ngOnInit() {
    this.glossaryName.pipe(takeUntil(this.destroy)).subscribe(glossary => {
      this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Business Glosssary", glossary.BusinessGlossaryName], ["business-glossary/", "business-glossary/glossary/" + glossary.BusinessGlossaryId]);
    });
  }

  ngOnDestroy() {
    this.destroy.next;
    this.destroy.complete;
    this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
  }

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private snackBarHandler: SnackBarHandler,
    private routeBreadcrumbService: RouteBreadcrumbService,
    private glossaryService: GlossaryService,
    private sharedService: SharedService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.glossaryName = this.glossaryService.getGlossaryById(Number(this.id));
        let routingPath = ["/business-glossary/glossary/","category"];

        this.tableDetails = { routing: {routingPath: routingPath, firstId: this.id}, edit: false };
        this.serviceData = this.authService.getFakeSubscribe([]);
        this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            var details = { routing: {routingPath: routingPath, firstId: this.id}, edit: permissions.update }
            if(permissions.add){
              details['add'] = "Category";
            }
            if(permissions.delete)
              details['delete'] = "CategoryName";

            this.tableDetails = details;
            this.serviceData = this.categoryService.getCategoriesByGlossaryId(Number(this.id));
            this.dropDownAdd();
            this.ref = true;
          }
          else {
            this.router.navigate(['/']);
          }
        });
        this.ref = false;
  }

  resetRefresh(value: boolean) {
    this.ref = value;
  }

  editModal({ Id, CategoryId, CategoryName, CategoryDescription, UsageContext, ParentCategory }: Category) {
    var tempDropdown = {
      type: "dropdown",
      dropdownOptions: []
    };
    this.serviceData.subscribe(res => {
      for(var i = 0; i < res.length; i++){
        if(res[i].CategoryId != CategoryId)
        tempDropdown.dropdownOptions.push(res[i].CategoryName);
      }
      tempDropdown.dropdownOptions.sort();

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: -1,
          value: Id,
          validators: []
        },
        {
          id: "CategoryId",
          label: -1,
          value: CategoryId,
          validators: []
        },
        {
          id: "BusinessGlossaryId",
          label: -1,
          value: this.id,
          validators: []
        },
        {
          id: "CategoryName",
          label: "Name",
          value: CategoryName,
          validators: ["required"]
        },
        {
          id: "CategoryDescription",
          label: "Description",
          value: CategoryDescription,
          validators: ["required"]
        },
        {
          id: "UsageContext",
          label: "Usage Context",
          value: UsageContext,
          validators: []
        },
        {
          id: "ParentCategory",
          label: "Parent Category",
          value: ParentCategory != null ? ParentCategory.CategoryName : "",
          validators: [],
          inputType: tempDropdown
        }
      ];
      let data: IDialogData = {
        description: "Edit " + CategoryName,
        numCols: 2,
        columnData: columnData
      }
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(EditComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          result.ParentCategoryId = result.ParentCategory != "" ? this.otherCategories.find(x => x.CategoryName == result.ParentCategory).Id : 0;
          this.categoryService.saveCategory(result).subscribe(savedCategory => {
            if (savedCategory) {
              this.sharedService.sendBGUpdateRequest(); //Sends the request to update the BG nav
              this.snackBarHandler.open(`Success: Edited '${result.CategoryName}'`, 'success');
              this.ref = true;
              this.dropDownAdd();
            }
          }, err => this.snackBarHandler.open(`Failure: Category not edited`, 'failure'));
        }
      });
    });
  }

  viewModal({ CategoryId, CategoryName, CategoryDescription, UsageContext, ParentCategory }: Category) {
    this.parentCategoryDropdown.dropdownOptions = [];
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      let columnData: IColumnData[] = [
        {
          id: "CategoryId",
          label: -1,
          value: CategoryId,
          validators: []
        },
        {
          id: "BusinessGlossaryId",
          label: -1,
          value: this.id,
          validators: []
        },
        {
          id: "CategoryName",
          label: "Name",
          value: CategoryName,
          validators: ["required"]
        },
        {
          id: "CategoryDescription",
          label: "Description",
          value: CategoryDescription,
          validators: ["required"]
        },
        {
          id: "UsageContext",
          label: "Usage Context",
          value: UsageContext,
          validators: []
        },
        {
          id: "ParentCategory",
          label: "Parent Category",
          value: ParentCategory != null ? ParentCategory.CategoryName : "",
          validators: []
        }
      ];
      let data: IDialogData = {
        description: "View " + CategoryName,
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(ViewComponent, dialogConfig);
  }

  dropDownAdd(){
    this.serviceData.subscribe(res => {
      this.otherCategories = res;
      this.parentCategoryDropdown.dropdownOptions = [];
      for(var i = 0; i < res.length; i++){
        if(res[i].Id != this.id)
          this.parentCategoryDropdown.dropdownOptions.push(res[i].CategoryName);
      }
      this.parentCategoryDropdown.dropdownOptions.sort();
    }, err => {
      this.parentCategoryDropdown.dropdownOptions = [];
    });
  }

  addModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let columnData: IColumnData[] = [
      {
        id: "Id",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "CategoryId",
        label: -1,
        value: -1,
        validators: []
      },
      {
        id: "BusinessGlossaryId",
        label: -1,
        value: this.id,
        validators: []
      },
      {
        id: "CategoryName",
        label: "Name",
        value: "",
        validators: ["required"]
      },
      {
        id: "CategoryDescription",
        label: "Description",
        value: "",
        validators: ["required"]
      },
      {
        id: "UsageContext",
        label: "Usage Context",
        value: "",
        validators: []
      },
      {
        id: "ParentCategory",
        label: "Parent Category",
        value: "",
        validators: [],
        inputType: this.parentCategoryDropdown
      }
    ];
    let data = {
      description: "Add Category",
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(AddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.ParentCategoryId = result.ParentCategory != "" ? this.otherCategories.find(x => x.CategoryName == result.ParentCategory).Id : 0;
        this.categoryService.saveCategory(result).subscribe(savedCategory => {
          if (savedCategory) {
            this.sharedService.sendBGUpdateRequest(); //Sends the request to update the BG nav
            this.snackBarHandler.open(`Success: Added '${result.CategoryName}'`, 'success');
            this.ref = true;
            this.dropDownAdd();
          }
        }, err => this.snackBarHandler.open(`Failure: Category not added`, 'failure'));
      }
    });
  }

  deleteRows(rows: number[]) {
    for (let row of rows) {
      this.categoryService.deleteCategory(row).subscribe(result => {
        this.sharedService.sendBGUpdateRequest(); //Sends the request to update the BG nav
        this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
        this.ref = true;
        this.dropDownAdd();
      }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));

    }
  }

}
