import { Component, OnInit, Input, SimpleChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { TokenService } from '../../../services/token.service';
import { MatDialog } from '@angular/material';
import { UserService } from '../../../services/user.service';
import { RoleService } from 'app/services/role.service';
import { EventEmitter } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
    selector: 'mp-navigation-explorer',
    templateUrl: './navigation-explorer.component.html',
    styleUrls: ['./navigation-explorer.component.scss'], 
})

export class NavigationExplorerComponent implements OnInit {
    private automade = false;
    private count = 0;
    private currentObj: any;
    private selectedId = "";


    @Input() _data: any[] = [];
    @Input() set selectId(value: string){
        this.selectedId = value;
    }
    @Output() changeSelected: EventEmitter<any> = new EventEmitter();

    
    private flatSystems: any[] = []; //for autofill
    private explorerStatus: boolean = false; //drops the menu when true


    constructor(private router: Router, 
        private userService: UserService, 
        private roleService: RoleService,
        private authService: AuthService, 
        private tokenService: TokenService, private dialog: MatDialog) { }

    ngOnInit() {
    }




    private toggleDropdownVisibility(dropdownName, obj?){
        let start = new Date().getTime();
        if(dropdownName === "explorer"){
            if(this.explorerStatus){
                this.explorerStatus = false;
            }
            else{
                this.explorerStatus = true;
            }
        }

        if(obj !== undefined){
            if(obj.dropdownToggle !== true && obj.dropdownToggle !== false){
                obj.dropdownToggle = true;
            }
            else if(obj.dropdownToggle !== true){
                obj.dropdownToggle = true;
            }
            else{
                obj.dropdownToggle = false;
            }
        }
        
    }


    private toggleSelected(obj){
        if(obj.type !== "ignore"){
            if(obj.toggleSelected !== true && obj.toggleSelected !== false){
                obj.toggleSelected = true;
                this.selectedId = obj.id;
            }
            else if(obj.toggleSelected !== true){
                obj.toggleSelected = true;
                this.selectedId = obj.id;
            }
            else if(obj.toggleSelected == true && this.selectedId == obj.id){
                this.selectedId = "";
                obj.toggleSelected = false;
            }
            else{
                obj.toggleSelected = true;
                this.selectedId = obj.id;
            }
            let parent = null;
            if(obj.parentIndex.length !== 0){
                parent = this._data[obj.parentIndex[0]];
                for(let i = 1; i < obj.parentIndex.length; i++){
                    let grandParent = parent;
                    parent = parent.children[obj.parentIndex[i]];
                    parent.parent = grandParent;
                }
            }
            this.changeSelected.emit({"selectedObject": obj, "parent": parent});
        }
    }

    private createAutoList(data?){
        this.automade = false;
        if(data !== undefined){
            for(let i = 0; i < data.length; i++){
                if(data[i].type !== "ignore"){
                    this.flatSystems.push({"name": data[i].name})
                    let children = false;
                    for(let key in data[i]){
                        if(key === "children"){
                            children = true;
                        }
                    }
                    if(children){
                        this.createAutoList(data[i].children);
                    }
                }
                else{
                    this.automade = false;
                }
            }
        }
        else{
            for(let i = 0; i < this._data.length; i++){
                if(this._data[i].type !== "ignore"){
                    this.flatSystems.push({"name": this._data[i].name})
                    let children = false;
                    for(let key in this._data[i]){
                        if(key === "children"){
                            children = true;
                        }
                    }
                    if(children){
                        this.createAutoList(this._data[i].children);
                    }
                }
                else{
                    this.automade = false;
                }
            }
        }
        
        

    }
    

    private searchNav(event){
        if(this._data !== [] && this.automade === false){
            this.createAutoList();
        }
        let val;
        try{
            val = event.target.value;
        }
        catch{ //used to update when something is selected from autocomplete (cannot get _data from event object due to value not being a "value")
            val = event;
        }
        for(let i in this.flatSystems){
            if(this.flatSystems[i].name.toLowerCase().includes(val.toLowerCase())){
                this.flatSystems[i].searchPossible = true;
            }
            else{
                this.flatSystems[i].searchPossible = false;
            }
        }
        for(let sys in this._data){
            let sysOpen = false;
            for(let table in this._data[sys]["children"]){
                let tableOpen = false;
                for(let col in this._data[sys]["children"][table]["children"]){
                    if(this._data[sys]["children"][table]["children"][col]["name"] == val){
                        this._data[sys]["children"][table]["children"][col]["searchFound"] = true;
                        sysOpen = true;
                        tableOpen = true;
                    }
                    else{
                        this._data[sys]["children"][table]["children"][col]["searchFound"] = false;
                    }
                }
                if(tableOpen){
                    this._data[sys]["children"][table]["dropdownToggle"] = true;
                }

                if(this._data[sys]["children"][table]["name"] == val){
                    this._data[sys]["children"][table]["searchFound"] = true;
                    sysOpen = true;
                }
                else{
                    this._data[sys]["children"][table]["searchFound"] = false;
                }
            }
            if(sysOpen){
                this._data[sys]["dropdownToggle"] = true;
            }

            if(this._data[sys]["name"] == val){
                this._data[sys]["searchFound"] = true;
            }
            else{
                this._data[sys]["searchFound"] = false;
            }
        }

    }

    private closeOpenDropdownsInNav(){
        for(let sys in this._data){
            for(let table in this._data[sys]["children"]){
                this._data[sys]["children"][table]["dropdownToggle"] = false
            }
            this._data[sys]["dropdownToggle"] = false;
        }
    }

    private expandDropdownsInNav(){
        for(let sys in this._data){
            for(let table in this._data[sys]["children"]){
                this._data[sys]["children"][table]["dropdownToggle"] = true;
            }
            this._data[sys]["dropdownToggle"] = true;
        }
    }

    //has children which in turn have children
    private hasChildren(obj){
        this.currentObj = obj;
        let children = false;
        for(let key in obj){
            if(key === "children"){
                children = true;
            }
        }
        let childOfChild = false;
        if(children === true){
            for(let key in obj.children[0]){
                if(key === "children"){
                    childOfChild = true;
                }
            }
        }
        return childOfChild;
    }
        

}
