import { Injectable } from '@angular/core';
import { TermStatus } from 'app/models/term-status';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TermStatusService {
    private apiPath = 'api/BGTermStatus';

    termStatus: TermStatus[] = [
        { Id: '1', Status: 'DRAFT' },
        { Id: '2', Status: 'PROPOSED' },
        { Id: '3', Status: 'IN REVIEW' },
        { Id: '4', Status: 'PUBLISHED' }
    ];

    constructor(private http: HttpClient) { }

    getTermStatus() {
        return this.termStatus;
    }

    getStatusIdByDescription(statusDescription: string): string {
        let statusId = '-1';

        this.termStatus.forEach(term => {
            if(term.Status === statusDescription.toUpperCase()) {
                statusId = term.Id;
            }
        });

        return statusId;
    }

}
