/**
 * Dialog to View and execute SQL Statement for the Target Table
 * @author Collin Atkins
 */
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatExpansionPanel } from '@angular/material';
import { Subject } from 'rxjs';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SnackBarHandler } from 'utilities/snackbar';
import { TableService } from 'app/services/target-table.service';
import { TargetTable } from 'app/models/target-table';
import { TableMapping } from 'app/models/table-mapping';

@Component({
    selector: 'sql-view-dialog',
    templateUrl: 'sql-view-dialog.component.html',
    styleUrls: ['sql-view-dialog.component.scss'],
    providers: [TableService]
})

export class SqlViewDialogComponent implements OnInit {
    @ViewChild('sqlStatementPanel') sqlStatementPanel: MatExpansionPanel;
    @ViewChild(DataTableDirective)

    private dtElement: DataTableDirective;
    private dtTrigger: Subject<TargetTable[]> = new Subject();

    private SqlStatement: string;
    private maxCount: string;
    private TargetTable: TargetTable = new TargetTable();
    private TableMapping: TableMapping = new TableMapping();

    private dataRecords = new Array();
    private ErrorMessage: string;

    private DataArray = new Array<any>();
    private ObjectKeys = new Array<string>();

    constructor(private dialogRef: MatDialogRef<SqlViewDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private targetTableService: TableService, private snackbar: SnackBarHandler) { }

    ngOnInit() {
        this.SqlStatement = this.data.SqlStatement;
        this.TargetTable = this.data.TargetTable;
        this.TableMapping = this.data.TableMapping;
        this.maxCount = "10";
        this.dataRecords = this.loadRecordCount();

        if (!this.data.SqlStatement) {
            this.snackbar.open(`No SQL Statement Found`, 'failure');
        } else {
            this.sqlStatementPanel.open();
        }
    }

    /**
     * returns all a list of records to retrieve
     */
    private loadRecordCount(): any[] {
        return [{ 'Name': '10' },
        { 'Name': '100' },
        { 'Name': '1000' }
       ];
    }

    /**
     * Calls service to execute sql statement
     * @author Collin Atkins / 10.30.17
     */
    private executeSql() {
        if (this.DataArray.length > 0) {
            this.rerender();
        }
        this.targetTableService.executeSql(this.SqlStatement, 
                    this.TableMapping.Id, this.maxCount)
            .subscribe(res => {
                this.ErrorMessage = null;
                this.DataArray = JSON.parse(res);
                if (this.DataArray && this.DataArray.length > 0) {
                    this.ObjectKeys = Object.keys(this.DataArray[0]);
                    this.dtTrigger.next();
                    this.sqlStatementPanel.close()
                } else {
                    this.ErrorMessage = 'No data retrieved';
                }
            }, err => {
                this.ErrorMessage = err;
                this.DataArray = new Array<any>();
                this.ObjectKeys = new Array<string>();
                this.sqlStatementPanel.close()
            });
    }

    /**
     * Gets target sql statement, used for retrieving it again
     * @author Collin Atkins / 10.30.17
     */
    private getTargetSql() {
        this.targetTableService.getTargetSql(this.TargetTable.Id + '', this.TableMapping.Id)
            .subscribe(sqlStatement => {
                this.SqlStatement = sqlStatement;
            });
    }

    private rerender() {
        if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        }
    }

}