
import {finalize} from 'rxjs/operators';
/**
 * Source Table Join of the SQLConfigurator
 * @author Collin Atkins / 10.16.17 / Fixed delete looping bug, reduced calls to check alias amount, fixed calls to refresh joins at correct time
 * @author Collin Atkins / 10.17.17 / Fixed bug of deleting alias before conditions are deleted
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SnackBarHandler } from 'utilities/snackbar';
import { SourceTableJoinConditionService } from 'app/services/source-table-join-condition.service';
import { SourceTableJoin } from 'app/models/source-table-join';
import { SourceTableJoinService } from 'app/services/source-table-join.service';
import { OperatorType } from 'app/models/operatortype';
import { SourceTableJoinCondition } from 'app/models/source-table-join-condition';
import { TableLineageService } from 'app/services/table-lineage.service';
import { SourceTable } from 'app/models/source-table';

@Component({
    selector: 'dp-sourcetablejoin',
    templateUrl: './source-table-join.component.html',
    providers: [SnackBarHandler, TableLineageService, SourceTableJoinConditionService]
})

export class SourceTableJoinComponent implements OnInit {

    @Input() Joins: SourceTableJoin[] = new Array<SourceTableJoin>();
    @Input() join: SourceTableJoin = new SourceTableJoin();
    @Input() operatorTypes: OperatorType[] = new Array<OperatorType>();
    @Input() SourceTables: SourceTable[] = new Array<SourceTable>();
    @Input() permissions: Object;
    @Output() refreshJoins = new EventEmitter();

    constructor(private sourceTableJoinService: SourceTableJoinService, private joinConditionService: SourceTableJoinConditionService,
        private snackBarHandler: SnackBarHandler, private tableLineageService: TableLineageService) { }

    ngOnInit() {
        if (this.join.SourceTableJoinConditions.length == 0) {
            this.join.SourceTableJoinConditions = [new SourceTableJoinCondition(this.join.SourceTableJoinId)];
        }
    }

    /**
     * Checks to see if it is deleting the last alias, if it is then it deletes the lineage
     * @author Collin Atkins / 10.16.17 / Removed service called and call getAliasCount instead
     */
    private checkForLineageDelete() {
        this.getAliasCount() <= 1 ? this.deleteTableLineage(this.join.EDWTableLineageId) : this.refreshJoins.emit();
    }

    /**
     * Creates a new Join Alias
     */
    private createJoinAlias() {
        const joinAlias: SourceTableJoin = JSON.parse(JSON.stringify(this.join));
        joinAlias.Id = -1;
        joinAlias.SourceTableJoinId = -1
        joinAlias.SourceTableJoinConditions = [];
        joinAlias.JoinAlias = `${joinAlias.SourceTable.FriendlyName}_${this.getAliasCount()}`;

        this.saveSourceTableJoin(joinAlias);
    }

    /**
     * Calls SourceTableJoinService to delete this join
     * @author Collin Atkins / 10.17.17 / Changed to rely on deleteJoinConditions completion
     */
    private deleteJoinAlias() {
        this.deleteJoinConditions()
            .then(() => {
                this.sourceTableJoinService.deleteSourceTableJoin(this.join.Id)
                    .subscribe(() => {
                        this.snackBarHandler.open(`${this.join.JoinAlias} was deleted.`, 'success');
                        this.checkForLineageDelete();
                    }, err => {
                        console.log(err);
                        this.snackBarHandler.open(`Join Alias deletion failed.`, 'failure');
                    });
            }, err => {
                console.log(err);
                this.snackBarHandler.open(`Join Alias deletion failed.`, 'failure');
            });
    }

    /**
     * Deletes all join table conditions
     * @author Collin Atkins / 10.13.17 / Added subscribe to fix bug of not resolving observable
     * @author Collin Atkins / 10.17.17 / Changed to promise to resolve when all services complete
     */
    private deleteJoinConditions(): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.join.SourceTableJoinConditions.forEach((cond, index) => {
                this.joinConditionService.deleteJoinConditions(cond.Id).pipe(
                    finalize(() => {
                        if (this.join.SourceTableJoinConditions.length - 1 == index) {
                            resolve();
                        }
                    }))
                    .subscribe();
            });
        });
    }

    /**
     * Calls service to delete table lineage by id
     * @param tableLineageId 
     */
    private deleteTableLineage(tableLineageId: number) {
        this.tableLineageService.deleteTableLineageById(tableLineageId)
            .subscribe(() => {
                this.snackBarHandler.open(`Table lineage was deleted.`, 'success');
                this.refreshJoins.emit();
            }, err => {
                console.log(err);
                this.snackBarHandler.open(`Failed to delete table lineage.`, 'failure');
            });
    }

    /**
     * Gets count of aliases based on source table id of join
     * @author Collin Atkins / 10.16.17
     */
    private getAliasCount(): number {
        return this.Joins.filter(j => j.SourceTableId == this.join.SourceTableId).length;
    }

    /**
     * Adds a new join condition to the join
     */
    private loadNewJoinCondition() {
        this.join.SourceTableJoinConditions.push(new SourceTableJoinCondition(this.join.SourceTableJoinId));
    }

    /**
     * Calls the service to save the SourceTableJoin
     */
    private saveSourceTableJoin(join: SourceTableJoin = this.join) {
        this.sourceTableJoinService.saveSourceTableJoin(join)
            .subscribe(sourcetablejoin => {
                join = sourcetablejoin;
                this.snackBarHandler.open(`${join.JoinAlias} was saved.`, 'success');
                this.refreshJoins.emit();
            }, err => {
                console.log(err);
                this.snackBarHandler.open(`Join Alias failed to save.`, 'failure');
            });
    }

}
