import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dp-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  //Current year for the copyright date in the footer
  private currentYear: number;

  ngOnInit() {
    //Gets current year to set the footer copyright date
    this.currentYear = new Date().getFullYear();
  }

}

