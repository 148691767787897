import { Component } from '@angular/core';
import { BaseDialogComponent } from 'app/shared/components/base/base-dialog/base-dialog.component';
import { StringHandler } from 'utilities/string';

@Component({
  selector: 'dp-source-columns-add',
  templateUrl: './source-columns-add.component.html',
  styleUrls: ['./source-columns-add.component.scss']
})
export class SourceColumnsAddComponent extends BaseDialogComponent {

  private stringHandler: StringHandler = new StringHandler();
  private hiddenItems: string[] = this.toggleHiddenItems();

  isASpecialField(id: string) {
    switch (id) {
      case "FriendlyName1": {
      }
      case "FriendlyName2": {
        this.uglifyString(id);
        break;
      }
      case "Precision": {
        this.generateFullDataType();
        break;
      }
      case "Scale": {
        this.generateFullDataType();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
     * @param str
     * @author Collin Atkins
     */
  private uglifyString(id: string) {
    this.form.controls[id + "Conv"].setValue(this.stringHandler.uglifyString(this.form.controls[id].value));
  }

  /**
     * @author Collin Atkins
     */
  generateFullDataType() {
    let result = this.form.controls["BaseDataType"].value;
    if (result) {
      var foundType = this.additionalData.find(item => item.Name == result);
      var fullDataType = "";
      if(foundType.HasPrecision && foundType.HasScale){
        fullDataType = this.setFullDataType(this.form.controls["BaseDataType"].value, this.form.controls["Precision"].value, this.form.controls["Scale"].value);
      }
      else if(foundType.HasPrecision){
        fullDataType = this.setFullDataType(this.form.controls["BaseDataType"].value, this.form.controls["Precision"].value, null);
      }
      else if(foundType.HasScale){
        fullDataType = this.setFullDataType(this.form.controls["BaseDataType"].value, null, this.form.controls["Scale"].value);
      }
      else {
        fullDataType = this.setFullDataType(this.form.controls["BaseDataType"].value, this.form.controls["Precision"].value, null);
      }
      this.form.controls["FullDataType"].setValue(fullDataType);
    }
  }

  setFullDataType(baseDataType, precision, scale){
    var fullDataType = ""
    if (precision && scale) {
      fullDataType = `${baseDataType}(${precision},${scale})`;
    } 
    else if(precision){
      fullDataType = `${baseDataType}(${precision})`;
    }
    else {
      fullDataType = `${baseDataType}`;
    }
    return fullDataType;
  }

  isHidden(id: string){
    if(this.hiddenItems.includes(id)){
      return true;
    } else{
      return false;
    }
  }

  toggleHiddenItems() : string[] {
    let tempHiddenItems = [];
    var foundType = this.additionalData.find(item => item.Name == this.form.controls['BaseDataType'].value);

    if(foundType != null){
      if(!foundType.HasPrecision && !foundType.HasScale){
        tempHiddenItems.push('Scale');
      }
      else if(!foundType.HasPrecision){
        tempHiddenItems.push('Precision');
      }
      else if(!foundType.HasScale){
        tempHiddenItems.push('Scale');
      }
    }

    return tempHiddenItems;
  }

  /**
 * @author Collin Atkins
 */
  clearFields() {
    this.form.controls["Precision"].setValue("");
    this.form.controls["Scale"].setValue("");
    this.form.controls["FullDataType"].setValue("");
    this.generateFullDataType();
  }

}
