import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { Term } from 'app/models/term';
import { TermModalComponent } from 'app/business-glossary/term/term-modal/term-modal.component';
import { TermService } from 'app/services/term.service';
import { TokenService } from '../../services/token.service';
import { Subject } from 'rxjs';
import { IColumnData, IDialogData, IInputType } from "app/models/dialog-interface";
import { AddComponent } from 'app/shared/components/add/add.component';
import { TermStatusService } from 'app/services/term-status.service';
import { TermStatus } from 'app/models/term-status';
import { SnackBarHandler } from '../../../utilities/snackbar';

@Component({
  selector: 'dp-term-group',
  templateUrl: './term-group.component.html',
  styleUrls: ['./term-group.component.scss'],
  providers: [TermService, TermStatusService]
})

export class TermGroupComponent implements OnInit {

  @Input() data_row: Term[][];
  @Input() data_label: string;
  @Input() permissions: Object;

  statusDropdown: IInputType = {
    type: "dropdown",
    dropdownOptions: []
  }

  private TermStatuses: TermStatus[] = new Array<TermStatus>();

  private dtTrigger: Subject<Term[]>;

  constructor(
    private termStatusService: TermStatusService,
    private dialog: MatDialog,
    private route: Router,
    private snackBarHandler: SnackBarHandler,
    private termService: TermService,
    private tokenService: TokenService
  ) {
    this.dtTrigger = new Subject();
  }

  ngOnInit() { 
    this.getTermStatus();
  }

  handleClickEvent(term: Term) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";

    let currentStatus = null;
    this.statusDropdown.dropdownOptions = [];
    for(var i = 0; i < this.TermStatuses.length; i++){
      this.statusDropdown.dropdownOptions.push(this.TermStatuses[i].Status);
      if(this.TermStatuses[i].Id == term.TermStatus){
        currentStatus = this.TermStatuses[i].Status;
      }
    }

    let columnData: IColumnData[] = [
      {
        id: "TermId",
        label: -1,
        value: term.TermId,
        validators: []
      },
      {
        id: "TermName",
        label: "Name",
        value: term.TermName,
        validators: ["required"]
      },
      {
        id: "TermStatus",
        label: "Status",
        value: currentStatus,
        validators: ["required"],
        inputType: this.statusDropdown
      },
      {
        id: "TermDescription",
        label: "Description",
        value: term.TermDescription,
        validators: ["required"]
      }
    ];

    let data: IDialogData = {
      description: "View " + term.TermName,
      numCols: 2,
      columnData: columnData
    };
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(AddComponent, dialogConfig)
    .afterClosed().subscribe(result => {
      if (result) {
        var previousStatus = term.TermStatus;
        var currentStatus = result.Relation != "" ? this.TermStatuses.find(x => x.Status == result.TermStatus).Id : "";
        
        term.TermName = result.TermName;
        term.TermDescription = result.TermDescription;
        term.TermName = result.TermName;
        term.TermStatus = result.Relation != "" ? this.TermStatuses.find(x => x.Status == result.TermStatus).Id : "";

        this.termService.saveShortTerm(term)
          .subscribe(returnedTerm => {
            this.moveTerm(term, previousStatus, currentStatus);
            this.snackBarHandler.open(`${returnedTerm.TermName} was saved.`, 'success');
          }, err => {
              this.snackBarHandler.open(`Term failed to save.`, 'failure');
          });
      }
    });
  }

  private moveTerm(term, previousStatus, currentStatus){
    if(previousStatus == "2")
      this.data_row[0] = this.data_row[0].filter(x => x.Id != term.Id);
    else if(previousStatus == "3")
      this.data_row[1] = this.data_row[1].filter(x => x.Id != term.Id);
    else if(previousStatus == "4")
      this.data_row[2] = this.data_row[2].filter(x => x.Id != term.Id);

    if(currentStatus == "2")
      this.data_row[0].push(term);
    else if(currentStatus == "3")
      this.data_row[1].push(term);
    else if(currentStatus == "4")
      this.data_row[2].push(term);
  }

  private getTermStatus() {
    this.TermStatuses = this.termStatusService.getTermStatus()
  }
}
