
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { TeamUser } from 'app/models/team-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TeamUserService {
    private apiPath = 'api/TeamUsers';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Save the Team ROle
     * @param Note: Team
     */
    saveTeamUser(teamuser: TeamUser): Observable<any> {
        return this.http.put(this.requestUrl, teamuser, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }
    getTeamUser(id:number): Observable<any> {
        return this.http.get(this.requestUrl + '/id=' + id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));

    }


    deleteTeamUser(teamid: number, userid: string){

        return this.http.delete(this.requestUrl + '?teamid=' +  teamid + '&userid=' + userid, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));

    }


}
