import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { onMainContentChange } from './animations/animations';
import { SidenavService } from './services/sidenav.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'dp-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange]
})

export class AppComponent {

  public onSideNavChange: boolean;
  public toggleNavParentVar: boolean = false;
  pageType: string = "";

  title = 'MetaPrism - Home';
  

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _sidenavService: SidenavService
    ) {
      this._sidenavService.sideNavState$.subscribe(res => {
        this.onSideNavChange = res;
      });

      router.events.subscribe((val) => {
        if(val instanceof NavigationEnd) {
          this.loadData();
        }
    });

  }

  ngOnInit() {
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          return this.titleCheck(this.activatedRoute.firstChild);
        })
      ).subscribe(
        (ttl: string) => {
          this.titleService.setTitle(ttl);
        }
      );
  }

  titleCheck(child){
    if (child != null && child.children.length > 0) {
      child = child.children[0];
      return this.titleCheck(child);
    }
    else if (child != null && child.snapshot.data.title) {
      return child.snapshot.data.title;
    }
    else {
      return this.titleService.getTitle();
    }   
  }

  loadData(){
    if(this.isSourceSystems()){
      this.pageType = "Source";

    } else if(this.isBusinessGlossary()) {
      this.pageType = "Business";
    }
  }


  /**
   * @author Collin Atkins / 12.13.17
   */
  public isLoginPage() {
    return this.router.url == '/login';
  }

    /**
   * @author Collin Atkins / 12.13.17
   */
  public isSourceSystems() {
    return this.router.url.includes('source');
  }

    /**
   * @author Collin Atkins / 12.13.17
   */
  public isBusinessGlossary() {
    return this.router.url.includes('business-glossary') && !this.router.url.includes('publisher');
  }

  updateToggleParent(value: boolean) {
    this.toggleNavParentVar = value;
  }

}
