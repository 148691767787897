import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth0User } from 'app/models/Auth0User';
import { Auth0Role } from 'app/models/Auth0Role';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'utilities/error';

@Injectable({
  providedIn: 'root'
})
export class Auth0ManagementApiService {
  private apiPath: string;
  constructor(public httpClient: HttpClient) {
    this.apiPath = environment.serviceUrl;
  }

   /**
   * Gets an array of users associated with a given tenant.
   * @param tenantName A string representing the name of the tenant to get
   * users from.
   * @returns An observable array of User objects representing the users
   * associated with the provided tenant.
   */
  getUsers(tenantName: string): Observable<Auth0User[]> {
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/?parameter=${tenantName}&method=GetUsers`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.httpClient.get(requestUrl, requestOptions).pipe(
      map(response => {
        // Declare
        let responseArray: object[] = response as object[];

        // Declare an array of users associated with the given tenant
        let users: Auth0User[] = [];

        /**
         * Iterate over the objects returned in the response to parse these to
         * an array of User objects.
         */
        responseArray.forEach(userObj => {
          let user: Auth0User = {
            user_id: userObj['user_id'],
            email: userObj['email'],
            name: userObj['name'],
            selected: false,
            teamRoleId: -1,
            roleString: ""
          };

          users.push(user);
        });

        return users;
      })
    );
  }

   /**
   * Gets details about a specific user by their user ID from Auth0.
   * @param userId A string representing the user ID for a user to get user
   * details for.
   * @returns A User object representing details about the current user
   * (e.g., user ID, name, title, etc.).
   */
  getUserDetails(userId: string): Observable<Auth0User> {
    // Declare and initialize a null User object
    let user: Auth0User = null;

    // Declare and initialize request parameters
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/?parameter=${userId}&method=GetUserDetails`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    /**
     * Request user details from the back-end (which in turn requests these
     * details from Auth0) and return an observable UserDetails object.
     */
    return this.httpClient.get(requestUrl, requestOptions).pipe(
      map(response => {
        user = {
            user_id: response['user_id'],
            email: response['email'],
            name: response['name'],
            selected: false,
            teamRoleId: -1,
            roleString: ""
        };
        return user;
      })
    );
  }

  editUserDetails(userId: string): Observable<Auth0User> {
    // Declare and initialize a null User object
    let user: Auth0User = null;

    // Declare and initialize request parameters
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/?parameter=${userId}&method=EditUserDetails`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    /**
     * Request user details from the back-end (which in turn requests these
     * details from Auth0) and return an observable UserDetails object.
     */
    return this.httpClient.get(requestUrl, requestOptions).pipe(
      map(response => {
        user = {
            user_id: response['user_id'],
            email: response['email'],
            name: response['name'],
            selected: false,
            teamRoleId: -1,
            roleString: ""
        };
        return user;
      })
    );
  }

  createUser(user){
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.httpClient.post(requestUrl, user, requestOptions).pipe(
      catchError(new ErrorHandler().handleError));
  }

  deleteUser(userId: string){
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI?userId=` + userId;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.httpClient.delete(requestUrl, requestOptions).pipe(
      catchError(new ErrorHandler().handleError));
  }

  //Returns users in with a specific role given a role id
  getRoleUsers(roleId: string): Observable<Auth0User[]> {
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/?parameter=${roleId}&method=GetRoleUsers`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.httpClient.get(requestUrl, requestOptions).pipe(
      map(response => {
        // Declare
        let responseArray: object[] = response as object[];

        // Declare an array of users associated with the given tenant
        let users: Auth0User[] = [];

        /**
         * Iterate over the objects returned in the response to parse these to
         * an array of User objects.
         */
        responseArray.forEach(userObj => {
          let user: Auth0User = {
            user_id: userObj['user_id'],
            email: userObj['email'],
            name: userObj['name'],
            selected: false,
            teamRoleId: -1,
            roleString: ""
          };

          users.push(user);
        });

        return users;
      })
    );
  }

  getRoles(nameFilter: string): Observable<Auth0Role[]> {
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/?parameter=${nameFilter}&method=GetRoles`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.httpClient.get(requestUrl, requestOptions).pipe(
      map(response => {
        // Declare
        let responseArray: object[] = response as object[];

        // Declare an array of roles associated with the given name filter
        let roles: Auth0Role[] = [];

        /**
         * Iterate over the objects returned in the response to parse these to
         * an array of role objects.
         */
        responseArray.forEach(roleObj => {
          let role: Auth0Role = {
            roleId: roleObj['RoleId'],
            roleName: roleObj['RoleName'],
            roleDescription: roleObj['RoleDescription']
          };

          roles.push(role);
        });

        return roles;
      })
    );
  }

  getPermissionsByUserId(userId: string): Observable<string[]>{
    var permissions;
    // Declare and initialize request parameters
    let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/?parameter=${userId}&method=GetPermissionsByUserId`;
    let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.httpClient.get(requestUrl, requestOptions).pipe(
      map(response => {
        permissions = response;
        return permissions;
      })
    );
  }

     /**
     * Updates an existing User
     * @param User
     */
    saveUpdatedUser(User: Auth0User): Observable<any> {
      let requestUrl = `${this.apiPath}api/Auth0ManagementAPI/`;
      let requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.httpClient.patch(requestUrl, JSON.stringify(User), requestOptions).pipe(     
        map(response => {
          return response;
        })
      );
    }
  }




