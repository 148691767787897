/**
 * Modal for adding/editing a term
 * @author Colling Atkins / 10.4.17 / Overhauled ui and refactored code
 */
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { SnackBarHandler } from '../../../../utilities/snackbar';
import { TermService } from 'app/services/term.service';
import { TermStatusService } from 'app/services/term-status.service';
import { Term } from 'app/models/term';
import { Category } from 'app/models/category';
import { TermStatus } from 'app/models/term-status';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dp-term-modal',
    templateUrl: './term-modal.component.html',
    styleUrls: ['./term-modal.component.scss'],
    providers: [TermService, TermStatusService, SnackBarHandler]
})

export class TermModalComponent implements OnInit {

    private Term: Term = new Term();
    private ParentCategory: Category = new Category();
    private TermStatuses: TermStatus[] = new Array<TermStatus>();

    constructor(private termService: TermService, private termStatusService: TermStatusService, private snackBarHandler: SnackBarHandler,
        private dialogRef: MatDialogRef<TermModalComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

    ngOnInit() { 
        if (this.data) {
            if(this.data.ParentCategory){
                this.ParentCategory = this.data.ParentCategory;
            }
            this.Term = this.data.Term ? Object.assign({}, this.data.Term) : new Term(this.ParentCategory.Id);
        } else {
            this.dialogRef.close();
            this.snackBarHandler.open('Invalid parameters', 'danger');
        }

        this.getTermStatus();
    }

    /**
     * Calls the termStatus Service to get the term statuses
     */
    private getTermStatus() {
        this.TermStatuses = this.termStatusService.getTermStatus()
    }

    /**
     * Saves term
     */
    private saveTerm() {
        this.termService.saveTerm(this.Term)
            .subscribe(term => {
                this.snackBarHandler.open(`${this.Term.TermName} was saved.`, 'success');
                this.dialogRef.close(term);
            }, err => {
                console.log(err);
                this.dialogRef.close();
                this.snackBarHandler.open(`Term failed to save.`, 'failure');
            });
    }

    /**
     * Saves term from outside modal
     * @author John Crabill
     */
    private saveTermWithTerm(term: Term) {
        this.termService.saveTerm(term)
            .subscribe(term => {
                this.snackBarHandler.open(`${term.TermName} was saved.`, 'success');
                this.dialogRef.close(term);
            }, err => {
                console.log(err);
                this.dialogRef.close();
                this.snackBarHandler.open(`Term failed to save.`, 'failure');
            });
    }
    
}
