
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SourceImport } from 'app/models/source-import';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SourceImportTableService {
    private apiPath = 'api/EDWSourceSystemImport';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Loads all the SourceTables into the local variable sourceTables
     * @param systemId id of source system
     */
    getCheckedTables(systemId): Observable<any> {
        return this.http.get<SourceImport>(this.requestUrl + '/' + systemId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Imports the selected tables into the sourcetables
     * @param schema
     */
    importTables(schema) {
        return this.http.put(this.requestUrl, schema, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
