/**
 * Data Lineage
 * @author Collin Atkins / 12.6.17 / Overhauled the tree list selection
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Schema } from 'app/models/schema';
import { TargetColumn } from 'app/models/target-column';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SchemaService } from 'app/services/schema.service';
import { Subject } from 'rxjs';
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'dp-datalineage',
    templateUrl: './datalineage.component.html',
    styleUrls: ['./datalineage.component.scss'],
    providers: [SchemaService]
})

export class DatalineageComponent implements OnInit {
    private TargetColumnSelected: TargetColumn;

    private Schemas: Schema[] = new Array<Schema>();
    private destroy = new Subject<void>();


    constructor(private schemaService: SchemaService, private router: Router,
        private authService: AuthService, private routeBreadcrumbService: RouteBreadcrumbService) { }

    ngOnInit(){
        this.authService.authorizeSection().subscribe(permissions =>{
            if(permissions.view){
                this.getSchemasWithChildren();
            }
            else {
                this.router.navigate(['/']);
            }
        });
        this.routeBreadcrumbService.updateBreadcrumbTitlesState(["Data Lineage"], ["data-lineage/"]);
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
      this.routeBreadcrumbService.updateBreadcrumbTitlesState([], []);
    }

    /**
     * Gets all Target Schemas with TargetTables and TargetColumns as parameters/children
     * @author Collin Atkins / 12.5.17
     */
    private getSchemasWithChildren() {
        this.schemaService.getSchemasWithChildren()
            .subscribe(schemas => {
                this.Schemas = schemas;
            }, err => console.log(err));
    }

    /**
     * @author Collin Atkins / 12.12.17
     */
    private gotoTargetTable() {
        this.router.navigate(['/schema/table/' + this.TargetColumnSelected.EDWTableId]);
    }

}
