import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgpSortModule } from "ngp-sort-pipe";
import { AddButtonDialogComponent } from './components/add-button-dialog/add-button-dialog.component';
import { AddComponent } from './components/add/add.component';
import { BaseDialogComponent } from "./components/base/base-dialog/base-dialog.component";
import { BaseTableComponent } from "./components/base/base-table/base-table.component";
import { BreadcrumbNewComponent } from './components/breadcrumb-new/breadcrumb-new.component';
import { BreadcrumbComponent } from 'app/breadcrumb/breadcrumb.component';
import { DeleteComponent } from './components/delete/delete.component';
import { EditEntryDialogComponent } from './components/edit-entry-dialog/edit-entry-dialog-component';
import { EditComponent } from './components/edit/edit.component';
import { NavigationExplorerComponent } from './components/navigation-explorer/navigation-explorer.component';
import { TableModifiedComponent } from './components/table-modified/table-modified.component';
import { TableComponent } from './components/table/table.component';
import { ViewComponent } from './components/view/view.component';
import { SharedRoutingModule } from './shared-routing.module';





@NgModule({
  declarations: [TableModifiedComponent, NavigationExplorerComponent, AddButtonDialogComponent, EditEntryDialogComponent, BreadcrumbNewComponent, TableComponent, AddComponent, EditComponent, DeleteComponent, BaseDialogComponent, BaseTableComponent, ViewComponent, BreadcrumbComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    SharedRoutingModule,
    NgpSortModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    CdkTableModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatToolbarModule,
    MatTabsModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  entryComponents: [AddButtonDialogComponent, EditEntryDialogComponent, AddComponent, EditComponent, DeleteComponent, ViewComponent],
  exports: [
    AddComponent,
    EditComponent,
    DeleteComponent,
    BaseTableComponent,
    BaseDialogComponent,
    TableComponent,
    AddButtonDialogComponent,
    TableModifiedComponent,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkTableModule,
    NavigationExplorerComponent,
    BreadcrumbNewComponent,
    BreadcrumbComponent
  ]
})
export class SharedModule { }
