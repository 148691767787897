import { Pipe, PipeTransform } from '@angular/core';
import { TransformationType } from 'app/models/transformation-type';

@Pipe({
    name: 'excludeTransformationType'
})
export class ExcludeTransformationTypePipe implements PipeTransform {

    /**
     * Excludes an object if it is found in another given array
     * @param array 
     * @param search
     * @author Collin Atkins / 11.9.17
     */
    transform(array: TransformationType[], search: string[]): any {
        if (!array || !search) {
            return array;
        }
        return array.filter(arr => {
            return search.findIndex(s => s.toLowerCase() == arr.Format.toLowerCase() || s.toLowerCase() == arr.Type.toLowerCase()) == -1;
        });
    }
}