import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { BaseDialogComponent } from 'app/shared/components/base/base-dialog/base-dialog.component';
import { cloneDeep } from 'lodash';
import { FileHandler } from 'utilities/file';
import { SnackBarHandler } from 'utilities/snackbar';

@Component({
  selector: 'dp-source-tables-new',
  templateUrl: './source-tables-add.component.html',
  styleUrls: ['./source-tables-add.component.scss']
})
export class SourceTablesAddComponent extends BaseDialogComponent {

  fileName: string = "";
  form0: FormGroup;
  pageIndex: number = 0;
  isLastPage: boolean = false;
  pageLength: number = 1;
  pageSize: number = 0;
  colArrays2: any[] = [];
  populated: boolean = false;
  delimiter: string = "";
  qualifier: string = "";

  private fileHandler: FileHandler = new FileHandler();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  delimiters: IFile[] = [
    { value: 'comma', viewValue: 'Comma' },
    { value: 'tab', viewValue: 'Tab' },
    { value: 'semicolon', viewValue: 'Semicolon' }
  ];
  qualifiers: IFile[] = [
    { value: 'singleQuote', viewValue: 'Single Quote' },
    { value: 'doubleQuote', viewValue: 'Double Quote' },
    { value: 'backQuote', viewValue: 'Back Quote' }
  ];

  delimiterControl = new FormControl('');
  qualifierControl = new FormControl('');

  constructor(dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) data: any, private snackBarHandler: SnackBarHandler) {
    super(dialogRef, data);
    this.form0 = new FormGroup({
      delimiter: this.delimiterControl,
      qualifier: this.qualifierControl
    });

    this.pageSize = this.colArrays.length;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  checkValid() {
    if (!this.form0.valid) {
      return false;
    } else {
      switch (this.form0.controls['delimiter'].value) {
        case 'comma': {
          this.delimiter = ',';
          break;
        }
        case 'tab': {
          this.delimiter = '\t';
          break;
        }
        case 'semicolon': {
          this.delimiter = ';';
          break;
        }
      }
      switch (this.form0.controls['delimiter'].value) {
        case 'singleQuote': {
          this.qualifier = `'`;
          break;
        }
        case 'doubleQuote': {
          this.qualifier = `"`;
          break;
        }
        case 'backQuote': {
          this.qualifier = '`';
          break;
        }
      }
      return true;
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(file: File) {
    let myFile: any = file;
    const [fName, fileExt] = file.name.split('.');
    this.colArrays2 = [];
    this.populated = false;
    if (fileExt === 'txt' || fileExt === 'csv') {
      if (fileExt === 'txt') {
        const fileContents = await this.readUploadedFileAsText(file);
        myFile = fileContents;

      }
      this.readFile(myFile);

      this.fileName = fName;
    } else {
      this.snackBarHandler.open(`Unsupported file type: ${fileExt}. Supported types are txt and csv`, 'failure');
    }

  }

  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };


  readFile(file: any) {
    this.fileHandler.readLocalCsv(file, true, this.delimiter, this.qualifier).then(csvObject => {
      this.pageLength = csvObject.length;
      for (let i = 1; i < csvObject.length; i++) {
        this.addDuplicateFormValues(i);
      }

      csvObject.forEach((item, index) => {
        for (let row of this.colArrays) {
          let tempRow = [];
          for (let col of row) {
            let tempObj: any = {};
            if (index == 0) {
              tempObj.id = col.id;
              tempObj.display = false;
            } else {
              tempObj.id = col.id + index;
              tempObj.display = true;
            }
            tempObj.page = index;
            tempObj.label = col.label;
            tempObj.value = item.hasOwnProperty(col.id) ? item[col.id] : col.value;
            if(col.hasOwnProperty("type")){
              tempObj.type = col.type;
            }
            if(col.hasOwnProperty("options")){
              tempObj.options = col.options;
            }
            tempRow.push(tempObj);
            this.form.controls[tempObj.id].setValue(tempObj.value);
          }
          this.colArrays2.push(tempRow);
        }
      });

      this.populated = true;



    });
  }

  /* set Form field to display / hide value */
  setPageFormFieldPaging(pageIndex: number, pageSize: number) {
    let lowerBound: number = ((pageIndex + 1) * pageSize) -
      pageSize;

    let upperBound: number = ((pageIndex + 1) * pageSize) - 1;
    let count = 0;
    this.colArrays2.forEach((row) => {
      row.forEach(col => {
        col.display = true;
        if (count >= lowerBound && count <= upperBound) {
          col.display = false;
        }
      });
      count++;
    });

  }


  pageEvent($event) {
    this.pageIndex = $event.pageIndex;
    this.setPageFormFieldPaging(this.pageIndex, this.pageSize)
    this.isLastPage = !this.paginator.hasNextPage();
  }

  addDuplicateFormValues(index: number) {
    Object.keys(this.form.controls).forEach(key => {
      this.form.addControl(key + index, cloneDeep(this.form.controls[key]) as FormControl);
    });
  }

  saveMultiple(){
    let final: any = [];
    let formKeys: any[] = Object.keys(this.form.controls);
    let formLength: number = formKeys.length/this.pageLength;
    let count: number = 0;
    let tempObj = {};
    let indexCount: number = 0;
    formKeys.forEach((key) => {
      if(count < formLength){
        if(indexCount == 0){
          tempObj[key] = this.form.controls[key].value;
        } else {
          tempObj[key.slice(0, -1)] = this.form.controls[key].value;
        }
        if(count == formLength-1){
          count = 0;
          final.push(tempObj);
          tempObj = {};
          indexCount++;
        } else{
          count++;
        }
      }
    });
    this.dialogRef.close(final);
  }

}



interface IFile {
  value: string;
  viewValue: string;
}
