export class TableMapping {
    Id: number;
    TargetTableId: number;
    SourceTableId: number
    SourceSystemId: number;
    Name: string;

    constructor(targetTableId: number = -1, sourceTableId: number = -1) {
        this.Id = -1;
        this.TargetTableId = targetTableId;
        this.SourceTableId = sourceTableId;
        this.SourceSystemId = -1;
        this.Name = name;
    }
}
