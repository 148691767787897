
import {map, startWith} from 'rxjs/operators';
/**
 * Row information for column lineage
 * @author Collin Atkins / 9.27.17 / Overhauled UI and refactored
 * @author Collin Atkins / 9.29.17 / Added input filters, fixed input bugs, and revised UI
 * @author Collin Atkins / 10.9.17 / Added initialization, fixed bug of column lineage not being disabled always, and added default choice to select sources
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SnackBarHandler } from 'utilities/snackbar';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ColumnLineageEditorDialogComponent } from 'app/target/target-table/lineage/column-lineage-row/column-lineage-editor-dialog/column-lineage-editor-dialog.component';
import { MatDialog } from '@angular/material';
import { SourceColumnService } from 'app/services/source-column.service';
import { ColumnLineageService } from 'app/services/column-lineage.service';
import { TableLineageService } from 'app/services/table-lineage.service';
import { ColumnLineage } from 'app/models/column-lineage';
import { SourceTable } from 'app/models/source-table';
import { TableMapping } from 'app/models/table-mapping';
import { SourceColumn } from 'app/models/source-column';
import { TableLineage } from 'app/models/table-lineage';

@Component({
    selector: 'dp-column-lineage-row',
    templateUrl: './column-lineage-row.component.html',
    providers: [SourceColumnService, ColumnLineageService, TableLineageService, SnackBarHandler],
    styleUrls: ['./column-lineage-row.component.scss']
})

export class ColumnLineageRowComponent implements OnInit {

    @Input() ColumnLineage: ColumnLineage = new ColumnLineage();
    @Input() SourceTables: SourceTable[] = new Array<SourceTable>();
    @Input() TableMapping: TableMapping = new TableMapping();
    @Input() permissions: Object;
    @Output() ColumnLineageChange = new EventEmitter();

    private sourceTablesControl: FormControl = new FormControl();
    private sourceColumnsControl: FormControl = new FormControl();
    private filteredSourceTables: Observable<SourceTable[]>;
    private filteredSourceColumns: Observable<SourceColumn[]>;

    constructor(private sourceColumnService: SourceColumnService, private columnLineageService: ColumnLineageService,
        private TableLineageService: TableLineageService, private snackBarHandler: SnackBarHandler, private dialog: MatDialog) { }

    ngOnInit() {
        this.ColumnLineage.SourceColumns = this.ColumnLineage.SourceColumns || new Array<SourceColumn>();
        this.initSourceTablesAndColumns();
    }

    /**
     * Calls service to delete column lineage; on success it emits to retrieve column lineages
     * @author Collin Atkins / 11.15.17
     */
    private deleteColumnLineage() {
        this.columnLineageService.deleteColumnLineage(this.ColumnLineage.Id)
            .subscribe(() => {
                this.snackBarHandler.open('Column Lineage deleted.', 'success');
                this.ColumnLineageChange.emit();
            }, err => {
                console.log(err);
                this.snackBarHandler.open('Column Lineage deletion failed.', 'failure');
            });
    }

    /**
     * Maps sourceTable object to name for input
     * @param sourceTable
     * @author Collin Atkins / 9.27.17 / 
     */
    private displaySourceTable(sourceTable: SourceTable): SourceTable | string {
        return sourceTable ? sourceTable.FriendlyName : sourceTable;
    }

    /**
     * Maps sourceColumn object to name for input
     * @param sourceColumn 
     * @author Collin Atkins / 9.27.17 / 
     */
    private displaySourceColumn(sourceColumn: SourceColumn): SourceColumn | string {
        return sourceColumn ? sourceColumn.FriendlyName1 : sourceColumn;
    }

    /**
     * @author Collin Atkins / 10.18.17
     */
    private enableSourceColumnSelect() {
        this.filteredSourceColumns = this.sourceColumnsControl.valueChanges.pipe(startWith(null),
            map(val => (val && typeof (val) === 'string') ? this.filterSourceColumns(val) : this.ColumnLineage.SourceColumns.slice()),);
        this.sourceColumnsControl.enable();
    }

    /**
     * Filters source tables input based on val entered
     * @param sourceTableName 
     * @author Collin Atkins / 9.29.17 / 
     */
    private filterSourceTables(sourceTableName: string): SourceTable[] {
        return this.SourceTables.filter(st => st.FriendlyName.toLowerCase().indexOf(sourceTableName.toLowerCase()) === 0);
    }

    /**
     * Filters source columns input based on val entered
     * @param sourceColumnName 
     * @author Collin Atkins / 9.29.17 / 
     */
    private filterSourceColumns(sourceColumnName: string): SourceColumn[] {
        return this.ColumnLineage.SourceColumns.filter(sc => sc.FriendlyName1.toLowerCase().indexOf(sourceColumnName.toLowerCase()) === 0);
    }

    /**
     * Calls sourceColService to get columns by SourceTableId
     * @param sourceTableId
     * @author Collin Atkins / 11.1.17
     */
    private getSourceColumns(sourceTableId: number = this.ColumnLineage.SourceTableId) {
        this.sourceColumnService.getSourceColumnsBySourceTable(sourceTableId)
            .subscribe(columns => {
                this.ColumnLineage.SourceColumns = columns;
                if (this.ColumnLineage.SourceColumnId && this.ColumnLineage.SourceColumnId >= 0) {
                    this.ColumnLineage.SourceColumn = this.ColumnLineage.SourceColumns.find(sourceColumn => sourceColumn.Id == this.ColumnLineage.SourceColumnId);
                }
                this.enableSourceColumnSelect();
            }, err => console.log(err));
    }

    /**
     * Inits source table and column objects as well as it sets up the select controls
     * @author Collin Atkins / 10.18.17
     */
    private initSourceTablesAndColumns() {
        this.ColumnLineage.SourceTable = this.SourceTables.find(sourceTable => sourceTable.Id == this.ColumnLineage.SourceTableId);

        this.filteredSourceTables = this.sourceTablesControl.valueChanges.pipe(startWith(null),
            map(val => (val && typeof (val) === 'string') ? this.filterSourceTables(val) : this.SourceTables.slice()),);

        if (this.ColumnLineage.SourceTable) {
            this.getSourceColumns();
        } else {
            this.sourceColumnsControl.disable();
        }
    }

    /**
     * Called when key is pressed on source table field
     * @param $event
     * @author Collin Atkins / 10.6.17
     */
    private inputSourceTable($event: KeyboardEvent) {
        if ($event.key == 'Backspace') {
            if (this.ColumnLineage.SourceTableId >= 0) {
                this.resetSourceTable();
            }
        }
        else if ($event.key == 'Enter') {
            this.selectSourceTable();
        }
    }

    /**
     * Called when key is pressed on source column field
     * @param $event
     *  @author Collin Atkins / 10.6.17 / 
     */
    private inputSourceColumn($event) {
        if ($event.key == 'Backspace') {
            if (this.ColumnLineage.SourceTableId) {
                this.resetSourceColumn();
            }
        }
        else if ($event.key == 'Enter') {
            this.selectSourceColumn();
        }
    }

    /**
     * @author Collin Atkins / 12.14.17
     */
    private openColumnLineageEditorDialog() {
        this.dialog.open(ColumnLineageEditorDialogComponent, {
            data: { TableMapping: this.TableMapping, ColumnLineage: this.ColumnLineage, permissions: this.permissions }
        })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.saveColumnLineage(result);
                }
            });
    }

    /**
     * Custom control for reseting source table input/select
     * @author Collin Atkins / 10.9.17 /
     */
    private resetSourceTable() {
        this.resetSourceColumn();
        this.sourceColumnsControl.disable();

        this.ColumnLineage.SourceColumns = new Array<SourceColumn>();
        this.ColumnLineage.SourceTableId = -1;
        this.ColumnLineage.SourceTable = new SourceTable();
    }

    /**
     * Custom control for reseting source column input/select
     * @author Collin Atkins / 10.9.17 /
     */
    private resetSourceColumn() {
        this.ColumnLineage.SourceColumnId = -1;
        this.ColumnLineage.SourceColumn = new SourceColumn();
    }

    /**
     * Saves the table lineage then saves the column lineage
     * @author Nash Lindley
     * @author Collin Atkins / 9.27.17 / Moved name mapping to init, added subscribe to saveTableLineage
     */
    private saveColumnLineage(columnLineage: ColumnLineage) {
        // Will fail if table lineage is not unique. This is expected, it should always move on to still create the column lineage
        this.TableLineageService.saveTableLineage(new TableLineage(this.TableMapping.Id, this.TableMapping.TargetTableId, columnLineage.SourceTableId, this.TableMapping.SourceSystemId))
            .subscribe();
        this.columnLineageService.saveColumnLineage(columnLineage)
            .subscribe(savedColumnLineage => {
                this.ColumnLineageChange.emit();
                this.snackBarHandler.open(`Column lineage saved.`, 'success');
            },
            err => {
                console.log(err);
                this.snackBarHandler.open(`Failed to save column lineage.`, 'failure');
            });
    }

    /**
     * Handles selection of source table. 
     * Sets ColumnLineage value then gets new source columns.
     * @param sourceTable: SourceTable - st selected
     * @author Collin Atkins / 9.27.17 / 
     * @author Collin Atkins / 9.29.17 / Edited to clear source column, added check to not repeat on same choice
     */
    private selectSourceTable(sourceTable: SourceTable = this.ColumnLineage.SourceTable) {
        if (sourceTable && sourceTable.Id >= 0) {
            this.ColumnLineage.SourceTableId = sourceTable.Id;
            this.resetSourceColumn();
            this.getSourceColumns();
        } else {
            this.resetSourceTable();
        }
    }

    /**
     * Handles selection of source table.
     * Sets column lineage value then saves the column lineage.
     * @param sourceColumn: SourceColumn - sc selected
     * @author Collin Atkins / 9.27.17 / 
     * @author Collin Atkins / 10.4.17 / Added if check to not repeat save
     */
    private selectSourceColumn(sourceColumn: SourceColumn = this.ColumnLineage.SourceColumn) {
        if (sourceColumn && sourceColumn.Id >= 0) {
            this.ColumnLineage.SourceColumnId = sourceColumn.Id;
            this.saveColumnLineage(this.ColumnLineage);
        } else {
            this.resetSourceColumn();
        }
    }

}
