import { Injectable } from '@angular/core';
import createAuth0Client, { IdToken, RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, throwError, iif } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Create an observable of Auth0 instance of client
  auth0Client = (from(
    createAuth0Client({
      domain: "login.metaprism.com",
      client_id: "E7LRt5LxyyVJOzT0at8yXpdKgUNdNjI2",
      redirect_uri: `${window.location.origin}`,
      connection: this.getTenantSubdomain(),
      useRefreshTokens: true,
      cacheLocation: "localstorage"
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated = this.auth0Client.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );

  handleRedirectCallback = this.auth0Client.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  // Create subject and public observable of user profile data
  private userProfileSubject = new BehaviorSubject<any>(null);
  userProfile = this.userProfileSubject.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private router: Router) {
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser(options?): Observable<any> {
    return this.auth0Client.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject.next(user))
    );
  }

  /**
   * Attempts to derive the subdomain through which a tenant user is accessing
   * ProSight from and either returns the subdomain as a string or null (based
   * on whether or not a subdomain could be found).
   * @returns A string representing the tenant's subdomain (e.g., teamtng).
   */
  private getTenantSubdomain(): string {
    let origin: string = window.location.origin;
    let subdomainRegExMatches: RegExpMatchArray = origin.match('(?=http[s]?:\/\/)?[A-Za-z0-9\-]+(?=\.{1}(metaprism\.com|2\.thenormandygroup\.com|localhost))');

    if (subdomainRegExMatches !== null && subdomainRegExMatches.length > 0) {
      return subdomainRegExMatches[0];
    }
    else {
      return 'teamtng-test';
    }
  }

  login(redirectPath: string = '/'): Observable<void>  {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    return this.auth0Client.pipe(
      concatMap((client: Auth0Client) =>
        // Call Auth0 login.
        from(client.loginWithRedirect({
          redirect_uri: `${window.location.origin}`,
          appState: { target: redirectPath }
        }))
      )
    );
  }

  handleAuthCallback(): Observable<any> {
    return of(window.location.search).pipe(
      concatMap((params: string) =>
        // Check to see if url includes code or state to see if it should check auth.
        iif(
          () => params.includes('code=') && params.includes('state='),
          // Handle Auth0 callback.
          this.handleRedirectCallback.pipe(
            concatMap((callbackResponse: RedirectLoginResult) =>
              // Check if authenticated through Auth0.
              this.isAuthenticated.pipe(
                take(1),
                // Return authenticated check result and callback url.
                map((loggedIn: boolean) => ({
                  loggedIn,
                  targetUrl:
                    callbackResponse.appState && callbackResponse.appState.target
                      ? callbackResponse.appState.target
                      : '/',
                })),
                tap((loggedIn) => {
                  if (loggedIn) {
                    this.getUser().subscribe();
                  }
                })
              )
            )
          ),
          this.isAuthenticated.pipe(
            take(1),
            map((loggedIn: boolean) => ({ loggedIn, targetUrl: null })),
            tap((loggedIn) => {
              if (loggedIn) {
                this.getUser().subscribe();
              }
            })
          )
        )
      )
    );
  }


  /**
   * Gets the user's assigned permissions as an observable string array.
   * @returns An observable string array containing the user's assigned
   * permissions.
   */
  getUserPermissions(): Observable<string[]> {
    return this.getUserJwt().pipe(
      map(userJwt => {
        if (userJwt !== undefined) {
          return userJwt['https://metaprism.com/user_permissions'];
        }
      })
    );
  }

  /**
   * Gets the user's assigned id as an observable string.
   * @returns An observable string  containing the user's assigned
   * id.
   */
  getUserId(): Observable<string> {
    return this.userProfile.pipe(
      map(userProfile => {
        return userProfile['https://metaprism.com/user_id']
      })
    );
  }

  /**
   * Gets the user's JWT as an observable IdToken.
   * @returns An observable IdToken object representing the user's JWT.
   */
  getUserJwt(): Observable<IdToken> {
    return this.auth0Client.pipe(concatMap((client: Auth0Client) => from(client.getIdTokenClaims())));
  }

  multiSplit(string, args ) {
    do {
      let arg = args.pop()
      string = string.replace(arg, args[0])
    } while (args.length > 2)
    return string.split(args[0])
  }

  authorizeSection(){
    return this.getUserJwt().pipe(
      map(userJwt => {
        if (userJwt !== undefined) {
          var permissions = userJwt['https://metaprism.com/user_permissions'];
          //Travel Locations is a feature that I believe will be necessary due to the linkage between
          //various parts of Source, Target, Glossary and Global Search Sections, which will be expanded on later
          var allowances = {view: false, add: false, update: false, delete: false, travelLocations: []}
          var urlFragments = this.multiSplit(window.location.pathname, ["/", "?"]);

          if(permissions.includes("admin:user"))
          {
            allowances = {view: true, add: true, update: true, delete: true, travelLocations: ['all']}
            return allowances;
          }
          switch(urlFragments[1]) { 
            case 'source': { 
              if(permissions.includes("lineageeditor:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            } 
            case 'schema': { 
              if(permissions.includes("lineageeditor:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            }
            case 'filesource': { 
              if(permissions.includes("lineageeditor:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            }
            case 'data-lineage': { 
              if(permissions.includes("lineageeditor:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            }
            case 'business-glossary': { 
              if(permissions.includes("businessglossary:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            }
            case 'business-glossary-publisher': { 
              if(permissions.includes("businessglossary:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            }
            case 'global-search': { 
              allowances.view = true;
              break; 
            }
            case 'job-history': { 
              if(permissions.includes("jobhistory:user")) 
                allowances = {view: true, add: true, update: true, delete: true, travelLocations: []}
              else 
                allowances.view = true;
              break; 
            }
            case 'users': {
              //Only Admins should have access, no permission granted
              break; 
            }
            case 'generated-jobs': { 
              //Only Admins should have access, no permission granted
              break; 
            } 
          }
          return allowances;
        }
      })
    );
  }

  getFakeSubscribe(array): Observable<any> {
    return Observable.of(array);
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: "E7LRt5LxyyVJOzT0at8yXpdKgUNdNjI2",
        returnTo: `${window.location.origin}`
      });
    });
  }

}
