export class SourceImport {
    Id: number;
    SchemaName: number;
    SchemaTables: any[];
    TablePrefix: string;

    constructor() {
        this.SchemaTables = new Array();
    }
}
