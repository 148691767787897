
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SummaryType } from 'app/models/summary-type';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class SummaryService {
    private apiPath = 'api/EDWSummary';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all Summaries of a column Lineage
     * @author Nash Lindley
     */
    getLineageSummaries(Id): Observable<any> {
        return this.http.get<SummaryType[]>(this.requestUrl + '?ColumnLineageId=' +Id, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
