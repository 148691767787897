import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessGlossaryComponent } from './business-glossary-component/business-glossary.component';
import { GlossaryComponent } from './glossary-component/glossary.component';
import { CategoryComponent } from './category-component/category.component';
import { SharedModule } from '../shared/shared.module';
import {ProcessFlowComponent} from './process-flow-component/process-flow.component';

@NgModule({
  declarations: [BusinessGlossaryComponent, GlossaryComponent, CategoryComponent, ProcessFlowComponent],
  exports: [
    BusinessGlossaryComponent,
    ProcessFlowComponent
  ],
  imports: [
    CommonModule, SharedModule]
  
})
export class BusinessGlossaryNewModule { }
