/**
 * Dialog for Importing Tables from a Source System's Connection
 */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarHandler } from 'utilities/snackbar';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { SourceImport } from 'app/models/source-import';
import { TableImport } from 'app/models/import-schema';
import { SourceImportTableService } from 'app/services/source-import.service';
import { SourceSystem } from 'app/models/source-system';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';

@Component({
    selector: 'import-source-tables-dialog',
    templateUrl: 'import-source-tables-dialog.component.html',
    styleUrls: ['import-source-tables-dialog.component.scss'],
    providers: [SourceImportTableService]
})

export class ImportSourceTablesDialogComponent implements OnInit {

    @ViewChild(DataTableDirective)
    private dtElement: DataTableDirective;
    private dtTrigger: Subject<TableImport[]> = new Subject();

    private SourceSystem: SourceSystem = new SourceSystem();
    private sourceImport: SourceImport = new SourceImport();
    private selectAllButton: boolean = false;
    private tablePrefix: string = "";
    private importArray: any[];
    private hasChecked: boolean;
    private dbSchemaName: string = "";

    tableDetails: ITableDetails;
    serviceData: any;
    ref: boolean;

    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: "Table Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.Id}`
      }
    ];

    constructor(private dialogRef: MatDialogRef<ImportSourceTablesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
        private sourceImportService: SourceImportTableService, private snackBarHandler: SnackBarHandler) {
        }

    ngOnInit() {
        this.SourceSystem = this.data && this.data.SourceSystem ? Object.assign({}, this.data.SourceSystem) : new SourceSystem();
        this.clearTable();
        this.setPrefix();
        this.getTables();
        this.serviceData = this.getFakeSubscribe([]);
        this.tableDetails = {routing: null, edit: false, connect:false, noView: true, checkbox:true, paginatorSize: 5};
        this.hasChecked = false;
    }

    /**
     * Created because the dp-table component needs a observable
     * This allows the manipulation of data before insertinging it
     * into the dp-table component
     */
    getFakeSubscribe(array): Observable<any> {
      return Observable.of(array);
    }

    private resetRefresh(value: boolean){
      this.ref = value;
    }

    /**
     * Gets a list of items from the table and
     * enables or disables the save button depending
     * on if one is checked or not
     */
    private isChecked(checkedTables){
        this.importArray = checkedTables;
        let checkedCount = 0;
        for (let key of Object.keys(checkedTables)) {
          if(checkedTables[key].checked == true){
            checkedCount++;
          }
        }
        if(checkedCount > 0){
          this.hasChecked = true;
        }
        else{
          this.hasChecked = false;
        }
    }

    /**
     * For empty modal
     */
    private clearTable() {
        this.sourceImport = new SourceImport();
        this.selectAllButton = false;
    }

    /**
     * @param imports
     */
    private createDictionary(imports) {
        this.sourceImport.Id = imports.Id;
        this.sourceImport.SchemaTables = new Array();
        for (let i = 0; i < imports.SchemaTables.length; i++) {
            this.sourceImport.SchemaTables.push({ Id: imports.SchemaTables[i], value: false });
        }
        this.sourceImport.SchemaTables = this.sourceImport.SchemaTables.sort(function(a, b) { 
          return ((a.Id < b.Id) ? -1 : ((a.Id > b.Id) ? 1 : 0));
        });
        this.serviceData = this.getFakeSubscribe(this.sourceImport.SchemaTables);
        this.resetRefresh(true);
    }

    /**
     * Uses schema service to get tables by schema connection
     */
    private getTables() {
        this.rerender();
        this.sourceImportService.getCheckedTables(this.SourceSystem.Id)
            .subscribe(schemas => {
                this.dbSchemaName = schemas.SchemaName;
                this.resetRefresh(true);
                this.createDictionary(schemas);
                this.dtTrigger.next();
            }, err => {
                this.dialogRef.close();
                this.snackBarHandler.open('Connection to source failed.', 'failure')
            });
    }

    /**
     * Set prefix in import
     * @author Collin Atkins / 10.24.17
     */
    private setPrefix() {
        if (this.SourceSystem) {
            if(this.SourceSystem.DBSchemaName == "")
                this.tablePrefix = this.SourceSystem.DBSchemaName;
            else 
                this.tablePrefix = this.SourceSystem.DBSchemaName + '.';
            if (this.SourceSystem && this.SourceSystem.DBVendorName && this.SourceSystem.DBVendorName.toLowerCase().trim() == 'sql server') {
                this.tablePrefix += 'dbo.';
            }
        }
    }

    /**
     * Sets up the object then calls importTables with it
     */
    private saveTables() {
        const trueImport = new TableImport();
        trueImport.Id = this.sourceImport.Id;
        for (let key of Object.keys(this.importArray)) {
          if(this.importArray[key].checked == true){
            trueImport.stringTables.push(this.importArray[key].id);
          }
        }

        this.dialogRef.close({trueImport: trueImport, tablePrefix: this.tablePrefix});
    }

    private rerender() {
        if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        }
    }

}
