export class Calculation {
    Id: number;
    AltId: number;
    Name: string;
    Type: string;
    Index: number;

    constructor(name: string = null, type: string = null, id: number = -1, altId: number = -1) {
        this.Name = name;
        this.Type = type;
        this.Id = id;
        this.AltId = altId;
    }
}