export class SourceSystem {
    Id: number;
    Name: string;
    UserName: string;
    DBVendorName: string;
    DBVersion: string;
    DBHostName: string;
    DBPort: number;
    DBDetail1: string;
    DBDetail2: string;
    DBUserName: string;
    DBProvider: string;
    DBPassword: string;
    DBSchemaName: string;
    DBEncoding: string;
    DBInstanceId: string;

    constructor() {
        this.Id = -1;
    }
}
