import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Report } from 'app/models/Report';
import { AccessTokenReq } from 'app/models/AccessTokenReq';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class ReportService {
    private apiPath = 'api/Report';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets Report by Source Id
     */
    getReportBySourceId(id): Observable<any> {
        return this.http.get(`${this.requestUrl}?sourceId=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets list of Reports
     */
    getReportPagination(paginated, page, rowsPerPage): Observable<any> {
        return this.http.get(`${this.requestUrl}?paginated=${paginated}
            &page=${page}&rowsPerPage=${rowsPerPage}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    
    /**
     * Adds or updates a Report
     * @param report 
     */
    saveReport(report: Report): Observable<any> {
        return this.http.put<Report>(this.requestUrl, report, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Retrieves reports from Source Location, such as Power BI Workspace from an Azure App
     * @param report 
     */
     retrieveReportsFromSourceLocation(id): Observable<any> {
        return this.http.get<Report>(`${this.requestUrl}?sourceId=${id}&fromSourceLocation=true`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a report for a source (In particular just Power BI currently)
     * @param source 
     */
    putReportsDataFromSource(reports): Observable<any> {
        return this.http.put(`${this.requestUrl}?isList=true`, reports, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Adds a report for a source without access token (In particular just Power BI currently)
     * @param source 
     */
    putReportFromSource(accessTokenReq: AccessTokenReq, alternateSource: boolean): Observable<any> {
        return this.http.put<AccessTokenReq>(`${this.requestUrl}?alternateSource=${alternateSource}`, accessTokenReq, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Deletes Report by Id
     * @param id 
     */
    deleteReport(id) {
        return this.http.delete(`${this.requestUrl}?id=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}