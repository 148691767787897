import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { AddUserModalComponent } from 'app/admin/users/add-user-modal/add-user-modal.component';
import { Auth0User } from 'app/models/Auth0User';
import { Role } from 'app/models/role';
import { User } from 'app/models/user';
import { AuthService } from '../services/auth.service';
import { Auth0ManagementApiService } from '../services/auth0-management-api.service';
import { UserService } from '../services/user.service';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [UserService,
    Auth0ManagementApiService
  ]
})
export class HeaderComponent {

  @Input() toggleLogotype = true;
  public toggleVar: boolean = true;
  public name: string;
  public admin: boolean = true;
  public user: Auth0User;
  public roles: Role[] = new Array<Role>();
  public routerName: string;
  public nameOfPage: string;


  constructor(private authService: AuthService,
    private auth0ManagementApiService: Auth0ManagementApiService,
    public auth: AuthService, private dialog: MatDialog, private router: Router) {
      router.events.subscribe( event => {
        if(event instanceof NavigationEnd ) {
          var temp = event.url.substring(1);
          if(temp){
            var index = temp.indexOf("/");
            if(index && index > 0){
              temp = temp.substring(0, index);
            }
            if(temp == "schema"){
              temp = "Target";
            }
            if(temp.indexOf("code") > 0){
              temp = "Dashboard";
            }
            var tempSplit = temp.split("-");
            tempSplit.forEach( (word, index) => {
              tempSplit[index] = word[0].toUpperCase() + word.substring(1).toLowerCase();
            })
            temp = tempSplit.join(" ");
          } else {
            temp = "Dashboard";
          }
          this.nameOfPage = temp;

          if(this.user.name == "")
            this.loadUser(); 
        }
      })
    }

  ngOnInit() {
    this.user = new Auth0User;
    this.user.name = "";
  }

  public openEditUserDialog(roles?: Array<Role>, user?: Array<User>) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container"; 
    dialogConfig.data = { Roles: roles, User: user}; 
    this.dialog.open(AddUserModalComponent, dialogConfig)
      .afterClosed().subscribe(result => {
        if (result) {
          this.user = result;
        }
      });


  }

  private loadUser() {
    this.authService.getUserJwt()
            .subscribe(jwt => {
              if(jwt != undefined){
                this.user.user_id = jwt["https://metaprism.com/user_id"];
                this.auth0ManagementApiService.getUserDetails(this.user.user_id)
                .subscribe(user => {
                    this.user = user;
                });
              }
            });

}

  public logOut() {
    this.name = undefined;
    this.authService.logout();
  }

  public getName(): string {
    return this.name ? this.name : this.user.name;
  }




}
