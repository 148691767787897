/**
 * Target Schema
 * @author Collin Atkins / 10.11.17 / New page routed from schemas containing detailed info on the target schema,
 * table of target tables belonging to schema, and buttons for adding, importing, editing, and deleting
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { IColumnData, IInputType } from 'app/models/dialog-interface';
import { Schema } from 'app/models/schema';
import { AuthService } from 'app/services/auth.service';
import { IColumns, ITableDetails } from 'app/models/table-interfaces';
import { TargetTable } from 'app/models/target-table';
import { RouteBreadcrumbService } from 'app/services/route-breadcrumb.service';
import { SchemaService } from 'app/services/schema.service';
import { TableService } from 'app/services/target-table.service';
import { AddComponent } from 'app/shared/components/add/add.component';
import { EditComponent } from 'app/shared/components/edit/edit.component';
import { EditSchemaDialogComponent } from 'app/target/target-schema/edit-schema-dialog/edit-schema-dialog.component';
import { ImportTablesDialogComponent } from 'app/target/target-schema/import-tables-dialog/import-tables-dialog.component';
import { EditTableDialogComponent } from 'app/target/target-table/edit-table-dialog/edit-table-dialog.component';
import { Subject } from 'rxjs';
import { SnackBarHandler } from 'utilities/snackbar';

@Component({
    selector: 'dp-target-schema',
    templateUrl: './target-schema.component.html',
    providers: [SchemaService, TableService, SnackBarHandler],
    'styleUrls': ['./target-schema.component.scss']
})

export class TargetSchemaComponent implements OnInit {

    @ViewChild(DataTableDirective)
    private dtElement: DataTableDirective;
    private dtTrigger: Subject<TargetTable[]> = new Subject();
    private dtOptions: DataTables.Settings = {};
    tableDetails: ITableDetails;
    serviceData:any;
    ref:boolean;
    enableImport:boolean = false;
    private Schema: Schema = new Schema();
    private TargetTables: TargetTable[] = new Array<TargetTable>();
    private destroy = new Subject<void>();
    columns: IColumns[] = [
      {
        columnDef: "Id",
        header: "ID",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.Id}`
      },
      {
        columnDef: "DatabaseName",
        header: "Table Name",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.DatabaseName}`
      },
      {
        columnDef: "TablePrefix",
        header: "Table Prefix",
        sortable: true,
        filterable: true,
        cell: (element: any) => `${element.TablePrefix}`
      }

    ];


    constructor(
      private schemaService: SchemaService,
      private tableService: TableService,
      private snackBarHandler: SnackBarHandler,
      private router: Router,
      private authService: AuthService,
      private route: ActivatedRoute,
      private dialog: MatDialog,
      private routeBreadcrumbService: RouteBreadcrumbService,
      private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon('importTables', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/pictures/Import_Tables.svg'));
        this.tableDetails = { routing: { routingPath: [`/schema/${this.Schema.Id}/table/`] }, edit: false };
        this.serviceData = this.authService.getFakeSubscribe([]);
        this.authService.authorizeSection().subscribe(permissions =>{
          if(permissions.view){
            this.route.params.subscribe(params => {
              this.Schema.Id = params['id'];
              this.serviceData = this.tableService.getTablesbySchema(this.Schema);
            });

            var details = { routing: { routingPath: [`/schema/${this.Schema.Id}/table/`] }, edit: permissions.update }
            if(permissions.add){
              details['add'] = "Target Table";
              this.enableImport = true;
            }
            if(permissions.delete)
              details['delete'] = "DatabaseName";

            this.tableDetails = details;
          }
          else {
            this.router.navigate(['/']);
          }
        });
        this.ref = false;
      }

    ngOnInit() {
        this.dtOptions = {
            columnDefs: [{
                targets: [3,4],
                orderable: false
            }]
        };

        this.getSchema();
        this.getTablesBySchema();
    }

    ngOnDestroy(){
      this.destroy.next;
      this.destroy.complete;
    }


    /**
     * This function handles the creation of the add dialog as well as
     * handling the addition of target schemas to the database
     */
    private addModal() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      /**
       * The IInputTypes allow the dialog to have different types of input
       */
      let schemaName: IInputType = {type: 'display'};
      let columnData: IColumnData[] = [
        {
          id: "SchemaId",
          label: -1,
          value: this.Schema.Id,
          validators: []
        },
        {
          id: "SchemaName",
          label: "Schema Name",
          value: this.Schema.SchemaName,
          validators: [],
          inputType: schemaName
        },
        {
          id: "DatabaseName",
          label: "Table Name",
          value: "",
          validators: ["required"]
        },
        {
          id: "TablePrefix",
          label: "Prefix",
          value: "",
          validators: ["required"]
        }
      ];
      let data = {
        description: "Add Target Table",
        numCols: 1,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(AddComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            result.DBSchemaName = this.Schema.DBSchemaName;
            this.tableService.saveTable(result).subscribe(savedTable => {
              if (savedTable) {
                this.snackBarHandler.open(`Success: Added '${result.DatabaseName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Target table not added`, 'failure'));
        }
      });
    }


    private editModal({Id, SchemaName, DatabaseName, TablePrefix}){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "custom-dialog-container";
      /**
       * The IInputTypes allow the dialog to have different types of input
       */
      let readOnly: IInputType = {type: 'display'}
      let columnData: IColumnData[] = [
        {
          id: "Id",
          label: "Target Table ID",
          value: Id,
          validators: [],
          inputType: readOnly
        },
        {
          id: "SchemaId",
          label: -1,
          value: this.Schema.Id,
          validators: []
        },
        {
          id: "SchemaName",
          label: "Schema Name",
          value: SchemaName,
          validators: ["required"],
          inputType: readOnly
        },
        {
          id: "DatabaseName",
          label: "Table Name",
          value: DatabaseName,
          validators: ["required"]
        },
        {
          id: "TablePrefix",
          label: "Prefix",
          value: TablePrefix,
          validators: []
        }
      ];
      let data = {
        description: "Edit " + SchemaName,
        numCols: 2,
        columnData: columnData
      };
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(EditComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            result.DBSchemaName = this.Schema.DBSchemaName;
            this.tableService.saveTable(result).subscribe(savedTable => {
              if (savedTable) {
                this.snackBarHandler.open(`Success: Updated '${result.DatabaseName}'`, 'success');
                this.ref = true;
              }
            }, err => this.snackBarHandler.open(`Failure: Target Table not added`, 'failure'));
        }
      });
    }

    private deleteRows(rows: TargetTable[]){
      for (let row of rows) {
        this.tableService.deleteTable(Number(row)).subscribe(result => {
          this.snackBarHandler.open(`Success: Selected item(s) have been deleted`, 'success');
          this.ref = true;
        }, err => this.snackBarHandler.open(`Failure: Selected item(s) not deleted`, 'failure'));
      }
    }

    resetRefresh(value: boolean) {
      this.ref = value;
    }

    /**
     * Calls tableService to delete Schema
     * @param schema - schema to be deleted
     * @author Collin Atkins / 10.11.17
     */
    private deleteSchema() {
        const deletedSchema = this.Schema;
        this.schemaService.deleteSchema(this.Schema)
            .subscribe(() => {
                this.snackBarHandler.open(deletedSchema.SchemaName + ' was deleted.', 'success');
                this.router.navigate(['/schema/']);
            }, err => {
                console.log(err);
                this.snackBarHandler.open('Schema failed to be deleted.', 'failure');
            });
    }

    /**
     * Calls tableService to delete given table by id then gets tables by schema name
     * @param table
     * @author Collin Atkins / 10.11.17
     */
    private deleteTable(table: TargetTable) {
        const deletedTable = table;
        this.tableService.deleteTable(table.Id)
            .subscribe(() => {
                this.getTablesBySchema();
                this.snackBarHandler.open(deletedTable.DatabaseName + ' was deleted.', 'success');
            }, err => {
                console.log(err);
                this.snackBarHandler.open('Table failed to be deleted.', 'failure');
            });
    }

    /**
     * @author Collin Atkins / 10.11.17
     */
    private getSchema() {
        this.schemaService.getSchemaById(this.Schema.Id)
            .subscribe(schema => {
                this.Schema = schema;
            }, err => console.log(err));
    }

    /**
     * @author Collin Atkins / 10.11.17
     */
    private getTablesBySchema() {
        this.rerender();

        this.resetRefresh(true);
        this.tableService.getTablesbySchema(this.Schema)
            .subscribe(tables => {
                this.TargetTables = tables;
                this.dtTrigger.next();
            }, err => console.log(err));
    }

    /**
     * @author Collin Atkins / 12.15.17
     */
    private openImportTablesDialog() {
        this.dialog.open(ImportTablesDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: { Schema: this.Schema },
        })
            .afterClosed().subscribe(result => {
                if (result) {
                  this.snackBarHandler.open('Starting Table Import', 'success');
                  this.importTables(result.trueImport, result.tablePrefix);
                }
            });
    }

    /**
     * Calls schema service to import tables
     * @param importedTables tables to import
     */
    private importTables(importedTables, tablePrefix) {
      importedTables.schemaName = this.Schema.SchemaName;
      importedTables.schemaId = this.Schema.Id;
      importedTables.DBType = this.Schema.DBType;
      importedTables.importType = "target";
      importedTables.TablePrefix = tablePrefix;

      this.schemaService.importTables(importedTables).subscribe(data => {
          this.getTablesBySchema();
          this.snackBarHandler.open('Table Import completed', 'success');
      }, err => {
        console.log(err);
        this.snackBarHandler.open('Table Import failed' + err, 'failure');
      });
    }

    /**
     * Redirects to the table route
     * @param table
     * @author Collin Atkins / 9.29.17 / Added qparam to reduce call to get name
     */
    private gotoTable(table: TargetTable) {
        this.router.navigate(['/schema/table/' + table.Id]);
    }

    /**
     * @author Collin Atkins / 10.11.17
     */
    private rerender() {
        if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        }
    }

}
