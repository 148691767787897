import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteBreadcrumbService {

  private breadcrumbTitlesState = new BehaviorSubject<string[]>([]);
  private breadcrumbUrlsState = new BehaviorSubject<string[]>([]);



  breadcrumbTitles: Observable<string[]>;
  breadcrumbUrls: Observable<string[]>;

  constructor() {
    this.breadcrumbTitles = this.breadcrumbTitlesState.asObservable();
    this.breadcrumbUrls = this.breadcrumbUrlsState.asObservable();
  }

  updateBreadcrumbTitlesState(newTitles: string[], newUrls: string[]) {
    this.breadcrumbTitlesState.next(newTitles);
    this.breadcrumbUrlsState.next(newUrls);
  }




}
