
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class GeneratedJobsService {
    private apiPath = 'api/SSISDownload';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all Users
     */
    getJobs(): Observable<any> {
        return this.http.get(this.requestUrl, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    downloadJob(filepath): Observable<any> {
        return this.http.get(`${this.requestUrl}?filepath=${filepath}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
