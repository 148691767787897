/**
 * Table Mapping Modal - Add or Edit a Table Mapping
 * @author Collin Atkins
 */
import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { SnackBarHandler } from 'utilities/snackbar';
import { TargetTable } from 'app/models/target-table';
import { SourceSystemService } from 'app/services/source-system.service';
import { SourceTableService } from 'app/services/source-table.service';
import { TableMappingService } from 'app/services/table-mapping.service';
import { ColumnLineageService } from 'app/services/column-lineage.service';
import { TableMapping } from 'app/models/table-mapping';
import { SourceSystem } from 'app/models/source-system';
import { SourceTable } from 'app/models/source-table';


@Component({
    selector: 'dp-table-mapping-modal',
    templateUrl: './table-mapping-modal.component.html',
    providers: [SnackBarHandler, SourceSystemService, TableMappingService, ColumnLineageService],
    styleUrls: ['./table-mapping-modal.component.scss']
})
export class TableMappingModalComponent implements OnInit {

    @Input() TargetTable: TargetTable = new TargetTable();
    @Output() TableMappingChange = new EventEmitter();

    private TableMapping: TableMapping = new TableMapping();
    private SourceSystems: SourceSystem[] = new Array<SourceSystem>();
    private SourceTables: SourceTable[] = new Array<SourceTable>();

    private srcTable : SourceTable = new SourceTable();

    constructor(private snackbar: SnackBarHandler,
        private sourceSystemService: SourceSystemService,
        private tableMappingService: TableMappingService,
        private sourceTableService:  SourceTableService,
        private columnLineageService: ColumnLineageService) { }

    ngOnInit() {

            this.srcTable.Id = -1;
    }

    /**
     * Calls service to get all Source Systems
     * @author Collin Atkins / 11.10.11
     */
    private getSourceSystems() {
        this.sourceSystemService.getAllSourceSystems(true)
            .subscribe(sourceSystems => {
                this.SourceSystems = sourceSystems;
            }, err => console.log(err));
    }

/**
     * Calls service to save the table mapping the emits the saved mapping
     * @author Collin Atkins / 11.10.11
     */
    private saveCopyMapping() {
        //this.saveTableMapping();
        this.columnLineageService.CopyMapping(this.TableMapping.TargetTableId, this.srcTable.Id, this.TableMapping.Id)
            .subscribe(tableMapping => {
                this.TableMappingChange.emit(this.TableMapping);
                this.snackbar.open(`${this.TableMapping.Name} was saved.`, 'success');
            }, err => {
                console.log(err);
                this.snackbar.open(`Copy Mapping failed to be saved.`, 'failure');
            });
    }



    /**
     * Calls service to save the table mapping the emits the saved mapping
     * @author Collin Atkins / 11.10.11
     */
    private saveTableMapping() {
        this.tableMappingService.saveTableMapping(this.TableMapping)
            .subscribe(tableMapping => {
                this.TableMapping = tableMapping;
            //    if (this.srcTable.Id > 0) {
            //        this.saveCopyMapping();
            //  }
                this.TableMappingChange.emit(this.TableMapping);
                this.snackbar.open(`${this.TableMapping.Name} was saved.`, 'success');
            }, err => {
                console.log(err);
                this.snackbar.open(`Table Mapping failed to be saved.`, 'failure');
            });
    }

    private selectSourceSystem(sourceSystem: SourceSystem) {
        if (sourceSystem && sourceSystem.Id >= 0) {
            this.getSourceTables(sourceSystem.Id);
        }
    }

    private selectSourceTable(sourceTable: SourceTable){
        this.srcTable = sourceTable;
    }


/**
     * Loads SourceTables by SourceSystemId and stores them in the SourceTableService to be accessed later
     * @author Shawn Carter
     */
    private getSourceTables(id: number) {
        this.sourceTableService.getSourceTablesBySourceSystem(id)
            .subscribe(sourceTables => {
                this.SourceTables = sourceTables;
            }, err => {
                console.log(err);
                this.snackbar.open('Failed to find any Source Tables.', 'failure');
            });
    }


    /**
     * Modal Variables
     */
    visible: boolean = false;
    private visibleAnimate: boolean = false;

    /**
     * Modal Functions
     */
    show(tableMapping?: TableMapping) {
        this.TableMapping = tableMapping ? Object.assign({}, tableMapping) : new TableMapping(this.TargetTable.Id);
        this.getSourceSystems();
        this.TableMapping.SourceSystemId != null && this.TableMapping.SourceSystemId > 0 ? this.getSourceTables(this.TableMapping.SourceSystemId) : null;
        this.visible = true;
        this.SourceTables = null;
        this.srcTable.Id =-1;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    hide() {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
    }

    onContainerClicked(event: MouseEvent) {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }

}
