import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SourceSystem } from 'app/models/source-system';
import { SourceSystemService } from 'app/services/source-system.service';
import { BaseDialogComponent } from 'app/shared/components/base/base-dialog/base-dialog.component';
import { SnackBarHandler } from 'utilities/snackbar';

@Component({
  selector: 'dp-source-systems-edit',
  templateUrl: './source-systems-edit.component.html',
  styleUrls: ['./source-systems-edit.component.scss'],
  providers: [SourceSystemService]
})
export class SourceSystemsEditComponent extends BaseDialogComponent {
  data: any;
  constructor(dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) data: any, private snackBarHandler: SnackBarHandler, private sourceService: SourceSystemService){
    super(dialogRef, data);
    this.data = data;
  }
    /**
* Tests Connection
* @author John Crabill
* @param system
*/
testConnection() {
  let system: SourceSystem = new SourceSystem();
  Object.keys(this.form.controls).forEach(key => {
    system[key] = this.form.controls[key].value;
  });
  this.sourceService.testConnection(system)
    .subscribe(conn => {
      if (conn == 'Success') {
        this.snackBarHandler.open('Connection Found', 'success');
      }
      else {
        this.snackBarHandler.open('Connection Failed', 'failure');
      }
    });
}
}
