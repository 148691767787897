export class StringHandler {
    /**
     * Removes underscores and replaces with spaces and changes to alphabetized case
     * @param str 
     */
    friendifyString(str: string): string {
        while (str.search('_') >= 0) {
            str = str.replace('_', ' ');
        }
        str = str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
        return str;
    }

    /**
     * Replaces spaces with underscores and capitilizes all
     * @param str 
     */
    uglifyString(str: string): string {
        while (str.search(' ') >= 0) {
            str = str.replace(' ', '_');
        }
        str = str.toUpperCase();
        return str;
    }
}