
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandler } from 'utilities/error';
import { DataHandler } from 'utilities/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable()
export class TargetColumnService {
    private apiPath = 'api/EDWColumn';
    private requestUrl = environment.serviceUrl + this.apiPath;

    constructor(private http: HttpClient) { }

    /**
     * Gets all the targetColumns of a given TargetTable
     * @param tableId target table id
     * @author Nash Lindley
     */
    getTargetColumnsByTargetTable(tableId): Observable<any> {
        return this.http.get(this.requestUrl + '?tableId=' + tableId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the targetColumns with column lineages of a given TargetTable and sourceSystem
     * @param tableId target table id
     * @param systemId source system id
     * @author Nash Lindley
     */
    getTargetColumnsByTargetAndSystem(tableId, systemId): Observable<any> {
        return this.http.get(this.requestUrl + '?tableId=' + tableId + '&systemId=' + systemId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getDistinctAllTables(): Observable<any> {
        return this.http.get(this.requestUrl + '?distinct=' + true, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * @param schemaId
     * @author Collin Atkins / 10.5.17 /
     */
    getDistincTablesBySchemaName(schemaId: number): Observable<any> {
        return this.http.get(this.requestUrl + '?schemaName=""&schemaId=' + schemaId, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    getColumnById(columnId): Observable<any> {
        return this.http.get(this.requestUrl + '?columnId=' + columnId + '&justName=' + false, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the target columns of a given target table by id and filters the lineages
     * @param tableId target table id
     * @author Nash Lindley
     */
    getTargetColumnsByTargetTableFilterLineages(tableId): Observable<any> {
        return this.http.get(this.requestUrl + '?tableId=' + tableId + '&filter=LINEAGES', httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the target columns of a given target table by id and filters the columns already linked
     * @param tableId target table id
     * @author Collin Atkins / 10.5.17 /
     */
    getTargetColumnsByTargetTableFilterLinked(tableId): Observable<any> {
        return this.http.get(this.requestUrl + '?tableId=' + tableId + '&filter=LINKED', httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the target columns with matching column names
     * @param columnName name of columns to retrieve
     * @author Nash Lindley
     */
    getTargetColumnsByTargetColumn(columnName): Observable<any> {
        return this.http.get(this.requestUrl + '?columnName=' + columnName, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Gets all the target columns with matching column names, then filters lineages
     * @param columnName name of columns to retrieve
     * @author Nash Lindley
     */
    getTargetColumnsByTargetColumnFilterLineages(columnName): Observable<any> {
        return this.http.get(this.requestUrl + '?columnName=' + columnName + '&filter=LINEAGES', httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    // getTargetColumnsBySourceSystemAndTable(sourceSystem: SourceSystem, targetTable: TargetTable): Observable<any> {
    //     return this.http.get(this.apiPath + '?sourceSystem=' + JSON.stringify(sourceSystem) + '&targetTable=' + JSON.stringify(targetTable))
    //         .map(new DataHandler().extractData)
    //         .catch(new ErrorHandler().handleError);
    // }

    /**
     * Saves given target column
     * @param column: TargetColumn
     * @author Nash Lindley
     */
    saveTargetColumn(column) {
        return this.http.put(this.requestUrl, column, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

    /**
     * Deletes column
     * @param id: TargetColumn Id
     * @author John Crabill
     */
    deleteColumn(id) {
        return this.http.delete(`${this.requestUrl}?Id=${id}`, httpOptions).pipe(
            catchError(new ErrorHandler().handleError));
    }

}
